import React from "react";
import { Link } from "react-router-dom";
import { Card, Elevation, Callout, Icon, Button } from "@blueprintjs/core";

const AdCreatedSuccessfully = props => (
  <>
    <div
      className={
        "w-full md:w-2/5 m-auto mt-20 sm:mt-8 sm:pt-2 md:pt-2 lg:pt-0 xl:pt-0"
      }
    >
      <div className={"p-12"}>
        <Card elevation={Elevation.ONE} className={"marginBottom-1em"}>
          <Callout>
            <div className={"p-12"}>
              <p className={"text-center text-green mb-8"}>
                <Icon iconSize={"48"} icon={"tick-circle"} />
              </p>
              <h3 className={"text-center text-green-darker mb-0"}>
                Campaña de posicionamiento creada exitosamente
              </h3>
            </div>
          </Callout>
          <div className={"block md:flex"}>
            <div className={"w-full md:w-1/2 md:mr-2"}>
              <Link to="/adForm">
                <Button
                  className={"BtnLCPrimary marginTop-05em"}
                  fill={true}
                  
                >
                  Crear otra campaña
                </Button>
              </Link>
            </div>
            <div className={"w-full md:w-1/2 md:ml-2"}>
              <Link to="wall">
                <Button
                  className={"BtnLCPrimary marginTop-05em"}
                  fill={true}
                  
                >
                  Ir al mural
                </Button>
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </>
);

export default AdCreatedSuccessfully;
