import React, { useEffect, useState } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import { Alignment, Button, Callout, Card, Elevation } from '@blueprintjs/core';
import ArticlesOfInterest from './../components/ArticlesOfInterest';
import axios from 'axios';
import { getPublicationsSaved } from './../store/actions/index';
import Comments from './../components/Comments';
import { GoogleApiWrapper } from 'google-maps-react';
import { config } from './../system/Config';
import { getHeader } from './../system/Sesion';
import Report from './../components//Report';
import DetailProductSkeleton from './../components/DetailProductSkeleton';
import OwnerInfo from './detailProduct/OwnerInfo';
import ProductDetailMainSection from './detailProduct/ProductDetailMainSection';
import { authAxios } from '../utility/axios';
import history from '../utility/history';
import './../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './detailProduct/DetailProduct.scss';

const DetailProduct = ({ match, location }) => {

  const dispatch = useDispatch;
  const { userId, publicationsSavedLoaded, publicationsSaved, isAuthenticated, otherInfoUser, suscription } = useSelector(({ auth }) => auth);
  const [userDetailPanel, setUserDetailPanel] = useState(false);
  const [pub, setPub] = useState(null);
  const [publicationSaved, setPublicationSaved] = useState(false); // el usuario le ha dado like a esta publicacion?
  const [isOpenReport, setIsOpenReport] = useState(false); // esta abierto el modal para Denunciar?
  const [editorState, setEditorState] = useState('');
  const [pubInTransaction, setPubInTransaction] = useState(false); // el usuario tiene esta publicacion en transaccion?
  const [chatIdTransaction, setChatIdTransaction] = useState(null); // chat asociado a la transaccion pendiente

  useEffect(() => {
    setPub(null);
    getPub(match.params.id);
  }, [location])

  useEffect(() => {
    getPub(match.params.id);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [])

  const fetchGetTransactionForUser = (idUser, idPub) => {
    authAxios
      .get(`${config.URL_API}/transaction_for_user?user_id=${idUser}&publication_id=${idPub}`)
      .then((response) => { setPubInTransaction(response.data) })
      .catch((err) => console.log(err));
  }

  const handlerVisibiltyUserDetailPanel = () => setUserDetailPanel(userDetailPanel);

  const fetchAPIGet = (id) =>
    axios.get(
      `${config.URL_API}/publications/${id}?latitude=${localStorage.getItem('latitude',
      )}&longitude=${localStorage.getItem('longitude',
      )}&user_id=${localStorage.getItem('userId')}`,
      { headers: getHeader() },
    );

  const getPub = async (id) => {
    try {
      fetchGetTransactionForUser(localStorage.getItem('userId'), id);
      const response = await fetchAPIGet(id);
      const getChatId = (chat) => chat ? chat.id : null;
      if (response.data.publication.publication_status !== 'Activo') history.push('/home');
      const transactions_asociated_user = response.data.transactions_asociated_user
        ? getChatId(response.data.transactions_asociated_user.chat) : null;
      setPub(response.data.publication);
      setChatIdTransaction(transactions_asociated_user);
      setEditorState(response.data.publication.editor_web);
      verifyIfPublicationSaved();
    } catch (err) {
      console.log(err);
    }
  };

  const like = async () => {
    try {
      setPublicationSaved(true);
      const dataAPI = { user_id: userId, publication_id: pub.id };
      await axios.post(`${config.URL_API}/publications_saveds`, dataAPI, { headers: getHeader() });
      dispatch(getPublicationsSaved(userId))
    } catch (err) {
      setPublicationSaved(false);
    }
  };

  const dislike = async () => {
    try {
      setPublicationSaved(false);
      const p = publicationsSaved.find((item) => item.publication_id === pub.id);
      await axios.delete(`${config.URL_API}/publications_saveds/${p.id}`);
      dispatch(getPublicationsSaved(userId))
    } catch (err) {
      setPublicationSaved(true);
    }
  };

  const verifyIfPublicationSaved = () => {
    if (publicationsSavedLoaded) {
      const saved = publicationsSaved?.find(({ publication_id }) => publication_id === pub.id);
      if (saved) setPublicationSaved(true);
    } else {
      setTimeout(() => { verifyIfPublicationSaved() }, 1000);
    }
  };

  const switchOpenReport = () => setIsOpenReport(!isOpenReport);
  const toChange = (link) => history.push(link);

  if (pub === null) return <DetailProductSkeleton />;
  if (!pub.owner.active) return <Redirect to="/user/inactive/tmp" />;

  return (
    <>
      <div className="detailProduct pt-32 pb-20 sm:pt-12 md:pt-16 lg:pt-16 xl:pt-16">
        <div className="customWrapper">
          <Row>
            <Col xs={12} md={7} lg={8} xl={9}>
              <ProductDetailMainSection
                pubInTransaction={pubInTransaction}
                pub={pub}
                toChange={toChange}
                isAuthenticated={isAuthenticated}
                userId={userId}
                suscription={suscription}
                handlerVisibiltyUserDetailPanel={
                  handlerVisibiltyUserDetailPanel
                }
              />
              {pub.editor_web ? (
                <Card elevation={Elevation.ONE} className="marginTop-1em">
                  <h3>Descripción</h3>
                  <p className='mt-4 mb-4'>
                    {editorState}
                  </p>
                </Card>
              ) : (
                <Card elevation={Elevation.ONE} className="marginTop-1em">
                  <Callout className="noActivityDisplay marginBottom-1em">
                    <h5 className="bp3-form-helper-text">
                      Artículo sin descripción
                    </h5>
                  </Callout>
                </Card>
              )
              }
              <Card className="marginTop-1em">
                <h3>Preguntas</h3>
                <Comments
                  isVisible={true}
                  pub={pub}
                  setCommentsTrue={{ comments: true }}
                />
              </Card>
            </Col>
            <Col className='profile-interest' xs={12} sm={12} md={5} lg={4} xl={3}>
              <Card
                elevation={Elevation.ONE}
                className="mt-4 sm:mt-4 md:mt-0 lg:mt-0 xl:mt-0"
              >
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="infoUserDetailPublication">
                      <Card
                        elevation={Elevation.ONE}
                        className="mt-4 sm:mt-4 md:mt-0 lg:mt-0 xl:mt-0"
                      >
                        <div className="infoUserDetailPublicationAvatar">
                          {pub.owner ? (
                            <OwnerInfo user={pub.owner} />
                          ) : null}
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              {isAuthenticated ? (
                                <div className="marginTop-1em">
                                  <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                      {publicationsSavedLoaded ? (
                                        publicationSaved ? (
                                          <Button
                                            className="marginBottom-05em"
                                            onClick={dislike}
                                            fill={true}
                                            minimal={true}
                                            icon="heart"
                                            text={
                                              'Eliminar de los Guardados'
                                            }
                                          />
                                        ) : (
                                          <Button
                                            className="marginBottom-05em"
                                            onClick={like}
                                            fill={true}
                                            icon="bookmark"
                                            minimal={true}
                                            text={'Guardar Publicación'}
                                          />
                                        )
                                      ) : null}
                                    </Col>
                                    {pubInTransaction === "update" && (
                                      <Col xs={12} sm={12} md={12} lg={12}>
                                        <Link
                                          to={`/chats/${chatIdTransaction}`}
                                        >
                                          <Button
                                            className="marginBottom-05em"
                                            minimal={true}
                                            fill={true}
                                            icon="chat"
                                            text={'Enviar Mensaje'}
                                          />
                                        </Link>
                                      </Col>
                                    )}
                                    {pub.owner.id !== userId &&
                                      < Col xs={12} sm={12} md={12} lg={12}>
                                        <Button
                                          className="marginBottom-1em"
                                          minimal={true}
                                          fill={true}
                                          icon="ban-circle"
                                          intent="danger"
                                          text={'Reportar Publicación'}
                                          onClick={switchOpenReport}
                                        />
                                      </Col>
                                    }
                                  </Row>
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </div>
                      </Card>
                      <div className="pt-5">
                        <h3>Intereses de cambio</h3>
                        <Callout className="marginTop-1em">
                          <h4 className="marginBottom-05em pb-2">
                            Artículos de interés
                          </h4>
                          <ArticlesOfInterest articles={pub.article_tags} />
                        </Callout>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Report
        isOpen={isOpenReport}
        onClose={switchOpenReport}
        owner={`${pub.owner.name} ${pub.owner.last_name}`}
        name={pub.name}
        id={pub.id}
      />
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: DetailProductSkeleton,
})(withRouter(DetailProduct));
