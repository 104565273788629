import React, { useState } from "react";
import { initialValue } from "../utilityFunctions";
import "./userStatsInfo/AvatarUser.scss";
import BlurImage from "./blurImage/BlurImage";

const AvatarUser = ({ user, background, skeleton }) => {
  const showAvatar = user?.avatar && user?.avatar !== 'empty';
  const skeletonClass = skeleton ? 'bp3-skeleton' : '';

  const userAvatar = (
    <img className='w-12 h-12 avatar' src={user.avatar} alt='avatarImage' />
  );

  // TODO: FIX BACK LOGIC WHEN THE USER REMOVE AVATAR IMAGE
  return (
    <div>
      {showAvatar ? (
        background ? (
          <div className={"blur-avatar"}>
            <BlurImage image={user.avatar} title='user avatar' />
          </div>
        ) : (
          <div className={`AvatarImage ${skeletonClass}`}>
            <div className='img_box'>
              {userAvatar}
            </div>
          </div>
        )
      ) : (
        <div className={`AvatarInitials ${skeletonClass}`}>
          <p className='text-xs'>
            <span>{initialValue(user?.name)}</span>
            <span>{initialValue(user?.last_name)}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default AvatarUser;
