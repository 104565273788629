import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RefineDecimals } from './../../../../utilityFunctions';
import './DetailBox.scss';
import { Button } from '@blueprintjs/core';

const DetailBox = ({
  title,
  plans,
  price,
  totalImpressions,
  ImpressionsColor,
  periodBackground,
}) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const titleType = {
    basico: 'Básico',
    medio: 'Medio',
    avanzado: 'Avanzado',
  };

  const detailPlans = (
    <p className="plans-text">
      {titleType[title]}
    </p>
  );

  const detailPrice = (
    <p>
      <span className={`${title}--text price-currency`}>$</span>
      <span className={`${title}--text price-text__big`}>{price}</span>
      <span className={`${title}--text price-currency price-decimals`}>,00</span>
    </p>
  );

  const detailImpressions = (
    <p>
      <span
        className={`${ImpressionsColor}-text bold`}
      >
        +{totalImpressions+' Impresiones'}
      </span>
    </p>
  );

  const userCreditButton = (
    <Button
      className="credit-button"
      text={
        <p>
          Usar <span className="yellow-text">créditos</span>
        </p>
      }
      onClick=""
    />
  );

  return (
    <div className={`box__container ${title}--shadow`}> 
      <div className="title"></div>
      <div className={`promotion_months ${periodBackground}_gradient`}>{detailPlans}</div>
      <div className="box-price">
        <div className="price">{detailPrice}</div>
        <div className="total-impressions">
          {detailImpressions}
        </div>
      </div>

    </div>
  );
};

export default DetailBox;
