import axios from 'axios';

import { config } from '../../system/Config';
import { getHeader } from '../../system/Sesion';

export const createMessage = (chatId, data) => axios.post(
  `${config.URL_API}/chats/${chatId}/messages`,
  data,
  { headers: getHeader() },
);

export const updateTransaction = (transactionId, data) => axios.put(
  `${config.URL_API}/v1/transactions/${transactionId}`,
  data,
  { headers: getHeader() },
);

export const rankUser = (data) => {
  return axios.put(`${config.URL_API}/ranke_user`, data, { headers: getHeader() });
};
