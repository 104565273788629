import React, { useContext } from 'react';
import { Checkbox, Divider, Icon } from '@blueprintjs/core';

import Card from '../../../../components/card/Card';
import BlurImage from '../../../../components/blurImage';
import { ChangeOperationContext } from '../../providers/ChangeOperationProvider';

import './ChangeProposalItem.scss';

const ChangeProposalItem = ({
  id,
  price,
  title,
  image,
  linkTo,
  status,
  currency,
  isModal,
  defaultChecked,
  addSelectedModalPublications,
  removeSelectedModalPublications,
}) => {
  const { removeSelectedPublication } = useContext(ChangeOperationContext);
  const handleChange = ({ target }) => {
    const publication = { id, price, title, image };
    target.checked ? addSelectedModalPublications(publication) : removeSelectedModalPublications(id);
  };

  return (
    <section className="change-proposal-item">
      <Card className="change-proposal-item__card">
        <div className="change-proposal-item__card--left">
          <div className="change-proposal-item__card--left-img">
            <BlurImage image={image} title={title} linkTo={linkTo} className={'small'} />
          </div>
        </div>
        <div className="change-proposal-item__card--center">
          <p className="change-proposal-item__card--center-title">{title}</p>
          <Divider className="change-proposal-item__card--center-divider" />
          <p className="change-proposal-item__card--center-price">{`${currency} ${price}`}</p>
        </div>
        <div className="change-proposal-item__card--right">
          {
            isModal ?
              <div className="change-proposal-item__card--right-checkbox">
                <Checkbox
                  alignIndicator="right"
                  className="custom-checkbox"
                  defaultChecked={defaultChecked}
                  onChange={handleChange}
                />
              </div>
              :
              <div className="change-proposal-item__card--right-cross">
                <Icon
                  className="custom-checkbox"
                  icon="cross"
                  iconSize={18}
                  onClick={() => removeSelectedPublication(id)}
                />
              </div>
          }
        </div>

      </Card>
      {status === 'waiting' &&
        <div className="change-proposal-item__layer"><p>Publicación de Disponible</p></div>
      }
    </section>
  );
};

export default ChangeProposalItem;