import { productNameUrl, checkWithCurrentUser, datelocambio, textForGrantedCredit } from '../utilityFunctions';
import axios from 'axios';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';
import systemAvatar from './systemNotificationAvatar';

const textNotifications = (notification) => {

  const proformaTitle = (notification) => {
    const status = notification.model.status
    const title = {
      approved: 'Ya puedes disfrutar del servicio que adquiriste',
      rejected: 'Revisa el motivo del rechazo e intenta de nuevo',
      to_be_confirmed: 'Te notificaremos apenas recibamos la confirmación',
    }
    return title[status]
  } 

  const proformaText = (notification) => {
    const status = notification.model.status
    const text = {
      approved: 'Tu pago fue confirmado' ,
      rejected: 'Tu pago fue rechazado' ,
      to_be_confirmed: 'Tu pago está por confirmar' ,
     }
    return text[status]
  } 

  const markNotificationAsRead = () => axios.put(
    `${config.URL_API}/notifications/${notification.id}`,
    { 
      read: true,
      toast_notification: true ,
    },
    { headers: getHeader() },
  );

  const getTypeNotificationProps = { 
    'new_question': {kind:'comment', text: 'Te hizo una pregunta'},
    'new_response_comment': {kind:'comment', text: 'Respodió tu pregunta'},
    'new_change': {kind:'chat', text: 'Te envió una propuesta', tab: 'proposal'},
    'rejected_proposal': {kind:'change', text: 'Rechazó tu propuesta', tab: 3},
    'accepted_proposal': {kind:'chat', text: 'Aceptó tu propuesta', tab: 'in-progress'},
    'exchange_rejected': {kind:'change', text: 'Anuló el cambio', tab: 3},
    'exchange_accepted': {kind:'chat', text: 'Confirmó el cambio y te calificó', tab: 'in-progress'},
    'transaction_completed_by_the_system': {kind:'transaction_completed_by_the_system', text: 'Pasaron 5 días desde que alguien confirmó este cambio', tab: 3},
    'publication_waiting_approval': {kind:'publication_waiting_approval', text: 'Puede que haya algo inusual en su contenido'},
    'approved_publication': {kind:'approved_publication', text: `${textForGrantedCredit(notification.model)}`},
    'invalid_avatar': {kind:'invalid_avatar', text: 'Foto de perfil eliminada por ser inapropiada'},
    'payment_gateway_status': {kind: 'payment_gateway_status', text: `${proformaTitle(notification)}`},
    'default': {kind:'Notificacion', text: 'Notificación no disponible'},
  }
  
  const notificationProps = getTypeNotificationProps[notification.type_notification_id] || getTypeNotificationProps['default']
  
  const getUrl= {
    'comment': `/${productNameUrl(notification.model.publication?.title || notification.model?.title)}/${notification.model.publication?.id}?commentId=${notification.model.id}`,
    'change': `/requests?tab=${notificationProps.tab}&transactionId=${notification.model.id}`,
    'chat': `/chats/${notification.model.id}?tab=${notificationProps.tab}`,
    'transaction_completed_by_the_system': `/requests?tab=${notificationProps.tab}&transactionId=${notification.model.id}`,
    'approved_publication': `/${productNameUrl(notification.model.publication?.title || notification.model?.title)}/${notification.model.id}`,
    'payment_gateway_status': '/notifications_page',
    'invalid_avatar': null,
    'default': null,
  }
  
  const url = getUrl[notificationProps.kind] || getUrl['default']
  const owner = notification.model?.publication?.owner?.id || notification.model?.owner?.id || notification.model?.id
  const isCurrent = checkWithCurrentUser(owner);

  const user = {
    'comment': notification.model.user,
    'change':  isCurrent ? notification.model?.interested_user : notification.model?.publication?.owner ,
    'chat':  isCurrent ? notification.model?.interested_user : notification.model?.publication?.owner ,
    'transaction_completed_by_the_system': {name:'Cambio', last_name:'Exitoso', avatar: systemAvatar},
    'publication_waiting_approval': {name:'Publicación en', last_name:'revisión', avatar: systemAvatar},
    'approved_publication': {name:'Publicación', last_name:'exitosa', avatar: systemAvatar},
    'invalid_avatar': Object.assign({}, notification?.model, { avatar: systemAvatar }),
    'default': {name:'Notificación', last_name:'automática', avatar: systemAvatar},
  }

  const publicationTitle = () => notification.model.publication?.title || notification.model.title

  const notificationUser = user[notificationProps.kind] || user['default']

  return {
    action: markNotificationAsRead,
    read: notification.read,
    userName: `${notificationUser.name} ${notificationUser.last_name}`,
    user: notificationUser,
    title: notificationProps.text,
    footer: publicationTitle() || proformaText(notification),
    date: datelocambio(notification.created_at, 'D MMM YYYY'),
    url: url,
  }
}

export default textNotifications;
