import { getAttr } from './../../utilityFunctions';
import { provincesVenezuela } from 'const';

// funciones auxiliares aqui.

// retorna el valor y menor precio y distancia de la lista de publicaciones
const findMinMax = (data) => {
  let price = { min: parseInt(data[0].price), max: parseInt(data[0].price) };
  let distance = {
    min: parseInt(data[0].distance),
    max: parseInt(data[0].distance),
  };

  for (let i = 1; i < data.length; i++) {
    let pr = parseInt(data[i].price);
    let di = parseInt(data[i].distance);

    price.min = pr < price.min ? pr : price.min;
    price.max = pr > price.max ? pr : price.max;

    distance.min = di < distance.min ? di : distance.min;
    distance.max = di > distance.max ? di : distance.max;
  }
  return { price, distance };
};

const findStates = (data) => {
  let states = [''];

  data.forEach((pub) => {
    let index = states.findIndex((state) => pub.location === state);
    if (index === -1) {
      states.push(pub.location);
    }
  });
  return states;
};

// retorna los valores que necesitan los filtros para trabajar (distance menor, distancia mayor, precio menor, precio mayor, etc)
export const valuesForFilter = (data) => {
  return {
    distanceMax: 100,
    distanceMin: 0,
    priceMin: 0,
    priceMax: 10000000000,
    states: provincesVenezuela,
  };
};

export const orderAttr = (categories) => {
  let attrCategories = [];
  categories.forEach((id) => {
    attrCategories.push(getAttr(id));
  });

  let allAttr = [];
  attrCategories.forEach((value, index) => {
    value.attr.forEach((e, i) => {
      const index = allAttr.findIndex((attr) => attr.attr === value.attr[i]);
      if (index === -1) {
        allAttr.push({ attr: value.attr[i], display: value.display[i] });
      }
    });
  });
  return allAttr;
};
