import { NEW_NOTIFICATION } from '../actions/actionTypes';

const initialState = {
  notification: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_NOTIFICATION:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default reducer;
