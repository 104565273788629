import PropTypes from 'prop-types';

export const titleShape = PropTypes.shape({
  value: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.oneOf(['bold', 'primary', 'accent'])),
});

export const titlePropType = PropTypes.oneOfType([
  titleShape,
  PropTypes.arrayOf(titleShape),
]);

export const withSwiperPropType = PropTypes.bool;

export const ribbonTextPropType = PropTypes.string;

export const collectionItemPropType = PropTypes.shape({
  title: titlePropType,
  greyFilter: PropTypes.bool,
  image: PropTypes.string,
  route: PropTypes.string,
  content: PropTypes.any,
  ribbonText: ribbonTextPropType,
}).isRequired;

export const collectionPropType = PropTypes.arrayOf(collectionItemPropType).isRequired;
