import React, { useEffect, useState } from "react";

import Card from "../../components/card/Card";
import "./UpdateProfile.scss";
import {
  FormGroup,
  InputGroup,
  Button,
  HTMLSelect,
  RadioGroup,
  Radio,
} from "@blueprintjs/core";
import {
  countries,
  countriesCode,
  provincesColombia,
  provincesPeru,
  provincesVenezuela,
  phoneAreaCodes,
} from "const";
import {
  capitalize,
  daysInMonth,
  getDateActual,
  getDayActual,
  getMonthActual,
  getYearActual,
  monthsOptions,
  numbersWithoutPoints,
} from "../../utilityFunctions";
import moment from "moment";
import { v1AuthAxios } from "../../utility/axios";
import { Toast } from "../../utility/toast";
import { customValidations } from "./validations";
import PasswordValidator from "../passwordValidator/PasswordValidator";
import { useDispatch } from "react-redux";
import { checkSesion } from "../../store/actions";

const UpdateProfile = ({ user, setLoading, loading }) => {
  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.last_name);
  const [email, setEmail] = useState(user.email);
  const [dobYear, setDobYear] = useState(user.date_of_birth.split("-")[0]);
  const [dobMonth, setDobMonth] = useState(user.date_of_birth.split("-")[1]);
  const [dobDay, setDobDay] = useState(user.date_of_birth.split("-")[2]);
  const [dobAreaCode, setDobAreaCode] = useState(user.phone?.slice(3, -7));
  const [dobPhone, setDobPhone] = useState(user.phone?.slice(6));
  const [gender, setGender] = useState(user.gender);
  const [country, setCountry] = useState(user.country);
  const [state, setState] = useState(user.state);
  const [city, setCity] = useState(user.city);
  const [stateList, setStateList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [daysList, setDaysList] = useState([]);
  const [handleErrors, setHandleErrors] = useState({});
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [focusPassword, setFocusPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const dispatch = useDispatch();

  const switchFocusPassword = () => {
    setFocusPassword(!focusPassword);
  };

  const updatedUserData = {
    id: user.id,
    name: name,
    last_name: lastName,
    date_of_birth: `${dobYear}-${dobMonth}-${dobDay}`,
    phone: `+58${dobAreaCode}${dobPhone}`,
    gender: gender,
    country: country,
    state: state,
    city: city,
    password: newPassword,
  };

  const validateFields = () => {
    const errors = {};
    Object.entries(updatedUserData).forEach(([key, value]) => {
      if (customValidations[key]) {
        customValidations[key].forEach((validation) => {
          if (validation.condition(value, confirmPassword, passwordValid)) {
            errors[key] = validation.errorMessage;
          }
        });
      }
    });

    Object.keys(errors).length === 0
      ? handleSaveChanges()
      : setHandleErrors(errors);
  };

  const countriesOptions = countries.map(
    ({ description, value, disabled }) => ({
      label: description,
      disabled: disabled,
      value,
    })
  );

  countriesOptions.unshift({
    label: "Seleccionar",
    disabled: true,
    selected: true,
  });

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const response = await v1AuthAxios.put(
        `/users/${user.id}`,
        updatedUserData
      );
      setHandleErrors({});
      dispatch(checkSesion());
      setNewPassword('');
      setConfirmPassword('');
      setPasswordValid(false);
      Toast.show({
        message: response.data.message
          ? response.data.message
          : response.data.error,
        icon: response.data.message ? "tick" : "error",
        intent: response.data.message ? "success" : "warning",
      });
    } catch (error) {
      Toast.show({
        message: error.response.data.error,
        icon: "error",
        intent: "warning",
      });
    }
    setLoading(false);
  };

  const setDateForm = () => {
    const years = [];
    const currentDate = moment(getDateActual());
    const maxDate = currentDate.subtract(18, "years");
    const minDate = maxDate.clone().subtract(75, "years");
    years.push({ label: "Año", value: "", disabled: true, selected: true });
    while (maxDate.isSameOrAfter(minDate, "year")) {
      const year = maxDate.format("YYYY");
      years.push({ label: year, value: year });
      maxDate.subtract(1, "year");
    }
    setYearList(years);
    setMonthList(monthsOptions);
  };

  const setDays = () => {
    if (!dobMonth || !dobYear) {
      setDaysList(["01"]);
      return;
    }
    const currentDay = getDayActual();
    const untilDate = getYearActual() - 18;
    const currentMonth = getMonthActual();
    const days = [
      { label: "Dia", value: "", disabled: true, selected: true },
      ...Array.from({ length: daysInMonth(dobMonth, dobYear) }, (_, i) => {
        const day = (i + 1).toString().padStart(2, "0");
        const isDisabled =
          day > currentDay &&
          dobYear === untilDate &&
          dobMonth === currentMonth;
        return { label: day, value: day, disabled: isDisabled };
      }),
    ];

    setDaysList(days);
  };

  const getStatesByCountry = () => {
    const provincesByCountry = {
      [countriesCode.VENEZUELA]: provincesVenezuela,
      [countriesCode.PERU]: provincesPeru,
      [countriesCode.COLOMBIA]: provincesColombia,
    };
    setStateList(provincesByCountry[country] || []);
  };

  useEffect(() => {
    setDays();
    setDateForm();
  }, [dobDay, dobMonth, dobYear]);

  useEffect(() => {
    getStatesByCountry();
  }, [country]);

  const updateForm = () => {
    return (
      <>
        <FormGroup
          label="Nombre"
          intent={handleErrors.name ? "danger" : "none"}
          helperText={handleErrors.name}
          labelInfo="*"
          className="update-profile__name"
        >
          <InputGroup
            value={capitalize({str: name, all: true})}
            onChange={(e) => {
              const trimmedName = e.target.value.trimStart();
              setName(trimmedName);
            }}
          />
        </FormGroup>

        <FormGroup
          label="Apellidos"
          intent={handleErrors.last_name ? "danger" : "none"}
          helperText={handleErrors.last_name}
          labelInfo="*"
          className="update-profile__last-name"
        >
          <InputGroup
            value={capitalize({str: lastName, all: true})}
            onChange={(e) => {
              const trimmedLastName = e.target.value.trimStart();
              setLastName(trimmedLastName);
            }}
          />
        </FormGroup>

        <FormGroup
          label="Correo electrónico"
          intent={handleErrors.email ? "danger" : "none"}
          helperText={handleErrors.email}
          className="update-profile__email"
        >
          <InputGroup
            type="email"
            value={email}
            disabled={true}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>

        <FormGroup
          label="Fecha de nacimiento"
          intent={handleErrors.DateofBirth ? "danger" : "none"}
          helperText={handleErrors.DateofBirth}
          labelInfo="*"
          className="update-profile__date-of-birth"
        >
          <div className="update-profile__date-of-birth__selects">
            <HTMLSelect
              value={dobYear}
              minimal
              onChange={(e) => setDobYear(e.target.value)}
              options={yearList}
              div
              className="update-profile__date-of-birth__selects__year"
            />
            <HTMLSelect
              value={dobMonth}
              minimal
              onChange={(e) => setDobMonth(e.target.value)}
              options={monthList}
              div
              className="update-profile__date-of-birth__selects__month"
            />
            <HTMLSelect
              value={dobDay}
              minimal
              onChange={(e) => setDobDay(e.target.value)}
              options={daysList}
              div
              className="update-profile__date-of-birth__selects__day"
            />
          </div>
        </FormGroup>

        <FormGroup
          label="Género"
          intent={handleErrors.gender ? "danger" : "none"}
          helperText={handleErrors.gender}
          labelInfo="*"
          className="update-profile__gender"
        >
          <RadioGroup 
            large 
            inline
            onChange={(e) => setGender(e.target.value)}
            selectedValue={gender}
          >
            <Radio
              label="Masculino"
              value="Masculino"
              className="update-profile__gender__male"
            />
            <Radio
              label="Femenino"
              value="Femenino"
              className="update-profile__gender__female"
            />
          </RadioGroup>
        </FormGroup>

        <div className="update-profile__location">
          <FormGroup
            label="País"
            intent={handleErrors.country ? "danger" : "none"}
            helperText={handleErrors.country}
            className="update-profile__country"
            labelInfo="*"
          >
            <HTMLSelect
              value={country}
              minimal
              onChange={(e) => setCountry(e.target.value)}
              options={countriesOptions}
            />
          </FormGroup>

          <FormGroup
            label="Estado"
            intent={handleErrors.state ? "danger" : "none"}
            helperText={handleErrors.state}
            className="update-profile__state"
            labelInfo="*"
          >
            <HTMLSelect
              value={state}
              minimal
              onChange={(e) => setState(e.target.value)}
              options={stateList}
            />
          </FormGroup>
        </div>

        <FormGroup
          label="Ciudad"
          intent={handleErrors.city ? "danger" : "none"}
          helperText={handleErrors.city}
          className="update-profile__city"
        >
          <InputGroup
            value={capitalize({str: city, all: true})}
            onChange={(e) => {
              const trimmedCity = e.target.value.trimStart();
              setCity(trimmedCity);
            }}
          />
        </FormGroup>

        <div className="update-profile__full-phone">
          <FormGroup
            label="Código"
            intent={handleErrors.phone ? "danger" : "none"}
            helperText={handleErrors.phone}
            className="update-profile__full-phone__code"
            labelInfo="*"
          >
            <HTMLSelect
              label="Código"
              value={dobAreaCode}
              minimal
              onChange={(e) => setDobAreaCode(e.target.value)}
              options={phoneAreaCodes}
            />
          </FormGroup>
          <FormGroup
            label="Teléfono"
            intent={handleErrors.phone ? "danger" : "none"}
            helperText={handleErrors.phone}
            className="update-profile__full-phone__phone"
            labelInfo="*"
          >
            <InputGroup
              onKeyPress={numbersWithoutPoints}
              value={dobPhone}
              onChange={(e) => setDobPhone(e.target.value)}
            />
          </FormGroup>
        </div>

        <h3 className="update-profile__password-title">Cambiar contraseña</h3>


        <PasswordValidator setPasswordValid={setPasswordValid} isOpen={focusPassword} password={newPassword}>
          <FormGroup
            label="Nueva contraseña"
            intent={handleErrors.password ? "danger" : "none"}
            helperText={handleErrors.password}
            className="update-profile__password"
          >
            <InputGroup
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onFocus={switchFocusPassword}
              onBlur={switchFocusPassword}
            />
          </FormGroup>
        </PasswordValidator>

        <FormGroup
          label="Confirmar contraseña"
          className="update-profile__confirm"
        >
          <InputGroup
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormGroup>

        <div className="update-profile__submit">
          <Button
            intent="primary"
            onClick={validateFields}
            className={'BtnLCPrimary'}
          >
            Guardar Cambios
          </Button>
        </div>
      </>
    );
  };

  return <Card className="update-profile">{updateForm()}</Card>;
};

export default UpdateProfile;
