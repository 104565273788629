export const config = {
  URL_API: `${process.env.REACT_APP_URL_BASE}/api`,
  URL_BASE: process.env.REACT_APP_URL_LOCAL,
  URL_IMAGE: `${process.env.REACT_APP_URL_BASE}/images_rails`,
  URL_SOCKET: `${process.env.REACT_APP_URL_BASE}/api/cable`,
  KEY_CAPTCHA: process.env.REACT_APP_KEY_CAPTCHA,
  KEY_GOOGLE_LOGIN: process.env.REACT_APP_KEY_GOOGLE_LOGIN,
  KEY_GOOGLE_MAPS: process.env.REACT_APP_KEY_GOOGLE_MAPS,
  KEY_GEOCODE: process.env.REACT_APP_KEY_GEOCODE,
  KEY_FACEBOOK_LOGIN: process.env.REACT_APP_KEY_FACEBOOK_LOGIN,
  GCS_BUCKET: process.env.REACT_APP_GCS_BUCKET,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  WS_NUMBER: process.env.REACT_APP_WS_PHONE_NUMBER,
  // MEGASOFT_URL_BASE: process.env.REACT_APP_MEGASOFT_URL_BASE,
  // MEGASOFT_SECURE_VIEW_URL: process.env.REACT_APP_MEGASOFT_SECURE_VIEW_URL,
  MEGASOFT_URL_BASE: 'https://paytest.megasoft.com.ve/',
  MEGASOFT_SECURE_VIEW_URL: 'payment/action/paymentgatewayuniversal-data?control=',
};