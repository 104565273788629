import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Card as BPCard } from '@blueprintjs/core';

import './Card.scss';

const Card = ({ children, className, size, shadow, ...cardProps }) => {

  const classNames = useMemo(() => {
    if (!size && !shadow) return '';

    const prefix = 'card--';
    const classNameParams = [{ param: size, prefix: '' }, { param: shadow, prefix: 'shadow-' }];

    return classNameParams.reduce((acc, classNameParam) => {
      if (!classNameParam.param) return acc;
      return `${acc} ${prefix}${classNameParam.prefix}${classNameParam.param}`;
    }, '');
  }, [size, shadow]);

  return (
    <BPCard {...cardProps} className={`card ${classNames} ${className}`}>
      {children}
    </BPCard>
  );
};

Card.propTypes = {
  ...BPCard.propTypes,
  children: PropTypes.element || PropTypes.array,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  shadow: PropTypes.oneOf(['green', 'yellow', 'blue', 'none']),

};

Card.defaultProps = {
  size: 'medium',
};

export default Card;