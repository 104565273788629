import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { wall } from '../../../../const';
import { withWallItemProps } from '../../hoc/withWallItemProps';
import { useDispatch } from "react-redux";
import { checkSesion } from "../../../../store/actions";

import './WallItemFactory.scss';


const { WALL_ITEMS_TYPES, WALL_ITEMS_COMPONENTS } = wall;

const WallItemFactory = ({ items }) => {
  const memoizedItems = useMemo(() => items, [items]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkSesion());
  }, [dispatch]);

  return (
    <section className="wall-item-factory">
      {
        memoizedItems.map((item) => {
            const ItemComponent = WALL_ITEMS_COMPONENTS[item.item_type];
            if (!ItemComponent) return null;

            const cardKind = item.resource?.card_kind;
            const baseKey = `${item.item_type}-${item.resource.id}`;
            const key = cardKind ? `${baseKey}-${cardKind}` : baseKey;
            const ItemComponentWithWallProps = withWallItemProps(ItemComponent);
            return <ItemComponentWithWallProps key={key} {...item} />;
          },
        )
      }
    </section>
  );
};

WallItemFactory.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    item_type: PropTypes.oneOf(Object.values(WALL_ITEMS_TYPES)).isRequired,
    resource: PropTypes.object.isRequired,
  })).isRequired,
};

const areEqual = (prevProps, nextProps) => (prevProps.items.length === nextProps.items.length);

export default memo(WallItemFactory, areEqual);