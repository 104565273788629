import React from 'react';
import PropTypes from 'prop-types';

import { titlePropType } from '../../propTypes';

// This classnames are dependent of the main scss, but that's ok, because this component
// should never be used outside the CardCollection component
const buildTextClassNames = (types) => types?.map((type) => `card-collection__text--${type}`).join(' ');
const Title = ({ title, className }) => {
  if (!title) return null;

  const classNames = `title ${className}`;

  return (
    <p className={classNames}>
      {
        Array.isArray(title) ?
          (
            title.map(({ value, types }, index) => {
              const classNames = buildTextClassNames(types);
              const key = `title-${value}-${classNames}-${index}`;
              return (<span key={key} className={classNames}> {value} </span>);
            })
          ) :
          <span className={buildTextClassNames(title.types)}>{title.value}</span>
      }
    </p>
  );
};

Title.propTypes = {
  title: titlePropType,
  className: PropTypes.string,
};

export default Title;