import React from 'react';
import { Link } from 'react-router-dom';
import AvatarUser from '../AvatarUser';
import './History.scss'
import RankingUserStar from '../RankingStar';
import { getHeader } from '../../system/Sesion';
import Transaction from './components/transaction/Transaction';

const History = ({history})  => {
      // props.setScrollTo(props.currentTransaction);
  const displayDirectory =  history.map((current, index) => {    
    return (<div key={`transaction${index}`} className="history__transaction">
        <Transaction id={current} />
      </div>)
    }
  );
    

  return (
    <div className="history">
      <div className="history__title">
        <h1>
          Historial de cambios
        </h1> 
      </div>
      <div className="history__wrapper">
        {displayDirectory}
      </div>
    </div>
  );
};

export default History;
