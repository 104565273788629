import { config } from "../../../../system/Config";
import "./SecurityChangeModal.scss";
import {
  Checkbox,
  Dialog,
  FormGroup,
  HTMLSelect,
  Icon,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { Col, Row } from "react-grid-system";
import React, { useEffect, useState } from "react";
import Form from "../../../../components/customForm";
import { Controller } from "react-hook-form";
import Button from "../../../../components/button";
import axios from "axios";
import { getHeader } from "../../../../system/Sesion";
import CustomCalendar from "../../../../components/customCalendar/CustomCalendar";
import { LCCustomDateFormater } from "../../../../utilityFunctions";
import PaymentResumeModal from "../../../../components/paymentResumeModal/PaymentResumeModal";
import { useGenericModal } from "../../../../hooks/useGenericModal/useGenericModal";

export default function SecurityChangeModal({
  isOpen,
  setIsOpen,
  transactionId,
}) {
  const { buildModal } = useGenericModal();
  const userId = parseInt(localStorage.getItem("userId"));
  const [terms, setTerms] = useState(true);
  const [exchangePlaces, setExchangePlaces] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loCambioProduct, setLoCambioProduct] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const user_id = localStorage.getItem("userId");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedExchangePlace, setSelectedExchangePlace] = useState();
  const handleSetHeadquarters = () => {
    const headquarterList = [
      { label: "Seleccionar sede", value: "", disabled: true, selected: true },
    ];

    exchangePlaces?.map((place) =>
      headquarterList.push({
        label: place.headquarter,
        value: place.headquarter,
      })
    );

    return headquarterList;
  };

  useEffect(() => {
    axios
      .get(`${config.URL_API}/exchange_place/`, {
        headers: getHeader(),
      })
      .then((response) => {
        setExchangePlaces(response.data.exchange_places);
        setSchedules(response.data.schedules);
        setLoCambioProduct(response.data.locambio_product[0]);
      });
  }, []);

  const paymentModal = {
    content: (
      <PaymentResumeModal
        currentPlan={loCambioProduct}
        locambio_product_attributes={{
          exchange_place_id: selectedExchangePlace,
          user_id: user_id,
          transaction_id: transactionId,
          date: selectedDate,
          schedule: selectedSchedule,
          locambio_product_id: loCambioProduct.id,
        }}
      />
    ),
    isOpen: true,
  };

  const validateData = () => {
    if (
      user_id !== null &&
      user_id !== undefined &&
      selectedExchangePlace !== null &&
      selectedExchangePlace !== undefined &&
      transactionId !== null &&
      transactionId !== undefined &&
      selectedDate !== null &&
      selectedDate !== undefined &&
      selectedSchedule !== null &&
      selectedSchedule !== undefined &&
      loCambioProduct !== null &&
      loCambioProduct !== undefined &&
      terms === false
    ) {
      return true;
    }
  };

  const handleChangeTerms = () => {
    setTerms(!terms);
  };

  const handleClosedModal = () => {
    setTerms(true);
    setSelectedDate(null);
    setSelectedSchedule(null);
    setSelectedExchangePlace(null);
  }

  return (
    <Dialog
      isOpen={isOpen}
      className="security-change__modal"
      onClosed={handleClosedModal}
    >
      <Icon
        className="security-change__cross"
        icon="cross"
        onClick={() => {
          setTerms(true);
          setIsOpen(false);
        }}
      />
      <div className="security-change__container">
        <div className="security-change__demo-Flag">SOLICÍTALO</div>
        <div className="security-change__header">
          <img
            className={"security-change__logo"}
            src="https://storage.googleapis.com/locambio-admin/logos/locambio-seguro0.png"
            alt="logo-locambioSeguro"
          />
          <div className={"security-change__text--top"}>
            <h1>Cambia Seguro</h1>
            <p>
              Acércate a nuestras sedes disponibles para hacer cambios exitosos
              de manera segura
            </p>
          </div>
        </div>
        <div className={"security-change__text--middle"}>
          <p>
            <span className={"first"}>Al solicitar el servicio</span> se hará un
            cobro de <span className={"first"}>7$, </span>
            <span>
              Seguidamente LoCambio se pondrá en contacto en la brevedad posible
              para confirmar el servicio.
            </span>
          </p>
        </div>
        <div>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row className="security-change__form">
              {!exchangePlaces.length ? (
                <h3 className="text-center">
                  No hay sedes para LoCambio Seguro
                </h3>
              ) : (
                <FormGroup>
                  <h3 className="security-change__form-title">
                    Escoge el lugar en donde desean hacer el Cambio Seguro
                  </h3>
                  <div className={"security-change__form--section "}>
                    <>
                      <HTMLSelect
                        id="place"
                        minimal
                        className={"security-change__form__places "}
                        options={handleSetHeadquarters()}
                        onChange={(e) =>{
                          const value = exchangePlaces.find((place) => place.headquarter === e.target.value);
                          setSelectedExchangePlace(value.id);
                        }

                        }
                      />
                      <h3 className="security-change__form-subtitle">
                        Reserva una fecha para el Cambio Seguro
                      </h3>
                      <div
                        className="security-change__open-modal"
                        onClick={() => {
                          setOpenCalendar(true);
                        }}
                      >
                        <Icon className="marginRight-05em" icon="calendar" />
                        {!selectedSchedule && !selectedDate ? (
                          <span>Escoge una fecha</span>
                        ) : (
                          <span>
                            <span>{`${LCCustomDateFormater(
                              selectedDate
                            )} / de la ${selectedSchedule}`}</span>
                          </span>
                        )}
                      </div>
                      <CustomCalendar
                        isOpen={openCalendar}
                        setIsOpen={setOpenCalendar}
                        schedules={schedules}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedSchedule={selectedSchedule}
                        setSelectedSchedule={setSelectedSchedule}
                      />
                    </>
                  </div>
                  <div className="security-change__terms">
                    <Checkbox
                      onChange={handleChangeTerms}
                      id="termsAndConditions"
                    />
                    <p>
                      Afirmo haberme puesto de acuerdo con el otro usuario
                      para el uso de este servicio
                      <span className="security-change__terms__required">
                        {" "}
                        *
                      </span>
                    </p>
                  </div>
                  <Button
                    className={"security-change__form--button"}
                    text="Solicitar"
                    intent="success"
                    type="submit"
                    disabled={!validateData()}
                    onClick={() => {
                      buildModal(paymentModal);
                      setIsOpen(false);
                    }}
                  />
                </FormGroup>
              )}
            </Row>
          </Col>
        </div>
      </div>
    </Dialog>
  );
}
