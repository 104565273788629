import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { AnchorButton, Button as BPButton } from '@blueprintjs/core';

import './Button.scss';

const Button = ({ className, bold, minWidth, text, hideTextOnMobile, ...props }) => {
  // TODO: use cleanClassName functions
  const classNamesArray = [
    className || '',
    'button',
    props.intent ? `button--${props.intent}` : '',
    bold ? 'button--bold' : '',
    minWidth ? 'button--min-with' : '',
    props.icon ? 'button--reduce-padding' : '',
  ];
  const classNames = classNamesArray.join(' ');
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const BPText = hideTextOnMobile && isMobile ? '' : text;
  const Component = props.href ? AnchorButton : BPButton;

  return (
    <Component className={classNames} text={BPText} {...props} />
  );
};
export default Button;