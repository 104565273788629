import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { errorImgUrl } from '../../utility/imageUrl';
import ImageZoom from '../imageZoom/ImageZoom';
import './ProductSwiper.scss';
import BlurImage from '../blurImage/BlurImage';

export const swiperParams = {
  modules: [Pagination, Navigation],
  pagination: {
    type: 'bullets',
    clickable: true,
  },
  navigation: true,
  spaceBetween: 30,
  rebuildOnUpdate: true,
  autoHeight: true,
};

const OnErrorImg = ({ target }) => {
  if (target.src !== errorImgUrl) {
    target.src = errorImgUrl;
  }
};

const Image = ({ alt, zoom, ...props }) => {
  if (zoom) {
    return (
      <ImageZoom
        src={props.src}
        alt={alt}
        {...props}
      />
    );
  }
  return <BlurImage image={props.src} title={alt} className={'productSwiper__blur-image'}/>;
};

const ImageWithLink = ({ urlPub, ...props }) => (
  <Link to={urlPub}>
    <Image {...props} />
  </Link>
);

const pictureComponents = ({ pictures, isWall, urlPub, onClick, imgClass, zoom }) => pictures?.map((value, index) => {
  const clickHandler = () => onClick?.(value);

  let dynamicImage = (
    <Image
      src={value?.url || value}
      alt=""
      onClick={clickHandler}
      onError={OnErrorImg}
      className={imgClass}
      zoom={zoom}
    />
  );

  if (isWall) {
    dynamicImage = (
      <Image
        src={value?.url || value}
        onClick={clickHandler}
        onError={OnErrorImg}
        className={imgClass}
        zoom={zoom}
      />
    );

    if (urlPub) {
      dynamicImage = (
        <ImageWithLink
          urlPub={urlPub}
          src={value?.url || value}
          onClick={clickHandler}
          onError={OnErrorImg}
          className={imgClass}
          zoom={zoom}
        />
      );
    }
  }

  return (
    pictures.length > 1 ? (
        <SwiperSlide key={value?.url || value} className="imageWrapper">
          {dynamicImage}
        </SwiperSlide>
      ) : (
        <div key={value?.url || value} className="imageWrapper">
          {dynamicImage}
        </div>
      )
  );
});

const dynamicSwiper = (props) => {
  if (props.pictures?.length > 1) {
    return (
      <Swiper {...swiperParams}>
        {pictureComponents(props)}
      </Swiper>
    );
  } else {
    return pictureComponents(props);
  }
};

const ProductSwiper = (props) => (
  <div className="productSwiper">
    {dynamicSwiper(props)}
  </div>
);

export default ProductSwiper;
