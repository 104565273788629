import React from "react";
import { dateMonthStringShort } from "../../../../utilityFunctions";

import "./VisualizationsSummary.scss";
import BlurImage from "../../../../components/blurImage/BlurImage";

const VisualizationsSummary = ({ data }) => {
  return (
    <section className="visualizations-summary">
      <p>{data.publicationTitle}</p>
      <div className="a">
        <BlurImage image={data.picture} title="publication" />
      </div>
      <p>Visualizaciones</p>
      <p className="visualizations-summary__visuals">
        <span>Orgánicas</span>
        <span>{data.visualizations.organics}</span>
      </p>
      <p className="visualizations-summary__visuals">
        <span>Promocionadas</span>
        <span>
          {data.visualizations.promotial.count}/
          {data.visualizations.promotial.total}
        </span>
      </p>
      <p className="visualizations-summary__visuals">
        <span>Totales</span>
        <span>{data.visualizations.totals}</span>
      </p>
      <p className="visualizations-summary__visuals">
        <span>Fecha de inicio</span>
        <span>{dateMonthStringShort(data.start_date)}</span>
      </p>
    </section>
  );
};

export default VisualizationsSummary;
