import React from 'react';
import { Spinner } from '@blueprintjs/core';

import './Loader.scss';

const Loader = ({ intent, ...props }) => {
  return (
    <div className="loader">
      <Spinner {...props} />
    </div>
  );
};

Loader.propTypes = {};

export default Loader;