import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Info from './components/info';
import Search from './components/search';
import Panel from './components/panel';

import './Header.scss';

const Header = () => {
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const headerDisplay = isLargerThanMobile ? (
    <header className="header bg-animation">
      <Info />
      <Search />
      <Panel />
    </header>
  ) : (
    <header className="header__mobile bg-animation">
      <div className='header__mobile-info-search'>
      <Info />
      <Search />
      </div>
      <Panel />
    </header>
  );

  return <>{headerDisplay}</>;
};

export default Header;
