const CHAT_KINDS = {
  inProgress: 'in_progress',
  proposal: 'proposal',
};

const CHAT_KINDS_URL = {
  [CHAT_KINDS.inProgress]: 'in-progress',
  [CHAT_KINDS.proposal]: 'proposal',
};

const CHAT_KINDS_URL_MAP = {
  [CHAT_KINDS_URL[CHAT_KINDS.inProgress]]: CHAT_KINDS.inProgress,
  [CHAT_KINDS_URL[CHAT_KINDS.proposal]]: CHAT_KINDS.proposal,
};

const CHAT_KINDS_BUTTONS = [
  { key: CHAT_KINDS.proposal, label: 'Propuestas' },
  { key: CHAT_KINDS.inProgress, label: 'En Proceso' },
];

export {
  CHAT_KINDS,
  CHAT_KINDS_URL,
  CHAT_KINDS_URL_MAP,
  CHAT_KINDS_BUTTONS,
};