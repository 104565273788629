import React from 'react';
import './BlurImage.scss';
import { Link } from 'react-router-dom';

const BlurImage = ({ image, title, className }) => {

  var backgroundBlurStyle = (image) => {
    return {
      backgroundImage: `url(${image})`,
    };
  };

  return (
    <section className={`blur-image ${className}`}>
      <div className={`blur-image__picture ${className}__picture`}>
        {image && (
          <Link>
            <div className={`blur-image__picture--background ${className}__picture--background`} style={backgroundBlurStyle(image)}></div>
            <div className={`blur-image__picture--img_box ${className}__picture--img_box`}>
              <img
                src={image}
                alt={title}
              />
            </div>
          </Link>
        )}
      </div>
    </section>
  );
};

export default BlurImage;