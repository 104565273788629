import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPublicationsSaved, removePublicationSaved } from '../../../../store/actions';
import { distanceFormat, formatPrice, productNameUrl, currencyFormat } from '../../../../utilityFunctions';
import { Link } from 'react-router-dom';
import ActivityWallCardDecorator from '../../../../components/activityWallCardDecorator';
import { publicationCardstyles } from '../../../../../src/const/';
import ArticlesOfInterest from '../../../../components/ArticlesOfInterest';
import '../activityNewPublication/styles/ActivityNewPublication.scss';
import UserInfo from '../../../../components/userInfo';
import Card from '../../../../components/card/Card';
import '../../../myPublications/PublicationItem';
import Actions from '../../../../components/publicationItem/components/actions';
import Layer from '../../../../components/publicationItem/components/layer/Layer';
import { Button } from '@blueprintjs/core';
import history from '../../../../utility/history';
import { authAxios } from '../../../../utility/axios';

const ActivityNewPublication = ({ pub, isPublicationSaved, actions, index, isOwner, className }) => {
  const {
    condition,
    product_status,
    price,
    publication_type,
    publish_date,
    title,
    id,
    owner,
    dist_metros,
    article_tags,
    card_kind,
    publication_status,
    picture: mainImage,
    active_transaction
  } = pub;

  const [buttonDisable, setButtonDisable] = useState( isOwner );
  const [searchList, setSearchList] = useState(true); //useState(location.split('/')[1] === 'search_list');
  const [proposalTextButton, setProposalTextButton] = useState(active_transaction ? 'Modificar Propuesta' : 'Hacer Propuesta')

  // TODO: promoted, near, ownerInterest, userInterested must be received from the backend as booleans
  const dispatch = useDispatch();
  const { userId } = useSelector(({ auth }) => auth);
  const { publicationsSaved } = useSelector(({ publicationsSaved }) => publicationsSaved);
  const data = publicationCardstyles.PUBLICATION_TYPES_DATA[card_kind];
  const active = publication_status === 'Activo';

  const distance =  formatPrice(distanceFormat(dist_metros + ' ',),)

  const checkPublicationSaved = () =>
    publicationsSaved.findIndex(
      ((publication) => publication.publication_id === id)
    );

  useEffect(
    () => {
      if (searchList && !isOwner){
        fetchGetTransactionForUser()
      }
    },
    [searchList]
);

  const fetchGetTransactionForUser = () => {
      authAxios.get(`/transaction_for_user?publication_id=${id}`)
        .then((response) => {
          if (response.data == 'disabled') {setButtonDisable(true)} 
          if (response.data == 'update') {setProposalTextButton('Modificar Propuesta')}
        })
        .catch((err) => console.log(err));
    }

  const handleSavePublication = () => {
    if (userId && id) {
      dispatch(addPublicationsSaved(userId, id));
    }
  };

  const handleProposal = (id) => {
    const url = userId ? `/publication/${id}/proposal/manager` : '/login';
    history.push(url);
  };

  const handleRemovePublication = () => {
    dispatch(removePublicationSaved(
      publicationsSaved[checkPublicationSaved()].id,
      checkPublicationSaved(),
    ));
  };

  var backgroundBlurStyle = (img) => {
    return {
      backgroundImage: `url(${img})`,
    };
  };

  function activity() {
    return (
      <main className="activity-new-publication">
        <div className="picture">
          {mainImage && (
            <>
              <div className="background" style={backgroundBlurStyle(mainImage)}></div>
              <div className="img_box">
                <Link to={`/${productNameUrl(title)}/${id}`}>
                  <img
                    src={mainImage}
                    alt={title}
                  />
                </Link>
              </div>
            </>
          )}
        </div>
        <section className="info">
          <div className="details">
            <div>
              <Link className={`title`} to={`/${productNameUrl(title)}/${id}`}>
                <p className={`${card_kind}`}>{title}</p>
              </Link>
            </div>

            <div className={'details_tags'}>
              {product_status && <p>{product_status}</p>}
              {condition && <p>{condition}</p>}
              {price && <p className={`dark_tag dark_tag--${data?.priceColor}`}>{localStorage.getItem("currency")}{currencyFormat(price)}</p>}
            </div>
            { ( distance !== 'NaN km' ) && <div className={'distance'}>
              Se encuentra a <b>
              {distance === '0 km' ? 'menos de 1 km' : distance}
              </b> 
              
              <b> {pub.location !== "[]" && `en ${pub.location}` } </b>
            </div>}
            {!isOwner && <div className={'user_info'}><UserInfo user={owner} size={{ width: '14', height: '14' }}></UserInfo></div>}
          </div>
          <div className="interests_content">
            <div className="interests">
              <h4>Intereses a cambio</h4>
              <div className={'article_tags'}>
                {article_tags && article_tags.length > 0 ? (
                  <>
                    <ArticlesOfInterest
                      articles={article_tags.slice(0, 6)}
                      tagsHides={article_tags.length > 6}
                      setTag={true}
                    />
                  </>
                ) : (
                  <ArticlesOfInterest
                    articles={[{ name: 'Cualquier Propuesta' }]}
                    setTag={false}
                  />
                )}
              </div>
            </div>
            {active ? (
              <div className={'buttons'}>
                {actions ? (
                  <Actions actions={actions} className={'BtnLCPrimary button'} publication={pub} index={index} />
                ) : (
                  <>
                    <Button
                      fill={true}
                      className={'BtnLCPrimary button'}
                      onClick={() => handleProposal(id)}
                      disabled={userId === owner?.id || buttonDisable}
                    >
                      {proposalTextButton}
                    </Button>
                    <Button
                      className={`button BtnLCPrimaryOutline ${checkPublicationSaved() !== -1 ? '' : 'BtnLCPrimaryOutline--empty'}`}
                      minimal={true}
                      fill={true}
                      icon="bookmark"
                      onClick={
                        checkPublicationSaved() !== -1
                          ? handleRemovePublication
                          : handleSavePublication
                      }
                    >
                      {checkPublicationSaved() !== -1
                        ? 'Guardada'
                        : 'Guardar'}
                    </Button>
                  </>
                )
                }
              </div>
            ) :
            (actions && (
                <div className={'buttons'}>
                  <Actions actions={actions} className={'BtnLCPrimary button'} publication={pub} index={index} />
                </div>
              ) 
            )
          }

          </div>
        </section>
      </main>
    );
  }

  return (
    <>
      <Card shadow={data?.shadow} className={className}>
        {isPublicationSaved && !active && <Layer title="Ya no está disponible" tagName="h3" actions={actions} publication={pub} index={index} />}
        <ActivityWallCardDecorator
          ribbonColor={data?.ribbonColor}
          text={data?.title.text}
          titleColor={data?.title.color}
          date={publish_date}
          publicationType={publication_type}
        />
        {activity()}
      </Card>
    </>
  );
};

// TODO: Check the memo usage for this case
export default ActivityNewPublication;
