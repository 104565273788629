import { Collapse, Icon } from '@blueprintjs/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './LoginDropdown.scss';

const LoginDropdown = () => {

  const [getCollapse, setCollapse] = useState(false);

  const changegetCollapse = () => setCollapse(!getCollapse);

  const flow = ['Recuperar mi contraseña', 'Reenviar correo de confirmación']

  return (
    <div className="LoginDropdown">

      <div className="accordion__section__question-container" onClick={changegetCollapse}>
        <p className="accordion__section__question-title">Soporte para iniciar sesión</p>
        <Icon
          className="icon"
          icon={getCollapse ? 'chevron-up' : 'chevron-down'}
          iconSize={25}
        />
      </div>
      <div className='collapse'>
        <Collapse isOpen={getCollapse}>
          <Link to={`login_flow/${flow[0]}`} className="collapseWords">{ flow[0]}</Link><br />
          <Link to={`login_flow/${flow[1]}`} className="collapseWords">{ flow[1]}</Link>
        </Collapse>
      </div>

    </div>
  );
};

export default LoginDropdown;