import { Icon } from '@blueprintjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Position } from '@blueprintjs/core';

import ItemList from '../itemList';
import DisplayMenu from '../displayMenu';
import { handleClosePopover } from '../../../../utilityFunctions';
import {
  CHAT_KINDS,
  CHAT_KINDS_URL,
  CHAT_KINDS_BUTTONS,
} from '../../../../utility/chat';

import './ElementsDropdown.scss';

const ElementsDropdown = ({
  elements,
  kind,
  unread,
  chatKind = CHAT_KINDS.proposal,
  setChatKind,
}) => {
  const history = useHistory();

  const detailsOptions = {
    chat: {
      icon: 'chat',
      url: `/chats?tab=${CHAT_KINDS_URL[chatKind]}`,
      title: 'Chats de intercambios',
      footerText: 'Ver Todos',
    },
    notification: {
      icon: 'notifications',
      url: '/notifications_page',
      title: 'Notificaciones',
      footerText: 'Ver Todas',
    },
  };

  const aditionalClass = !elements.length
    ? `elements-dropdown--short-${kind}`
    : `elements-dropdown--${kind}`;

  const details = detailsOptions[kind];

  const { url, icon, title, footerText } = details;

  const handleViewAll = (e, hideMenu) => {
    if (hideMenu) hideMenu();
    handleClosePopover(e);
    history.push(url);
  };

  const numberBubble = () =>
    unread !== 0 && (
      <section className="elements-dropdown__icon-bubble">
        <div className="elements-dropdown__icon-text">{unread}</div>
      </section>
    );

  const popoverProps = {
    placement: 'bottom',
    boundary: 'scrollParent',
    modifiers: {
      arrow: { enabled: true },
      preventOverflow: { enabled: true },
    },
  };

  const deployer = (
    <section className="elements-dropdown__icon-container">
      <Tooltip2 content={title} position={Position.TOP}>
        <>
          <Icon
            className="elements-dropdown__icon"
            icon={icon}
            iconSize={Icon.SIZE_LARGE}
            />
          {numberBubble()}
        </>
      </Tooltip2> 
    </section>
  );

  const navigation = (
    <navigation className={'elements-dropdown__chat-buttons-box'}>
      {CHAT_KINDS_BUTTONS.map((button) => {
        return (
          <div
            key={`notification-button-${button.key}`}
            className={`elements-dropdown__chat-button ${
              button.key === chatKind
                ? 'elements-dropdown__chat-button--active'
                : ''
            }`}
            onClick={() => setChatKind(button.key)}
          >
            <p tagName="h3">{`${button.label}`}</p>
            <Icon
              icon={button.key === chatKind ? 'chevron-down' : 'chevron-up'}
              iconSize={Icon.SIZE_LARGE}
            />
          </div>
        );
      })}
    </navigation>
  );

  const content = (hideMenu) => (
    <section className={`elements-dropdown ${aditionalClass}`}>
      <header className="elements-dropdown__header">
        <h2 className="elements-dropdown__title">{title}</h2>
      </header>
      {kind === 'chat' && navigation}
      <ItemList elements={elements} kind={kind} hideMenu={hideMenu} />
      {!!elements.length && (
        <footer
          className="elements-dropdown__footer"
          onClick={(e) => {
            handleViewAll(e, hideMenu);
          }}
        >
          <h2 className="elements-dropdown__footer-text"> {footerText}</h2>
        </footer>
      )}
    </section>
  );

  return (
    <DisplayMenu
      children={content}
      deployer={deployer}
      popoverProps={popoverProps}
      color={'black'}
    />
  );
};

export default ElementsDropdown;
