import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { CHAT_KINDS, CHAT_KINDS_URL, CHAT_KINDS_BUTTONS } from '../../../../utility/chat';

import ElementsDropdown from '../elementsDropdown';

const Messages = () => {
  const [chatKind, setChatKind] = useState(CHAT_KINDS.proposal);
  const unreadChats = useSelector(
    ({ chat }) =>
      chat.unreadChats[CHAT_KINDS.proposal] +
      chat.unreadChats[CHAT_KINDS.inProgress]
  );
  const { [chatKind]: chats} = useSelector(({ chat }) => chat);

  return (
    <ElementsDropdown elements={chats} kind={'chat'} unread={unreadChats} chatKind={chatKind} setChatKind={setChatKind} />
  )
};

export default Messages;
