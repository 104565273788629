import actionCable from 'actioncable';
import { config } from '../system/Config';

const cableApp = actionCable.createConsumer(config.URL_SOCKET);

// TODO: Add all channels
export const CABLE_CHANNELS = {
  message: 'MessageChannel',
  notification: 'NotificationChannel'
};

export default cableApp;
