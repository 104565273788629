import React, { Component } from "react";
import { Callout } from "@blueprintjs/core";
import { connect } from "react-redux";
import {
  currencyFormat,
  initialValue,
  needSkeletonClass,
} from "../../utilityFunctions";
import { Link } from "react-router-dom";
import UserStatsInfo from "../../components/UserStatsInfo";
import "./userInformation/UserInformation.scss";
import UserName from "../../components/userName/UserName";
import Card from "../../components/card";
import BlurImage from "../../components/blurImage/BlurImage";

class UserInformation extends Component {
  state = {
    user: {
      picture_data_profile: "",
      picture_data_cover: "",
    },
    haveImage: null, // el usuario tiene imagen?
    publicationsActive: [],
  };

  componentDidUpdate() {
    if (
      this.props.pictureData &&
      this.props.pictureData.length > 0 &&
      this.state.haveImage === null
    ) {
      this.setState({ haveImage: true });
    }
  }

  componentDidMount() {
    if (
      this.props.pictureData &&
      this.props.pictureData.length > 0 &&
      this.state.haveImage === null
    ) {
      this.setState({ haveImage: true });
    }
  }

  componentWillMount() {
    this.props.userPublications.forEach((item) => {
      if (item.publication_status == "Activo") {
        this.state.publicationsActive.push(item);
      }
    });
  }

  imgProfile = (user) => user?.picture_users?.[0]?.url;

  render() {
    let otherInfoUser = { ...this.props.otherInfoUser };
    const { user } = this.props;

    return (
      <>
        <div className="UserInformationContainer">
          <Card className="padding0">
            <div className={"WallActivityUserInformation"}>
              <div className="">
                <figure className={""}></figure>
              </div>
              <div className="User-Avatar-Name">
                <figure className="m-auto">
                  {this.props.pictureData ? (
                    <Callout className="avatarContainer m-auto">
                      {this.state.haveImage ? (
                        <Link to="/profile?tab=profile">
                          <div
                            id={`image-profile-user`}
                            className="profile__container"
                          >
                            <BlurImage
                              className="blur-image"
                              image={this.imgProfile(user) || user?.avatar}
                            />
                          </div>
                        </Link>
                      ) : (
                        <div className="initials-container">
                          <div className="initials-container--box">
                            <b className="initials-container--box-initials">
                              <span>{initialValue(user?.name)}</span>
                              <span>{initialValue(user?.last_name)}</span>
                            </b>
                          </div>
                        </div>
                      )}
                    </Callout>
                  ) : null}
                </figure>
                <div
                  className={needSkeletonClass(
                    this.props.otherInfoUser,
                    "userName"
                  )}
                >
                  <Link to="/profile?tab=profile">
                    <UserName
                      className="mt-4 mb-2"
                      user={user}
                      htmlTag="h1"
                    ></UserName>
                  </Link>
                  <p>
                    <span className="balanceTxt">Saldo: </span>{" "}
                    <span className="balanceMoney">
                      {localStorage.getItem("currency")}
                      {otherInfoUser.balance
                        ? currencyFormat(otherInfoUser.balance)
                        : "0,00"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="padding-1em">
                <UserStatsInfo user={user} />
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSuscription: state.auth.suscription,
    userPublications: state.auth.publications,
    otherInfoUser: state.auth.otherInfo,
    followers: state.auth.followers,
    following: state.auth.following,
    pictureData: state.auth.imageProfile,
    pictureCover: state.auth.imageCover,
  };
};

export default connect(mapStateToProps)(UserInformation);
