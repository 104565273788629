import React, { useState, useEffect } from 'react';
import { config } from '../../system/Config';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import axios from 'axios';
import { getHeader } from '../../system/Sesion';
import { Breadcrumbs } from '@blueprintjs/core';
import Form from '../../components/customForm';
import './NewPublication.scss';
import { withRouter, useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import GeneralStep from './components/generalStep';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import Step4 from './components/step4';
import { getGCSUrl, checkWithCurrentUser } from '../../utilityFunctions';

const mapStateToProps = (state) => {
  return {
    categories: state.categories.all,
  };
};

const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === '{}';
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCategories: () => dispatch(actions.getCategories()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(function NewPublication({ _history, categories }) {
    const [step, setStep] = useState('step1');
    const [loadingButton, setLoadingButton] = useState(false);
    const [stepTitle, setStepTitle] = useState('Paso 1');
    const [canContinue, setCanContinue] = useState(false);
    const [location, setLocation] = useState(window.location.pathname);
    const [update, setUpdate] = useState(location.split('/').pop());
    const [oldData, setOldData] = useState({});
    const [firstIteraction, setFirstIteraction] = useState(true);
    const [files, setFiles] = useState([]);
    const [publicationType, setPublicationType] = useState(null);

    const history = useHistory();

    const setPublicationData = (params) => {
      const { setValue } = params;
      let newFiles = [];
      oldData.pictures.map((current, index) => {
        newFiles.unshift(current.id);
        setValue(`articleImages-${current.id}`, {id: current.id,  url: current.url});
      });
      setFiles(newFiles);
    };

    const clickTimeline = (e) => {
      switch (e.target.innerHTML) {
        case 'Define tu publicación':
          handleTimelineClick(1, 'step1');
          break;
        case 'Detalla tu publicación':
          handleTimelineClick(2, 'step2');
          break;
        case 'Define tus intereses':
          handleTimelineClick(3, 'step3');
          break;
        case 'Sugiere un lugar para el cambio' || 'Dónde prestas tus servicios':
          handleTimelineClick(4, 'step4');
          break;
      }
    };
    
    const timeLineCreator = (step) => {
      const newTL = [];
      switch (step) {
        case 4:
          newTL.unshift({
            current: false,
            text: publicationType === 'product' ? 'Sugiere un lugar para el cambio' : 'Dónde prestas tus servicios',
            onClick: clickTimeline,
          });
        case 3:
          newTL.unshift({
            current: false,
            text: 'Define tus intereses',
            onClick: clickTimeline,
          });
        case 2:
          newTL.unshift({
            current: false,
            text: 'Detalla tu publicación',
            onClick: clickTimeline,
          });
        case 1:
          newTL.unshift({
            current: false,
            text: 'Define tu publicación',
            onClick: clickTimeline,
          });
      }
      newTL[newTL.length - 1].current = true;
      setTimeLine(newTL);
    };

    const handleTimelineClick = (number, step) => {
      timeLineCreator(number);
      prevStep(step);
    };

    const [timeLine, setTimeLine] = useState([
      {
        current: true,
        text: 'Define tu producto',
        onClick: clickTimeline,
      },
    ]);

    const nextStep = () => {
      switch (step) {
        case 'step1':
          setCanContinue(false);
          setStep('step2');
          setStepTitle('Paso 2');
          handleTimelineClick(2, 'step2');
          break;
        case 'step2':
          setStep('step3');
          handleTimelineClick(3, 'step3');
          setStepTitle('Paso 3');
          break;
        case 'step3':
          setCanContinue(false);
          setStep('step4');
          handleTimelineClick(4, 'step4');
          setStepTitle('Paso 4');
          break;
        case 'step4':
          setCanContinue(false);
          break;
      }
    };

    const prevStep = (step) => {
      switch (step) {
        case 'step1':
          setStepTitle('Paso 1');
          setStep(step);
          break;
        case 'step2':
          setStepTitle('Paso 2');
          setStep(step);
          break;
        case 'step3':
          setStepTitle('Paso 3');
          setStep(step);
          break;
        case 'step4':
          setStepTitle('Paso 4');
          setStep(step);
          break;
      }
    };

    useEffect(() => {
      if (location.includes('edit')) {
        let publicationId = location.split('/').pop();
        axios
          .get(`${config.URL_API}/publications/${publicationId}`, {
            headers: getHeader(),
          })
          .then((res) => {
            const isOwner = checkWithCurrentUser(res.data.publication.owner_id);
            isOwner
              ? setOldData(res.data.publication)
              : history.push('/new_publication');
          });
      }
    }, [location]);

    const formRender = (params) => {
      if (firstIteraction) {
        if (!location.includes('edit')) {setPublicationType(params.watch('product'))}
        if (location.includes('edit') && oldData.tags) {
          const parentsArray = [];
          let parent = {};
          let found = false;
          if (oldData.product_category.category_level > 0) {
            parent = oldData.product_category.product_category;
            if (parent.category_level > 0) {
              parentsArray.push(parent.product_category);
            }
            parentsArray.push(parent);
          }
          parentsArray.push(oldData.product_category);

          const changePlace = {
            10.4975585: '1',
            10.4665252: '2',
            10.4939733: '3',
          };

          setPublicationData(params);

          params.setValue('openToProposal', oldData.accept_changes ? 1 : 0);
          params.setValue('address_1', oldData.address_1);
          params.setValue('reference', oldData.address_2);
          params.setValue('changePlace', oldData.address_1);
          params.setValue(
            'interest',
            oldData.article_tags.map((elem) => elem.name)
          );
          params.setValue('conditionSpecific', oldData.condition);
          params.setValue('state', oldData.location);
          params.setValue('title', oldData.name);
          params.setValue('estimatedValue', oldData.price);
          params.setValue('categoryWithParents', parentsArray);
          params.setValue('publish_date', new Date().toISOString().split('T')[0]);
          params.setValue('condition', oldData.product_status);
          params.setValue('category', oldData.product_category.id);
          params.setValue('description', oldData.editor_web);
          params.setValue(
            'product',
            oldData.publication_type === 'Producto' ? 'product' : 'service'
          );
          params.setValue(
            'latitudeWT',
            parseFloat(oldData.coordinate.latitude)
          );
          params.setValue(
            'longitudeWT',
            parseFloat(oldData.coordinate.longitude)
          );
          params.setValue(
            'changePlace',
            changePlace[String(oldData.latitude)]
              ? changePlace[String(oldData.latitude)]
              : '4'
          );
          setPublicationType(params.watch('product'))
          setFirstIteraction(false);
        }
      }

      if (!isEmptyObject(params.errors)) setCanContinue(false);
      switch (step) {
        case 'step1':
          return (
            <Step1
              categories={categories}
              control={params.control}
              errors={params.errors}
              setValue={params.setValue}
              setContinue={setCanContinue}
              canContinue={canContinue}
            />
          );

        case 'step2':
          return (
            <Step2
              control={params.control}
              errors={params.errors}
              setValue={params.setValue}
              canContinue={canContinue}
              setContinue={setCanContinue}
              resetField={params.resetField}
              setFiles={setFiles}
              files={files}
              watch={params.watch}
            />
          );

        case 'step3':
          return (
            <Step3
              setContinue={setCanContinue}
              control={params.control}
              errors={params.errors}
              canContinue={canContinue}
              setValue={params.setValue}
            />
          );

        case 'step4':
          return (
            <Step4
              setCanContinue={setCanContinue}
              control={params.control}
              errors={params.errors}
              setValue={params.setValue}
              canContinue={canContinue}
              loadingButton={loadingButton}
              publicationType={publicationType}
              watch={params.watch}
            />
          );
      }
    };

    const filterImages = (data) => {
      let imagesKeys = Object.keys(data).filter((key) =>
      key.startsWith('articleImages-')
      );
      const images = [];
      files.map((key) => {
        if (data[`articleImages-${key}`]) images.push(data[`articleImages-${key}`].id ? data[`articleImages-${key}`].id : data[`articleImages-${key}`].image);
      });
      return images;
    };

    const handleSubmit = (data) => {
      const imagesArray = filterImages(data);
      ReactPixel.init('317028946379263', {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      });
      ReactPixel.pageView();
      ReactPixel.track('successful_publication', 'Successful publication');

      const formData = new FormData();
      formData.append('accept_changes', data.openToProposal);
      formData.append('address_1', data.address_1);
      formData.append('address_2', data.reference);
      formData.append(
        'condition',
        data.conditionSpecific ? data.conditionSpecific : ''
      );
      formData.append('description', data.description);
      formData.append('editor_web', data.description);
      formData.append('name', data.title);
      formData.append('product_status', data.condition ? data.condition : 'Nuevo');
      formData.append('price', `${data.estimatedValue}`);
      formData.append('product_categories_id', `${data.category}`);
      formData.append(
        'subCategory',
        data.product === 'product' ? '' : data.product
      );
      formData.append('aditional_info', '');
      formData.append('exchange_interest_category', 'Servicios');
      formData.append('latitude', data.coordinates.lat);
      formData.append('longitude', data.coordinates.lng);
      formData.append('publish_date', new Date().toISOString().split('T')[0]);
      formData.append('reference_price', '');
      formData.append('token', localStorage.tokenId);
      formData.append('location', data.location);
      apendArray(formData, 'article_tags', data.interest);
      apendArray(formData, 'tags', data.title.split(' '));
      imagesArray.map((value, index) => {
        formData.append(`picture_data[]`, value);
      });

      return formData;
    };

    const apendArray = (form, arr, data) => {
      if (data) data.forEach((item) => form.append(`${arr}[]`, item));
    };

    const isUpdate = !isNaN(parseInt(update));

    return (
      <div className="new-Publication">
        <Breadcrumbs className="timeline" items={timeLine} />
        <GeneralStep title={stepTitle}>
          <Form
            url={
              isUpdate
                ? `${config.URL_API}/publications/${update}`
                : `${config.URL_API}/publications`
            }
            verifiData={handleSubmit}
            successMessage={
              isUpdate
                ? `La publicación ha sido actualizada exitosamente`
                : `La publicación ha sido creada exitosamente`
            }
            hasCaptcha={false}
            header
            redirect="/publicacion_creada"
            setLoadingButton={setLoadingButton}
            isUpdate={isUpdate}
          >
            {(params) => formRender(params)}
          </Form>
        </GeneralStep>
        {step !== 'step4' ? (
          <div>
            <button
              className={`formButton ${!canContinue ? 'disabled' : ''}`}
              disabled={!canContinue}
              onClick={nextStep}
            >
              {' '}
              Siguiente{' '}
            </button>
          </div>
        ) : null}
      </div>
    );
  })
);
