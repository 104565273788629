import React from 'react';

import { ribbonTextPropType } from '../../propTypes';

const Ribbon = ({ text }) => {
  return (
    <section className="ribbon__wrapper">
      <section className="ribbon">{text}</section>
    </section>
  );
};

Ribbon.prototype = {
  text: ribbonTextPropType,
};
export default Ribbon;