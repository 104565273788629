import React, { Component } from 'react';
import { Button, Card, FormGroup, InputGroup } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import { emailValidation, spaceValidation } from '../utilityFunctions';
import { config } from '../system/Config';
import { Link, Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import './forgotMyPassword/ForgotMyPassword.scss';
import Footer from '../components/Footer';
import { Toast, toastWithLink } from '../utility/toast';
import MediaQuery from 'react-responsive';
import Recaptcha from 'reaptcha';

class LoginFlow extends Component {
  state = {
    email: "",
    viewPassword: false,
    verifyCaptcha: false,
    captchaReady: false,
    renderedCaptcha: false,
    loading: false,
    type: '',
    path: '',
    url: '',
    notification: { 
      message: 'El correo indicado no está registrado. Haga clic aquí para registrarse', 
      url: '/registration'
    },
    forgotSuccessfully: false,
    resendSuccessfully: false,
    sendRequest: false
  };

  /**
   * It fires when the captcha is ready.
   */
  onLoad = () => this.setState({ captchaReady: true });

  /**
   * It fires when the captcha is verified.
   */
  onVerify = () => this.setState({ verifyCaptcha: true });

  /**
   * It fires when an error occurs in the captcha.
   */
  onErrorCaptcha = () => {
    Toast.show({ message: 'Por favor verifica tu conexion a internet', intent: 'warning', icon: 'warning-sign' });
  }

  componentDidMount() {
    if (this.props.match.params.type === 'Recuperar mi contraseña') {
      this.setState({ path: "/successful_message/recovery" });
      this.setState({ url: "send_mail_recovery_password" });
    }
    if (this.props.match.params.type === 'Reenviar correo de confirmación') {
      this.setState({ path: "/successful_message/resend" });
      this.setState({ url: "resend_confirmation_email" });
    }
    this.setState({ type: this.props.match.params.type });
  }


  componentDidUpdate() {
    /**
     * If in the reducer this property is "true" the user is redirected to the home.
     */
    if (this.props.loggued) {
      this.props.onSetLoggued(false);
      this.props.history.push(`/wall`);
    }

    /**
     * To avoid an error with the captcha library.
     */
    if (this.state.captchaReady && !this.state.renderedCaptcha) {
      this.setState({ renderedCaptcha: true }, () =>
        this.captcha.renderExplicitly(),
      );
    }
  }

  validations = () => {
    let valid = true;
    if (!spaceValidation(this.state.formData.email)) {
      Toast.show({ intent: 'warning', message: 'Por favor escribe el email', icon: 'warning-sign' });
      valid = false;
    } else if (!emailValidation(this.state.formData.email)) {
      Toast.show({ intent: 'warning', message: 'Por favor escriba un email valido', icon: 'warning-sign' });
      valid = false;
    }
    if (!valid) this.setState({ loading: false });
    return valid;
  };


  inputChangeHandler = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  sendToApi = () => {
    if (!this.validations()) {
      return;
    }
    if (!this.state.verifyCaptcha && !process.env.REACT_APP_LOCAL_ENV) {
      this.setState({ loading: false })
      Toast.show({ message: 'Debe completar el Captcha', intent: 'warning', icon: 'warning-sign' });
      return;
    }
    this.setState({ loading: true });
    axios
      .post(`${config.URL_API}/${this.state.url}`, {
        email: this.state.email
      })
      .then(data => {
        this.handlerResponseApi(data.data.response, this.state.message);
      })
      .catch(error => {
        console.error(error);
        Toast.show({ message: 'Lo sentimos, hubo un error' })
        this.setState({ loading: false });
      });
  };

  handlerResponseApi = (response, msg) => {
    switch (response) {
      case "email not found":
        Toast.show(toastWithLink(this.state.notification));
        this.setState({ loading: false });
        break;
      case "email sent successfully":
      case "email resent successfully":
        this.setState({
          loading: false,
          sendRequest: true,
        });
        if (this.state.type === 'Recuperar mi contraseña') this.setState({ forgotSuccessfully: true });
        if (this.state.type === 'Reenviar correo de confirmación') this.setState({ resendSuccessfully: true });
        break;
      default:
    }
  };

  validations = () => {
    let valid = true;
    if (!spaceValidation(this.state.email)) {
      Toast.show({ message: 'Por favor escribe tu correo electrónico' });
      valid = false;
    } else if (!emailValidation(this.state.email)) {
      Toast.show({ message: 'Por favor escribe un correo electrónico valido' })
      valid = false;
    }
    return valid;
  };

  requestKey = event => {
    if (event.which === 13) {
      this.sendToApi();
    }
  };

  render() {

    const { path, forgotSuccessfully, resendSuccessfully } = this.state;

    if (resendSuccessfully || forgotSuccessfully) return <Redirect to={`${path}`} />;

    return (
      <>
        <section className="forgotPasswordSection mt-48 sm:mt-8 sm:pt-12 md:pt-12 lg:pt-12 xl:pt-12">
          <div className=""></div>
          <div className="forgotPasswordContainer">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h1 className="textAlignCenter textColorWhite text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl m-8 sm:m-10 md:m-10 lg:m-10 xl:m-10">
                    Escribe tu correo electrónico
                  </h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card className="marginBottom-1em">
                    <FormGroup
                      // label="Correo Electrónico"
                      // helperText="Helper text with details..."
                      labelFor="text-input"
                      labelInfo="*"
                    >
                      <InputGroup
                        placeholder="Correo Electrónico*"
                        id="email"
                        value={this.state.email}
                        onKeyPress={this.requestKey}
                        onChange={this.inputChangeHandler}
                      />
                    </FormGroup>

                    <Row>
                      <div className="centerRecaptcha">
                        <MediaQuery query="(min-device-width: 348px)">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="w-min">
                              <Recaptcha
                                className="recaptcha mb-6"
                                ref={(e) => (this.captcha = e)}
                                sitekey={config.KEY_CAPTCHA}
                                onLoad={this.onLoad}
                                onVerify={this.onVerify}
                                onError={this.onErrorCaptcha}
                                explicit
                              />
                            </div>
                          </Col>
                        </MediaQuery>
                        <MediaQuery query="(max-device-width: 347px)">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Recaptcha
                              className="recaptcha marginBottom-1em"
                              ref={(e) => (this.captcha = e)}
                              sitekey={config.KEY_CAPTCHA}
                              onLoad={this.onLoad}
                              onVerify={this.onVerify}
                              onError={this.onErrorCaptcha}
                              explicit
                              size={'compact'}
                            />
                          </Col>
                        </MediaQuery>
                      </div>
                    </Row>

                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button
                          className="BtnLCAccent"
                          large="true"
                          loading={this.state.loading}
                          fill="true"
                          onClick={this.sendToApi}
                        >
                          {!this.state.sendRequest
                            ? this.state.type
                            : "Solicitud enviada"}{" "}
                        </Button>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Link to="/login">
                          <Button
                            className="ButtonTransparent marginTop-1em"
                            icon="arrow-left"
                            large="true"
                            fill="true"
                          >
                            Volver a inicio de sesión
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className={"mt-32"}>
                    <Footer />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </>
    );
  }
}

export default LoginFlow;
