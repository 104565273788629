import axios from 'axios';
import { config } from '../../../../system/Config';
import { getHeader } from '../../../../system/Sesion';
import React, { useState, useEffect } from "react";
import './StatisticalItems.scss'
import { skeletonClass } from '../../../../utilityFunctions';

const StatisticalItems = ({publication}) => {

  const [statistics, setStatistics] = useState({}) 
  

  const getStatistics = async () => {
    try {
      const response = await axios.get(
        `${config.URL_API}/v1/publication/${publication.id}/stats`,{
        headers: getHeader(),
        }
      );
      setStatistics(response.data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  useEffect(() => {
    getStatistics()
  }, []);


  const buildElement = (id, title, value, fontColor) => <div className={`statistical-items__container  ${fontColor ? `statistical-items__container--${fontColor}`: ''}`}>
    <div className= {`statistical-items__title`}>{title}</div>
    <div key={`${title}${id}`} className= {`statistical-items__value ${(value===undefined) ? skeletonClass : ''}`}>{value}</div>
  </div>
  
  return (
    <div className='statistical-items'>
      {buildElement(publication.id, 'Visualizaciones', publication.views, 'black')}
      {buildElement(publication.id, 'Preguntas', statistics?.comment_count)}
      {buildElement(publication.id, 'Propuestas', statistics?.transactions_count)}
      {buildElement(publication.id, 'Cambios en proceso', statistics?.in_progress_count)}
    </div>
  )
}

export default StatisticalItems;
