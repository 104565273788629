import React from 'react';
import withCable from 'hocs/withCable';
import { connect } from 'react-redux';
import { addChat, removeChat, updateChat } from '../store/actions';

class ChatCable extends React.Component {
  // cable connection
  cableConnection = null;

  componentDidMount() {
    this.cableConnection = this.props.cableApp.subscriptions.create({
      channel: 'ChatChannel',
      user_id: localStorage.getItem('userId'),
    }, {
      received: (response) => {
        if (response.data.remove) {
          this.props.onRemoveChat(response.data);
        } else if (response.data.create) {
          this.props.onAddChat(response.data);
        } else {
          this.props.onUpdateChat(response.data);
        }
      },
    });
  };

  render() {
    return null;
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onAddChat: (data) => dispatch(addChat(data)),
    onUpdateChat: (data) => dispatch(updateChat(data)),
    onRemoveChat: (data) => dispatch(removeChat(data)),
  };
};

export default connect(null, mapDispatchToProps)(withCable(ChatCable));
