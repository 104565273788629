import {
  SET_SCROLL_TO,
} from '../actions/actionTypes';

const initialState = {
  scrollTo: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_TO:
      return { ...state, scrollTo: action.payload };
    default:
      return state;
  }
};

export default reducer;
