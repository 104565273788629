import { Button, Divider } from '@blueprintjs/core';
import React, { useContext, useMemo } from 'react';

import Card from '../../../../components/card/Card';
import ChangeProposalModal from '../changeProposalModal';
import { sumOfPrices } from '../../../../utilityFunctions';
import WrittenChangeProposal from '../writtenChangeProposal';
import ChangeProposalPublications from '../changeProposalPublications';
import { ChangeOperationContext } from '../../providers/ChangeOperationProvider';
import { useGenericModal } from '../../../../hooks/useGenericModal/useGenericModal';

import './ChangeProposalContainer.scss';

const ChangeProposalContainer = ({ setTag, tag}) => {
  const { buildModal } = useGenericModal();
  const currency = localStorage.getItem('currency');
  const {
    selectedPublications,
    isSelectedPublication,
    setSelectedPublications,
  } = useContext(ChangeOperationContext);

  const total = useMemo(
    () => selectedPublications.length ? sumOfPrices(selectedPublications) : 0,
    [selectedPublications],
  );

  const hasPublications = useMemo(
    () => Boolean(selectedPublications.length),
    [selectedPublications],
  );

  const pluralizeSelectedTitle = (publications) => {
    return `Seleccionada${publications.length > 1 ? 's' : ''}`;
  };

  const publicationsToChangeModal = {
    content: (
      <ChangeProposalModal
        pluralizeSelectedTitle={pluralizeSelectedTitle}
        isSelectedPublication={isSelectedPublication}
        setSelectedPublications={setSelectedPublications}
        selectedPublications={selectedPublications}
      />
    ),
    isOpen: true,
    className: 'publications-card',
  };

  const request = (
    <div className="proposal-container">
      <h4 className="proposal-container__title">Qué ofreces a cambio</h4>
      <div className="proposal-container__add_publication">
        <p className="proposal-container__add_publication--title">Mis publicaciones</p>
        <Button
          fill
          small
          minimal
          onClick={() => buildModal(publicationsToChangeModal)}
          className="proposal-container__add_publication--button"
        >
          Agregar
        </Button>
      </div>
      <div className="proposal-container__publications_container">
        {hasPublications && <ChangeProposalPublications pluralizeSelectedTitle={pluralizeSelectedTitle} />}
      </div>
      <Divider className="proposal-container__divider" />
      {hasPublications && (
        <p className="proposal-container__total_value">
          <span className="proposal-container__total_value--bold">Total </span>
          <span>{currency} </span>
          <span>{total} </span>
        </p>
      )}
      <WrittenChangeProposal
        hasPublications={hasPublications}
        setTag={setTag}
        tag = {tag}
      />
    </div>
  );

  return (
    <section className="change-proposal-container">
      <Card className="proposal-container">
        {request}
      </Card>
    </section>
  );
};

export default ChangeProposalContainer;