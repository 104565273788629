import React, { memo } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Card, Elevation, Text } from "@blueprintjs/core";

import AvatarUser from "../../../../components/AvatarUser";
import UserName from "../../../../components/userName/UserName";

import { DRAWERS } from "../../../../utility/drawers";
import { updateDrawer } from "../../../../store/actions";
import { CHAT_KINDS, CHAT_KINDS_URL } from "../../../../utility/chat";
import {
  checkWithCurrentUser,
  datelocambio,
} from "../../../../utilityFunctions";

const Item = ({ chat }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const unread = chat.unread_messages > 9 ? "9+" : chat.unread_messages;
  const isCurrentChat = parseInt(id) === chat.id;
  const publication = chat.transaction.publication;
  const isOwner = checkWithCurrentUser(chat.owner.id);
  const user = isOwner ? chat.interested : chat.owner;
  const fullName = `${user?.name} ${user?.last_name}`;
  const typeText = `Propuesta ${isOwner ? "recibida" : "enviada"}`;

  const handleClickItem = () => {
    if (isCurrentChat) {
      return dispatch(updateDrawer({ [DRAWERS.chats]: false }));
    }
    const listKind =
      chat?.transaction?.status !== "active"
        ? CHAT_KINDS_URL[CHAT_KINDS.inProgress]
        : CHAT_KINDS_URL[CHAT_KINDS.proposal];
    history.push(`/chats/${chat.id}?tab=${listKind}`);
  };
  return (
    <Card
      interactive
      elevation={isCurrentChat ? Elevation.TWO : Elevation.ZERO}
      className={`item ${isCurrentChat && "item__active"}`}
      onClick={handleClickItem}
    >
      <Text className="date">
        <time>{datelocambio(chat.updated_at, "D MMM YYYY")}</time>
      </Text>
      <Link  to={`/profile/${user.id}`}>
      <AvatarUser user={user} /></Link>
      <section className="item__content">
        <header className="item__header">
          <Link to={`/profile/${user.id}`}>
            <UserName className ="bp3-text-overflow-ellipsis item__name" user={user} htmlTag='h4'></UserName>
          </Link>
        </header>
        <Text className="item__text">{typeText}</Text>
        <Text
          ellipsize
          tagName="h2"
          className="item__title"
          title={publication?.title}
        >
          {publication?.title}
        </Text>
      </section>
      {!!unread && (
        <div className="item__unread">
          <p className="item__unread-count">{unread}</p>
        </div>
      )}
    </Card>
  );
};

const areEqual = ({ chat: prevChat }, { chat: nextChat }) => {
  return (
    prevChat?.updated_at === nextChat?.updated_at &&
    prevChat?.unread_messages === nextChat?.unread_messages
  );
};

export default memo(Item, areEqual);
