import React from 'react';

import './loCambioSeguro/LoCambioSeguro.scss';
import LinkInfoPages from '../components/linkInfoPages/';
import ServicesLC from './home/components/ServicesLC';
import Footer from './home/components/footer/Footer';
import ScrollToElement from '../components/scrollToElement/ScrollToElement';
import { locambioServices } from '../const';

const { LOCAMBIO_SERVICES_TYPES } = locambioServices;

const LoCambioSeguro = () => {
  const importance = (
    <p className="default-text">
      Conscientes de la importancia que tiene la seguridad a la hora de
      concretar un cambio, hemos concebido LoCambio Seguro, poniendo a
      tu disposición nuestras Sedes de Cambio para ofrecerte una mayor seguridad.
    </p>
  );

  const firstTitle = (
    <h1 className="lo-cambio-seguro__secure-change-title-secondary">
      Cómo{' '}
      <span className="lo-cambio-seguro__secure-change-title-secondary--orange">
        solicitarlo
      </span>
    </h1>
  );

  const secondTitle = (
    <h1 className="lo-cambio-seguro__secure-change-title">
      CAMBIA{' '}
      <span className="lo-cambio-seguro__secure-change-title--black">
        SEGURO
      </span>
    </h1>
  );

  const step1 = (
    <p className="default-text">
      Desde el chat de tu negociación, presiona el botón <span className="purple-text bold">“LoCambio Seguro”</span>.
    </p>
  );

  const step2 = (
    <p className="default-text">
      Selecciona una de nuestras <span className="purple-text">Sedes Seguras</span>, una fecha y turno para confirmar tu cita de cambio.
    </p>
  );

  const step3 = (
    <p className="default-text">
      Escoge el <span className="purple-text">método de pago</span> de tu preferencia y listo. Asiste a la cita confirmada y completa tu cambio exitoso.
    </p>
  );

  const logo = (
    <img
      className="lo-cambio-seguro__logo"
      alt="locambio seguro logo"
      src="https://storage.googleapis.com/locambio-admin/pages/seguro/seguro0.png"
    />
  );

  const stepOneImg = (
    <img
      alt="locambio seguro step 1"
      src="https://storage.googleapis.com/locambio-admin/pages/seguro/step1.jpg"
    />
  );

  const stepTwoImg = (
    <img
      alt="locambio seguro step 2"
      src="https://storage.googleapis.com/locambio-admin/pages/seguro/step2.jpg"
    />
  );

  const stepThreeImg = (
    <img
      alt="locambio seguro step 3"
      src="https://storage.googleapis.com/locambio-admin/pages/seguro/step3.png"
    />
  );

  const bottomText = () => {
    let image = (
      <img
        className="coin-img"
        alt="locambio gana creditos"
        src="https://storage.googleapis.com/locambio-admin/pages/premium/coins0.svg"
      />
    );
    return (
      <div className="lo-cambio-ads__container--mid--bottom-text">
        {image}
        <p>
          <span className="purple-bold extra-bold">Gana créditos </span>
          <span className="bold">por publicar </span>Productos y Servicios en
          LoCambio.com y úsalos para probar nuestros servicios
        </p>
        {image}
      </div>
    );
  };

  const list1 = (
    <>
      <h3>Qué <yellowLight>Incluye</yellowLight></h3>
      <ol style={{paddingLeft: '20px'}}>
        <li><yellowLight>Espacios seguros</yellowLight> y controlados</li>
        <li>Personal de <yellowLight>seguridad</yellowLight></li>
        <li><yellowLight>Atención</yellowLight> al cliente</li>
        <li><yellowLight>Cámaras de vigilancia</yellowLight></li>
        <li><yellowLight>Control</yellowLight> de acceso</li>
      </ol>
    </>
  );

  const list2 = (
    <>
      <h3>Constancia de cambio</h3>
      <h3><font>realizado</font></h3>
      <ol style={{paddingLeft: '20px'}}>
        <li><font>Datos</font> de las partes involucradas</li>
        <li><font>Detalles</font> del acuerdo pautado</li>
        <li><font>Firma</font> digital</li>
        <li><font>Envío automático</font> al correo</li>
      </ol>
    </>
  );

  return (
    <>
      <ScrollToElement
        scroll={true}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      ></ScrollToElement>
      <div className="lo-cambio-seguro">
        <div className="section">
          <div className="lo-cambio-seguro__container">
            <div className="lo-cambio-seguro__container--top">
              <div className="lo-cambio-seguro__container--top--left">
                {logo}
                {secondTitle}
                {importance}
              </div>
              <div className="lo-cambio-seguro__container--top--right">
                <div className="lo-cambio-seguro__container--top--right--boxTop">
                  {list1}
                </div>
                <div className="lo-cambio-seguro__container--top--right--boxBottom">
                  {list2}
                </div>
              </div>
            </div>
            <div className="lo-cambio-seguro__container--mid">
              {firstTitle}
              <div className="lo-cambio-seguro__container--mid--steps">
                <div className="lo-cambio-seguro__info-box">
                  <div className="lo-cambio-seguro__info-box--step-number">
                    1
                  </div>
                  {step1}
                  {stepOneImg}
                </div>
                <div className="lo-cambio-seguro__info-box">
                  <div className="lo-cambio-seguro__info-box--step-number">
                    2
                  </div>
                  {step2}
                  {stepTwoImg}
                </div>
                <div className="lo-cambio-seguro__info-box">
                  <div className="lo-cambio-seguro__info-box--step-number">
                    3
                  </div>
                  {step3}
                  {stepThreeImg}
                </div>
              </div>
              {bottomText()}
            </div>
          </div>
          <div className="lo-cambio-seguro__slider-container">
            <ServicesLC
              services={[
                LOCAMBIO_SERVICES_TYPES.locambioVerificationId,
                LOCAMBIO_SERVICES_TYPES.locambioPremium,
                LOCAMBIO_SERVICES_TYPES.locambioAds,
              ]}
            />
          </div>
        </div>
        <div className="lo-cambio-seguro__links">
          <LinkInfoPages active={'LoCambio.com Seguro'} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoCambioSeguro;
