import { ADD_DRAWER, REMOVE_DRAWER, UPDATE_DRAWER } from '../actions/actionTypes';

const initialState = {};

const removeDrawer = (state, drawer) => {
  const { [drawer]: removedKey, ...newState } = state;
  return newState;
};

const updateDrawer = (state, drawerObject) => {
  const key = Object.keys(drawerObject)[0];
  if (typeof state[key] === 'boolean') {
    return { ...state, ...drawerObject };
  }
  return state;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DRAWER:
      return { ...state, ...action.data };
    case REMOVE_DRAWER:
      return removeDrawer(state, action.data);
    case UPDATE_DRAWER:
      return updateDrawer(state, action.data);
    default:
      return state;
  }
};

export default reducer;
