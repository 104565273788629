import React, { useState } from "react";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import LocambioPromos from "../../../../components/locambioPromos";
import PaymentResumeModal from "../../../../components/paymentResumeModal/PaymentResumeModal";

import "./SelectPlan.scss";
import { useGenericModal } from "../../../../hooks/useGenericModal/useGenericModal";
import BlurImage from '../../../../components/blurImage/BlurImage';

const SelectPlan = ({ title, image, id, handleSelectPlan }) => {
  const { buildModal, closeModal } = useGenericModal();
  const [currentPlan, setCurrentPlan] = useState();
  const [loading, setLoading] = useState(false);

  const paymentModal = {
    content: (
      <PaymentResumeModal
      currentPlan={currentPlan} 
      locambio_product_attributes={{
        publication_id: id,
        locambio_product_id: currentPlan?.id,
      }}/>
    )
  };

  return (
    <section className="select-plan">
      <Text className="select-plan__title select-plan__title--large" color="primary">{title}</Text>
      <BlurImage image={image} title={title} className="select-plan__image"/>
      <section className="select-plan__info">
        <Text tagName="span" className="select-plan__title" color="blue">
          Selecciona un paquete{" "}
        </Text>
        <Text tagName="span" className="select-plan__title">
          para promocionar
        </Text>
      </section>
      <LocambioPromos onSelectedPlan={setCurrentPlan} />
      <Button
        bold
        loading={loading}
        intent="accent"
        text="Promocionar"
        className="select-plan__button"
        disabled={!currentPlan}
        onClick={() => buildModal(paymentModal)}
      />
    </section>
  );
};

export default SelectPlan;
