import React from "react";
import "./InfoModal.scss";
import { useGenericModal } from "../../../../hooks/useGenericModal/useGenericModal";

const InfoModal = ({ displayModal }) => {

  const { buildModal, closeModal } = useGenericModal();

  const title = (
    <div className="info-modal__title">
      Agrega intereses
      <span className="info-modal__title--font"> a tus publicaciones</span>
    </div>
  )

  const content = (
      <p className="info-modal__content">
        <span className="info-modal__content--bold">Mientras más intereses agregues,</span>&nbsp;
        más usuarios verán tus publicaciones, aumentando las posibilidades de hacer negocios que coincidan con lo que quieres o necesitas.
      </p>
  );

  const modalActions = [
    {
      intent: 'accent',
      text: 'Entendido',
      onClick: () => (closeModal()),
    },
  ];
  const buildingModal = {
    title: title,
    content: content,
    isOpen: true,
    actions: modalActions,
    className: 'info-modal'
  };

  const modal = () => {
    buildModal(buildingModal)
  }

  return (
    <>
      {displayModal && modal()}
    </>
  );
};

export default InfoModal;
