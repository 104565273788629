import * as actionTypes from "./../actions/actionTypes";
import { updateObject } from "./../../utilityFunctions";

const initialState = {
    changeSuccess: false,
    idChange: null,
}

const setChange = (state, action) => {
    return updateObject(state, {
        changeSuccess: true,
        idChange: action.id
    })
}

const resetChange = (state, action ) => {
    return updateObject(state, {
        changeSuccess: false,
        idChange: null
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_CHANGE_SUCCESS: return setChange(state, action);
        case actionTypes.RESET_CHANGE_SUCCESS: return resetChange(state, action);
        default: return state;
    }
}

export default reducer;