import React, { useState } from 'react';
import Form from '../../../../components/customForm';
import { FormGroup, InputGroup, Spinner, TextArea } from '@blueprintjs/core';
import { config } from '../../../../system/Config';
import { Controller } from 'react-hook-form';

export default () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    return data;
  };

  const formRender = ({ memoCaptcha, control, errors }) => {
    return (
      <div className="form">
        <FormGroup
          label="Nombre Completo"
          labelFor="name"
          className="inputLabel"
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <div className="fullWidth">
                <InputGroup
                  {...field}
                  className="inputField"
                  id="name"
                  intent={errors.name && 'danger'}
                  type="text"
                  placeholder="Escribe aqui"
                />
                {errors.name && (
                  <p className="contact-form-error-message">
                    Este campo es obligatorio
                  </p>
                )}
              </div>
            )}
          />
        </FormGroup>
        <FormGroup label="Correo" labelFor="email" className="inputLabel">
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <div className="fullWidth">
                <InputGroup
                  {...field}
                  className="inputField"
                  id="email"
                  intent={errors.email && 'danger'}
                  type="text"
                  placeholder="Escribe aqui"
                />
                {errors.email && (
                  <p className="contact-form-error-message">
                    Este campo es obligatorio
                  </p>
                )}
              </div>
            )}
          />
        </FormGroup>
        <FormGroup label="Asunto" labelFor="subject" className="inputLabel">
          <Controller
            name="subject"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <div className="fullWidth">
                <InputGroup
                  {...field}
                  className="inputField"
                  id="subject"
                  intent={errors.subject && 'danger'}
                  type="text"
                  placeholder="Escribe aqui"
                />
                {errors.subject && (
                  <p className="contact-form-error-message">
                    Este campo es obligatorio
                  </p>
                )}
              </div>
            )}
          />
        </FormGroup>
        <FormGroup label="Mensaje" labelFor="message" className="inputLabel">
          <Controller
            name="message"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <div className="fullWidth">
                <TextArea
                  {...field}
                  className="round-corners"
                  placeholder="Escribe aqui"
                  intent={errors.message && 'danger'}
                  growVertically
                  large
                  fill
                />
                {errors.message && (
                  <p className="contact-form-error-message">
                    Este campo es obligatorio
                  </p>
                )}
              </div>
            )}
          />
        </FormGroup>
        {memoCaptcha}
        <div className="contact-form-footer">
          <button
            className={'formButton'}
          >
            {loading ? (
              <Spinner className={'contact-form-spinner'} size="20" />
            ) : (
              'Enviar'
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="form-width">
      <h5 className="form-title">Queremos saber tu opinión</h5>
      <p className="form-description">
        Con tu apoyo podemos mejorarnos cada día y brindar la plataforma que
        queremos para Venezuela
      </p>
      <Form
        url={`${config.URL_API}/contact_forms`}
        verifiData={onSubmit}
        hasCaptcha
        successMessage="Los datos han sido enviados exitosamente"
        resetFormOnSuccess
        setLoadingButton={setLoading}
      >
        {(params) => <>{formRender(params)}</>}
      </Form>
    </div>
  );
};
