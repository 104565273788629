import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Text } from '@blueprintjs/core';

import List from './components/list';
import Panel from './components/panel';
import DrawerButton from './components/drawerButton';

import { DRAWERS } from '../../utility/drawers';
import { CHAT_KINDS } from '../../utility/chat';

import './Chat.scss';

const Chat = () => {
  const isOpen = useSelector(({ drawers }) => drawers[DRAWERS.chats]);
  const chatsCount = useSelector(({ chat }) => chat[CHAT_KINDS.proposal].length + chat[CHAT_KINDS.inProgress].length);

  return (
    <main className={`chat ${isOpen || !chatsCount ? '' : 'chat--full'}`}>
      {
        chatsCount ? (
          <>
            <DrawerButton />
            <List />
            <Panel />
          </>
        ) : (
          <section className="chat__empty-state">
            <Icon icon="chat" iconSize={30} className="mb-4" />
            <Text tagName="h2">
              No hay chats por el momento
            </Text>
          </section>
        ) 
      }
    </main>
  );
};

export default Chat;