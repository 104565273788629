import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@blueprintjs/core";

export default () => (
  <div className={"OverlayPremium p-4"}>
    <figure className={"w-full h-full flex items-center"}>
      <h4 className={"margin0 marginBottom-05em"}>Contenido Premium</h4>
      <p className={"margin0"}>
        Para poder visualizar este contenido debes activar la suscripción
        premium
      </p>
      <div className={"btnOverlayPremium"}>
        <Link to="/suscriptions">
          <Button
            alignText={"center"}
            fill={true}
            
            className={"BtnLCPrimary marginTop-1em marginBottom-1em"}
          >
            Activar Premium
          </Button>
        </Link>
      </div>
    </figure>
  </div>
);
