import { REMOVE_MODAL, UPDATE_MODAL } from '../actions/actionTypes';

const initialState = {
  title: '',
  content: '',
  actions: [],
  resource: {},
  onClose: null,
  className: '',
  headerClassName: '',
  bodyClassName: '',
  footerClassName: '',
  children: null,
  isOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODAL:
      return { ...state, ...action.data };
    case REMOVE_MODAL:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
