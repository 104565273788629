import { Icon } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import React, { useEffect, useMemo, useState } from 'react';

import Button from '../../../../components/button';
import { authAxios } from '../../../../utility/axios';
import { sumOfPrices } from '../../../../utilityFunctions';
import ChangeProposalItem from '../changeProposalItem';
import { useGenericModal } from '../../../../hooks/useGenericModal/useGenericModal';

import './ChangeProposalModal.scss';

const ChangeProposalModal = ({
  pluralizeSelectedTitle,
  isSelectedPublication,
  setSelectedPublications,
  selectedPublications,
}) => {
  const { closeModal } = useGenericModal();
  const currency = localStorage.getItem('currency');
  const [filteredItems, setFilteredItems] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState('');
  const [userPublicationsActives, setUserPublicationsActives] = useState([]);
  const [selectedModalPublications, setSelectedModalPublications] = useState([]);
  const selectedPublicationsIds = useMemo(
    () => selectedPublications.map((publication) => publication.id),
    [selectedPublications],
  );

  useEffect(() => {
    authAxios.get('/users/active_publications')
      .then(({ data }) => {
        setUserPublicationsActives(data);
        const newSelectedModalPublications = data.filter((publication) => {
          return selectedPublicationsIds.includes(publication.id);
        });
        setSelectedModalPublications(newSelectedModalPublications);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setFilteredItems(userPublicationsActives);
  }, [userPublicationsActives]);

  const addSelectedModalPublications = (publication) => {
    setSelectedModalPublications([...selectedModalPublications, publication]);
  };

  const removeSelectedModalPublications = (id) => {
    setSelectedModalPublications(selectedModalPublications.filter(publication => publication.id !== id));
  };

  const filterItems = (query) => {
    setInputFieldValue(query);
    setFilteredItems(
      userPublicationsActives.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase()),
      ),
    );
  };

  const clearInput = () => {
    setInputFieldValue('');
  };

  const handleOnClickModal = () => {
    setSelectedPublications(selectedModalPublications);
    closeModal();
  };

  return (
    <section className="change-proposal-modal">
      <section className="change-proposal-modal__top">
        <p className="change-proposal-modal__top--title">Selecciona tus Publicaciones</p>
        <div className="change-proposal-modal__top--filter-bar">
          <Icon icon="filter-list" className={'change-proposal-modal__top--filter-bar-input-filter'} />
          <Suggest
            className="change-proposal-modal__top--filter-bar-input"
            inputProps={{ placeholder: '' }}
            query={inputFieldValue}
            placeholder="Selecciona las publicaciones"
            items={userPublicationsActives}
            itemRenderer={() => null}
            inputValueRenderer={(item) => item.title}
            onQueryChange={(query) => filterItems(query)}
          />
          <Icon
            icon="cross"
            className={'change-proposal-modal__top--filter-bar-input-cross'}
            onClick={clearInput}
          />
        </div>

        <p className="change-proposal-modal__top--counter">
          <span className="change-proposal-modal__top--counter-choiced">
            {pluralizeSelectedTitle(selectedModalPublications)}: {selectedModalPublications.length}
          </span>
        </p>
      </section>
      <section className="change-proposal-modal__middle">
        <div className="change-proposal-modal__middle--publication-form__container">
          {!userPublicationsActives ?
            <p className="change-proposal-modal__middle--publication-form__container__without_publication">No tienes Publicaciones activas</p> :
            filteredItems.map((pub) => (
              <ChangeProposalItem
                id={pub?.id}
                key={`ModalChangeProposalItem${pub?.id}`}
                price={pub?.price}
                title={pub?.title}
                image={pub?.image}
                linkTo={pub?.linkTo}
                currency={currency}
                isModal
                defaultChecked={isSelectedPublication(pub?.id)}
                addSelectedModalPublications={addSelectedModalPublications}
                removeSelectedModalPublications={removeSelectedModalPublications}
              />
            ))
          }
        </div>
      </section>
      <section className="change-proposal-modal__bottom">
        {
          selectedModalPublications && (
            <p className="change-proposal-modal__bottom--total">
              <span className="change-proposal-modal__bottom--total-bold">Total</span>
              {`${currency} ${sumOfPrices(selectedModalPublications)}`}
            </p>
          )
        }
      </section>
      <section className="change-proposal-modal__button-wrapper">
        <Button
          className="change-proposal-modal__button"
          intent="accent"
          text="Listo"
          onClick={handleOnClickModal}
          disabled={!userPublicationsActives}
        />
      </section>
    </section>
  );
};

export default ChangeProposalModal;