import React from 'react';
import { Callout, Icon } from '@blueprintjs/core';
import PropTypes from "prop-types"

export default function PasswordPopover({ regularExpressions, password }) {
  return (
    <div className="popOverContent">
      <Callout className="" icon="key" title="Tu contraseña debe contener">
        <ul>
          <li>
            {password.length < 6 ? (
              <Icon className="iconRed" icon="cross" />
            ) : (
              <Icon className="iconGreen" icon="tick" />
            )}
            Al menos seis carácteres
          </li>
          <li>
            {!regularExpressions.upperCase.test(password) ? (
              <Icon className="iconRed" icon="cross" />
            ) : (
              <Icon className="iconGreen" icon="tick" />
            )}
            Al menos una mayúscula
          </li>
          <li>
            {!regularExpressions.number.test(password) ? (
              <Icon className="iconRed" icon="cross" />
            ) : (
              <Icon className="iconGreen" icon="tick" />
            )}
            Al menos un carácter numérico
          </li>
          <li>
            {!regularExpressions.special.test(password) ? (
              <Icon className="iconRed" icon="cross" />
            ) : (
              <Icon className="iconGreen" icon="tick" />
            )}
            Al menos un carácter especial ("!#@?-_&*")
          </li>
        </ul>
      </Callout>
    </div>
  );
}

PasswordPopover.propTypes = {
  regularExpressions: PropTypes.object,
  password: PropTypes.string
}