import React from 'react';

import Accordion from '../../components/accordion';
import Footer from '../home/components/footer/Footer';
import LinkInfoPages from '../../components/linkInfoPages';
import ScrollToElement from '../../components/scrollToElement/ScrollToElement';

import './SecurityAdvice.scss';

const SecurityAdvice = () => {
  const data = {
    title: 'Consejos de seguridad',
    sections: [
      {
        title: '',
        questions: [
          {
            id: 1,
            title: 'Publicaciones',
            answer: (
              <ul className="security-advice__list">
                <li>
                  No compartas información privada como dirección, cédula de
                  identidad o teléfono. Recuerda que lo que publiques en tu
                  anuncio será información de acceso público.
                </li>
                <li>
                  Utiliza la plataforma para definir el punto de intercambio y
                  mantener cualquier comunicación con interesados. Elige puntos
                  de encuentro públicos, con cámaras y de fácil acceso para la
                  comodidad y seguridad de los usuarios, así como horarios
                  matutinos..
                </li>
                <li>
                  Se detallista en tus publicaciones para evitar cualquier
                  malentendido. En el chat de soporte se te proporcionará apoyo
                  en caso de cualquier inconveniente.
                </li>
                <li>
                  Utiliza fotos del artículo que estás ofreciendo, no coloques
                  imágenes genéricas o de Internet.
                </li>
                <li>
                  Ponemos a tu disposición nuestro servicio LoCambio Seguro, que
                  te ofrece una gama de opciones que proporcionan seguridad a la
                  hora del cambio.
                </li>
              </ul>
            ),
          },
          {
            id: 2,
            title: 'Cambio',
            answer: (
              <ul className="security-advice__list">
                <li>
                  Valida la identidad del interesado en cambiar pidiéndole una
                  copia de su documento de identidad.
                </li>
                <li>
                  Utiliza siempre la mensajería instantánea que ofrece nuestro
                  chat de intercambios, para registrar los acuerdos conversados
                  en la negociación de intercambio.
                </li>
                <li>
                  Asegúrate de cambiar el artículo solo con la persona con la
                  que hayas hecho el contacto o con Verifica el funcionamiento y
                  características generales del artículo o servicio al momento
                  del cambio. Lo más importante es tu conformidad al momento de
                  recibir un producto o servicio.Utiliza fotos del artículo que
                  estás ofreciendo, no coloques imágenes genéricas o de
                  Internet.
                </li>
                <li>
                  Ponemos a tu disposición nuestro servicio LoCambio Seguro, que
                  te ofrece una gama de opciones que proporcionan seguridad a la
                  hora del cambio.
                </li>
              </ul>
            ),
          },
          {
            id: 3,
            title: 'Artículos',
            answer: (
              <ul className="security-advice__list">
                <li>
                  Asegura que tu artículo cuente con las especificaciones, los
                  atributos o accesorios que están en la publicación.
                </li>
                <li>
                  Revisa la lista de Artículos no permitidos en las ¨Preguntas
                  frecuentes¨ y en ¨Términos y Condiciones¨ para evitar que tu
                  publicación sea rechazada.
                </li>
                <li>
                  Para las publicaciones de alto valor, valida los documentos de
                  propiedad legal sobre el bien antes de realizar cualquier
                  permuta.
                </li>
              </ul>
            ),
          },
          {
            id: 4,
            title: 'Usuarios',
            answer: (
              <ul className="security-advice__list">
                <li>
                  Nunca reveles tu nombre de usuario o contraseña a otras
                  personas.
                </li>
                <li>
                  Utiliza una contraseña segura que contenga al menos seis (6)
                  caracteres incluyendo números, símbolos y mayúsculas.
                </li>
                <li>
                  No permitas que la clave se guarde en el navegador de Internet
                  en computadoras de uso público.
                </li>
                <li>
                  Si crees que alguien está usando la plataforma de forma
                  fraudulenta, ve a Contáctanos y reporta al usuario o
                  publicación.
                </li>
                <li>
                  Denuncia a las autoridades pertinentes de tu ciudad en caso de
                  haber sido víctima de fraude y notificarnos a través del Chat
                  de Soporte.
                </li>
              </ul>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
              <ScrollToElement
          scroll={true}
          options={{ behavior: 'auto', block: 'center' }}
          highlight={false}
        >
        </ScrollToElement>
      <div className="security-advice">
        <div className="security-advice__container">
          <Accordion data={data} initialDisplay />
        </div>
        <div className="security-advice__links">
          <LinkInfoPages active={'Consejos de Seguridad'} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SecurityAdvice;
