import * as actionTypes from '../actions/actionTypes';

const initialState = {
  initialLoading: true,
  notifications: [],
};

const setNotifications = (state, action) => {
  return {
    ...state,
    notifications: [
      ...action.data,
    ]
  }
};

const updateNotification = (state, action) => {
  const { data } = action;
  const indexNotification = state.notifications.findIndex(notification => notification.id === data.id);
  const notifications = {};

    if (indexNotification < 0) {
      notifications['notifications'] = [data, ...state.notifications];
    } else {
      notifications['notifications'] = [...state.notifications];
      notifications['notifications'].splice(indexNotification, 1);
      notifications['notifications']  = [data, ...notifications['notifications']];
    }

  return {
    ...state,
    ...notifications,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATIONS:
      return setNotifications(state, action);
    case actionTypes.UPDATE_NOTIFICATIONS:
      return updateNotification(state, action);
    default:
      return state;
  }
};

export default reducer;