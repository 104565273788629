import * as actionTypes from '../actions/actionTypes';

import { updateObject, TYPESEARCH } from './../../utilityFunctions';

const initialState = {
  typeSearch: localStorage.getItem("typeSearch") || TYPESEARCH.TAGS
}

const setTypeSearch = (state, action) => {
  return updateObject(state,  {
    typeSearch: action.typeSearch
  })
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_TYPE_SEARCH: return setTypeSearch(state, action);
    default:
      return state;
  }
}

export default reducer;
