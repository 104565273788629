import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { collectionPropType, withSwiperPropType } from '../../propTypes';

import CollectionItem from '../collectionItem';

const Collection = ({ withSwiper, collection }) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });
  const hideSwiper = !withSwiper && !isMobileOrTablet;

  const settings = {
    modules: [Pagination, Navigation],
    navigation: true,
    initialSlide: 1,
    slidesPerView: isMobileOrTablet ? 1 : 3,
    spaceBetween: 46,
    centeredSlides: true,
    loop: true,
    pagination: { type: 'bullets', clickable: true },
  };

  const allCollection = collection.map((collectionItem) => {
    const key = `card-collection-slide-${collectionItem.image}`;
    const content = <CollectionItem {...collectionItem} key={key} />;

    if (hideSwiper) return content;

    return (
      <SwiperSlide key={key}>
        {content}
      </SwiperSlide>
    );
  });

  return (
    <section className="collection">
      {
        hideSwiper ?
          allCollection :
          <Swiper {...settings}>{allCollection}</Swiper>
      }
    </section>
  );
};

Collection.propTypes = {
  withSwiper: withSwiperPropType,
  collection: collectionPropType,
};

export default Collection;