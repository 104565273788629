import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import './Registration.scss';
import IndividualForm from './Components/individualForm';
import CompanyForm from './Components/companyForm';
import { Controller } from 'react-hook-form';
import { provincesColombia, provincesPeru, provincesVenezuela } from 'const';
import Form from '../../components/customForm';
import { Checkbox, Icon, FormGroup, Button, Dialog } from '@blueprintjs/core';
import { Link, withRouter } from 'react-router-dom';
import { config } from '../../system/Config';
import * as actions from './../../store/actions/index';
import { useDispatch, connect } from 'react-redux';
import history from '../../utility/history';
import PopUp from '../../components/popUp';
import { useMediaQuery } from 'react-responsive';

function Register(props) {
  const dispatch = useDispatch();
  const [registerType, setRegisterType] = useState('individual');
  const [registerData, setRegisterData] = useState({});
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [states, setStates] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [identityVerification, setidentityVerification] = useState(false);
  const [countries, setCountries] = useState([
    { label: 'Seleccionar', value: '', disabled: true, selected: true },
    { label: 'Colombia', value: 'CO', disabled: true },
    { label: 'Perú', value: 'PE', disabled: true },
    { label: 'Venezuela', value: 'VE' },
  ]);
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const handleChangeidentityVerification = () => {
    setidentityVerification(!identityVerification);
  };

  const name = props.omniauth_user?.name;
  const lastName = props.omniauth_user?.last_name;
  const email = props.omniauth_user?.email;
  const token = props.omniauth_user?.token;
  

  const onSubmit = (data, e) => {
    if (data.password !== data.confirmPassword) {
      setLoadingButton(false)
      return { error: 'Las contraseñas no coinciden.' };
    }
    // let formatedData = {};

    const formatedData = new FormData();
    if (registerType === 'individual'){
      formatedData.append("name", data.firstName);
      formatedData.append("last_name", data.lastName);
      formatedData.append("email", data.email);
      formatedData.append("phone", `+58${data.phoneType}${data.phoneString}`);
      formatedData.append("date_of_birth", `${data.year}-${data.month}-${data.day}`);
      formatedData.append("gender", data.gender);
      formatedData.append("password", data.password);
      formatedData.append("city", data.city);
      formatedData.append("state", data.state);
      formatedData.append("country", data.country);
      if (data.profilePicture) {
        formatedData.append(`picture_data[0]`, data.profilePicture?.[0].image); //avatar
        formatedData.append(`picture_data[1]`, data.profilePicture?.[1].image); //thumb
      }
      formatedData.append("identity_verification", identityVerification);
      formatedData.append("email_path", "/confirm/user");
    }

    ReactPixel.init('317028946379263', {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    });
    ReactPixel.pageView();
    ReactPixel.track('successful_registration', 'Successful registration');

    return formatedData;
  };

  useEffect(() => {
    setStates({
      CO: formatStates(provincesColombia),
      PE: formatStates(provincesPeru),
      VE: formatStates(provincesVenezuela),
    });
    return () => {
      dispatch(actions.authIncomplete(false, null, null));
    };
  }, []);
  

  const formatStates = (states) => {
    return [
      { label: 'Seleccionar', value: '', disabled: true, selected: true },
    ].concat(
      states.map((elem) => {
        return { label: elem, value: elem };
      })
    );
  };

  function handleShowSecurityModal(handleShowSecurityModal) {
    setShowSecurityModal(!showSecurityModal);
  }

  const handleTabClick = (e) => {
    setRegisterType(e);
  };
  const handleFormChange = (event) => {
    const key = event.target.name;
    const newForm = { ...registerData };
    newForm[key] = true;
    setRegisterData(newForm);
  };

  const goToLoginOnSuccess = () => {
    history.push(`/login`);
  };

  const formRender = (params) => {
    return (
      <IndividualForm
        name={name}
        lastName={lastName}
        email={email}
        register={params.register} 
        errors={params.errors}
        handleFormChange={handleFormChange}
        countries={countries}
        states={states}
        control={params.control}
        regularExpressions={params.regularExpressions}
        hasPassword={!props.omniauth}
        setValue={params.setValue}
        resetField={params.resetField}
      />
    );
  };

  return (
    <div className="registrationContainer">
      <div className="tabRow">
        <div className="tab">
          <h2
            onClick={(e) => handleTabClick('individual')}
            className={registerType === 'individual' ? 'active' : 'disabled'}
          >
            Regístrate
          </h2>
          {registerType === 'individual' && <hr className="activeBar" />}
        </div>
      </div>
      <Form
        url={
          props.omniauth
            ? `${config.URL_API}/complete_registration/${token}`
            : `${config.URL_API}/users` 
        } 
        // {`${config.URL_API}/users`} //TODO: CHANGE URL TO UPDATE
        verifiData={onSubmit}
        hasCaptcha={true}
        successMessage={
          props.omniauth
            ? 'Listo! Ya puedes ingresar con tu Cuenta de Google o Facebook'
            : '¡Registro exitoso! Verifica tu cuenta con el enlace enviado a tu correo.'
        }
        goToLoginOnSuccess={goToLoginOnSuccess}
        setLoadingButton={setLoadingButton}
        redirect="/login"
      >
        {(params) => (
          <>
            {formRender(params)}

            <hr className="divisionLine" />

            <div className="marginLeft">
              <Controller
                name="termsAndConditions"
                control={params.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <Checkbox
                      {...field}
                      id="termsAndConditions"
                      className={`checkBoxAlign ${
                        params.errors.termsAndConditions && 'redText'
                      }`}
                    >
                      <p>
                        Acepto los{' '}
                        <Link
                          to="/terminos-y-condiciones/"
                          className={`${
                            params.errors.termsAndConditions && 'redText'
                          }`}
                        >
                          <ins className="verification_link">Términos</ins> y <ins className="verification_link">Condiciones</ins>
                        </Link>
                        , así como las{' '}
                        <Link
                          to="/politicas-de-privacidad/"
                          className="verification_link"
                        >
                          Políticas de Privacidad
                        </Link>
                      </p>
                    </Checkbox>
                    {params.errors.termsAndConditions && (
                      <p className="errorMessage">
                        Debe aceptar los terminos y condiciones
                      </p>
                    )}
                  </>
                )}
              />
            </div>

            <div className="marginLeft">
              <Controller
                name="identityVerification"
                control={params.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <Checkbox
                      {...field}
                      id="identityVerification"
                      onChange={handleChangeidentityVerification}
                      className={`checkBoxAlign ${
                        params.errors.identityVerification && 'redText'
                      }`}
                    >
                      <p>
                        Deseo ser contactado por LoCambio.com para{' '}
                        <Link onClick={handleShowSecurityModal}>
                          <span className="verification_link">Verificar la Identidad </span>
                        </Link>{' '}
                        de mi cuenta
                      </p>
                    </Checkbox>
                  </>
                )}
              />
            </div>
            {params.memoCaptcha}

            <Button
              className={'formButton'}
              type="submit"
              loading={loadingButton}
            >
              Registrarme
            </Button>
          </>
        )}
      </Form>
      <PopUp
        displayModal={showSecurityModal}
        hideModal={handleShowSecurityModal}
        >
        <img src={isLargerThanMobile? "https://storage.googleapis.com/locambio-admin/popups/registration/popup0-600x400.jpg" : "https://storage.googleapis.com/locambio-admin/popups/registration/popup0-250x300.jpg"} />
      </PopUp>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    incompleteRegister: state.auth.incompleteRegister,
    omniauth_user: state.auth.omniauth_user,
    omniauth: state.auth.omniauth,
  };
};

export default withRouter(connect(mapStateToProps)(Register));
