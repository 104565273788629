import { useParams } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

import history from '../../utility/history';
import { config } from '../../system/Config';
import Loader from '../../components/loader';
import { v1AuthAxios } from '../../utility/axios';
import Button from '../../components/button/Button';
import Form from '../../components/customForm/CustomForm';
import PageWithTitle from '../../components/pageWithTitle';
import { checkWithCurrentUser } from '../../utilityFunctions';
import ChangeProposalContainer from './components/changeProposalContainer';
import ChangeProposalPublicationInfo from './components/changeProposalPublicationInfo';
import ChangeOperationProvider, { ChangeOperationContext } from './providers/ChangeOperationProvider';

import './ChangeOperation.scss';

const ChangeOperationWrapper = () => {
  return (
    <ChangeOperationProvider>
      <ChangeOperation />
    </ChangeOperationProvider>
  );
};

const ChangeOperation = () => {
    const { id } = useParams();
    const transactionIdRef = useRef();
    const [tag, setTag] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [publication, setPublication] = useState(null);
    const { selectedPublications, setSelectedPublications } = useContext(ChangeOperationContext);
    const isUpdate = Boolean(transactionIdRef.current);
    const disabledSubmitButton = !selectedPublications.length && !tag;

    useEffect(() => {
      setLoading(true);
      v1AuthAxios.get(`/transactions/show_by_publication/${id}`)
                 .then(({ data }) => {
                   handleProposalData(data.proposal);
                   setPublication(data.publication);
                 })
                 .finally(() => setLoading(false));
    }, []);

    const handleSubmit = () => {
      return {
        tags: tag,
        publication_id: publication.id,
        publication_interesteds_ids: selectedPublications.map((selectedPublication) => selectedPublication.id),
      };
    };

    const successUtilityFunction = (request) => {
      const id = request?.data?.chat?.id
      if (id) { history.push(`/chats/${id}`); }
    }

    const handleProposalData = (proposal) => {
      if (proposal) {
        const isOwner = checkWithCurrentUser(proposal.interested_id);
        const verifyPublication = Number(id) === proposal.publication_id;
        isOwner && verifyPublication ? setProposalData(proposal) : history.push('/wall');
      }
    };

    const setProposalData = ({ id, publication_interesteds, tags }) => {
      transactionIdRef.current = id;
      setSelectedPublications(publication_interesteds || []);
      setTag(tags);
    };

    const formRender = () => {
      return (
        <>
          {publication && <ChangeProposalPublicationInfo {...publication} />}
          <ChangeProposalContainer setTag={setTag} tag={tag} />
          <Button
            className="change-operation__send_request--button"
            type="submit"
            intent="accent"
            loading={loadingSubmit}
            disabled={disabledSubmitButton}
            bold
          >
            <p>Enviar Propuesta</p>
          </Button>
        </>
      );
    };

    const mainSection = (
      <section className="change-operation">
        {
          loading ?
            <Loader /> :
            <Form
              url={isUpdate ?
                `${config.URL_API}/v1/transactions/${transactionIdRef.current}` :
                `${config.URL_API}/v1/transactions/`}
              verifiData={handleSubmit}
              successMessage={
                isUpdate
                  ? 'Propuesta actualizada exitosamente'
                  : 'Propuesta enviada exitosamente'
              }
              hasCaptcha={false}
              successUtilityFunction={ successUtilityFunction }
              isUpdate={isUpdate}
              header
              setLoadingButton={setLoadingSubmit}
            >
              {(params) => formRender(params)}
            </Form>
        }
      </section>
    );

    return (
      <PageWithTitle title="Mi propuesta" children={mainSection} className="change-operation__page_with_title" historyBack/>
    );
  }
;

export default ChangeOperationWrapper;
