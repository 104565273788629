import React from 'react';

import './AboutLoCambio.scss';
import LinkInfoPages from '../../components/linkInfoPages';
import { Link } from 'react-router-dom';
import Card from './components/card';
import Footer from '../home/components/footer/Footer';
import { useMediaQuery } from 'react-responsive';
import ScrollToElement from '../../components/scrollToElement/ScrollToElement';

export default function AboutLoCambioNew() {
  const isNotMobile = useMediaQuery({ minWidth: 769 });

  const mision = (
    <p>
      <b>Traer el trueque a la era digital</b> con la más completa, segura, e
      innovadora aplicación web, <b>para Venezuela y Latinoamérica</b>,
      conectando a todos aquellos individuos que estén interesados en canjear
      y/o negociar artículos, bienes, servicios y habilidades,{' '}
      <b>sin que sea necesario mediar exclusivamente con dinero.</b>
    </p>
  );
  const vision = (
    <p>
      <b>Convertirnos</b> en la plataforma digital líder de trueque en Venezuela
      y Latinoamérica. <br />
      <br />
      <b>Deseamos ser</b> agentes transformadores del sistema económico
      ordinario, contribuyendo a modificar el ciclo de vida de los artículos en
      desuso al darles una segunda oportunidad. <br />
      <br />
      <b>Ser el vehículo</b> entre personas y comunidades, para generar impacto
      social y conciencia ecológica en la población a través del canje,{' '}
      <b>
        fomentando la economía <span className="orangeText">colaborativa</span>{' '}
        y<span className="purpleText"> circular.</span>
      </b>
    </p>
  );
  const valores = (
    <ul className="valores">
      <li>Innovación tecnológica.</li>
      <li>Etica Ambiental.</li>
      <li>Sostenibilidad</li>
      <li>Conectividad.</li>
      <li>Conciencia social.</li>
      <li>Pasión por el trabajo.</li>
    </ul>
  );

  return (
    <>
              <ScrollToElement
          scroll={true}
          options={{ behavior: 'auto', block: 'center' }}
          highlight={false}
        >
        </ScrollToElement>
      <div className="about_container">
        <div className="section">
          <div className="AboutLoCambio">
            <div className="firstSection">
              <div className="row">
                <img
                  className="aboutImage"
                  src="https://storage.googleapis.com/locambio-admin/pages/about/about-locambio.png"
                />
                <div className="aboutTextSection">
                  <h1 className="title">
                    Qué es <span className="orangeText">LoCambio.com</span>
                  </h1>

                  <p className="aboutText">
                    LoCambió.com es una <b>plataforma web,</b> que ofrece a sus
                    usuarios la alternativa de intercambiar sus bienes, artículos,
                    servicios y hasta habilidades, de una manera fácil y segura
                    diseñada para brindar una experiencia única.
                    <br />
                    <br />
                    <b>
                      Sin pagos <span className="purpleText">ni comisiones</span>
                    </b>{' '}
                    de ningún tipo por transacciones realizadas. Los números de
                    publicaciones, cambios y propuestas, son <b>ilimitados</b>;
                    con total libertad de expresión y comunicación entre los
                    usuarios durante sus negociaciones.
                    <br />
                  </p>
                </div>
              </div>
              <p className="aboutText">
                <b>
                  Es un marketplace{' '}
                  <span className="orangeText">diferenciado,</span>
                </b>{' '}
                con funcionalidad de red social, donde la principal premisa del
                sitio es el Cambio o Permuta de Bienes y Servicios.
                <br />
                <br /> <b>Contamos con servicios que ofrecen seguridad</b> y
                comodidad al usuario a la hora de realizar cambios, los cuales
                serán{' '}
                <b>
                  <span className="purpleText">gratuitos</span> durante nuestra{' '}
                  <span className="orangeText">promoción de lanzamiento.</span>
                </b>
              </p>
              <div className="cardRow">
                <Card
                  Children={mision}
                  title="MISIÓN"
                  gradient="orangeGradient"
                  width="100%"
                />
                <Card
                  Children={vision}
                  title="VISIÓN"
                  gradient="purpleGradient"
                  width="62%"
                />
                <Card
                  Children={valores}
                  title="VALORES"
                  gradient="blackGradient"
                  width="30%"
                />
              </div>
            </div>
            <div className="secondSection">
              <h2 className="subTitle">
                Cómo <span className="orangeText">funciona</span>
              </h2>
              <div className="row justify_row">
                <div className="howFirstColumn">
                  <p className="howText">
                    <b>
                      Entra en LoCambio.com, crea una cuenta y comienza a publicar
                    </b>{' '}
                    tus artículos, productos, servicios o habilidades. También
                    puedes hacer tus propuestas de lo que quieres o necesitas,
                    interactuando con otros a través del chat. Luego de hacer las
                    respectivas negociaciones,{' '}
                    <b>
                      <span className="purpleText">
                        pacta con el otro usuario
                      </span>{' '}
                      el día, hora y lugar para lograr el{' '}
                      <span className="orangeText">cambio exitoso.</span>
                    </b>
                  </p>
                  <h3 className="sectionTitle purpleText">
                    Explora Publicaciones
                  </h3>
                  <p className="howText">
                    <b>
                      Existen{' '}
                      <span className="orangeText">dos formas de explorar</span>
                    </b>{' '}
                    publicaciones en LoCambio.com:
                  </p>
                  <h3 className="sectionNumeration">
                    <span className="purpleText">{'1)'} </span>Explorador
                    Bidireccional
                  </h3>
                  <p className="howText">
                    <span className="purpleText">
                      Ubicado en la parte superior central
                    </span>{' '}
                    de nuestra <b>barra de navegación.</b>
                  </p>
                  <p className="howText">
                    Se trata de un novedoso buscador de artículos, que consta de
                    dos partes:
                  </p>
                  <div className="row">
                    <div className="column">
                      <div className="row imageRow">
                        <img
                          className="searchImage"
                          src="https://storage.googleapis.com/locambio-admin/pages/about/searchbar1.png"
                        />
                        <p className="searchInfo">
                          {' '}
                          1.
                          <b className="purpleText">Lo quiero:</b> La barra de
                          búsqueda encontrará los artículos o servicios que el
                          usuario quiere a cambio.
                        </p>
                      </div>
                      <div className="row imageRow">
                        <img
                          className="searchImage"
                          src="https://storage.googleapis.com/locambio-admin/pages/about/searchbar2.png"
                        />
                        <p className="searchInfo">
                          2.
                          <b className="orangeText">Lo tengo:</b> La barra de
                          búsqueda encontrará los artículos o servicios que
                          ofrecen otros usuarios por lo que tienes.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h3 className="sectionNumeration">
                    <span className="purpleText">{'2)'} </span>Mural
                  </h3>
                  <div className="row imageRow">
                    <p className="wallInfo">
                      <span className="purpleText">
                        Ubicado en la parte inferior derecha{' '}
                      </span>{' '}
                      de nuestra <b>barra de navegación</b>
                    </p>
                    <img
                      className="wallImage"
                      src="https://storage.googleapis.com/locambio-admin/pages/about/navigation.png"
                    />
                  </div>
                  <p className="wallInfoAlone">
                    <b>Gracias a la función de geolocalización</b>, podrás{' '}
                    <b>ver y explorar</b>
                    las publicaciones de artículos, servicios profesionales y
                    habilidades más <b>cerca de ti</b>, según la ubicación que
                    hayas proporcionado al momento de registrarte.{' '}
                  </p>
                  <p className="wallInfoAlone">
                    <b>
                      <span className="orangeText">
                        ¡Únete a la red social donde conviven la Oferta y la
                        Demanda
                      </span>{' '}
                      de productos y servicios que satisfacen necesidades!
                    </b>
                  </p>
                </div>
                <div className="howSecondColumn">
                  <div className="card padding_card">
                    <h3 className="sectionTitle purpleText">
                      Sobre las publicaciones
                    </h3>
                    <p className="wallInfoAlone">
                      Toda publicación debe contener datos como:
                    </p>
                    <ul className="publicationList wallInfoAlone">
                      <li>Nombre del producto, bien o servicio a publicar.</li>
                      <li>Valor de referencia.</li>
                      <li>
                        Artículo, bien o servicio de interés a cambio de lo que
                        ofrece.
                      </li>
                      <li>Lugar para el intercambio.</li>
                    </ul>
                    <p className="wallInfoAlone">
                      Además, las publicaciones deben cumplir con los{' '}
                      <Link to="/terminos-y-condiciones/">
                        Términos y Condiciones
                      </Link>{' '}
                      de Locambio.com, por ejemplo:
                    </p>
                    <ul className="publicationList wallInfoAlone">
                      <li>
                        Deben ser representativas de lo que ofreces y no pueden
                        ser engañosas en ningún sentido, contener información de
                        contacto o de otro sitio web.
                      </li>
                      <li>
                        No deben violar los derechos de autor o ser en cualquier
                        modo ofensivas.
                      </li>
                      <li>
                        Las imágenes tampoco pueden contener desnudos, contenido
                        erótico o inapropiado.
                      </li>
                    </ul>
                  </div>
                  <div className="card padding_card">
                    <h3 className="sectionTitle purpleText">
                      Sobre el chat de Intercambio
                    </h3>
                    <p className="wallInfoAlone">
                      Los usuarios tienen la opción de interactuar sobre detalles
                      adicionales de lo que ofrecen en{' '}
                      <b>
                        nuestra la mensajería instantánea de LoCambio.com, que
                        denominamos{' '}
                        <span className="orangeText">Chat de Intercambio.</span>
                      </b>{' '}
                      El contenido de éste chat, será eliminado al terminar la
                      negociación con el cambio exitoso.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isNotMobile && (
          <div className="links_container">
            <LinkInfoPages active="Sobre LoCambio.com" />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
