import axios from "axios";
import { getHeader } from "./../../system/Sesion";
import { config } from "./../../system/Config";
import * as actionTypes from "./actionTypes";

export const setNotifications = data => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    data: data
  };
};

export const updateNotifications = data => {
  return {
    type: actionTypes.UPDATE_NOTIFICATIONS,
    data: data
  };
};

export const getNotifications = () => {
  return async dispatch => {
    try {
      const response = await axios.get(`${config.URL_API}/notifications`, {
        headers: getHeader()
      });
      dispatch(setNotifications(response.data));
    } catch (err) {
      throw err;
    }
  };
};
