import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';

const SearchCategory = ({ handleSearch }) => {
  const inputRef = useRef(null);

  const handleClick=(e=>{
    e.target.addEventListener("keydown", handleInputClicked)
  })
  
  const handleInputClicked = (e)=>{
    if(e.keyCode === 13) handleSearch(e.target.value)
    if(e.keyCode === 8 || e.keyCode === 46) handleSearch('')
  }
  const handleBlur = (e) =>{
    e.target.removeEventListener("keydown", handleInputClicked)
  }

  return (
    <section className="search-category">
      <div className="search-category__input-wrapper">
        <input
          ref={inputRef}
          className="search-category__input"
          type="text"
          placeholder="Busca tu categoría"
          onClick={handleClick}
          onBlur={handleBlur}
        />
        <button
          className="search-category__icon-wrapper"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSearch(inputRef.current?.value);
          }}
        >
          <Icon className="search-category__icon" icon="search" iconSize={22} />
        </button>
      </div>
    </section>
  );
};

SearchCategory.propTypes = {
  handleSearch: PropTypes.func,
};

export default SearchCategory;
