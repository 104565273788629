import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import './Step2.scss';
import {
  InputGroup,
  FormGroup,
  Button,
  Icon,
  RadioGroup,
  Radio,
  HTMLSelect,
  TextArea,
  Callout,
  Label,
  Tag,
} from '@blueprintjs/core';
import { provincesVenezuela } from 'const';
import { Controller, useWatch } from 'react-hook-form';
import DropZone from '../../../../components/customDropZone';
import ScrollToElement from '../../../../components/scrollToElement/ScrollToElement';
import ShowImage from '../showImage';
import { Suggest } from '@blueprintjs/select';
import { getHeader } from '../../../../system/Sesion';
import axios from 'axios';
import { config } from '../../../../system/Config';

export default function Step2({
  control,
  errors,
  setValue,
  setContinue,
  canContinue,
  resetField,
  setFiles,
  files,
  watch,
}) {
  const [states, setStates] = useState([]);
  const [used, setUsed] = useState(false);
  const divCreatorReference = useRef(null);
  const divImageReference = useRef(null);
  const { suscription } = useSelector(({ auth }) => auth); //toDo
  const [showError, setShowError] = useState(false);
  const [excludedWords, setExcludedWords] = useState([]);
  const wordLength = { min: 1, max: 24 }
  const titleLength = 60;
  const descriptionLength = 300;
  const imagesLimit = suscription ? 7 : 7; //TODO: increase when user has subscription
  let checkImageLimit = files.length > imagesLimit

  const titleInputProps = {
    className: 'suggest-field',
    placeholder: 'Escribe aquí el título de tu artículo',
    id: "title",
    maxLength: titleLength,
  };

  const title = useWatch({
    control,
    name: 'title',
  });

  const state = useWatch({
    control,
    name: 'state',
  });

  const estimatedValue = useWatch({
    control,
    name: 'estimatedValue',
  });

  const condition = useWatch({
    control,
    name: 'condition',
  });

  const description = useWatch({
    control,
    name: 'description',
  });
  
  const product = useWatch({
    control,
    name: 'product',
  });

  useEffect(() => {
    axios
      .get(`${config.URL_API}/excluded_words`, {
        headers: getHeader(),
      })
      .then((response) => setExcludedWords(response.data));
  }, []);

  const handleUsed = (e) => {
    setUsed(e.target.value === 'Usado');
  };

  useEffect(() => {
    if (divCreatorReference?.current) {
      divCreatorReference.current.scrollIntoView({ block: "end" })
    }
    if (divImageReference?.current) {
      divImageReference.current.scrollIntoView({ block: "end" })
    }
  }, [files]);

  useEffect(() => {
    setStates(formatStates(provincesVenezuela));
  }, []);

  useEffect(() => {
    checkImageLimit = files.length > imagesLimit
    const canContinueInternal =
      title !== '' &&
      title !== undefined &&
      showError === false &&
      estimatedValue !== undefined &&
      estimatedValue !== '' &&
      files.length &&
      !(checkImageLimit) &&
      description !== undefined;
    if (canContinue !== canContinueInternal) {
      setContinue(canContinueInternal);
    }
  }, [title, state, estimatedValue, condition, description, files, showError]);

  const formatStates = (states) => {
    return [{ label: 'Seleccionar', value: '', disabled: true }].concat(
      states.map((elem) => {
        return { label: elem, value: elem };
      })
    );
  };

  const deleteAllImages = () => {
    files.map((_elem, index) => {
      resetField(`articleImages-${_elem}`, _elem);
    })
    const updateFiles = [];
    setFiles(updateFiles);
    ;
  }

  const checkNumeric = (value, kind) => {
    const patron = /^[0-9]+([.])?([0-9]+)?$/;
    const patronOnBlur = /^[0-9]+[.]$/;;
    if ((patronOnBlur.test(value) || patron.test(value) || value === '') && !kind === 'onBlur') {
      return value;
    }
    if (kind === 'onBlur' || kind === 'onPaste') {
      if (!isNaN(parseFloat(value))) return parseFloat(value);
      if (!isNaN(parseFloat(value.replace(/[^0-9.]+/g, "")))) return parseFloat(value.replace(/[^0-9.]+/g, ""));
      if (patronOnBlur.test(value)) return value.slice(0, -1)
      if (isNaN(parseFloat(value.replace(/[^0-9.]+/g, "")))) return ''
    }
    if (!patronOnBlur.test(value) && !patron.test(value)) {
      if (!isNaN(parseInt(value)) && parseInt(value) !== 0 && !isNaN(parseFloat(value.replace(/[^0-9.]+/g, "")))) {
        return Math.abs(parseFloat(value))
      } else if (isNaN(parseFloat(value.replace(/[^0-9.]+/g, "")))) {
        return ''
      } else {
        return parseFloat(value.replace(/[^0-9.]+/g, ""))
      }
    }
    return value
  }

  function removePunctuationMarksatEnd(word) {
    return word.replace(/[,|.|:|;|'|/|=|¡|¿|\|^]+$/, "")
  }

  const validateInput = (query) => {
    setValue('title', query);
    const tags = query.split(" ").filter(Boolean);
    tags.forEach((tag) => {
      if (
        tags.some(tag => tag.length > wordLength.max) ||
        tags.some(tag => tag.length < wordLength.min) ||
        query.length > titleLength
      ) {
        setShowError(true)
      } else {
        setShowError(false)
      }
    });
  };

  const handleVerifytitle = (query) => {
    const newQuery = []
    if (query.charAt(0) !== '') {
      query.split(' ').map((word) => {
        word = removePunctuationMarksatEnd(word)
        if (word !== '' && !excludedWords.includes(word) && !newQuery.map((element) => (element.props.children)).includes(word)) {
          if (word.length > wordLength.max || word.length < wordLength.min) {
            newQuery.push(<Tag className='alert step-one-seggestion-tags-error'>{word.toLowerCase()}</Tag>);
          } else {
            newQuery.push(<Tag className='purple-text step-one-seggestion-tags'>{word.toLowerCase()}</Tag>);
          }
        }
      });
    }
    return (
      <div >
        <p>
          <span className='blue-text extra-bold-text suggestion-title'>
            Etiquetas de esta publicación:
          </span>
          <div className={'erguevo'}>{newQuery}</div>
        </p>
      </div>
    )
  };

  const createNewItemRenderer = (query, active, handleClick) => {
    return (
      <div className='step-one-suggestion-pop' onClick={handleClick}>
        <div>{handleVerifytitle(query, handleClick)}</div>
        {showError ?
          <div>
            <span className='alert bold-text error'>
              {`Título de máx. ${titleLength} caracteres y términos de máx. ${wordLength.max} caracteres`}
            </span>
          </div>
          : null
        }
      </div>
    )
  };

  const filesCreation = (
    <div ref={divCreatorReference} className={`minWidth firstElem${!files.length ? ' last-element' : ''}`}>
      <DropZone
        control={control}
        name={`articleImages-${(files[files.length - 1] || 0) + 1}`}
        setValue={setValue}
        next={(files[files.length - 1] || 0) + 1}
        setFile={setFiles}
        filesIndexes={files}
        scale={0.75}
      />
    </div>
  )

  const initialContent = (
    <div>
      <Callout className={'info-text'}>
        <h3 className="extra-bold-text blue-text">Tip</h3>
        <p className={'padding40px'}>
          Las etiquetas de búsqueda de tu publicación se crearán en base al título. Sé astuto.
        </p>
      </Callout>
    </div>
  );

  const imagesList = files.map((_elem, index) => {
    if (index < imagesLimit) {
      return (
        <ShowImage
          key={`articleImages-${_elem}`}
          control={control}
          name={`articleImages-${_elem}`}
          referenceImage={(index === files.length - 2) ? divImageReference : undefined}
          current={_elem}
          setFile={setFiles}
          resetField={resetField}
          filesIndexes={files}
          watch={watch}
        />
      );
    }
  });

  return (
    <div className="step2">
      <ScrollToElement
        scroll={true}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      >
      </ScrollToElement>
      <div className="card">
        
          <FormGroup
            className="full-Width inputLabel step-one-inputLabel"
            label="Título"
            labelInfo="*"
            labelFor="title"
          >
            <div className="suggestions-bar">
              <div className="suggestions-input">
                <Suggest
                  items={[]}
                  query={title}
                  minimal
                  onQueryChange={validateInput}
                  inputValueRenderer={() => ''}
                  createNewItemFromQuery={(query) => query}
                  createNewItemRenderer={createNewItemRenderer}
                  initialContent={initialContent}
                  inputProps={titleInputProps}
                />
                <div className="word-counter">
                  <p className={`${title?.length >= titleLength ? 'red-text' : ''} `}>
                    {`${title === undefined ? 0 : title.length}`}/{`${titleLength}`}
                  </p>
                </div>
              </div>
            </div>
          </FormGroup>
       
        <div className="row content-Formater">
          <FormGroup
            className="inputLabel dollarLabel"
            label="Valor estimado"
            labelInfo="*"
            labelFor="estimatedValue"
          >
            <Controller
              name="estimatedValue"
              control={control}
              rules={{ validate: (value) => parseFloat(value) > 0 || value === undefined }}
              render={({ field }) => (
                <InputGroup
                  type="text"
                  {...field}
                  onChange={(e) => field.onChange(checkNumeric(e.target.value))}
                  onPaste={(e) => field.onChange(checkNumeric(e.target.value, 'onPaste'))}
                  onBlur={(e) => field.onChange(checkNumeric(e.target.value, 'onBlur'))}
                  autoComplete='off'
                  id="estimatedValue"
                  maxLength={9}
                  className={`inputField ${errors.estimatedValue && '.bp4-intent-danger'
                    }`}
                  intent={errors.estimatedValue && 'danger'}
                  placeholder="Ingrese el valor aquí"
                />
              )}
            />
            <Icon
              icon="dollar"
              className="purple-Text icon-width"
              iconSize={20}
            />
          </FormGroup>
        </div>
      </div>
      <div className="card">
        <section className='header-image-container'>
          <h3 className="header-image-container__label">
            {`Imágenes ${product === 'product' ? 'de tu artículo' : 'de tu servicio'}`}<span className='bp3-text-muted'>*</span> <span className={`counter ${checkImageLimit ? 'alert' : ''}`}>{`(${files.length}/${imagesLimit})`}  {checkImageLimit && `Elimina ${files.length - imagesLimit} imágenes para continuar`}</span>

          </h3>
          <p className={`delete-all-images delete-all-images${!files.length ? '--disabled' : ''}`} onClick={deleteAllImages}>
            <Icon
              icon="eraser"
              iconSize={17}
            />
            {' '} Eliminar todas
          </p>
          <p className='header-image-container__label--full'>
            Sube hasta {imagesLimit} imágenes para mostrar tu artículo{' '}
            <span className='bp3-text-muted'>
              (Pueden ser en formato JPG, GIF o PNG. Y el limite del tamaño de los
              archivos es de 15MB.)
            </span>
          </p>
        </section>
        <div class="scroll-container">
          <div className="fileRow">{(files.length < imagesLimit) && filesCreation} {imagesList}</div>
        </div>
      </div>
      {(product === 'product') && <div className="card column">
        <FormGroup
          label="Condición del artículo"
          labelInfo="*"
          labelFor="condition"
          className="inputLabel columnRadio"
        >
          <RadioGroup large className="radioTags">
            <Controller
              name="condition"
              control={control}
              rules={{ required: true }}
              defaultValue="Nuevo"
              render={({ field }) => (
                <>
                  <Radio
                    {...field}
                    onChange={(e) => {
                      handleUsed(e);
                      field.onChange(e);
                    }}
                    label="Nuevo"
                    value="Nuevo"
                    id="condition"
                    checked={field.value == 'Nuevo'}
                  />
                  <Radio
                    {...field}
                    onChange={(e) => {
                      handleUsed(e);
                      field.onChange(e);
                    }}
                    label="Usado"
                    value="Usado"
                    id="condition"
                    checked={field.value == 'Usado'}
                  />
                </>
              )}
            />
          </RadioGroup>
          {used ? (
            <>
              <p className="purple-text">Especifica la condición de uso</p>
              <RadioGroup large className="radioTags">
                <Controller
                  name="conditionSpecific"
                  control={control}
                  rules={{ required: true }}
                  defaultValue="Excelente"
                  render={({ field }) => (
                    <>
                      <Radio
                        {...field}
                        label="Excelente"
                        value="Excelente"
                        id="conditionSpecific"
                        checked={field.value == 'Excelente'}
                      />
                      <Radio
                        {...field}
                        label="Poco uso"
                        value="Bueno"
                        id="conditionSpecific"
                        checked={field.value == 'Bueno'}
                      />
                      <Radio
                        {...field}
                        label="Con Detalles"
                        value="Detalles"
                        id="conditionSpecific"
                        checked={field.value == 'Detalles'}
                      />
                      <Radio
                        {...field}
                        label="Para Repuesto"
                        value="Repuesto"
                        id="conditionSpecific"
                        checked={field.value == 'Repuesto'}
                      />
                    </>
                  )}
                />
              </RadioGroup>
            </>
          ) : null}
          {errors.condition && (
            <p className="errorMessage">Este campo es obligatorio</p>
          )}
        </FormGroup>
      </div>}
      <div className="card">
        <FormGroup
          label={`Descripción ${product === 'product' ? 'del artículo' : 'del servicio'}`}
          labelInfo="*"
          labelFor="description"
          className="inputLabel full-Width"
        >
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div className="full-width">
                <TextArea
                  {...field}
                  className={`round-corners ${errors.description && '.bp4-intent-danger'
                    }`}
                  placeholder={`Escribe aqui los detalles de tu ${product === 'product' ? 'artículo' : 'servicio'}`}
                  intent={errors.description && 'danger'}
                  growVertically
                  large
                  fill
                  maxLength={descriptionLength}
                />
                <div className="word-counter">
                  <p className={`${description?.length >= descriptionLength ? 'red-text' : ''} `}>
                    {`${description === undefined ? 0 : description.length}`}/{`${descriptionLength}`}
                  </p>
                </div>
              </div>

            )}
          />
        </FormGroup>
      </div>
    </div>
  );
}
