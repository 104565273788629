import React, { useState, useEffect, useRef } from "react";
import { Button, Spinner } from "@blueprintjs/core";
import { useDispatch } from "react-redux";

import { initialValue } from "../../utilityFunctions";
import { checkSesion } from "../../store/actions/index";
import { authAxios } from "../../utility/axios";
import BlurImage from "../blurImage/BlurImage";
import { Toast } from "../../utility/toast";
import ModalCrop from "../ModalCrop";
import Card from "../card/Card";

import "./ProfileAvatar.scss";

const ProfileAvatar = (props) => {
  const { user, editable } = props;
  const [loading, setLoading] = useState(null);

  const [isCropping, setIsCropping] = useState(false);
  const [srcAvatar, setSrcAvatar] = useState("");
  const [showAvatarInitials, setShowAvatarInitials] = useState(!user?.avatar);

  const inputFileRef = useRef(null);

  const dispatch = useDispatch();

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    setShowAvatarInitials(!user?.avatar);
  }, [user]);

  const onSelectFile = (e, typeSrc) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setSrcAvatar(reader.result);
        setIsCropping(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const cancelCrop = () => {
    setIsCropping(false);
    setSrcAvatar("");
  };

  const displayToast = (message, icon, intent) => {
    Toast.show({ show: true, message, icon, intent });
  };

  const fetchDeleteImgProfile = (data) => {
    return authAxios.put("/delete_profile_photo", data);
  };

  const deleteImgProfile = async () => {
    const data = {
      id_user: user.id,
    };
    setLoading(true);
    try {
      await fetchDeleteImgProfile(data);
      displayToast(
        "Los cambios han sido guardados exitosamente",
        "tick",
        "success"
      );
      dispatch(checkSesion());
    } catch (err) {
      displayToast(
        "Lo sentimos, hubo un error eliminando la foto de perfil",
        "tick",
        "danger"
      );
    } finally {
      setLoading(false);
    }
  };

  const onCropComplete = async (canvas) => {
    const newImage = await canvas[0];
    const newImageThumb = await canvas[1];
    const url = URL.createObjectURL(newImage);
    const formatedData = new FormData();
    formatedData.append("id_image", user.picture_users?.[0]?.id);
    formatedData.append("id_user", user.id);
    formatedData.append(`picture_data[0]`, newImage);
    formatedData.append(`picture_data[1]`, newImageThumb);

    setIsCropping(false);
    setLoading(true);
    return authAxios
      .put("/update_profile_photo", formatedData)
      .then((response) => {
        displayToast(
          "La imagen de perfil se ha guardado correctamente",
          "tick",
          "success"
        );
        dispatch(checkSesion());
      })
      .catch((err) => {
        if (err?.response?.status === 406) {
          displayToast(
            "Su foto de perfil no cumple con las politicas de Locambio.com, pruebe con otra imagen",
            "error",
            "danger"
          );
          dispatch(checkSesion());
        } else {
          displayToast(
            "Ocurrio un error al guardar la imagen de perfil",
            "tick",
            "error"
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const content = showAvatarInitials ? (
    <div
      className={
        "profile-avatar__initials" +
        (editable ? "" : "profile-avatar__initials--noBlur")
      }
    >
      <p className="margin0">
        <span>{user.name ? initialValue(user.name) : null}</span>
        <span> {user.last_name ? initialValue(user.last_name) : null} </span>
      </p>
    </div>
  ) : (
    <BlurImage
      image={user.avatar}
      title={"profile"}
      className={"avatar-blur-image"}
    />
  );

  return (
    <Card className="profile-avatar">
      <section className="profile-avatar__container">
        {loading ? (
          <div className="profile-avatar__container__spinner">
            <Spinner />
            <div className="profile-avatar__container__spinner__text">Estamos evaluando su foto</div>
          </div>
        ) : (
          <>
            <ModalCrop
              isAvatar={true}
              isOpen={isCropping}
              img={srcAvatar}
              handleComplete={onCropComplete}
              handleCancel={cancelCrop}
              className="profile-avatar__custom-modal"
              withThumb
            />
            {editable && (
              <div className="profile-avatar__edit-container">
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  onChange={(e) => {
                    onSelectFile(e, "avatar");
                  }}
                />
                <Button
                  className="profile-avatar__edit-button"
                  icon="edit"
                  onClick={() => onBtnClick(inputFileRef)}
                />
                {!showAvatarInitials && (
                  <Button
                    minimal
                    intent="danger"
                    onClick={deleteImgProfile}
                    icon="trash"
                    className="profile-avatar__edit-button"
                  />
                )}
              </div>
            )}
            {content}
          </>
        )}
      </section>
    </Card>
  );
};

export default ProfileAvatar;
