import axios from 'axios';
import { Toast } from '../../utility/toast';
import { config } from './../../system/Config';
import { getHeader, setHeader } from './../../system/Sesion';
import * as actionTypes from './actionTypes';
import { getChats, getPublicationsSaved } from './index';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authFail = (errorMessage) => {
  Toast.show({
    intent: 'warning',
    message: errorMessage,
    icon: 'warning-sign',
  });
  return {
    type: actionTypes.AUTH_FAIL,
    error: errorMessage,
  };
};

export const authIncomplete = (
  incompleteRegister,
  omniauth_user,
  omniauth,
) => {
  return {
    type: actionTypes.AUTH_INCOMPLETE,
    incompleteRegister: incompleteRegister,
    omniauth_user: omniauth_user,
    omniauth: omniauth,
  };
};

export const authSuccess = (user, loggued) => {
  return (dispatch) => {
    dispatch(getPublicationsSaved(user.id));
    dispatch(setAuthSuccess(user, loggued));
  };
};

export const setAuthSuccess = (user, loggued) => {
  const otherInfo = {
    activePublications: user.active_publications,
    reputation: user.reputation,
    registration_date: user.registration_date,
    sold: user.sold,
    change: user.change,
    balance: user.balance,
    view_notifications: user.view_notifications,
    address: user.address,
    address_2: user.address_2,
    city: user.city,
    state: user.state,
    active: user.active,
    notifications_email: user.notifications_email,
    country: user.country,
    type_suscription: user.type_suscription,
  };

  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: user.token,
    userId: user.id,
    name: user.name,
    lastName: user.last_name,
    fullName: `${user.name} ${user.last_name}`,
    suscription: user.suscription,
    otherInfo: otherInfo,
    loggued: loggued,
    imageProfile: user.picture_users,
    publications: [],
    avatar: user.avatar,
    verified: user.verified,
    user: {
      ...otherInfo,     
      idToken: user.token,
      userId: user.id,
      name: user.name,
      lastName: user.last_name,
      last_name: user.last_name,
      fullName: `${user.name} ${user.last_name}`,
      suscription: user.suscription,
      avatar: user.avatar,
      imageProfile: user.picture_users,
      picture_data: user.picture_users,
      picture_users: user.picture_users,
      verified: user.verified,
      publication_owners: user.publication_owners,
    }
  };
};

export const setLoggued = (value) => {
  return {
    type: actionTypes.AUTH_SET_LOGGUED,
    value: value,
  };
};


export const authLogout = (value) => {
  return {
    type: actionTypes.AUTH_LOGOUT,
    value: value,
  };
};

export const setFollow = data => {
  return {
    type: actionTypes.SET_FOLLOWS,
    followers: data.followers,
    following: data.following,
  };
};

export const setUsersFollowers = (followers) => {
  return {
    type: actionTypes.SET_USERS_FOLLOWERS,
    usersFollowers: followers,
  };
};

export const setUsersFollowing = (following) => {
  return {
    type: actionTypes.SET_USERS_FOLLOWING,
    usersFollowing: following,
  };
};

export const setpubShares = (pubShares) => {
  return {
    type: actionTypes.SET_PUB_SHARES,
    pubShares: pubShares,
  };
};

export const setLikes = (likes) => {
  return {
    type: actionTypes.SET_LIKES,
    likes,
  };
};

const logOutStorageItems = [
  'tokenId',
  'access-token',
  'client',
  'uid',
  'expiry',
  'token-type',
  'sesionValid',
  'userId',
];

export const logout = () => {
  logOutStorageItems.forEach((key) => localStorage.removeItem(key));

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

function ShowWellcomeMessage() {
  if (localStorage.signInCount === '1') {
    Toast.show({ message: 'Bienvenido! Empecemos a cerrar buenos cambios' });
  } else {
    Toast.show({ message: 'Te extrañabamos, bienvenido de vuelta. Empecemos a cerrar \nbuenos Cambios!' });
  }
}

export const auth = (email, password) => async (dispatch) => {
  dispatch(authStart());
  try {
    const dataLogin = { email: email, password: password };
    const data = await axios.post(`${config.URL_API}/auth/sign_in/`, dataLogin)
    localStorage.setItem('tokenId', data.data.data.token);
    localStorage.setItem('userId', data.data.data.id);
    localStorage.setItem('signInCount', data.data.data.sign_in_count)

    const response = await axios
      .get(
        `${config.URL_API}/currency_by_country?country=${data.data.data.country}`,
        { headers: data.headers }
      )
      .catch((err) => {
        console.log(err);
      });

    if (response.data.errors) {
      localStorage.setItem('currency', 'USD');
    } else {
      localStorage.setItem('currency', response.data.currency);
    }

    setHeader(data.headers);
    dispatch(getChats());
    dispatch(authSuccess(data.data.data, true));

    ShowWellcomeMessage();
  } catch (err) {
    console.error(err);
    err.response?.data?.errors?.forEach((error) => {
      if (error === errorCredentials) {
        dispatch(authFail(error));
      }
      if (errorUnconfirmated.test(error)) {
        dispatch(authFail(unconfirmedErrorMessage));
      } else {
        dispatch(authFail(error));
      }
    })
    return err
  }
};

export const authWithGoogle = (tokenId) => {
  return (dispatch) => {
    dispatch(authStart());
    const dataLogin = { token: tokenId };

    axios
      .post(`${config.URL_API}/validate_login_google`, dataLogin)
      .then(async (response) => {
        const { data } = response;
        checkRegisterComplete(
          data.register_complete,
          data.user,
          data.headers,
          dispatch
        );
      })
      .catch(() => {
        dispatch(authFail(unknowErrorMessage));
      });
  };
};

export const authWithFacebook = (token, email) => {
  return (dispatch) => {
    dispatch(authStart());
    const dataLogin = { token: token, email: email };

    axios
      .post(`${config.URL_API}/validate_login_facebook`, dataLogin)
      .then(async (response) => {
        const { data } = response;
        checkRegisterComplete(
          data.register_complete,
          data.user,
          data.headers,
          dispatch
        );
      })
      .catch((error) => {
        dispatch(authFail(unknowErrorMessage));
      });
  };
};

function checkRegisterComplete(
  registerComplete,
  user,
  headers,
  dispatch
) {
  if (registerComplete === true) {
    localStorage.setItem('tokenId', user.token);
    localStorage.setItem('userId', user.id);
    setHeader(headers);
    dispatch(getChats());
    dispatch(authSuccess(user, true));
    ShowWellcomeMessage();
  } else {
    localStorage.setItem('tokenId', user.token);
    Toast.show({
      message: completeRegisterMessage,
      icon: 'tick',
      intent: 'success',
    });
    dispatch(
      authIncomplete(
        true,
        {
          name: user.name,
          last_name:
          user.last_name,
          email: user.email,
          user_id: user.id,
          token: user.token
        },
        true,
      )
    );
  }
}

export const validateSesionUser = token => {
  return dispatch => {
    axios
      .get(`${config.URL_API}/users?token=${token}`, { headers: getHeader() })
      .then(async (data) => {
        dispatch(authSuccess(data.data, false));
        await dispatch(getChats());
        localStorage.setItem('tokenId', data.data.token);
        localStorage.setItem('userId', data.data.id);
      })
      .catch((error) => {
        dispatch(setSesionVerifed());
      });
  };
};

// esto obtiene la cantidad de seguidores y seguidos (cantidad)
export const getFollows = (user_id) => {
  return (dispatch) => {
    axios
      .get(`${config.URL_API}/all_cant?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then((data) => {
        dispatch(setFollow(data.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// este obtiene los usuarios seguidores y seguidos (informacion)
export const getUsersFollow = (user_id) => {
  return (dispatch) => {
    axios
      .get(`${config.URL_API}/followers?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then((data) => {
        dispatch(setUsersFollowers(data.data.followers));
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${config.URL_API}/following?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then((data) => {
        dispatch(setUsersFollowing(data.data.following));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// obtiene las publicaciones que ha compartido un usuario
export const getShares = (user_id) => {
  return (dispatch) => {
    axios
      .get(`${config.URL_API}/share_by_user?user_id=${user_id}`, {
        headers: getHeader(),
      })
      .then((data) => {
        dispatch(setpubShares(data.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getLikes = (user_id) => {
  return (dispatch) => {
    axios
      .get(`${config.URL_API}/likes_user/${user_id}`, { headers: getHeader() })
      .then((data) => dispatch(setLikes(data.data.likes)));
  };
};

export const checkSesion = () => {
  return (dispatch) => {
    if (
      localStorage.getItem('access-token') &&
      localStorage.getItem('client') &&
      localStorage.getItem('uid') &&
      localStorage.getItem('expiry') &&
      localStorage.getItem('token-type')
    ) {
      dispatch(validateSesionUser(localStorage.getItem('tokenId')));
    } else {
      dispatch(logout());
      dispatch(setSesionVerifed());
    }
  };
};

export const checkAuthentication = () => (dispatch) => {
  const shouldLogOut = logOutStorageItems.every((key) => !localStorage.getItem(key));
  if (!shouldLogOut) return;
  dispatch(logout());
};

export const setSesionVerifed = () => {
  return {
    type: actionTypes.SET_SESSION_VERIFED,
  };
};

const errorUnconfirmated = /follow the instructions in the email before your account can be activated/;
const errorCredentials = 'Credenciales inválidas, intente de nuevo';
const unconfirmedErrorMessage = 'Se envió un correo electrónico de confirmación a su correo. Debe seguir las instrucciones en el correo electrónico antes de poder activar su cuenta'
const unknowErrorMessage = 'Lo sentimos, hubo un error';
const completeRegisterMessage =
  'Para disfrutar de toda la experiencia Locambio debes completar tu registro';
