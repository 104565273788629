import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { initialValue } from '../../utilityFunctions';

import './UserBubble.scss';
import BlurImage from '../blurImage/BlurImage';

const UserBubble = () => {
  const { avatar, user } = useSelector(({ auth }) => auth);
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const userInitials = (
    <p className="img_box_initial">
      {user.name[0].toUpperCase()}{user.last_name[0].toUpperCase()}
    </p>
  );

  return (
    <div className="user-bubble">
        {avatar ? (
          <BlurImage image={avatar} title="user photo"/>
        ) : (
          userInitials
        )}
        {isLargerThanMobile && <div><Icon icon="chevron-down" iconSize={22} /></div>}
    </div>
  );
};

export default UserBubble;
