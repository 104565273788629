import { SET_IMAGE_TRANSLATE, SET_SRC } from './actionTypes';

export const setImageTranslate = (translate) => ({
  type: SET_IMAGE_TRANSLATE,
  payload: translate,
});

export const setSrc = (src) => ({
  type: SET_SRC,
  payload: src,
});
