import React from 'react';
import { Autoplay } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import './footer/Footer.scss';

const images = [
  'https://storage.googleapis.com/locambio-admin/pages/wall/slider0-0.jpg',
  'https://storage.googleapis.com/locambio-admin/pages/wall/slider0-1.jpg',
];

const params = {
  modules: [Autoplay],
  centeredSlides: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 30,
  rebuildOnUpdate: true,
};

const imageArray = images.map((elem, index) => {
  return (
    <SwiperSlide key={index}>
      <img src={elem} />
    </SwiperSlide>
  );
});

const Footer = ({ showText = true, marginTop = true, className }) => {
  const isTablet = useMediaQuery({ maxWidth: 1224 });
  const classNames = `Footer ${marginTop ? 'marginTop-1em' : ''} ${className}`;

  return (
    <div className={classNames}>
      <Swiper {...params}>{imageArray}</Swiper>

      {
        showText && (
          <div className="mt-4 mb-4">
            <ul>
              <li>
                <p className="bp3-form-helper-text">
                  t-locambio, C.A. 2019 - {new Date().getFullYear()} {isTablet ? <br></br> : ''} RIF:
                  J-40865264-1
                </p>
              </li>
              <li>
                <p className="bp3-form-helper-text">
                  Todos los Derechos Reservados {isTablet ? <br></br> : ''} &#9400; locambio.com
                </p>
              </li>
            </ul>
          </div>
        )
      }
    </div>
  );
};

export default Footer;
