import { useForm } from 'react-hook-form';
import { Classes, Icon } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Message from '../message';
import Attachment from '../attachment';
import PanelHeader from '../panelHeader';
import Button from '../../../../components/button';
import MapAttachment from '../mapAttachment/MapAttachment';

import { createMessage } from '../../ChatAPI';
import { useCable } from '../../../../hooks/useCable';
import { DRAWERS } from '../../../../utility/drawers';
import { objectToFormData } from '../../../../utility/blob';
import { CABLE_CHANNELS } from '../../../../utility/cableApp';
import { useQueryString } from '../../../../hooks/useQueryString';
import { setSecurityChangeRequest, updateDrawer } from '../../../../store/actions';
import { CHAT_KINDS, CHAT_KINDS_URL, CHAT_KINDS_URL_MAP } from '../../../../utility/chat';

const now = new Date();

const placeholderMessages = [
  {
    id: 'message-placeholder-1',
    user_from: parseInt(localStorage.getItem('userId')) + 1,
    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    created_at: now,
    random_id: 1,
  },
  {
    id: 'message-placeholder-2',
    user_from: parseInt(localStorage.getItem('userId')),
    message: 'Ut enim ad minim veniam',
    created_at: now,
    random_id: 2,
  },
  {
    id: 'message-placeholder-3',
    user_from: parseInt(localStorage.getItem('userId')) + 1,
    message: 'quis nostrud exercitation ullamco laboris',
    created_at: now,
    random_id: 3,
  },
];

const Panel = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryString = useQueryString();
  const online = useSelector(({ network }) => network.online);
  const chats = useSelector(({ chat }) => [...chat[CHAT_KINDS.proposal], ...chat[CHAT_KINDS.inProgress]]);

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [publicationInteresteds, setPublicationInteresteds] = useState(null);

  const listKind = CHAT_KINDS_URL_MAP[queryString.get('tab') || CHAT_KINDS_URL[CHAT_KINDS.proposal]];
  const chat = useMemo(() => {
    return chats.find((chat) => chat.id === parseInt(id))
  }, [chats, id]);

  useEffect(() => {
    const PubIntChat = chats.find((chat) => chat.id === parseInt(id))
    setPublicationInteresteds(PubIntChat?.transaction?.publication_interesteds)
    }, [chats]
  )

  const { register, handleSubmit, reset, formState: { isValid } } = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
      image_base: '',
    },
  });

  const handleConnected = () => {
    setConnected(true);
  };

  const handleDisconnected = () => {
    setConnected(false);
  };

  const handleReceived = function ({ messages: newMessages, new_chat, security_change, errors }) {
    if (errors) return console.error(errors);
    if (new_chat) dispatch(setSecurityChangeRequest(security_change.data));

    if (security_change?.new ) {
      dispatch(setSecurityChangeRequest(security_change.data));
      return 
    }

    setMessages((prevValue) => {
      if (new_chat) return newMessages;

      const messages = [...prevValue];
      newMessages.forEach((message) => {
        const index = messages.findIndex((m) => m.random_id === message.random_id);
        if (index === -1) {
          messages.push(message);
        } else {
          messages[index] = message;
        }
      });

      return messages;
    });
    setLoading(false);
    this.perform('read_messages');
  };

  const handleFollow = function (id) {
    this.perform('follow', { chat_id: id, user_id: localStorage.getItem('userId') });
  };

  const baseCableOptions = {
    channelOptions: { channel: CABLE_CHANNELS.message },
    channelFunctions: {
      connected: handleConnected,
      received: handleReceived,
      follow: handleFollow,
      disconnected: handleDisconnected,
    },
  };

  const [subscription, setSubscription] = useCable(baseCableOptions);

  const handleChangeChat = useCallback(() => {
    if (!id || !connected) return;
    subscription.follow(id);
  }, [connected, id, subscription]);

  // TODO: Move this effect of redirects and drawers updates to Chat
  useEffect(() => {
    if (!chat) {
      return dispatch(updateDrawer({ [DRAWERS.chats]: true }));
    }
    if (chat.transaction?.status !== 'active' && listKind === CHAT_KINDS.proposal) {
      history.push(`/chats/${id}?tab=${CHAT_KINDS_URL[CHAT_KINDS.inProgress]}`);
    }
  }, [chat?.transaction?.status]);

  useEffect(() => {
    if (id && chat) dispatch(updateDrawer({ [DRAWERS.chats]: false }));
  }, [chat, dispatch, id]);

  useEffect(() => {
    handleChangeChat();
  }, [handleChangeChat]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
  }, [id]);

  const messagesElements = useMemo(() => {
    const currentMessages = loading ? placeholderMessages : messages;
    return currentMessages?.map((message, i) => (
        <Message
          key={`message-${message.random_id}`}
          message={message}
          skeleton={loading}
          isLastMessage={i === messages.length - 1}
        />
      ),
    );
  }, [loading, messages]);

  if (!chat) return null;

  const onSubmit = async (data) => {
    reset();

    const userId = localStorage.getItem('userId');
    const random_id = `${userId}-${Date.now()}`;

    const previewMessage = {
      random_id,
      user_from: parseInt(userId),
      created_at: new Date(),
      picture_url: data.picture,
      ...data,
    };

    setMessages((prevValue) => [...prevValue, previewMessage]);
    try {
      const messageData = objectToFormData({ random_id, ...data });
      const { data: message } = await createMessage(id, messageData);
      setMessages((prevValue) => {
        const previewMessageIndex = prevValue.findIndex((message) => message.random_id === previewMessage.random_id);
        const newMessages = [...prevValue];
        newMessages[previewMessageIndex] = message;
        return newMessages;
      });
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <section className="panel">
      <PanelHeader chat={chat} loading={loading} publicationInteresteds={publicationInteresteds}/>
      <section className="panel__body">
        {messagesElements}
      </section>
      <footer>
        <form className="panel__controls" onSubmit={handleSubmit(onSubmit)}>
          <section className="panel__input-wrapper">
            <input
              {
                // TODO: Check the validate (change for required o empty built-in validation)
                ...register(
                  'message',
                  {
                    validate: (value) => Boolean(value?.trim()) && online,
                  },
                )
              }
              autoComplete="off"
              className={`${Classes.INPUT} panel__input`}
              placeholder="Escribir mensaje"
              type="text"
            />
            <section className="panel__icon-wrapper">
              <MapAttachment handler={({ latitude, longitude }) => onSubmit({ latitude, longitude })}>
                {({ open }) => (
                  <Icon
                    className="chat__icon panel__icon children-without-transition"
                    icon="map-marker"
                    onClick={open}
                  />
                )}
              </MapAttachment>
              <Attachment accept="image/*" handler={(picture) => onSubmit({ picture })}>
                {({ open }) => (
                  <Icon
                    className="chat__icon panel__icon children-without-transition"
                    icon="media"
                    onClick={open}
                  />
                )}
              </Attachment>
            </section>
          </section>
          <Button
            large
            hideTextOnMobile
            type="submit"
            intent="accent"
            icon="send-message"
            disabled={!isValid}
            text="Enviar"
          />
        </form>
      </footer>
    </section>
  );
};

export default Panel;