import React from 'react';
import { Card, Callout, Elevation } from '@blueprintjs/core';

const NotResults = () => (
  <Card elevation={Elevation.ONE} className={'marginTop-1em'}>
    <Callout className={'noActivityDisplay marginBottom-1em'}>
      <h5 className={'bp3-form-helper-text'}>No se encontraron resultados</h5>
    </Callout>
  </Card>
)

export default NotResults;