import * as React from 'react';
import './verificationCheck.scss';

export default function verificationCheck(typeSuscription, size = { width: '16', height: '16' }) {
  return (
    <svg viewBox="0 0 120 110" width={size.width} height={size.height} className={'verified_check_mark '} alt="verified checkmark">
      <g className={`verified_check_mark__${typeSuscription}`}>
        <path class="star" d="M110.6,30.5c-0.1,1.5-0.7,2.9-1.2,4.3c-0.7,1.8-1.6,3.6-2,5.5c-0.4,1.7,0,3.1,1.3,4.4c2,2,4.7,3.2,6.9,5
          c2.8,2.3,3.1,4.5,0.5,7.2c-1.3,1.3-2.6,2.6-4,3.7c-3.4,2.8-3.9,4.8-1.9,8.6c1,1.8,2.1,3.5,3,5.3c1.7,3.5,0.8,5.6-3,6.9
          c-2.1,0.7-4.4,1.3-6.5,2c-2.9,1-3.9,2.3-3.8,5.4c0,2.3,0.3,4.7,0.3,7c0,4-1.8,5.5-5.8,5c-2.1-0.3-4.3-0.6-6.4-0.9
          c-2.9-0.4-4.6,0.5-6,3c-1.1,2-1.8,4.2-2.7,6.3c-1.7,3.8-4.1,4.6-7.7,2.3c-1.8-1.1-3.6-2.4-5.4-3.6c-2.8-1.7-4.3-1.7-6.8,0.3
          c-1.8,1.4-3.4,2.9-5.1,4.4c-3.3,2.8-5.5,2.6-7.9-1c-1.2-1.7-2.2-3.6-3.3-5.3c-2-3.1-3.6-3.7-7.2-2.8c-2.4,0.6-4.7,1.6-7.2,1.9
          c-2.8,0.4-4.3-0.6-5-3.3c-0.5-2-0.4-4.1-0.7-6.2c-0.1-1-0.2-2-0.4-3c-0.4-1.6-1.4-2.7-3-3.3c-2.1-0.8-4.3-0.9-6.5-1.2
          c-6-0.9-7-2.7-5-8.2c0.7-1.9,1.7-3.7,2.2-5.7c0.6-2.4-0.1-4.2-2-5.5c-1.4-1-2.7-1.9-4.1-2.8c-5.2-3.3-5.5-6-1-10.1
          c1.1-1,2.2-2,3.4-3c2.3-2,2.7-4.1,1.3-7c-0.7-1.3-1.4-2.5-2.2-3.7c-0.8-1.4-1.5-2.8-1.9-4.3c-0.5-1.6,0.1-2.9,1.5-3.9
          c1.6-1.1,3.6-1.5,5.4-2.1c1.4-0.4,2.8-0.9,4.1-1.4c2-0.7,2.9-2.2,3-4.3c0.1-2.6-0.4-5.1-0.4-7.6c0-4.1,1.8-5.6,5.9-5
          c2.3,0.3,4.5,0.6,6.8,0.9c2.5,0.3,4.3-0.5,5.4-2.8c1.1-2.3,1.9-4.6,3-6.9c1.8-3.7,3.7-4.2,7.2-2.1C47.5,4,49.2,5.2,51,6.3
          c3.1,2,4.6,2,7.5-0.3c1.6-1.3,3.2-2.7,4.7-4.1c3.3-2.9,5.9-2.6,8.2,1.2c1.3,2,2.5,4.1,3.8,6.1c1.2,1.9,2.9,2.4,5.1,2
          c1.1-0.2,2.2-0.5,3.3-0.8c1.4-0.4,2.8-0.9,4.2-1.1c4.1-0.8,6,0.7,6.4,4.8c0.2,2.2,0.4,4.4,0.6,6.7c0.3,2.7,2.1,4.1,4.7,4.5
          c1.7,0.3,3.4,0.5,5.1,0.8c1.3,0.2,2.6,0.5,3.7,1C109.9,27.8,110.7,28.9,110.6,30.5z M51.2,63.6c-1,1-1.6,0.8-2.5-0.1"/>
        <path id="XMLID_1_" class="check" d="M50,78.9c0.8-0.4,1.4-1,2.1-1.7c5.6-5.5,11.2-11.1,16.8-16.6c6.8-6.7,13.6-13.3,20.5-20
          c0.5-0.5,0.7-1,0.2-1.5c-2-1.9-4-3.9-6-5.8c-0.7-0.6-1.2-0.4-1.7,0.1c-5.4,5.3-10.8,10.5-16.2,15.8c-4.8,4.7-9.6,9.5-14.4,14.2
          c-1,1-1.6,0.8-2.5-0.1c-3.5-3.5-7-6.9-10.5-10.4c-0.8-0.7-1.3-0.9-2.1-0.1c-1.8,1.8-3.6,3.6-5.5,5.4c-0.7,0.7-0.7,1.1,0,1.8
          c6.2,6,12.4,12.1,18.5,18.1C49.3,78.5,49.5,78.8,50,78.9z"/>
      </g>
    </svg>
  );
}
