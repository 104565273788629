const validationsText = (field) => ({
  empty: `El ${field} no puede estar vacío`,
  shortKey: `El ${field} debe contener al menos 2 caracteres`,
  longKey: `El ${field} debe contener máximo 50 caracteres`,
  cityLongKey: `La ${field} debe contener máximo 50 caracteres`,
  phoneFormat: `Por favor, verifique el número de ${field}`,
  passwordConfirmation: `Las ${field} deben coincidir`,
  passwordFormatValid: `La ${field} no es válida`,
});

const validationConditions = {
  minLength: (value, length) => value.length < length,
  maxLength: (value, length) => value.length > length,
  notEmpty: (value) => !value,
  exactLength: (value, length) => value.length !== length,
  passwordConfirmation: (password, confirmPassword) => password !== confirmPassword, 
  passwordFormatValid: (password, passwordValid) => password && !passwordValid, 
};

const customValidations = {
  name: [
    {
      condition: (value) => validationConditions.minLength(value, 2),
      errorMessage: validationsText("nombre").shortKey,
    },
    {
      condition: (value) => validationConditions.maxLength(value, 50),
      errorMessage: validationsText("nombre").longKey,
    },
    {
      condition: (value) => validationConditions.notEmpty(value),
      errorMessage: validationsText("nombre").empty,
    },
  ],
  last_name: [
    {
      condition: (value) => validationConditions.minLength(value, 2),
      errorMessage: validationsText("apellido").shortKey,
    },
    {
      condition: (value) => validationConditions.maxLength(value, 50),
      errorMessage: validationsText("apellido").longKey,
    },
    {
      condition: (value) => validationConditions.notEmpty(value),
      errorMessage: validationsText("apellido").empty,
    },
  ],
  city: [
    {
      condition: (value) => validationConditions.maxLength(value, 50),
      errorMessage: validationsText("ciudad").cityLongKey,
    },
  ],
  phone: [
    {
      condition: (value) => validationConditions.exactLength(value, 13),
      errorMessage: validationsText("teléfono").phoneFormat,
    },
  ],
  password: [
    {
      condition: (password, _, passwordValid) => validationConditions.passwordFormatValid(password, passwordValid),
      errorMessage: validationsText("contraseña").passwordFormatValid,
    },
    {
      condition: (password, confirmPassword) => validationConditions.passwordConfirmation(password, confirmPassword),
      errorMessage: validationsText("contraseñas").passwordConfirmation,
    },
  ],
};

export { validationsText, customValidations };


