import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPublications } from '../../../services/wall';

export const usePublications = () => {
  const { latitude, longitude } = useSelector(({ geolocation }) => geolocation);
  const [publications, setPublications] = useState([]);
  const [nextQuery, setNextQuery] = useState({ current_page: 1, offset: 1 });
  const [isLoading, setIsLoading] = useState(false);

  const params = () => ({
    date: new Date().toISOString(),
    latitude,
    longitude,
    ...nextQuery,
  });

  useEffect(() => {
    loadPublications();
  }, []);

  const loadPublications = () => {
    if (isLoading) return;

    setIsLoading(true);
    getPublications(params())
    .then(({ response, next_query }) => {
      setPublications([...publications, ...response]);
      setNextQuery(next_query);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  return {
    loadPublications,
    publications,
    isLoading,
  };
};