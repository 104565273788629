import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ServicesLC from '../home/components/ServicesLC';
import Steps from './components/steps';
import LinkInfoPages from '../../components/linkInfoPages';
import './IdVerification.scss';
import Footer from '../home/components/footer/Footer';
import ScrollToElement from '../../components/scrollToElement/ScrollToElement';
import { locambioServices } from '../../const';

const { LOCAMBIO_SERVICES_TYPES } = locambioServices;

export default () => {
  const isNotMobile = useMediaQuery({ minWidth: 769 });

  const steps = [
    {
      text: (
        <p className="text-align">
          Contacta al chat de soporte solicitando tu verificación de identidad.
        </p>
      ),
      img: 'https://storage.googleapis.com/locambio-admin/pages/id_verification/support.png',
    },
    {
      text: (
        <p className="text-align">
          Envía los requisitos indicados por el operador a través del chat de
          soporte.
        </p>
      ),
      img: 'https://storage.googleapis.com/locambio-admin/pages/id_verification/id.png',
    },
    {
      text: (
        <p className="text-align">
          Recibirás respuesta en un lapso máximo de 72 horas sobre tu proceso de
          verificación.
        </p>
      ),
      img: 'https://storage.googleapis.com/locambio-admin/pages/id_verification/check.png',
    },
  ];

  return (
    <div className="IdVerififation_container">
      <ScrollToElement
        scroll={true}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      ></ScrollToElement>
      <div className="IdVerification">
        <div className="section">
          <div className="inner">
            <div className="card">
              <div className="row inner_card">
                <img
                  className="card__image"
                  src="https://storage.googleapis.com/locambio-admin/pages/id_verification/verificacionid.png"
                />
                <div className="card__text">
                  <h4 className="card__title">
                    CAMBIA <span className="black_text">CON CONFIANZA</span>
                  </h4>
                  <div className="row inner_card">
                    <p className="card__description_text">
                      Verificación de Identidad del usuario mediante un proceso
                      de confirmación de los datos suministrados y la identidad
                      real detrás de la cuenta. Así la gestión de cambio
                      adquiere mayor veracidad y tranquilidad.
                    </p>
                    <p className="card__description_text">
                      Como <b className="accent_text">Usuario Verificado</b>, se
                      aumentan las posibilidades de hacer más negociaciones y
                      cambios exitosos, así mismo, mejorará exponencialmente la
                      credibilidad de la cuenta.
                    </p>
                  </div>
                </div>
              </div>
              <Steps steps={steps} />
              <div className="bottom_card">
                <p>
                  El servicio de <b>Verificación de Identidad</b> está{' '}
                  <span className="orange">
                    {' '}
                    disponible sin costo durante la promoción de lanzamiento.
                  </span>
                </p>
              </div>
            </div>
            <div className="sliders">
              <ServicesLC
                services={[
                  LOCAMBIO_SERVICES_TYPES.locambioPremium,
                  LOCAMBIO_SERVICES_TYPES.locambioSeguro,
                  LOCAMBIO_SERVICES_TYPES.locambioAds,
                ]}
              />
            </div>
          </div>
        </div>
        <div className="links_container">
          {isNotMobile && <LinkInfoPages active="Verificación ID" />}
        </div>
      </div>
      <Footer />
    </div>
  );
};
