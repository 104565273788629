import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilityFunctions';

const initialState = {
  securityChangeRequest: false,
};

const setSecurityChangeRequest = (state, action) => {
  return updateObject(state, {
    securityChangeRequest: action.securityChangeRequest,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SECURITY_CHANGE_REQUEST:
      return setSecurityChangeRequest(state, action);
    default:
      return state;
  }
};

export default reducer;