import React from 'react';

import './LoCambioAds/LoCambioAds.scss';
import LinkInfoPages from './../components/linkInfoPages/';
import ServicesLC from './home/components/ServicesLC';
import Footer from './home/components/footer/Footer';
import ScrollToElement from '../components/scrollToElement/ScrollToElement';
import DetailBox from './LoCambioAds/components/detailBox';
import { useMediaQuery } from 'react-responsive';
import { locambioServices } from '../const';

const { LOCAMBIO_SERVICES_TYPES } = locambioServices;

const LoCambioAds = () => {
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const description = (
    <div className="default-text">
      <b className="extra-bold">Consigue más exposición </b>
      <span>
        en los resultados de búsqueda y más visibilidad en el mural de
        LoCambio.com.
      </span>
      <br />
      <br />
      <b className="extra-bold">¿Estás pensando en grande?</b>
      <span>
        Llega más fácil y rápido a los usuarios interesados en cambiar lo que
        tienes.
      </span>
    </div>
  );

  const firstTitle = (
    <h1 className="lo-cambio-ads__titles--align-left">
      {isMobile ? (
        <div>
          <p className="big-title">LOGRA </p>
          <p className="big-title orange"> MÁS CAMBIOS </p>
          <p className="medium-title">LLEGANDO A MÁS PERSONAS</p>
        </div>
      ) : (
        <p className="big-title">
          LOGRA <span className="orange"> MÁS CAMBIOS </span>
          <p className="small-title">LLEGANDO A MÁS PERSONAS</p>
        </p>
      )}
    </h1>
  );

  const secondTitle = (
    <h1 className="lo-cambio-ads__titles">
      <span className="orange">PROMOCIONA </span>
      <span> Y </span> <span className="purple">LOGRA MÁS </span>
      <span>EN MENOS TIEMPO</span>
    </h1>
  );

  const thirdTitle = (
    <h1 className="lo-cambio-ads__titles">
      Cómo <span className="orange">promocionar</span>
    </h1>
  );

  const bottomText = () => {
    let image = (
      <img
        className="coin-img"
        alt="locambio gana creditos"
        src="https://storage.googleapis.com/locambio-admin/pages/premium/coins0.svg"
      />
    );
    return (
      <div className="lo-cambio-ads__container--mid--bottom-text">
        {image}
        <p>
          <span className="purple extra-bold">Gana créditos </span>
          <span className="bold">por publicar </span>Productos y Servicios en
          LoCambio.com y úsalos para probar nuestros servicios
        </p>
        {image}
      </div>
    );
  };

  const business1 = (
    <DetailBox
      title="basico"
      plans={1}
      price={5}
      totalImpressions={2500}
      ImpressionsColor="black"
      periodBackground="plain"
    />
  );

  const business2 = (
    <DetailBox
      title="medio"
      plans={6}
      price={10}
      totalImpressions={5000}
      ImpressionsColor="blue"
      periodBackground="blue"
    />
  );

  const business3 = (
    <DetailBox
      title="avanzado"
      plans={12}
      price={15}
      totalImpressions={7500}
      ImpressionsColor="orange"
      periodBackground="orange"
    />
  );

  const step1 = (
    <p className="steps-text">
      Desde la pestaña <span className="extra-bold"> "Campaña" </span> de tu
      Perfil, <span className="orange bold"> selecciona la publicación </span>{' '}
      que deseas promocionar.
    </p>
  );

  const step2 = (
    <p className="steps-text">
      <span className="orange">Selecciona el plan </span>de promoción de tu
      publicación
    </p>
  );

  const step3 = (
    <p className="steps-text">
      Presiona <p className="orange">"Promocionar" </p>y ¡listo!
    </p>
  );

  const stepOneImg = (
    <img
      alt="locambio ads step 1"
      src="https://storage.googleapis.com/locambio-admin/pages/ads/step1.svg"
    />
  );

  const stepTwoImg = (
    <img
      alt="locambio ads step 2"
      src="https://storage.googleapis.com/locambio-admin/pages/ads/step2.svg"
    />
  );

  const stepThreeImg = (
    <img
      alt="locambio ads step 3"
      src="https://storage.googleapis.com/locambio-admin/pages/ads/step3.svg"
    />
  );

  const logo = (
    <img
      className="lo-cambio-ads__logo"
      alt="locambio ads logo"
      src="https://storage.googleapis.com/locambio-admin/pages/ads/locambioads0.png"
    />
  );

  return (
    <>
      <ScrollToElement
        scroll={true}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      ></ScrollToElement>
      <div className="lo-cambio-ads">
        <div className="section">
          <div className="lo-cambio-ads__container">
            <div className="lo-cambio-ads__container__top">
              <div className="lo-cambio-ads__container__top__logo">
                <div className="lo-cambio-ads__container__top__logo--left">
                  {logo}
                </div>
                <div className="lo-cambio-ads__container__top__logo--right">
                  {firstTitle}
                </div>
              </div>
              <div className="lo-cambio-ads__container__top__description">
                {description}
              </div>
            </div>
            <div className="lo-cambio-ads__container--mid">
              {secondTitle}
              <div className="lo-cambio-ads__container--mid--business">
                <div className="lo-cambio-ads__images">{business1}</div>
                <div className="lo-cambio-ads__images">{business2}</div>
                <div className="lo-cambio-ads__images">{business3}</div>
              </div>
            </div>
            <div className="lo-cambio-ads__container--mid">
              {thirdTitle}
              <div className="lo-cambio-ads__container--mid--steps">
                <div className="lo-cambio-ads__info-box">
                  <div className="lo-cambio-ads__info-box--step-number">1</div>
                  {step1}
                  {stepOneImg}
                </div>
                <div className="lo-cambio-ads__info-box">
                  <div className="lo-cambio-ads__info-box--step-number">2</div>
                  {step2}
                  {stepTwoImg}
                </div>
                <div className="lo-cambio-ads__info-box">
                  <div className="lo-cambio-ads__info-box--step-number">3</div>
                  {step3}
                  {stepThreeImg}
                </div>
              </div>
              {bottomText()}
            </div>
          </div>
          <div className="lo-cambio-ads__slider-container">
            <ServicesLC
              services={[
                LOCAMBIO_SERVICES_TYPES.locambioVerificationId,
                LOCAMBIO_SERVICES_TYPES.locambioSeguro,
                LOCAMBIO_SERVICES_TYPES.locambioPremium,
              ]}
            />
          </div>
        </div>
        <div className="lo-cambio-ads__links">
          <LinkInfoPages active={'LoCambio.com Ads'} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoCambioAds;
