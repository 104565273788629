export const attrCategories = [
  // LoCambio Social
  {
    id: 728, // Alimentación
    attr: ['brand','weight','quantity','due_date'],
    attrDisplay: ['Marca','Peso','Cantidad','Fecha de Nacimiento']
  },
  {
    id: 740, // Utiles Escolares y Papeleria
    attr: ['size','material', 'school_cycle', 'gender'],
    attrDisplay: ['Talla o Medida','Material','Ciclo Escolar','Género']
  },
  {
    id: 741, // Uniformes y Vestimentas
    attr: ['size','material', 'school_cycle', 'gender'],
    attrDisplay: ['Talla o Medida','Material','Ciclo Escolar','Género']
  },
  {
    id: 742, // Libros, Textos y útiles escolares
    attr: ['editor', 'number_edition', 'school_cycle'],
    attrDisplay: ['Editora','Número de Edición','Ciclo Escolar']
  },
  {
    id: 743, // Medicinas
    attr: ['laboratory', 'weight', 'type_medication', 'quantity'],
    attrDisplay: ['Laboratorio', 'Peso', 'Tipo de Medicamento', 'Cantidad']
  },
  {
    id: 744, //Equipos Médicos
    attr: ['brand', 'model', 'quantity'],
    attrDisplay: ['Marca', 'Modelo', 'Cantidad']
  },

  // Audio
  {
    id: 604,
    attr: ['brand','model','year','watss','color'],
    attrDisplay: ['Marca','Modelo','Año','Watss','Color']
  },
  {
    id: 605,
    attr: ['brand','model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 608,
    attr: ['brand','model','year','color','potencia_watss'],
    attrDisplay: ['Marca','Modelo','Año','Color','Potencia Watss']
  },
  {
    id: 611,
    attr: ['brand','model','year','color','screen_size','storage'],
    attrDisplay: ['Marca','Modelo','Color','Tamaño de Pantalla','Capacidad de Almacenamiento']
  },

  // Accesorio para Vehiculos
  {
    id: 486, // accesorios nauticos
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 487, // accesorios para carros
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 488, // accesorios para motos
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 489, // accesorios para rusticos
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 490, // Audio
    attr: ['brand', 'model', 'year', 'potencia_kw'],
    attrDisplay: ['Marca','Modelo','Año','Potencia Watss']
  },
  {
    id: 492,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 746, // Repuestos y Mecánica
    attr: ['brand'],
    attrDisplay: ['Marca']
  },

  // Bebes
  {
    id: 497,
    attr: ['brand', 'due_date','old'],
    attrDisplay: ['Marca','Fecha de Vencimiento','Edad']
  },
  {
    id: 498,
    attr: ['brand', 'model', 'due_date', 'color'],
    attrDisplay: ['Marca','Modelo','Año del Modelo','Color']
  },
  {
    id: 499,
    attr: ['brand', 'due_date','old'],
    attrDisplay: ['Marca','Fecha de Vencimiento','Edad']
  },
  {
    id: 500,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 501,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 502,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 503,
    attr: ['brand', 'due_date','old'],
    attrDisplay: ['Marca','Fecha de Vencimiento','Edad']
  },
  {
    id: 505,
    attr: ['brand', 'color','old'],
    attrDisplay: ['Marca','Color','Edad']
  },
  {
    id: 506,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 507,
    attr: ['brand', 'color','old'],
    attrDisplay: ['Marca','Color','Edad']
  },
  {
    id: 508,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },

  // Carros, Motos y Otros
  {
    id: 514,
    attr: ['brand', 'displacement', 'km', 'number_door', 'number_posts', 'type_gas', 'type_transmission', 'color'],
    attrDisplay: ['Marca','Cilindrada','Kilometraje','Número de Puertas','Número de Puestos','Tipo de Combustible','Tipo de Transmisión','Color']
  },
  {
    id: 515,
    attr: ['brand', 'displacement', 'km', 'color', 'type_transmission'],
    attrDisplay: ['Marca','Cilindrada','Kilometraje','Color','Tipo de Transmisión']
  },
  {
    id: 516,
    attr: ['brand', 'type_ship'],
    attrDisplay: ['Marca','Tipo de Barco']
  },

  // Celulares y Telefonos
  {
    id: 517,
    attr: ['brand', 'size'],
    attrDisplay: ['Marca','Tamaño']
  },
  {
    id: 518,
    attr: ['brand', 'model', 'year', 'screen_size', 'storage', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Capacidad de Almacenamiento','Color']
  },
  {
    id: 520,
    attr: ['brand','band','model'],
    attrDisplay: ['Marca','Banda','Modelo']
  },
  {
    id: 521,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 522,
    attr: ['brand','model'],
    attrDisplay: ['Marca','Modelo']
  },

  // Coleccionables y Hobbies
  {
    id: 523,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 524,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 525,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 526,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 527,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 528,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 529,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 530,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 531,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 532,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 533,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 534,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 535,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 536,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },
  {
    id: 537,
    attr: ['color','quantity','model'],
    attrDisplay: ['Color','Cantidad','Modelo']
  },

  // Computacion
  {
    id: 538,
    attr: ['brand', 'model', 'year', 'screen_size', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Color']
  },
  {
    id: 539,
    attr: ['brand', 'model', 'year', 'screen_size', 'storage'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Color']
  },
  {
    id: 540,
    attr: ['brand', 'model', 'year', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Color']
  },
  {
    id: 541,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 542,
    attr: ['brand', 'model', 'year', 'storage', 'processor', 'memory_ram', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento','Memoria RAM','Color']
  },
  {
    id: 543,
    attr: ['brand', 'model', 'year', 'color', 'storage'],
    attrDisplay: ['Marca','Modelo','Año','Color','Capacidad de Almacenamiento']
  },
  {
    id: 544,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 545,
    attr: ['brand', 'model', 'year', 'screen_size', 'storage', 'processor', 'memory_ram', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Capacidad de Almacenamiento','Procesador','Memoria RAM', 'Color']
  },
  {
    id: 546,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 547,
    attr: ['brand', 'model', 'year', 'storage', 'color', 'screen_size'],
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento','Color','Tamaño de Pantalla']
  },
  {
    id: 548,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 549,
    attr: ['brand', 'model', 'year', 'screen_size', 'storage', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Capacidad de Almacenamiento','Color']
  },
  {
    id: 550,
    attr: ['brand', 'model', 'year', 'storage'],
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento']
  },
  {
    id: 551,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 552,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 553,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 554,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 555,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 556,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 557,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 558,
    attr: ['brand', 'model', 'year', 'screen_size', 'storage', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Capacidad de Almacenamiento','Color']
  },
  {
    id: 559,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 560,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 754,
    attr: ['brand', 'model', 'year', 'power'],
    attrDisplay: ['Marca','Modelo','Año','Potencia']
  },

  // COnsolas y VideoJuegos
  {
    id: 766,
    attr: ['brand','model_2','year','storage','color'] ,
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento','Color']
  },
  {
    id: 767,
    attr: ['brand','model_2','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 768,
    attr: ['brand','model_2'] ,
    attrDisplay: ['Marca','Modelo']
  },

  // Electrónicos
  {
    id: 756,
    attr: ['brand','model','color'],
    attrDisplay: ['Marca','Modelo','Color']
  },
  {
    id: 757,
    attr: ['brand','model','color'],
    attrDisplay: ['Marca','Modelo','Color']
  },
  {
    id: 758,
    attr: ['brand','model','multifuncional','year'],
    attrDisplay: ['Marca','Modelo','Multifuncional','Año']
  },
  {
    id: 759,
    attr: ['brand','model','year','screen_size','color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Color']
  },
  {
    id: 760,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 761,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 762,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 763,
    attr: ['brand','model','year','screen_size','storage','processor','memory_ram','color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Capacidad de Almacenamiento','Procesador','Memoria RAM','Color']
  },
  {
    id: 764,
    attr: ['brand','model','year','screen_size','color'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño de Pantalla','Color']
  },
  {
    id: 765,
    attr: ['brand','model','year','storage','color'],
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento','Color']
  },

  // Camaras y Accesorios
  {
    id: 509,
    attr: ['brand', 'model', 'year', 'storage', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento','Color']
  },
  {
    id: 510,
    attr: ['brand', 'model', 'year', 'megapixels'],
    attrDisplay: ['Marca','Modelo','Año','Megapixeles']
  },
  {
    id: 511,
    attr: ['brand', 'model', 'year', 'megapixels', 'zoom', 'type_general'],
    attrDisplay: ['Marca','Modelo','Año','Megapixeles','Zoom','Tipo']
  },
  {
    id: 512,
    attr: ['brand', 'model', 'year', 'storage'],
    attrDisplay: ['Marca','Modelo','Año','Capacidad de Almacenamiento']
  },
  {
    id: 513,
    attr: ['brand', 'model', 'year', 'megapixels','type_general','resolution','lens'],
    attrDisplay: ['Marca','Modelo','Año','Megapixeles','Tipo','Resolución','Lente']
  },
  {
    id: 769,
    attr: ['brand', 'model', 'year', 'size', 'optic'],
    attrDisplay: ['Marca','Modelo','Año','Óptica']
  },
  {
    id: 770,
    attr: ['brand','model','power'],
    attrDisplay: ['Marca','Modelo','Potencia']
  },
  {
    id: 771,
    attr: ['brand','model','year','megapixels','lens','resolution','zoom'],
    attrDisplay: ['Marca','Modelo','Año','Megapixeles','Lente','Resolución','Zoom']
  },

  // Deportes y Fitness
  {
    id: 772,
    attr: ['brand', 'model', 'color','size','material','year','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Color','Talla o Medida','Año','Género','Disciplina']
  },
  {
    id: 773,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Color','Talla o Medida','Año','Género','Disciplina']
  },
  {
    id: 774,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Color','Talla o Medida','Año','Género','Disciplina']
  },
  {
    id: 775,
    attr: ['brand','model','year','color','size','material','gender'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Género']
  },
  {
    id: 776,
    attr: ['brand','model','year','color','size','material', 'discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Disciplina']
  },
  {
    id: 777,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Disciplina']
  },
  {
    id: 778,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Disciplina']
  },
  {
    id: 779,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Disciplina']
  },
  {
    id: 780,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Disciplina']
  },
  {
    id: 781,
    attr: ['brand','model','year','color','size','material','gender','discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Talla o Medida','Material','Disciplina']
  },
  {
    id: 782,
    attr: ['brand','model','year','color','discipline'],
    attrDisplay: ['Marca','Modelo','Año','Color','Disciplina']
  },
  {
    id: 783,
    attr: ['brand','color','size','discipline'],
    attrDisplay: ['Marca','Color','Talla o Medida','Disciplina']
  },
  {
    id: 784,
    attr: ['brand','weight','quantity','due_date'],
    attrDisplay: ['Marca','Peso','Cantidad','Fecha de Vencimiento']
  },
  {
    id: 785,
    attr: ['brand','year','color','size','material','discipline'],
    attrDisplay: ['Marca','Año','Color','Talla o Medida','Material','Disciplina']
  },

  // Electrodomesticos
  {
    id: 595,
    attr: ['brand', 'model', 'year', 'size', 'power', 'material'],
    attrDisplay: ['Marca','Modelo','Año','Medida','Potencia','Material']
  },
  {
    id: 596,
    attr: ['brand', 'model', 'year', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Color']
  },
  {
    id: 597,
    attr: ['brand', 'model', 'year', 'color'],
    attrDisplay: ['Marca','Modelo','Año','Color']
  },
  {
    id: 598,
    attr: ['brand', 'model', 'year', 'color','kg'],
    attrDisplay: ['Marca','Modelo','Año','Color','Kg']
  },
  {
    id: 599,
    attr: ['brand', 'model', 'year', 'color','number_doors'],
    attrDisplay: ['Marca','Modelo','Año','Color','Número de Puertas']
  },
  {
    id: 600,
    attr: ['brand', 'model', 'year', 'size', 'color', 'power'],
    attrDisplay: ['Marca','Modelo','Año','Medida','Color','Potencia']
  },
  {
    id: 601,
    attr: ['brand', 'model', 'year', 'size', 'color', 'power'],
    attrDisplay: ['Marca','Modelo','Año','Tamaño','Color','Potencia']
  },

  // Estetica y belleza
  {
    id: 616,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 617,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 618,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 619,
    attr: ['kind_care','brand','weight','gender'],
    attrDisplay: ['Tipo de Cuidado','Marca','Peso','Género']
  },
  {
    id: 620,
    attr: ['brand','color','weight'],
    attrDisplay: ['Marca','Color','Peso']
  },
  {
    id: 621,
    attr: ['brand','gender','name_aux'],
    attrDisplay: ['Marca','Género','Nombre del Producto']
  },

  // Hogar y Muebles
  {
    id: 622,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 623,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 624,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 625,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 626,
    attr: ['brand', 'model','size'],
    attrDisplay: ['Marca','Modelo','Medida']
  },
  {
    id: 627,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 628,
    attr: ['brand', 'model', 'power'],
    attrDisplay: ['Marca','Modelo','Potencia']
  },
  {
    id: 629,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 630,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 786,
    attr: ['color','material','type_sofa'],
    attrDisplay: ['Color','Marca','Tipo de Mueble']
  },
  {
    id: 787,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },

  // Oficinas y Muebles
  {
    id: 789,
    attr: ['brand','quantity','size'],
    attrDisplay: ['Marca','Cantidad','Medida']
  },
  {
    id: 793,
    attr: ['brand','model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 791,
    attr: ['brand','model','power'],
    attrDisplay: ['Marca','Modelo','Potencia']
  },
  {
    id: 790,
    attr: ['type_sofa','color','material'],
    attrDisplay: ['Tipo de Mueble','Color','Material']
  },
  {
    id: 792,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },

  // Industrias
  {
    id: 631,
    attr: ['brand', 'model','material','quantity'],
    attrDisplay: ['Marca','Modelo','Material','Cantidad']
  },
  {
    id: 632,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 633,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 634,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 635,
    attr: ['brand', 'material','color','size'],
    attrDisplay: ['Marca','Material','Color','Tamaño o Medida']
  },
  {
    id: 636,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 637,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 638,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 639,
    attr: ['brand', 'model'],
    attrDisplay: ['Marca','Modelo']
  },
  {
    id: 794,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },

  // Inmuebles
  {
    id: 640,
    attr: ['urbanization','name_club'],
    attrDisplay: ['Urbanización','Nombre del Club']
  }, 
  {
    id: 641,
    attr: ['meters_2','number_room','number_bath','urbanization','number_pool_parking_lot','living_room','dinning_room'],
    attrDisplay: ['Metros Cuadrados','Número de Habitaciones','Número de Baños','Urbanización','Número de Puestos de Estacionamiento','Sala','Comedor']
  },
  {
    id: 642,
    attr: ['meters_2','number_room','number_bath','living_room','dinning_room','urbanization','number_pool_parking_lot'],
    attrDisplay: ['Metros Cuadrados','Número de Habitaciones', 'Número de Baños', 'Sala','Comedor','Urbanización','Número de Puestos de Estacionamiento']
  },
  {
    id: 643,
    attr: ['meters_2','number_room','number_bath','living_room','dinning_room','urbanization','number_pool_parking_lot'],
    attrDisplay: ['Metros Cuadrados','Número de Habitaciones', 'Número de Baños', 'Sala','Comedor','Urbanización','Número de Puestos de Estacionamiento']
  },
  {
    id: 644,
    attr: ['type_build','meters_2_construction','meters_ground','number_apartments','urbanization','number_floor'],
    attrDisplay: ['Tipo de Edificio','Metros Cuadrados de Construcción','Metros de Terreno','Urbanización','Número de Pisos']
  },
  {
    id: 645,
    attr: ['meters_2_construction', 'urbanization'],
    attrDisplay: ['Metros Cuadrados de Construcción','Urbanización']
  },
  {
    id: 646,
    attr: ['meters','number_bathrooms', 'urbanization' ],
    attrDisplay: ['Metros','Número de Baños','Urbanización']
  },
  {
    id: 647,
    attr: ['meters_2_construction', 'meters_ground'],
    attrDisplay: ['Metros Cuadrados de Construcción','Metros de Terreno']
  },
  {
    id: 648,
    attr: ['type_build','meters_2_construction','meters_ground','number_room','number_floor','urbanization'],
    attrDisplay: ['Tipo de Edificio','Metros Cuadrados de Construcción','Metros de Terreno','Número de Habitaciones','Número de Pisos','Urbanización']
  },
  {
    id: 649,
    attr: ['meters_2','number_areas','number_bath','urbanization'],
    attrDisplay: ['Metros Cuadrados','Número de Áreas','Número de Baños','Urbanización']
  },
  {
    id: 650,
    attr: ['year_constitution'],
    attrDisplay: ['Año de Construcción']
  },
  {
    id: 651,
    attr: ['meters_2', 'number_cubicles', 'number_bath','urbanization'],
    attrDisplay: ['Metros Cuadrados','Número De Cubículos','Número de Baños','Urbanización']
  },
  {
    id: 652,
    attr: ['urbanization','name_aux','year_constitution'],
    attrDisplay: ['Urbanización','Nombre del Cementerio','Año de Construcción']
  },
  {
    id: 653,
    attr: ['urbanization', 'meters_ground'],
    attrDisplay: ['Urbanización','Metros de Terreno']
  },
  {
    id: 654,
    attr: ['meters', 'number_beedroom', 'number_bathrooms', 'living_room', 'dinning_room', 'urbanization'],
    attrDisplay: ['Metros','Número de Habitaciones','Número de Baños','Sala','Comedor','Urbanización']
  },

  // Instrumentos Musicales
  {
    id: 655,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 656,
    attr: ['brand','model','year','color','number_ropes','type_aux'],
    attrDisplay: ['Marca','Modelo','Año','Color','Número de Cuerdas','Tipo']
  },
  {
    id: 657,
    attr: ['brand','model','year','color','type_aux','number_pads'],
    attrDisplay: ['Marca','Modelo','Año','Color','Tipo','Número de Pads']
  },
  {
    id: 658,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 659,
    attr: ['brand','model','year','color','number_ropes','type_aux'],
    attrDisplay: ['Marca','Modelo','Año','Color','Número de Cuerdas','Tipo']
  },
  {
    id: 660,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 662,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 663,
    attr: ['brand','model','year','type_aux'],
    attrDisplay: ['Marca','Modelo','Año','Tipo']
  },
  {
    id: 661,
    attr: ['brand','model','year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 824,
    attr: ['brand','model','year','color'],
    attrDisplay: ['Marca','Modelo','Año','Color']
  },
  {
    id: 825,
    attr: ['type_aux'],
    attrDisplay: ['Tipo']
  },
  {
    id: 826,
    attr: ['name_band','name_gender','year','color','name_album'],
    attrDisplay: ['Nombre de la Band','Género','Año','Color','Nombre del Album']
  },

  // Juegos y Juguetes
  {
    id: 664,
    attr: ['brand', 'model', 'year','old','gender'],
    attrDisplay: ['Marca','Modelo','Año','Edad','Género']
  },
  {
    id: 665,
    attr: ['brand', 'model', 'year','old','gender'],
    attrDisplay: ['Marca','Modelo','Año','Edad','Género']
  },
  {
    id: 666,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 667,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 668,
    attr: ['brand', 'model', 'year', 'gender'],
    attrDisplay: ['Marca','Modelo','Año','Género']
  },
  {
    id: 669,
    attr: ['brand', 'model', 'year'],
    attrDisplay: ['Marca','Modelo','Año']
  },
  {
    id: 670,
    attr: ['brand', 'model', 'year','old'],
    attrDisplay: ['Marca','Modelo','Año','Edad']
  },
  {
    id: 671,
    attr: ['brand', 'model', 'year','old','gender'],
    attrDisplay: ['Marca','Modelo','Año','Edad','Género']
  },
  {
    id: 672,
    attr: ['brand', 'model', 'year','old','gender'],
    attrDisplay: ['Marca','Modelo','Año','Edad','Género']
  },

  // Libro, Musica y Peliculas
  {
    id: 673,
    attr: ['name_gender', 'year', 'name_movie_or_book'],
    attrDisplay: ['Nombre del Genero', 'Año', 'Nombre del Libro']
  },
  {
    id: 674,
    attr: ['autor','name_comic','year'],
    attrDisplay: ['Autor', 'Nombre del Comic', 'Año']
  },
  {
    id: 675,
    attr: ['editor', 'number_edition', 'year', 'autor'],
    attrDisplay: ['Editor', 'Número de Ediciòn', 'Año', 'Autor'],
  },
  {
    id: 676,
    attr: ['editor', 'number_edition', 'year', 'autor'],
    attrDisplay: ['Editor', 'Número de Ediciòn', 'Año', 'Autor'],
  },
  {
    id: 677,
    attr: ['name_movie_or_book', 'name_band', 'gender_movie', 'year'],
    attrDisplay: ['Nombre', 'Banda', 'Género', 'Año'],
  },
  {
    id: 678,
    attr: ['director', 'name_study', 'name_gender', 'year'],
    attrDisplay: ['Director', 'Estudio', 'Género', 'Año'],
  },
  {
    id: 679,
    attr: ['editor', 'number_edition', 'year'],
    attrDisplay: ['Editor', 'Nùmero de Ediciòn', 'Año']
  },
  {
    id: 680,
    attr: ['productor', 'name_gender', 'year', 'season'],
    attrDisplay: ['Productor', 'Genero', 'Año', 'Temporada']
  },

  // Relojes, Joyas y Bisuteria
  {
    id: 681,
    attr: ['brand','material','weight'],
    attrDisplay: ['Marca','Material','Peso']
  },
  {
    id: 815,
    attr: ['brand'],
    attrDisplay: ['Marca']
  },
  {
    id: 682,
    attr: ['brand', 'model', 'year','material'],
    attrDisplay: ['Marca','Modelo','Año','Material']
  },
  {
    id: 683,
    attr: ['brand','size','material','gender'],
    attrDisplay: ['Marca','Tamaño','Material','Género']
  },

  // Accesorios de Vestir
  {
    id: 837,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 838,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 839,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 840,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 841,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 842,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 843,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 844,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 845,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 846,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 847,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 848,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },
  {
    id: 849,
    attr: ['brand', 'material', 'size', 'gender','color'],
    attrDisplay: ['Marca','Material','Talla','Género','Color']
  },

  // Ropas
  {
    id: 900,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 901,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 902,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 903,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 904,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 905,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 906,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 907,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 908,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 909,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 910,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 911,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 912,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 913,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 914,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },
  {
    id: 915,
    attr: ['brand','gender','size','color','material'],
    attrDisplay: ['Marca','Género','Talla','Color','Material']
  },

  // Zapatos
  {
    id: 828,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 829,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 830,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 831,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 832,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 833,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 834,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 835,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },
  {
    id: 836,
    attr: ['brand','color','size','material','model','year','gender'],
    attrDisplay: ['Marca','Color','Talla','Material','Modelo','Año','Género']
  },

  // Animales y Mascotas
  {
    id : 747,
    attr: ['race', 'weight', 'old', 'color'],
    attrDisplay: ['Raza','Peso','Edad','Color']
  },
  {
    id: 748,
    attr: ['brand', 'weight', 'due_date', 'type_animal'],
    attrDisplay: ['Marca','Peso','Fecha de Vencimiento','Tipo de Animal']
  },
  {
    id: 749,
    attr: ['brand','type_animal'],
    attrDisplay: ['Marca','Tipo de Animal']
  },
  {
    id: 750,
    attr: ['laboratory', 'mg_ml', 'number_tablet', 'type_animal'],
    attrDisplay: ['Laboratorio','Mg/Ml','Número de Pastillas','Tipo de Animal']
  },
  {
    id: 751,
    attr: ['color', 'size', 'type_animal'],
    attrDisplay: ['Color','Tamaño','Tipo de Animal']
  },
  {
    id: 752,
    attr: ['color', 'size', 'type_animal'],
    attrDisplay: ['Color','Tamaño','Tipo de Animal']
  },
  {
    id: 495,
    attr: ['race', 'brand', 'model'],
    attrDisplay: ['Raza','Marca','Modelo']
  },
  // Otras Categorias
  {
    id: 721,
    attr: ['brand','material'],
    attrDisplay: ['Marca','Material']
  },
  {
    id: 722,
    attr: ['autor', 'year','name_aux'],
    attrDisplay: ['Autor','Áño', 'Nombre']
  },
  {
    id: 723,
    attr: ['brand', 'name_aux', 'year'],
    attrDisplay: ['Marca', 'Nombre', 'Áño']
  }
]