import React, { Component } from 'react';
import './searchListPagination/SearchListPagination.scss';
import Pagination from 'react-js-pagination';

class SearchListPagination extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div className="pagination_wrapper">
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.perPage}
            totalItemsCount={this.props.totalPublications}
            pageRangeDisplayed={this.props.showMax}
            onChange={this.props.handlePageChange}
            prevPageText={'Anterior'}
            nextPageText={'Siguiente'}
            activeClass={'active'}
            hideFirstLastPages
            disabledClass={'disabled'}
            itemClassNext={'next'}
            itemClassPrev={'prev'}
            
          />
        </div>
      </>
    );
  }
}

export default SearchListPagination;
