import React, { useState } from "react";
import { Icon, Tooltip, Position } from "@blueprintjs/core";
import { Toast } from "../../utility/toast";
import { useMediaQuery } from "react-responsive";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Popup from "../../components/popUp";
import "./ShareButton.scss";

const Share = "https://storage.googleapis.com/locambio-admin/icons/share0.svg"

function ShareButton() {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [copiedURL, setCopiedURL] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const shareURL = window.location.href;
  const handleCloseModal = () => {
    setDisplayPopup(false);
  };

  const copyURL = async () => {
    await navigator.clipboard.writeText(shareURL);
    Toast.show({
      message: "URL copiado exitosamente",
      intent: "success",
      icon: "saved",
    });
    setCopiedURL(true);
  };

  const mobileShare = async()=>{
    if (navigator.share){
      await navigator.share({url:window.location.href, title:"", text:"¡Increíble! Mira lo que me acabo de encontrar en #LoCambio 🧐"}).then(result=>{console.log("Success", result)})
    }
  }

  const manageSharing = () => {
    if (isDesktop) {
      setCopiedURL(false)
      setDisplayPopup(true);
    } else {
      mobileShare()
    }
  }

  const DesktopMedia = (
    <div className="share-modal__buttons">
      <FacebookShareButton url={shareURL}>
        <FacebookIcon size={50} round={true} />
        Facebook
      </FacebookShareButton>

      <TwitterShareButton
        url={shareURL}
        title="¡Increíble! Mira lo que me acabo de encontrar en #LoCambio 🧐"
        hashtags={["HagamosBuenosCambios"]}
        via="LoCambioVE"
      >
        <TwitterIcon size={50} round={true} />
        Twitter
      </TwitterShareButton>

      <WhatsappShareButton
        url={shareURL}
        title="¡Increíble! Mira lo que me acabo de encontrar en #LoCambio 🧐"
      >
        <WhatsappIcon size={50} round={true} />
        Whatsapp
      </WhatsappShareButton>
    </div>
  );

  return (
    <div>
      <Tooltip content="Compartir" position={Position.TOP}>
        <button className="share-button" onClick={() => manageSharing()}>
          <img className="share-button__image" src={Share} alt="Button"/>
          { isDesktop && <div>Compartir</div> }
        </button>
      </Tooltip>
      {displayPopup && (
        <Popup displayModal={displayPopup} hideModal={handleCloseModal}>
          <div className="share-modal__container">
            <h2>Compartir</h2>
            {DesktopMedia}
            <div className="share-modal__URL">
              <Icon
                className="share-modal__URL-icon"
                icon="link"
                iconSize={20}
              />
              <input className="share-modal__URL-input" type="text" disabled placeholder={shareURL}/>
            </div>
            <div className="share-modal__button">
              <button
                disabled={copiedURL}
                onClick={(_) => {
                  copyURL();
                }}
              >
                {copiedURL ? "¡Copiado!" : "Copiar URL"}
              </button>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default ShareButton;
