import React, { Component } from "react";
import { Button, Callout, Drawer, Icon, Position } from "@blueprintjs/core";
import { Col, Container, Row } from "react-grid-system";
import MediaQuery from "react-responsive";
import SearchListResults from "./searchList/SearchListResults";
import SearchListPagination from "./searchList/SearchListPagination";
import Footer from "./../components/Footer";
import { TYPESEARCH } from "utility";
import { config } from "./../system/Config";
import { getHeader } from "./../system/Sesion";
import { orderAttr } from "./searchList/aux";
import axios from "axios";
import { Redirect, withRouter } from "react-router-dom";
import ProductSearchListSkeleton from "./../components/ProductSearchListSkeleton";
import NotResults from "./searchList/NotResults";
import { connect } from "react-redux";
import { provincesVenezuela, typeAds } from "const";
import "./searchList/SearchList.scss";
import debounce from "../utility/debounce";
import ScrollToElement from "../components/scrollToElement/ScrollToElement";
import Card from "../components/card/Card";
import HorizontalScroll from "../components/horizontalScroll/HorizontalScroll";

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publications: { print: [], categories: [] },
      categories: [],
      categoryId: "",
      loading: true,
      incorrectSearch: false,
      pagination: {
        page: 1,
        perPage: 10,
        totalPublications: 0,
        showMax: 10,
      },
      search_by: this.props.match.params.search_by,
      tags: this.props.match.params.tags,
    };
    this.toSearch = this.toSearch.bind(this);
    this.fetchSearch = this.fetchSearch.bind(this);
  }


  componentDidMount() {
    this.getUrlAndData(this.props.match.params);
  }

  componentWillReceiveProps(nextProps) {
    const pagination = { ...this.state.pagination, page: 1 };
    const categoryId = this.state.tags === nextProps.match.params.tags ? this.state.categoryId : '';
    this.setState({ pagination: pagination, categoryId: categoryId }, () => {
      this.handleTagChange(nextProps.match.params);
    });
  }

  debounceSearch = debounce(() => {
    this.getUrlAndData(this.props.match.params);
  }, 1500);

  toChange = (link) => {
    this.props.history.push(link);
  };

  handleTagChange(params) {
    if (this.state.search_by === params.search_by) {
      this.getUrlAndData(params);
    } else {
      const pagination = {
        ...this.state.pagination,
        page: 1,
        totalPublications: 0,
      };
      this.setState(
        { pagination: pagination, search_by: params.search_by, categoryId: "" },
        () => {
          this.getUrlAndData(params);
        }
      );
    }
  }

  getUrlAndData(params) {
    const category_id = params.category_id ? params.category_id : "";
    const typeSearch = params.typeSearch;
    let url = "";
    const { page, perPage } = this.state.pagination;

    if (params.search_by === 'values') {
      url = `${config.URL_API}/search_publications_by_values?left=${params.left}&right=${params.right}&latitude=${params.latitude}&longitude=${params.longitude}&location=${params.location}&type=${params.type}&km=null&page=${page}&per_page=${perPage}&condition=&status=`;
    } else {
      if (typeSearch === TYPESEARCH.TAGS || typeSearch === TYPESEARCH.PRICE) {
        url = `${config.URL_API}/search_publications?tags=${decodeURIComponent(
          params.tags
        )}&location=${params.location}&type=${params.type}&latitude=${params.latitude
          }&longitude=${params.longitude
          }&km=null&page=${page}&per_page=${perPage}&search_by=${params.search_by
          }&condition=&status=&category_id=${category_id}`;
      } else if (typeSearch === TYPESEARCH.INTERST) {
        url = `${config.URL_API}/find_pub_ids?ids=${params.ids}&latitude=${params.latitude}&longitude=${params.longitude}&location=${params.location}&page=${page}&per_page=${perPage}&search_by=${params.search_by}&condition=&status=`;
      } else if (params.query === "category") {
        url = `${config.URL_API}/search_publications_by_category?category=${params.category}&latitude=${params.latitude}&longitude=${params.longitude}&page=${page}&per_page=${perPage}&search_by=${params.search_by}&condition=&status=`;
      }
    }
    this.toSearch(url);
  }

  fetchSearch(url) {
    return axios.get(url, { headers: getHeader() });
  }

  async toSearch(url) {
    this.setState({ loading: true });
    try {
      const response = await this.fetchSearch(url);
      let publications = { ...this.state.publications };
      let pagination = { ...this.state.pagination };
      publications.print = response.data.sponsored
        ? [...response.data?.sponsored, ...response.data.data]
        : response.data.data;
      pagination.totalPublications = response.data.totalPublications;
      this.setState({
        publications: publications,
        loading: false,
        pagination: pagination,
        categories: response.data.categories,
      });
      if (response.data.data.length === 0) {
        return;
      }
    } catch (err) {
      console.log("error", err);
    }
  }
  handleClickCategory = (categoryId) => {
    const {
      tags,
      location,
      type,
      km,
      latitude,
      longitude,
      sbp,
      typeSearch,
      search_by,
      category_id,
    } = this.props.match.params;
    const piece1 = `/search_list/${tags}`;
    const piece2 = `/${categoryId}`;
    const piece3 = `/${location}/${type}/${km}/${latitude}/${longitude}/${sbp}/${typeSearch}/${search_by}`;
    const link =
      categoryId.toString() === category_id
        ? piece1 + piece3
        : piece1 + piece2 + piece3;
    this.props.history.push(link);
    const initialPagination = {
      pagination: { ...this.state.pagination, page: 1 },
    };
    this.setState({ ...initialPagination }, () => {
      this.getUrlAndData(this.props.match.params);
    });
  };

  handlePageChange = (pageNumber) => {
    let pagination = { ...this.state.pagination };
    pagination.page = pageNumber;
    this.setState({ pagination: pagination }, () => {
      this.getUrlAndData(this.props.match.params);
    });
  };

  render() {
    const { loading, publications, incorrectSearch } = this.state;
    const typeSearch = this.props.match.params.typeSearch;
    if (incorrectSearch) {
      return <Redirect to="/" />;
    }
    let attrCategories = [];

    if (publications.categories.length > 0) {
      attrCategories = orderAttr(publications.categories);
    }

    return (
      <>
        <ScrollToElement
          scroll={true}
          options={{ behavior: "auto", block: "center" }}
          highlight={false}
        ></ScrollToElement>
        <div className={"SearchList pt-12 sm:pt-12 md:pt-12 lg:pt-12 xl:pt-12"}>
          <div className="customWrapper">
            {this.state.categories?.length > 0 && (
              <Row justify="center">
                <Col xs={12} sm={12} md={12} lg={9}>
                  <Card className="SearchList__categories children-without-transition">
                    <HorizontalScroll>
                      <div
                        size="small"
                        className="SearchList__categories__slider"
                      >
                        {this.state.categories?.map((category, i) => (
                          <div
                            className={`SearchList__categories__slider__item ${category[4] === "actual" &&
                              "SearchList__categories__slider__item--active"
                              } `}
                            onClick={() =>
                              this.handleClickCategory(category[0])
                            }
                          >
                            <div className="SearchList__categories__slider__item__left">
                              <div key={i}>
                                <span
                                  className={`SearchList__categories__slider__item__left--father ${category[4] === "actual" &&
                                    "SearchList__categories__slider__item__left--father--white"
                                    } `}
                                >
                                  {category[2]}
                                </span>
                                <div>{`${category[1]} (${category[3]})`}</div>
                              </div>
                            </div>
                            <div className="SearchList__categories__slider__item__right">
                              {category[4] === "actual" && (
                                <Icon
                                  iconSize="14"
                                  className="SearchList__category__slider__item--icon"
                                  icon="cross"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </HorizontalScroll>
                  </Card>
                </Col>
              </Row>
            )}
            <Row justify="center">
              <Col xs={12} sm={12} md={12} lg={9}>
                {typeSearch === TYPESEARCH.PRICE ? (
                  <Callout
                    className="marginBottom-1em"
                    intent="primary"
                    icon="search"
                    title="Búsqueda por rango de precios"
                  />
                ) : null}
                {typeSearch === TYPESEARCH.INTERST ? (
                  <Callout
                    className="marginBottom-1em"
                    intent="primary"
                    icon="search"
                    title="Búsqueda por intereses de cambio"
                  />
                ) : null}
                {!loading && publications.print.length > 0 ? (
                  <SearchListResults
                    toChange={this.toChange}
                    publications={publications.print}
                  />
                ) : null}
                {!loading && publications.print.length === 0 ? (
                  <NotResults />
                ) : null}
                {loading ? (
                  <>
                    <ProductSearchListSkeleton />
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {this.state.pagination.totalPublications >
                this.state.pagination.perPage ? (
                <SearchListPagination
                  handlePageChange={this.handlePageChange}
                  totalPublications={this.state.pagination.totalPublications}
                  activePage={this.state.pagination.page}
                  perPage={this.state.pagination.perPage}
                  showMax={this.state.pagination.showMax}
                />
              ) : null}
            </Col>
          </Row>
        </div>
        <div className="customWrapper">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Footer />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

export default withRouter(connect(mapStateToProps)(SearchList));
