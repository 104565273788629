import React from 'react';

import Footer from './home/components/footer/Footer';
import LinkInfoPages from './../components/linkInfoPages/LinkInfoPages';
import './termsAndConditions/TermsAndConditions.scss';
import ScrollToElement from '../components/scrollToElement/ScrollToElement';

const TermsAndConditions = () => {
  const sections = [
    {
      title: 'Términos y condiciones WWW.LOCAMBIO.COM',
      text: [
        'Los acuerdos legales que se describen a continuación rigen el uso, manejo y presentación de los términos y condiciones generales (en lo adelante los “Términos y Condiciones”) para la utilización de la red social web WWW.LOCAMBIO.COM y su aplicación móvil incluyendo la contratación de los servicios administrados u ofrecidos por o a través de WWW.LOCAMBIO.COM',
        'El usuario registrado (en lo adelante el “Usuario”) de WWW.LOCAMBIO.COM declara tener capacidad jurídica para contratar en su nombre o de representar a quien la tenga, así como haber leído, y estar de acuerdo de someterse a los presentes “Términos y Condiciones”. Si se ha inscrito a un “Usuario” como empresa o persona jurídica, se debe tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos de este acuerdo. WWW.LOCAMBIO.COM podrá negar o terminar la prestación de los servicios a quienes no posean capacidad para contratar y a quienes no hayan convenido en la anterior declaración. Si el “Usuario” no está de acuerdo con los “Términos y Condiciones”, debe abstenerse de utilizar el sitio y/o los servicios.',
        'WWW.LOCAMBIO.COM se reserva el derecho a realizar cambios en el sitio web, en la aplicación, en las políticas de servicio y en todo lo referente a los “Términos y Condiciones”, en cualquier momento, con o sin previo aviso, y empezarán a regir desde el momento de su publicación en la página de WWW.LOCAMBIO.COM con lo cual se entenderá como aceptados por el “Usuario” al haber suscrito estos “Términos y Condiciones”. Si alguna de las presentes condiciones fuera declarada inválida, nula o por cualquier causa ineficáz, dicha condición se entenderá excluida, sin que la mencionada declaración pueda afectar la validéz ni la exigibilidad del resto de las condiciones.',
        'LOS PRESENTES Y FUTUROS “TÉRMINOS Y CONDICIONES” GENERALES, TIENEN CARÁCTER OBLIGATORIO Y EN CONSECUENCIA VINCULANTE. EL “USUARIO” QUE NO ACEPTE LO ANTERIOR NO PODRÁ UTILIZAR NUESTRA RED SOCIAL WEB, APLICACIÓN MOVIL Y/O LOS PRODUCTOS OFRECIDOS.',
      ],
    },
    {
      title: 'Condiciones Generales',
      text: [
        'WWW.LOCAMBIO.COM es una red social web que conecta a las personas de manera efectiva para el intercambio de bienes muebles e inmuebles, servicios y habilidades. A través de la plataforma se facilitará una mensajería instantánea entre los posibles permutantes, y en ella se puede interactuar y se podrá compartir la ubicación del producto para facilitar el posible intercambio.',
        'WWW.LOCAMBIO.COM ha tomado todas las previsiones para asegurar que los datos personales del “Usuario” permanezcan confidenciales.',
        'WWW.LOCAMBIO.COM no se hace responsable por cualquier violación, usurpación, hurto, robo o sustracción de la data, que pueda sufrir el sistema de seguridad de la plataforma, sea que se haya ocasionado por terceras personas, fallas en el sistema, problemas con el servidor, entre otras razones. De igual manera, WWW.LOCAMBIO.COM no se hace responsable por la obtención de información personal por medios ilegales o violaciones del sistema de información que se encuentra alojada en nuestro servidor, cuyos actos y hechos ilícitos se encuentran tipificados y sancionados de conformidad con la Ley Especial Contra los Delitos Informáticos, incluyendo el uso que se le otorgue a la información maliciosamente obtenida.',
        'WWW.LOCAMBIO.COM intentará garantizar el uso ininterrumpido y continuado del servicio; sin embargo, las consecuencias que se generen con ocasión de fallas en el sistema o dificultades técnicas que puedan surgir durante la prestación del servicio u otra razón ajena a la voluntad de WWW.LOCAMBIO.COM no serán imputables a ella.',
        'Los “Usuarios” de WWW.LOCAMBIO.COM convienen y se comprometen a mantener una conducta diligente y responsable como buen pater famile.',
        'WWW.LOCAMBIO.COM no se hará responsable del contenido que los “Usuarios” anuncien o sean transmitidos a través de nuestros servicios; y que al utilizar nuestra plataforma usted puede estar expuesto a contenido ofensivo, indecente, impreciso o engañoso. Bajo ninguna circunstancia WWW.LOCAMBIO.COM será responsable por el contenido o bien las pérdidas o los daños y perjuicios que el “Usuario” pueda sufrir como resultado de un contenido publicado.Los “Usuarios” serán los únicos responsables por todo el contenido publicado, y WWW.LOCAMBIO.COM conservará el derecho, mas no la obligación, de eliminar aquel contenido que sea contrario a la ley y a las buenas costumbres.',
        'WWW.LOCAMBIO.COM se reserva el derecho de modificar el Precio locambio.com de los productos ofrecidos de conformidad a lo establecido por nuestros expertos.',
        'WWW.LOCAMBIO.COM no ofrece sus servicios a menores de edad. Los menores de 18 años sólo podrán utilizar los servicios de WWW.LOCAMBIO.COM bajo la supervisión de padres, representantes o un tutor previa y debidamente autorizados.',
      ],
    },
    {
      title: 'Condiciones de la Permuta',
      text: [
        'Para que el “Usuario” pueda realizar y confirmar un intercambio mediante un contrato de permuta en WWW.LOCAMBIO.COM es necesario e indispensable que acepte los “Términos y Condiciones” establecidos en este texto. En este sentido, para todos los efectos la permuta debe entenderse como un contrato por el cual cada una de las partes se obliga a dar una cosa para obtener otra a cambio. El contrato queda configurado desde el momento que los usuarios expresan su consentimiento y compromiso de transferirse, recíprocamente, la propiedad de dos o más cosas a través de la plataforma de WWW.LOCAMBIO.COM',
        'WWW.LOCAMBIO.COM tendrá como finalidad: facilitar el proceso de intercambio de bienes permitido, de conformidad con el marco legal vigente al momento en que se exprese el consentimiento entre los usuarios.',
        'WWW.LOCAMBIO.COM no garantiza ni se hace responsable por la titularidad de la cosa permutada. De igual manera, no se hace responsable por las condiciones del bien permutado, ni por las publicidades engañosas y los riesgos de confusión que pueden derivarse de ellas.',
        'WWW.LOCAMBIO.COM se exime de toda responsabilidad en los supuestos en que uno de los permutantes padezca evicción de la cosa que recibió, o en caso de que haya recibido la cosa que se le dio en permuta y demuestre que el otro contratante no era el dueño de ella.',
        'El “Usuario” que sufra algún daño imputable a otro “Usuario”, debe denunciar dicho hecho de manera inmediata ante las autoridades pertinentes. Así mismo podrá notificar a través de los canales de soporte al usuario a WWW.LOCAMBIO.COM, quien tomará las medidas necesarias dentro de su plataforma para evitar posibles fraudes, y se reserva el derecho de suspender o inhabilitar la(s) cuenta(s) que ocasionaron el daño, de manera temporal o indefinida.',
        'Asimismo, WWW.LOCAMBIO.COM se exime de toda responsabilidad, por las consecuencias que pueden derivarse, frente al “Usuario” afectado por los vicios ocultos de un bien permutado; sin embargo, el “Usuario” tiene derecho a la garantía de conformidad prevista en la legislación vigente en Venezuela.',
        'Todas las condiciones requeridas para el efectivo intercambio de los bienes serán definidas entre los ¨Usuarios¨ de manera consensual. En ese sentido, WWW.LOCAMBIO.COM se exime de toda responsabilidad que pueda derivarse como consecuencia de dicha interacción realizada entre los ¨Usuarios¨, incluyendo cualquier pérdida que fuera atribuible al incumplimiento de alguna de las partes.',
      ],
    },
    {
      title: 'Artículos Prohibidos de ser Sujetos a Permuta',
      text: [
        'El “Usuario" es responsable de la legalidad y legitimidad de los bienes y servicios que ofrece para ser objeto de permuta a través de la plataforma WWW.LOCAMBIO.COM, en consecuencia, WWW.LOCAMBIO.COM se exime de responsabilidad alguna por la existencia en el sitio de bienes y servicios que infrinjan las políticas o cualquier ley o resolución judicial vigente. En este sentido, el “Usuario" acepta no anunciar, ni poner a disposición contenido que sea: dañino; abusivo; o difamatorio; que intimide o sea irrespetuoso a individuos o a grupos; que sea falso o engañoso. Asimismo el “Usuario” se compromete a no publicar mediante la plataforma de WWW.LOCAMBIO.COM ningún supuesto que sea contrario a la ley y a las buenas costumbres.',
        'Entre los artículos prohibidos se encuentran: (i) Armas, municiones y material explosivo, (ii) Estupefacientes y sustancias prohibidas, (iii) Propiedad robada y hurtada, (iv) Huesos, órganos y residuos humanos, (v) Bienes que atenten contra la propiedad intelectual, (vi) Flora y fauna en peligro de extinción, (vii) Vehículos sin documentación y productos para vehículos que infrinjan leyes vigentes, (viii) Base de datos, (ix) Documentos legales, (x) Patrimonio histórico; cultural; arqueológico y paleontológico, (xi) Bienes promocionados por el Estado, (xii) Productos de regulación especial, (xii) Loterías y rifas, (xiv) Programas para hackear, (xv) Máquinas fiscales, (xvi) Controles universales que abran todo tipo de vehículo, (xvii) Se prohíbe la oferta de divisas y monedas o billetes vigentes y/o en circulación o de curso legal o extranjeras.',
        'El “Usuario” titular de derecho o cualquier “Usuario” de WWW.LOCAMBIO.COM tendrá la obligación de identificar, informar y solicitar la remoción de aquellos artículos que a su criterio infrinjan o violen lo establecido en los “Términos y Condiciones” Generales.',
      ],
    },
    {
      title: 'Protección de los Derechos de Propiedad Intelectual',
      text: [
	      'Cualquier persona natural o jurídica que sea titular de un Derecho de Propiedad Intelectual podrá presentar el reclamo cuando considere se le ha vulnerado algún derecho, por el intercambio de un bien ofrecido a través de la plataforma o de la aplicación móvil de WWW.LOCAMBIO.COM.',
        'En el caso de personas jurídicas, éste reclamo deberá ser presentado por su representante legal o quien esté autorizado debidamente mediante documento escrito.',
        'Para poder realizar el reclamo, se deberá acreditar ante WWW.LOCAMBIO.COM la titularidad de los Derechos de Propiedad Intelectual que se presumen vulnerados. En caso que se tratase de marcas de productos, deberá acompañarse copia del registro marcario. En el caso de marcas notorias, deberá acompañarse tanto material probatorio necesario para demostrar el conocimiento generalizado del consumidor. No se tendrán en cuenta constancias de solicitudes que aún se encuentren en trámite.',
        'Una vez recibida la reclamación en WWW.LOCAMBIO.COM, se tramitará la investigación correspondiente, en la cual se revisará la documentación aportada y de ser necesario se solicitará información a quien intercambia los productos objeto de la presunta infracción, de ese modo el departamento encargado designado por WWW.LOCAMBIO.COM, analizará las pruebas aportadas para verificar que no se están violando los Derechos de Propiedad Intelectual de terceros.',
      ],
    },
  ];

  const allSections = sections.map((section, index) => {
    const content = (
      <div className="terms-and-conditions__container">
        <div className="terms-and-conditions__container--box">
          <h2 className="title">{section.title}</h2>
          {section.text.map((block, index) => {
            const paragraph = <p>{block}</p>;
            return paragraph;
          })}
        </div>
      </div>
    );
    return content;
  });

  return (
    <>
              <ScrollToElement
          scroll={true}
          options={{ behavior: 'auto', block: 'center' }}
          highlight={false}
        >
        </ScrollToElement>
      <div className="terms-and-conditions">
        <div className="section">{allSections}</div>
        <div className="terms-and-conditions__links">
          <LinkInfoPages active={'Términos y Condiciones'} />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default TermsAndConditions;
