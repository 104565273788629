import { useDispatch } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import { Collapse, Icon, Text } from '@blueprintjs/core';
import PanelButtons from '../panelButtons';
import AvatarUser from '../../../../components/AvatarUser';
import { DRAWERS } from '../../../../utility/drawers';
import { updateDrawer } from '../../../../store/actions';
import ChatInterestedPublicationsModal from '../../../changeOperation/components/chatInterestedPublicationsModal';
import { checkWithCurrentUser } from '../../../../utilityFunctions';
import { useGenericModal } from '../../../../hooks/useGenericModal/useGenericModal';
import BlurImage from '../../../../components/blurImage/BlurImage';
import './PanelHeader.scss';
import Card from '../../../../components/card/Card';
import history from '../../../../utility/history';
import UserName from '../../../../components/userName/UserName';

const PanelHeader = ({ chat, loading, publicationInteresteds }) => {
  const dispatch = useDispatch();
  const { buildModal } = useGenericModal();
  const [showContent, setShowContent] = useState(true);
  const [isEnableLeft, setIsEnableLeft] = useState(true);
  const [isEnableRight, setIsEnableRight] = useState(true);
  const [interested, setInterested] = useState({});
  const publication = chat.transaction.publication;
  const isOwner = checkWithCurrentUser(chat.owner.id);
  const user = isOwner ? chat.interested : chat.owner;
  const fullName = `${user?.name} ${user?.last_name}`;
  const handleToggle = () => {
    dispatch(updateDrawer({ [DRAWERS.chats]: true }));
  };

  useEffect(() => {
    if (publication && publicationInteresteds) {
      setIsEnableLeft(true);
      setIsEnableRight(true);
      const s = isOwner ? '' : 's';
      const object = { id: '', title: 'Propuesta\nescrita', name: `Ofrece${s}`, image: '' };
      if (publicationInteresteds?.length > 0) {
        object.id = publicationInteresteds?.[0].id
        object.title = publicationInteresteds?.[0].title
        object.name = `Ofrece${s} (${publicationInteresteds?.length})`
        object.image = publicationInteresteds?.[0].picture
        object.publication_status = publicationInteresteds?.[0].publication_status
      }
      if (isOwner) {
        if (object.id === '') setIsEnableRight(false);
        setInterested({
          left: {
            id: publication.id,
            title: publication.title,
            name: 'Quiere',
            image: publication.picture,
            publication_status: publication.publication_status
          },
          right: object
        })
      } else {
        if (object.id === '') setIsEnableLeft(false);
        setInterested({
          left: object,
          right: {
            id: publication.id,
            title: publication.title,
            name: 'Quieres',
            image: publication.picture,
            publication_status: publication.publication_status
          }
        })
      }
    }
  }, [publication, publicationInteresteds])

  const pluralizePublicationsQuantity = () => {
    return `Publica${publicationInteresteds?.length > 1 ? 'ciones' : 'ción'}`;
  };

  const handleLeftCard = () => {
    if (isOwner) {
      if (isEnableLeft) canLinkToPublication(interested, "left");
    } else {
      if (publicationInteresteds?.length > 1) {
        buildModal(interestedPublicationsModal);
      } else {
        if (isEnableLeft) canLinkToPublication(interested, "left");
      }
    };
  };

  const handleRightCard = () => {
    if (isOwner) {
      if (publicationInteresteds?.length > 1) {
        buildModal(interestedPublicationsModal);
      } else {
        canLinkToPublication(interested, "right");
      }
    } else {
      canLinkToPublication(interested, "right");
    };
  };

  const canLinkToPublication = (interested, position) => {
    if (interested.right.publication_status === "Activo") {
      history.push(`/${interested[position].title}/${interested[position].id}`)
    }
  };

  const interestedPublicationsModal = {
    content: (
      <ChatInterestedPublicationsModal
        isOwner={isOwner}
        user={user}
        publications={publicationInteresteds}
        pluralizePublicationsQuantity={pluralizePublicationsQuantity}
      />
    ),
    isOpen: true,
    className: 'publications-card',
  };

  return (
    <header className="panel-header">
      <section className="panel-header__navigation">
        <Icon
          className="chat__icon panel-header__icon"
          icon="arrow-left"
          iconSize={15}
          onClick={handleToggle}
        />
        <div onClick={handleToggle}>
          <AvatarUser user={user} background onClick={handleToggle} />
        </div>
        <Text ellipsize tagName="h4" title={fullName}>
          <UserName user={user} htmlTag='p' className={'verified'}/>
        </Text>
        <Icon
          className={`chat__icon panel-header__icon ${showContent ? '' : 'panel-header__icon--down'}`}
          icon="chevron-up"
          iconSize={20}
          onClick={() => setShowContent(!showContent)}
        />
      </section>
      <Collapse isOpen={showContent} keepChildrenMounted>
        <section className="panel-header__content">
          <Card
            onClick={() => handleLeftCard()}
            className={`panel-header__content--interests ${isEnableLeft ? 'show-pointer' : 'hide-card'} panel-header__left`}
          >
            <div className="panel-header__image-wrapper__left">
              { isEnableLeft ?
                <BlurImage image={interested.left?.image} title={interested.left?.title} className={'small'} /> :
                <Icon icon="manually-entered-data" iconSize={48} />
              }
            </div>
            <div>
              <Text
                className="panel-header__title panel-header__title--primary"
              >
                {interested.left?.name}
                <Icon className="panel-header__title--arrow" icon="arrow-right" iconSize={14} />
              </Text>
              <div className="panel-header__title--sub-title">
                {interested.left?.title}
              </div>
            </div>
          </Card>
          <Card
            onClick={() => handleRightCard()}
            className={`panel-header__content--interests ${isEnableRight ? 'show-pointer' : 'hide-card'} panel-header__right`}
          >
            <Text
              title={interested.right?.title}
              className="panel-header__title panel-header__title--accent"
            >
              <Icon className="panel-header__title--arrow" icon="arrow-left" iconSize={14} />
              {interested.right?.name}
              <div className="panel-header__title--sub-title">
                {` ${interested.right?.title}`}
              </div>
            </Text>
            <div className="panel-header__image-wrapper__right">
              { isEnableRight ?
                <BlurImage image={interested.right?.image} title={interested.right?.title} className={'small'} /> :
                <Icon icon="manually-entered-data" iconSize={48} />
              }
            </div>
          </Card>
        </section>
        <section className="panel-header__center-buttons">
          <PanelButtons
            status={chat.transaction.status}
            isOwner={isOwner}
            transactionId={chat.transaction.id}
            loading={loading}
            linkToEdit={`/publication/${publication.id}/proposal/manager`}
          />
        </section>
      </Collapse>
    </header>
  );
};

const areEqual = ({ chat: prevChat, loading: prevLoading, publicationInteresteds: prevPublicationInteresteds }, { chat: nextChat, loading: nextLoading, publicationInteresteds: nextPublicationInteresteds }) => {
  return (
    prevLoading === nextLoading &&
    prevChat.id === nextChat.id &&
    prevChat.transaction.status === nextChat.transaction.status &&
    JSON.stringify(prevPublicationInteresteds) === JSON.stringify(nextPublicationInteresteds)
  );
};

export default memo(PanelHeader, areEqual);
