import React, { useState, useEffect, useRef } from 'react';
import {
  InputGroup,
  FormGroup,
  Button,
} from '@blueprintjs/core';
import { getHeader } from '../../../../system/Sesion';
import Geocode from 'react-geocode';
import PopUp from '../../../../components/popUp'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { config } from '../../../../system/Config';
import WrappedMap from '../map/Map';
import './Step4.scss';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';

export function Step4({
  setValue,
  control,
  setCanContinue,
  canContinue,
  loadingButton,
  watch,
  publicationType,
}) {
  const address_2 = watch('reference') || ''
  const address_1 = watch('address_1')
  const [selectedPlace, setSelectedPlace] = useState('');
  const [center, setCenter] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState(window.location.pathname);
  const [editing, setEditing] = useState(false);
  const refMap = useRef(null);
  const [userCountry, setUserCountry] = useState(null);
  const [publicationLocation, setPublicationLocation] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({
    lat: Number(localStorage.latitude),
    lng: Number(localStorage.longitude),
  });
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });
  const referencePointMaxLength = 70;
  const [isHandleChange, setIsHandleChange] = useState(false);

  useEffect(() => {
    Geocode.setApiKey(config.KEY_GEOCODE);
  }, []);

  useEffect(() => {
    setValue('changePlace', '4');
    setSelectedPlace('');
  }, []);

  useEffect(() => {
    fetchGetCountryStates();
  }, []);

  useEffect(() => {
    if (address_1 && !isHandleChange) {
      setCanContinue(true);
    } else {
      setCanContinue(false)
    }
  }, [selectedPlace]);

  useEffect(() => {
    setValue('coordinates', center);
    setValue('location', publicationLocation)
  }, [center, publicationLocation])

  useEffect(() => {
    if (location.includes("edit")) {
      setEditing(true);
    }
  }, []
  )

  useEffect(() => {
    getInputAddress();
  }, [])

  const getInputAddress = async () => {
    if (address_1) {
      handleSelect(address_1)
    } else {
      const address = await getAddress(currentPosition.lat, currentPosition.lng);
      setValue('address_1', address);
      setSelectedPlace(address);
    }
  }

  useEffect(() => {
    if (center) { getAddress(center.lat, center.lng) };
  }, [center])

  async function fetchGetCountryStates() {
    await (axios.get(`${config.URL_API}/v1/states`, {
      headers: getHeader(),
    })
      .then(response => setUserCountry(response.data)))
  }

  const getReferencePointFieldLimit = (event) => {
    setValue("reference", event.target.value.slice(0, referencePointMaxLength));
  };

  const handleBoundsChanged = () => {
    const mapCenter = refMap?.current?.getCenter()?.toJSON();
    if (mapCenter) { setCenter(mapCenter) };
  };

  const handleChange = (address) => {
    setSelectedPlace(address);
    setValue('address_1', address);
    setIsHandleChange(true);
  };

  function handleShowModal() {
    setShowModal(!showModal);
  }

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setSelectedPlace(address);
        setCurrentPosition(latLng);
        setValue('address_1', address);
      })
      .catch((error) => console.error('Error', error));
    setIsHandleChange(false);
  };

  function getPublicationCountryState(results) {
    if (userCountry) {
      let location = { country: null, state: null }
      results.forEach((result) => {
        result.address_components.forEach((address) => {
          if (userCountry.states.includes(address.long_name)) { location.state = address.long_name }
          if (userCountry.name.includes(address.long_name)) { location.country = address.long_name }
        })
      })
      if (location.country) return location.state
    }
  }

  function setMostCompleteAddress(addresses) {
    const arr = addresses.map((address) => { return address.formatted_address });
    return arr.sort(function (a, b) { return b.length - a.length })[0];
  }

  const getAddress = async (lat, lng) => {
    if (center) {
      const response = await Geocode.fromLatLng(lat || center.lat, lng || center.lng);
      const CountryState = getPublicationCountryState(response.results)
      setPublicationLocation(CountryState);
      if (!CountryState && userCountry) {
        setPublicationLocation('outOfLimit');
        setSelectedPlace('');
      } else {
        setIsHandleChange(false);
        const completeAddres = await setMostCompleteAddress(response.results);
        setValue('address_1', completeAddres);
        setSelectedPlace(completeAddres);
        return completeAddres;
      }
    }
  };

  const autocomplete = (
    <PlacesAutocomplete
      value={selectedPlace}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: { country: 've' },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="inputLabel">
          <FormGroup
            label="Busca un lugar en específico"
            labelInfo="*"
            labelFor="address_1"
            className="autocompleteForm"
          >
            <div className="full-width">
              <InputGroup
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
                placeholder="Escribe la ubicación aqui"
                id="address_1"

              />
            </div>
          </FormGroup>
          <div className="autocomplete-dropdown-container">
            {loading && (
              <div className={'flex items-stretch'}>
                <div className={'self-center'}>
                  <h3 className={'loading'}>Cargando...</h3>
                </div>
              </div>
            )}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              const style = suggestion.active
                ? {
                  backgroundColor: '#fafafa',
                  cursor: 'pointer',
                }
                : {
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
          {publicationLocation === 'outOfLimit' ? (
            <p className="errorMessage">Ubicación no disponible. Seleccione una distinta desplazando el mapa</p>
          ) : <div></div>}
        </div>
      )}
    </PlacesAutocomplete>
  );

  return (
    <div className="step4">
      <FormGroup>
        <Controller
          name="coordinates"
          control={control}
          rules={{ required: true }}
          defaultValue={currentPosition}
          render={({ field }) => (
            <InputGroup
              {...field}
              id="coordinates"
              type="hidden"
            />
          )}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          name="location"
          control={control}
          rules={{ required: true }}
          defaultValue={' '}
          render={({ field }) => (
            <InputGroup
              {...field}
              id="location"
              type="hidden"
            />
          )}
        />
      </FormGroup>
      <PopUp
        displayModal={showModal}
        hideModal={handleShowModal}
      >
        <img src={isLargerThanMobile ? "https://storage.googleapis.com/locambio-admin/popups/locambioseguro/popup0-600x400.jpg" : "https://storage.googleapis.com/locambio-admin/popups/locambioseguro/popup0-250x300.jpg"} />
      </PopUp>
      <div className="card">
        <div className="text_fields">
          {publicationType === 'product' ?
            (
              <>
                <h2>Lugar para hacer el cambio</h2>
              </>
            ) : (
              <h2>Lugar donde prestas tus servicios</h2>
            )
          }
          <div className=''>
            {autocomplete}
          </div>

            <FormGroup
              label="Punto de referencia (opcional)"
              labelFor="reference"
              className="inputLabel"
            >
              <Controller
                name="reference"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="full-width">
                    <InputGroup
                      {...field}
                      id="reference"
                      placeholder="Escribe los puntos de referencia aquí"
                      onChange={(e) => {
                        getReferencePointFieldLimit(e)
                      }}
                    />
                    <div className="word-counter">
                      <p className={`${address_2.length === referencePointMaxLength ? 'red-text' : ''} `}>
                        {`${address_2.length}`}/{`${referencePointMaxLength}`}
                      </p>
                    </div>
                  </div>

                )}
              />
            </FormGroup>
        </div>

        <div
          className='map map-wrapper map-wrapper'
        >
          <div style={{ position: 'relative', width: '100%', height: '25rem' }}>
            <WrappedMap
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.KEY_GOOGLE_MAPS}`}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div id="map" style={{ height: '100%' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              currentPosition={currentPosition}
              handleBoundsChanged={handleBoundsChanged}
              refMap={refMap}
              center={center}
            />
          </div>
        </div>
      </div>
      <Button
        className={`formButton ${!canContinue ? 'disabled' : ''} children-without-transition`}
        type="submit"
        disabled={!canContinue}
        loading={loadingButton}
      >
        {editing ? "Actualizar" : "Publicar"}
      </Button>
    </div>
  );
}

export default Step4;
