import { updateObject } from 'utility';
import { CHAT_KINDS } from '../../utility/chat';
import { ADD_CHAT, ADD_MESSAGE, REMOVE_CHAT, SET_CHATS, UPDATE_CHAT } from '../actions/actionTypes';

const initialState = {
  [CHAT_KINDS.inProgress]: [],
  [CHAT_KINDS.proposal]: [],
  unreadChats: {
    [CHAT_KINDS.inProgress]: 0,
    [CHAT_KINDS.proposal]: 0,
  },
  loading: true,
};

const countChats = (chats) => chats.reduce((prev, chat) => chat.unread_messages ? prev + 1 : prev, 0);

const addCountChats = (stateCopy) => {
  stateCopy.unreadChats = {
    [CHAT_KINDS.inProgress]: countChats(stateCopy[CHAT_KINDS.inProgress]),
    [CHAT_KINDS.proposal]: countChats(stateCopy[CHAT_KINDS.proposal]),
  };
  return stateCopy;
};

/*
 * Set the chats in the state.
 * @param {Object} state 
 * @param {Object} action 
 */

const setChats = (state, action) => {
  const { data } = action;
  return updateObject(state, {
    ...data,
    loading: false,
  });
};

/*
 * Add or update a chat.
 * @param {Object} state 
 * @param {Object} action 
 */

const removeChat = (state, action) => {
  const { data } = action;
  const indexProposalChat = state[CHAT_KINDS.proposal].findIndex(chat => chat.id === data.id);
  const indexInProgressChat = state[CHAT_KINDS.inProgress].findIndex(chat => chat.id === data.id);
  const chats = {
    [CHAT_KINDS.proposal]: [...state[CHAT_KINDS.proposal]],
    [CHAT_KINDS.inProgress]: [...state[CHAT_KINDS.inProgress]],
  };

  if (indexProposalChat >= 0) {
    chats[CHAT_KINDS.proposal].splice(indexProposalChat, 1);
  } else if (indexInProgressChat >= 0) {
    chats[CHAT_KINDS.inProgress].splice(indexInProgressChat, 1);
  }

  return updateObject(state, chats);
};

const addChat = (state, action) => {
  const { data } = action;
  const chats = {
    [CHAT_KINDS.proposal]: [data, ...state[CHAT_KINDS.proposal]],
    [CHAT_KINDS.inProgress]: [...state[CHAT_KINDS.inProgress]],
  };

  return updateObject(state, chats);
};

const updateChat = (state, action) => {
  const { data } = action;
  const indexProposalChat = state[CHAT_KINDS.proposal].findIndex(chat => chat.id === data.id);
  const indexInProgressChat = state[CHAT_KINDS.inProgress].findIndex(chat => chat.id === data.id);
  const chats = {};

  // TODO: Improve these conditionals
  if (data.transaction?.status !== 'active') {
    if (indexProposalChat < 0) {
      if (data.order) {
        chats[CHAT_KINDS.inProgress] = [data, ...state[CHAT_KINDS.inProgress]];
        chats[CHAT_KINDS.inProgress].splice(indexInProgressChat + 1, 1);
      } else {
        chats[CHAT_KINDS.inProgress] = [...state[CHAT_KINDS.inProgress]];
        chats[CHAT_KINDS.inProgress][indexInProgressChat] = data;
      }
    } else {
      chats[CHAT_KINDS.proposal] = [...state[CHAT_KINDS.proposal]];
      chats[CHAT_KINDS.proposal].splice(indexProposalChat, 1);
      chats[CHAT_KINDS.inProgress] = [data, ...state[CHAT_KINDS.inProgress]];
    }
  } else {
    if (indexProposalChat < 0) {
      chats[CHAT_KINDS.proposal] = [data, ...state[CHAT_KINDS.proposal]];
    } else {
      if (data.order) {
        chats[CHAT_KINDS.proposal] = [data, ...state[CHAT_KINDS.proposal]];
        chats[CHAT_KINDS.proposal].splice(indexProposalChat + 1, 1);
      } else {
        chats[CHAT_KINDS.proposal] = [...state[CHAT_KINDS.proposal]];
        chats[CHAT_KINDS.proposal][indexProposalChat] = data;
      }
    }
  }
  return updateObject(state, chats);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS:
      return addCountChats(setChats(state, action));
    case ADD_CHAT:
      return addCountChats(addChat(state, action));
    case UPDATE_CHAT:
      return addCountChats(updateChat(state, action));
    case REMOVE_CHAT:
      return addCountChats(removeChat(state, action));
    default:
      return state;
  }
};

export default reducer;
