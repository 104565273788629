import React, { useEffect, useRef, useState } from "react";
import "./HorizontalScroll.scss";

const HorizontalScroll = ({ children }) => {
  const horizontalRef = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const DRAG_MULTIPLIER = 1;

  useEffect(() => {
    const el = horizontalRef.current;

    const onMouseEnter = () => {
      setIsHovered(true);
    };

    const onMouseLeave = () => {
      setIsHovered(false);
      setIsDragging(false);
    };

    const onMouseDown = (e) => {
      if (!isHovered) return;
      setIsDragging(true);
      setStartX(e.pageX - el.offsetLeft);
      setScrollLeft(el.scrollLeft);
    };

    const onMouseUp = () => {
      setIsDragging(false);
    };

    const onMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - el.offsetLeft;
      const walk = (x - startX) * DRAG_MULTIPLIER;
      el.scrollLeft = scrollLeft - walk;
    };

    const preventScroll = (e) => {
      if (!isHovered) return;
      if (e.deltaY !== undefined && e.deltaY !== -0) {
        e.preventDefault();
        const delta = e.deltaY || e.originalEvent.deltaY || e.originalEvent.wheelDelta;
        el.scrollLeft += delta;
      }
    };

    if (el) {
      el.addEventListener("mouseenter", onMouseEnter);
      el.addEventListener("mouseleave", onMouseLeave);
      el.addEventListener("mousedown", onMouseDown);
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("mousemove", onMouseMove);
      el.addEventListener("wheel", preventScroll, { passive: false });
      return () => {
        el.removeEventListener("mouseenter", onMouseEnter);
        el.removeEventListener("mouseleave", onMouseLeave);
        el.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("mousemove", onMouseMove);
        el.removeEventListener("wheel", preventScroll);
      };
    }
  }, [isDragging, isHovered, scrollLeft, startX]);

  return (
    <section
      ref={horizontalRef}
      className={`horizontal-scroll hide-scroll ${isHovered ? "hovered" : ""}`}
      style={{ overflowX: "scroll" }}
    >
      {children}
    </section>
  );
};

export default HorizontalScroll;