import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Search = (props) => {
  const [activeTab, setActiveTab] = useState('name');
  const [allowWriting, setAllowWriting] = useState(false);
  const [searchingValue, setSearchingValue] = useState('');
  const [searchingValueLeft, setSearchingValueLeft] = useState('');
  const [searchingValueRight, setSearchingValueRight] = useState('');
  const { latitude, longitude } = useSelector(({ geolocation }) => geolocation);
  const { typeSearch } = useSelector(({ searchbar }) => searchbar);
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });
  const formatView = isLargerThanMobile ? "search" : "mobile"

  const searchTabs = (
    <section className={`${formatView}__tabs`}>

      <div
        className={
          activeTab === 'name'
            ? `${formatView}__tabs-purple`
            : `${formatView}__tabs-disable--purple`
        }
        onClick={() => {
          setActiveTab('name');
        }}
      >
        <p>Lo quiero</p>
      </div>

      <div
        className={
          activeTab === 'trade'
            ? `${formatView}__tabs-orange`
            : `${formatView}__tabs-disable--orange`
        }
        onClick={() => {
          setActiveTab('trade');
        }}
      >
        <p>Lo tengo</p>
      </div>

      <div
        className={
          activeTab === 'values'
            ? `${formatView}__tabs-values`
            : `${formatView}__tabs-disable--values`
        }
        onClick={() => {
          setActiveTab('values');
        }}
      >
        <p>Lo vale</p>
      </div>

    </section>
  );

  const handleChange = (event) => {
    setSearchingValue(event.target.value);
  };

  const handleChangeLeft = (event) => {
    setSearchingValueLeft(event.target.value);
  };

  const handleChangeRight = (event) => {
    setSearchingValueRight(event.target.value);
  };

  const handleClick = () => {
    setAllowWriting(true);
  };

  const handleBlur = () => {
    if (searchingValue.length === 0) setAllowWriting(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 'values') {
      if (searchingValueLeft === '' && searchingValueRight === '') return;
      const left = searchingValueLeft === '' ? searchingValueRight : searchingValueLeft;
      const right = searchingValueRight === '' ? searchingValueLeft : searchingValueRight;
      setSearchingValueLeft(left);
      setSearchingValueRight(right);
      props.history.push(
        `/search_list/[${left}...${right}]/all/0/10/${latitude}/${longitude}/false/${activeTab}`
      );
    } else {
      props.history.push(
        `/search_list/${encodeURIComponent(searchingValue).replaceAll(
          '%20',
          ','
        )}/all/0/10/${latitude}/${longitude}/false/${typeSearch}/${activeTab}`
      );
    }

  };

  const searchValues = (
    <section className={`${formatView}__bars`}>
      <form className={`${formatView}__bar ${formatView}__bar-values ${formatView}__bar-values--left`} onSubmit={handleSubmit}>
        <input
          value={searchingValueLeft}
          onChange={handleChangeLeft}
          type="search"
          placeholder="Mínimo"
        />
        <Icon
          icon="dollar"
          className={`${formatView}__bar-icon`}
          iconSize={16}
          onClick={handleSubmit}
        />
      </form>
      <form className={`${formatView}__bar ${formatView}__bar-values ${formatView}__bar-values--right`} onSubmit={handleSubmit}>
        <input
          value={searchingValueRight}
          onChange={handleChangeRight}
          type="search"
          placeholder="Máximo"
        />
        <Icon
          icon="dollar"
          className={`${formatView}__bar-icon`}
          iconSize={16}
          onClick={handleSubmit}
        />
      </form>
    </section>
  )

  const searchBar =
    activeTab === 'name' ? (
      allowWriting ? (
        <form className={`${formatView}__bar-purple`} onSubmit={handleSubmit}>
          <input
            value={searchingValue}
            id="searchInput"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            type="search"
          />
          <Icon
            icon="search"
            className={`${formatView}__bar-icon`}
            iconSize={22}
            onClick={handleSubmit}
          />
        </form>
      ) : (
        <section className={`${formatView}__bar-purple`} onClick={handleClick}>
          <p className="search__bar__text">
            Busca lo que <span>quieres</span> a cambio
          </p>
        </section>
      )
    ) : allowWriting ? (
      <form className={`${formatView}__bar-orange`} onSubmit={handleSubmit}>
        <input
          value={searchingValue}
          id="searchInput"
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          type="search"
        />
        <Icon
          icon="search"
          className={`${formatView}__bar-icon`}
          iconSize={22}
          onClick={handleSubmit}
        />
      </form>
    ) : (
      <section className={`${formatView}__bar-orange`} onClick={handleClick}>
        <p>
          Busca <span>quién quiere</span> lo que ofreces
        </p>
      </section>
    );

  return (
    <section className={formatView}>
      {searchTabs}
      {activeTab === 'values' ? searchValues : searchBar}
    </section>
  );
};

export default withRouter(Search);
