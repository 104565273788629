import { checkWithCurrentUser, datelocambio } from '../utilityFunctions';

const textChats = (chat) => {

  const message = (message) => {
    if (message?.latitude && message?.longitude) return '[Dirección]';
    if (message?.picture_url) return '[Image]';
    return message?.message;
  };

  const isOwner = checkWithCurrentUser(chat.owner.id);
  const user = isOwner ? chat.interested : chat.owner;

  const publicationTitle = () => chat.transaction.publication.title
  const url = `/chats/${chat.id}`

  return {
    read: !chat.unread_messages,
    userName: `${user.name} ${user.last_name}`,
    user: user,
    title: message(chat.last_message),
    footer: publicationTitle(),
    date: datelocambio(chat.updated_at, 'D MMM YYYY'),
    url: url,
  }
}

export default textChats;
