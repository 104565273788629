import React from 'react';
import './ChangeProposalPublicationInfo.scss';
import Card from '../../../../components/card/Card';
import BlurImage from '../../../../components/blurImage/BlurImage';
import PriceTag from '../../../../components/priceTag';
import ChangeProposalMap from '../changeProposalMap';
import { useGenericModal } from '../../../../hooks/useGenericModal/useGenericModal';

const ChangeProposalPublicationInfo = ({ title, image, price, address_1, address_2, coordinate }) => {
  
  const { buildModal } = useGenericModal();

  const mapModal = {
    content: <ChangeProposalMap {...coordinate}/>,
    isOpen: true,
    className: "map_modal",
  };

  return (
    <section className="change-proposal-publication">
      <Card className="change-proposal-publication__info">
        <section className="change-proposal-publication__info--main-img">
          <div className="change-proposal-publication__info--main-img-image-container">
            <div>
              {image && <BlurImage image={image} title={title} />}
            </div>
          </div>
        </section>
        <section className="change-proposal-publication__info--main-info">
          <div className="change-proposal-publication__info--main-info-title">{title}</div>
          <PriceTag price={price} className="change-proposal-publication__info--main-info-value" />
          <div className="change-proposal-publication__info--main-info-location-info">
              <p className="change_title">Lugar de intercambio</p>
          </div>
          <div className="change-proposal-publication__info--main-info-location">
            <div className="change-proposal-publication__info--main-info-location-address">
              {address_1} {address_2 && `, ${address_2}`}
              <span className="change-proposal-publication__info--main-info-location__link-to-map" onClick={() => buildModal(mapModal)}>Ver maps</span>
            </div>
          </div>
        </section>
      </Card>
    </section>
  );
};

export default ChangeProposalPublicationInfo;