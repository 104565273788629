import React from 'react';
import { useMediaQuery } from 'react-responsive';
import LinkInfoPages from '../../components/linkInfoPages';
import Footer from '../home/components/footer/Footer';
import ContactForm from './components/contactForm';
import './Contacts.scss';
import ScrollToElement from '../../components/scrollToElement/ScrollToElement';
import { config } from '../../system/Config';
import { phoneFormatter } from '../../utilityFunctions';

export default () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 769px)' });

  return (
    <div className="contacts">
              <ScrollToElement
          scroll={true}
          options={{ behavior: 'auto', block: 'center' }}
          highlight={false}
        >
        </ScrollToElement>
      <div className="row">
        <div className="section">
          <div className="column">
            {isTabletOrMobile && (
              <img
                src="https://storage.googleapis.com/locambio-admin/pages/contact/contact.png"
                className="contact-image"
              />
            )}
            <div className="row wrap-reverse mb-2 justify-between">
              <div>
                {!isTabletOrMobile && (
                  <img
                    src="https://storage.googleapis.com/locambio-admin/pages/contact/contact.png"
                    className="contact-image"
                  />
                )}
                <div className="card">
                  <div className="card-section">
                    <h4>Ubícanos fácilmente</h4>
                    <p>
                      Plaza Venezuela, Torre Polar, Caracas 1052, Distrito
                      Capital, Venezuela
                    </p>
                  </div>
                  <div className="card-section">
                    <h4>Envíanos un email</h4>
                    <p>contacto@locambio.com</p>
                  </div>
                </div>
              </div>
              <div>
                <ContactForm />
              </div>
            </div>
            <div className="social-media-row">
              <div className="card-section">
                <h4>Escríbenos un mensaje</h4>
                <p>{phoneFormatter(config.WS_NUMBER)}</p>
                <div className="mini-row">
                  <a href={`https://wa.me/${config.WS_NUMBER}`} target="_blank">
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/wsc.svg"
                      alt="logo whatsapp"
                    />
                  </a>
                  <a href="http://t.me/LoCambioVe" target="_blank">
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/tgc.svg"
                      alt="logo telegram"
                    />
                  </a>
                </div>
              </div>
              <div className="card-section">
                <h4>
                  Síguenos en nuestras redes y aprende más de{' '}
                  <span className="orange_text">LoCambio.com</span>
                </h4>
                <p>@locambiove</p>
                <div className="mini-row">
                  <a
                    href="https://www.instagram.com/locambio.ve/?hl=es"
                    target="_blank"
                  >
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/igc.svg"
                      alt="logo whatsapp"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/locambiove/"
                    target="_blank"
                  >
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/fbc.svg"
                      alt="logo whatsapp"
                    />
                  </a>
                  <a
                    href="https://twitter.com/locambiove?t=Ne34mVDBPWUn5vR6DQvyTA&s=09"
                    target="_blank"
                  >
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/twc.svg"
                      alt="logo whatsapp"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCtw_sZ7rp6bISUG6AJixiiA"
                    target="_blank"
                  >
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/ytc.svg"
                      alt="logo whatsapp"
                    />
                  </a>
                  <a href="https://www.tiktok.com/@locambiove" target="_blank">
                    <img
                      className="w-6 h-6"
                      src="https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/ttc.svg"
                      alt="logo whatsapp"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isTabletOrMobile && (
          <div className="info-pages">
            <LinkInfoPages active="Contáctanos" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
