const PUBLICATION_TYPES = {
  new: 'new',
  interest: 'interest',
  interested: 'interested',
  nearby: 'nearby',
  premium: 'premium',
  sponsored: 'sponsored',
  premium_sponsored: 'premium_sponsored',
};

const PUBLICATION_TYPES_DATA = {
  [PUBLICATION_TYPES.new]: {
    title: { text: 'Nueva', color: 'white' },
    ribbonColor: 'red_gradient'
  },
  [PUBLICATION_TYPES.interest]: {
    title: { text: 'Te interesa', color: 'white' },
    ribbonColor: 'purple'
  },
  [PUBLICATION_TYPES.interested]: {
    title: { text: 'Interesado', color: 'white' },
    ribbonColor: 'orange'
  },
  [PUBLICATION_TYPES.nearby]: {
    title: { text: 'Cercana', color: 'white' },
    ribbonColor: 'blue'
  },
  [PUBLICATION_TYPES.premium]: {
    title: { text: 'Premium', color: 'black' },
    ribbonColor: 'yellow',
    shadow: 'yellow',
    priceColor: "yellow",
  },
  [PUBLICATION_TYPES.sponsored]: {
    title: { text: 'Promo', color: 'white' },
    ribbonColor: 'blue_gradient',
    shadow: 'blue',
    priceColor: "yellow"
  },
  [PUBLICATION_TYPES.premium_sponsored]: {
    title: { text: 'Premium', color: 'yellow' },
    ribbonColor: 'black_gradient',
    shadow: 'blue',
    priceColor: "yellow",
  },
};

export default {
  PUBLICATION_TYPES_DATA,
  PUBLICATION_TYPES
};

