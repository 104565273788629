import { products, proformas } from '../const';

const { PRODUCTS } = products;
const { STATUSES } = proformas;

const paymentsPermissions = (product, ...props) => {
  return paymentsPermissionsMapping[product]?.(...props);
};

const paymentsPermissionsMapping = {
  [PRODUCTS.locambioPromo]: (props) => locambioPromoPaymentsPermissions(props),
  [PRODUCTS.locambioSeguro]: (props) => locambioSeguroPaymentsPermissions(props),
};

const locambioSeguroPaymentsPermissions = (proformaStatus) => {
  const generateSecurityChangeStatuses = [STATUSES.rejected, STATUSES.pending];

  return {
    generateSecurityChange: generateSecurityChangeStatuses.includes(proformaStatus),
  };
};

const locambioPromoPaymentsPermissions = (proformaStatus) => {
  // TODO: change logic to create campaigns
  const generateSecurityChangeStatuses = [STATUSES.rejected, STATUSES.pending];

  return {
    createCampaign: generateSecurityChangeStatuses.includes(proformaStatus),
  };
};

export {
  paymentsPermissions,
};


