import * as actionTypes from '../actions/actionTypes';

import { updateObject } from './../../utilityFunctions';

const initialState = {
  error: null,
  loading: false,
  comments: [],
  savingComment: false,
  errorSavingComment: null
}

const getComments = (state, action) => {

}

const getCommentsStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const setComments = (state, action) => {

}

const failGetComment = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const saveComment = (state, action) => {

}

const saveCommentStart = (state, action) => {
  return updateObject(state, {errorSavingComment: null, savingComment: true})
}

const failSaveComment = (state, action) => {
  return updateObject(state, {errorSavingComment: action.error, savingComment: false})
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMENTS: return getComments(state, action)
    case actionTypes.GET_COMMENTS_START: return getCommentsStart(state, action)
    case actionTypes.FAIL_GET_COMMENTS: return failGetComment(state, action)
    case actionTypes.SET_COMMENTS: return setComments(state, action)
    case actionTypes.SAVE_COMMENT: return saveComment(state, action)
    case actionTypes.SAVE_COMMENT_START: return saveCommentStart(state, action)
    case actionTypes.FAIL_SAVE_COMMENT: return failSaveComment(state, action)
    default:
      return state;
  }
}

export default reducer;