import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HTMLSelect, FormGroup, InputGroup } from '@blueprintjs/core';
import { Controller } from 'react-hook-form';
import { preventOverflow } from '@popperjs/core';

export default function SelectWithInput({
  inputName,
  options,
  selectName,
  control,
  rule,
  errors,
  label,
  setValue,
}) {

  const checkNumeric = (e, name) => {
    const value = e.target.value;
    const patron= /^[0-9]{0,7}?$/;
    console.log({value}, patron.test(value) )
    if (patron.test(value) || value === '') {
      setValue(name, value)
    } else {
      setValue(name, value.replace(/[^0-9]{0,7}/g, ""))
    }
  }

  return (
    <FormGroup
      label={label}
      labelFor={inputName}
      labelInfo="*"
      className="inputLabel"
    >
      <div className="box">
        <div className="selectWithInput phoneSelect">
          <div className="selectInputContainer">
            <Controller
              name={selectName}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <HTMLSelect
                  {...field}
                  name={selectName}
                  minimal
                  className={`${
                    errors[selectName] ? 'bp3-intent-danger' : null
                  }`}
                  options={options}
                />
              )}
            />
            <Controller
              name={inputName}
              control={control}
              rules={{
                pattern: rule && rule.phone,
                required: true,
              }}
              render={({ field }) => (
                <InputGroup
                  {...field}
                  onChange={(e) => checkNumeric(e, inputName)}
                  onPaste={(e) => checkNumeric(e, inputName)}
                  autoComplete='off'
                  className={`inputField ${
                    errors[inputName] && '.bp4-intent-danger'
                  }`}
                  intent={errors[inputName] && 'danger'}
                  id={inputName}
                  type="text"
                  placeholder="Escribe aquí"
                />
              )}
            />
          </div>
        </div>
        {errors[inputName] && (
          <p className="errorMessage">Este campo es obligatorio</p>
        )}
        {errors[selectName] && !errors[inputName] && (
          <p className="errorMessage">Este campo es obligatorio</p>
        )}
      </div>
    </FormGroup>
  );
}

SelectWithInput.propTypes = {
  inputName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  selectName: PropTypes.string,
  control: PropTypes.object,
  rule: PropTypes.object,
  errors: PropTypes.object,
};
