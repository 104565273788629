import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { useMediaQuery } from 'react-responsive';
import BottomScrollListener from 'react-bottom-scroll-listener';

import Credit from '../../components/credit';
import Footer from '../../components/Footer';
import { usePublications } from './hooks/usePublications';
import WallItemFactory from './components/wallItemFactory';
import UserInformation from '../activitiesWall/UserInformation';

import './Wall.scss';

const Wall = () => {
  const user = useSelector(({ auth }) => auth.user);
  const isDesktop = useMediaQuery({ minWidth: '1025px' });
  const { publications, isLoading, loadPublications } = usePublications();

  return (
    <article className="wall">
      <section className={`wall--side wall__profile-wrapper ${isDesktop ? 'wall--sticky' : ''}`}>
        <div className="wall__user-wrapper">
          <UserInformation user={user} />
        </div>
        <Credit className="marginTop-1em" />
      </section>
      <section className="wall__main wall--content">
        <WallItemFactory items={publications} />
        {
          isLoading && (
            <Spinner
              className="marginTop-1em marginBottom-1em"
              size="32"
            />
          )
        }
      </section>
      <Footer showText={false} marginTop={false} className="wall--sticky wall--side wall__ad-wrapper" />
      <BottomScrollListener onBottom={loadPublications} offset={80} />
      <div className="extra"></div>
    </article>
  );
};

export default Wall;