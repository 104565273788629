import React from "react";
import { Col, Row } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import { Button, Callout, Divider, Tag } from "@blueprintjs/core";
import {
  datelocambio,
  distanceFormat,
  formatPrice,
  currencyFormat,
} from "../../../utilityFunctions";

import ShareButton from "../../../components/shareButton";
import ArticlesOfInterest from "../../../components/ArticlesOfInterest";

export default function PublicationOverviewSection({
  pub,
  toChange,
  wrapperImageZoom,
  imageSection,
}) {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const currency =
    pub.price === null
      ? "A convenir"
      : `${localStorage.getItem("currency")}${currencyFormat(pub.price)}`;

  const transactionButton = () => {
    return (
      <div className={`buttons ${isDesktop && 'buttons--desktop'}`}>
        <Button
          onClick={() => toChange('/login')}
          className="bp3-intent-primary-btnLC BtnLCPrimary buttons__border buttons--first"
          fill={isMobile ? true : false}
        >
          Hacer Propuesta
        </Button>
        <Button
          className={`
            BtnLCPrimaryOutline buttons__border
            ${isDesktop && 'buttons--second'}
          `}
          icon="bookmark"
          onClick={() => toChange('/login')}
        >
          {!isMobile && <div>Guardar</div>}
        </Button>
        <ShareButton />
      </div>
    );
  };

  const dateAndType = (
    <div className={'dateAndType toRightTop'}>
      <div className="infoDetailPublication">
        {datelocambio(pub.publish_date)}
      </div>
      <p className="bp3-form-helper-text mt-2 mb-2 font-bold">
        {pub.publication_type}
      </p>
    </div>
  )

  return (
    <>
      <div className="product-detail-container">
        {dateAndType}
        <div className="product-detail-section">
          <Col xs={12} lg={6}>
            {imageSection}
          </Col>
          <Col xs={12} lg={6}>
            {wrapperImageZoom}
            <div className="InfoProductContainer">
              <div className="publicationName">
                <div className="titleWithButton">
                  <div>
                    <h1 className="mb-4 sm:mb-4 md:mb-2 lg:mb-2 xl:mb-2 accent">
                      {pub.name}
                    </h1>
                  </div>
                </div>
                {pub.publication_type === "Producto" ? (
                  <div className="infoDetailPublication mt-3 mb-2">
                    {pub.product_status === "Nuevo" ? (
                      <Tag className="marginBottom-1em mr-2" minimal>
                        Nuevo
                      </Tag>
                    ) : (
                      <>
                        <Tag className="marginBottom-1em mr-2" minimal>
                          Usado
                        </Tag>
                        <Tag
                          className="mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0 mr-2"
                          minimal
                        >
                          {pub.condition}
                        </Tag>
                      </>
                    )}
                    <Tag className="marginBottom-1em">
                      <strong>{currency}</strong>
                    </Tag>
                  </div>
                ) : null}
                <Row>
                  <Col xs={12}>
                    <div className="">
                      <span className="bp3-form-helper-text mt-4 mb-2 mr-2">
                        Se encuentra a
                      </span>
                      <strong>
                        {`${formatPrice(
                          distanceFormat(pub.distance).split(" km")[0]
                        ).toString()} km`}
                      </strong>
                      <span className="bp3-form-helper-text mt-4 mb-2 ml-2 mr-2">
                        en
                      </span>
                      <strong>
                        {pub.location}
                      </strong>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Col xs={12}>
                <div className="pt-5">
                  <h3>Intereses</h3>
                  <Callout className=" interests">
                    <ArticlesOfInterest articles={pub.article_tags} setTag={true} />
                  </Callout>
                </div>
              </Col>
            </Row>
            <div className="mb-4">
              {pub.accept_changes && (
                <>
                  <Divider className="marginTop-05em marginBottom-1em" />
                  <div className="qwerty">
                    <p className="qwerty bp3-form-helper-text mt-2 mb-2">
                      <strong>
                        Abierto a escuchar otras ofertas
                      </strong>
                    </p>
                    <div className="openToListen">
                      Sí
                    </div>
                  </div>
                </>
              )}
            </div>
          </Col>
        </div>

        <div className="product-detail-section">
          <Col xs={12} lg={6}></Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12}>
                <div className="marginBottom-1em">
                  {transactionButton()}
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div >
    </>
  );
}
