import React from 'react';
import { wall } from '../../../const';

const { WALL_ITEMS_TYPES } = wall;

export const withWallItemProps = (WrappedComponent) => {
  return ({ item_type, resource }) => {
    let props = { resource };

    switch (item_type) {
      case WALL_ITEMS_TYPES.change:
        props = { change: resource };
        break;
      case WALL_ITEMS_TYPES.publication:
        props = { pub: resource };
        break;
      case WALL_ITEMS_TYPES.banner:
        props = { ...resource };
        break;
      default:
        break;
    }

    return <WrappedComponent {...props} />;
  };
};