import React, { useEffect, useState } from "react";

import "./PasswordValidator.scss";
import { Callout, Icon, Popover, Position } from "@blueprintjs/core";
import {
  minCharacter,
  thereNumber,
  thereSpecialChar,
  thereUpper,
} from "../../utilityFunctions";

const PasswordValidator = ({
  setPasswordValid,
  isOpen,
  password,
  children,
}) => {
  const hasMinCharacter = password && minCharacter(password);
  const hasNumber = password && thereNumber(password);
  const hasSpecialChar = password && thereSpecialChar(password);
  const hasUpper = password && thereUpper(password);

  useEffect(() => {
    if (hasMinCharacter && hasNumber && hasSpecialChar && hasUpper) {
      setPasswordValid(true);
    }
  }, [password]);

  const selectedIcon = (valid) => {
    return (
      <Icon
        className={
          valid
            ? "password-validator__icon--green"
            : "password-validator__icon--red"
        }
        icon={valid ? "tick" : "cross"}
      />
    );
  };

  return (
    <Popover
      className="password-validator"
      isOpen={isOpen}
      content={
        <div className="popOverContent password-validator__asd">
          <Callout
            icon="key"
            title="Tu contraseña debe contener"
            className="password-validator__container"
          >
            <ul>
              <li>{selectedIcon(hasMinCharacter)} Al menos seis caracteres</li>
              <li>{selectedIcon(hasNumber)} Al menos un caracter numérico</li>
              <li>{selectedIcon(hasSpecialChar)} Al menos un caracter especial(!"#@?-_")</li>
              <li>{selectedIcon(hasUpper)} Al menos una mayúscula</li>
            </ul>
          </Callout>
        </div>
      }
      position={Position.TOP_LEFT}
    >
      {children}
    </Popover>
  );
};

export default PasswordValidator;
