import React, { useCallback, useState } from 'react';

export const ChangeOperationContext = React.createContext({
  selectedPublications: [],
  setSelectedPublications: () => {},
  isSelectedPublication: () => {},
  removeSelectedPublication: () => {},
});

const ChangeOperationProvider = ({ children }) => {
  const [selectedPublications, setSelectedPublications] = useState([]);

  const isSelectedPublication = useCallback((id) => {
    return Boolean(selectedPublications.find((pub) => pub.id === id));
  }, [selectedPublications]);

  const removeSelectedPublication = useCallback((id) => {
   setSelectedPublications(selectedPublications.filter(publication => publication.id !== id))
  }, [selectedPublications]);

  const providerValue = {
    selectedPublications,
    setSelectedPublications,
    isSelectedPublication,
    removeSelectedPublication
  };

  return (
    <ChangeOperationContext.Provider value={providerValue}>
      {children}
    </ChangeOperationContext.Provider>
  );
};

export default ChangeOperationProvider;