import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { RefineDecimals } from './../../../../utilityFunctions';
import './DetailBox.scss';
import { Button } from '@blueprintjs/core';

const DetailBox = ({
  title,
  periodInMonths,
  price,
  securityChangeQuantity,
  periodBackground,
}) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });

  const setMountByMonth = (months, price) => {
    if (months === 1) {
      return null;
    } else {
      return RefineDecimals(price / months, 2);
    }
  };

  const convertToYear = (months) => {
    if (months >= 12) {return months / 12}
    return months
  };

  const setPeriodName = (months) => {
    if (months === 1) return ' Mes';
    if (months > 1 && months < 12) return ' Meses';
    if (months >= 12 && months < 24) return ' Año';
    if (months >= 24) return ' Años';
  };

  const titleType = {
    regular: 'Regular',
    popular: 'Popular',
    recommended: 'Recomendado',
  };

  const detailTitle = <p className={`${title}--title`}>{titleType[title]}</p>;

  const detailMonths = (
    <p className="months-text">
      {convertToYear(periodInMonths)}
      {setPeriodName(periodInMonths)}
    </p>
  );

  const detailPrice = (
    <p>
      <span className={`${title}--text price-currency`}>$</span>
      <span className={`${title}--text ${periodInMonths === 1 ? 'price-text__big' : 'price-text'}`}>{price}</span>
      <span className={`${title}--text price-currency price-decimals`}>,00</span>
    </p>
  );

  const detailsecurityChangeQuantity = (
    <p>
      <span
        className={`${title}--text bold`}
      >
        +{securityChangeQuantity}
      </span>{' '}
      Locambio Seguros
    </p>
  );

  const detailPriceByMonths = (
    <p>
      <span className="price-currency">$(</span>
      <span className="months-text">
        {setMountByMonth(periodInMonths, price)}
      </span>
      <span className="price-currency">/mes)</span>
    </p>
  );

  const userCreditButton = (
    <Button
      className="credit-button"
      text={
        <p>
          Usar <span className="yellow-text">créditos</span>
        </p>
      }
      onClick=""
    />
  );

  const displayWithDetails = (months) => {
    if (months !== 1) {
      return (
        <div>
          <div className="price-by-month">{detailPriceByMonths}</div>
        </div>
      );
    }
  };

  return (
    <div className={`box__container ${title}--shadow`}> 
      <div className="title">{detailTitle}</div>
      <div className={`promotion_months ${periodBackground}_gradient`}>{detailMonths}</div>
      <div className="box-price">
        <div className="price">{detailPrice}</div>
        {displayWithDetails(periodInMonths)}
        <div className="security-change-quantity">
          {detailsecurityChangeQuantity}
        </div>
      </div>
      {/* TODO: uncomment this when the credit module will be done */}
      {/* <div className="go-to-credit">{userCreditButton}</div> */}
    </div>
  );
};

export default DetailBox;
