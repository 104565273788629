import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import ModalImage from '../ModalImage';

import './ModalApp.scss';

const ModalApp = () => {
  const sessionVerifed = useSelector(({ auth }) => auth.sessionVerifed);
  const [showModalGeolocation, setShowModalGeolocation] = useState(false);
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const modalGeolocationUrl =
    !isLargerThanMobile ?
      'https://storage.googleapis.com/locambio-admin/popups/home/popup0-250x300.jpg' :
      'https://storage.googleapis.com/locambio-admin/popups/home/popup0-600x400.jpg';

  useEffect(() => {
    const clientDeniedGeolocation = localStorage.getItem('clientDeniedGeolocation');
    if (sessionVerifed && clientDeniedGeolocation) setShowModalGeolocation(true);
  }, []);

  return (
    <ModalImage
      src={modalGeolocationUrl}
      visible={showModalGeolocation}
      close={() => setShowModalGeolocation(false)}
      aditionalClass={!isLargerThanMobile ? 'modal-image--info-mobile' : ''}
    />
  );
};

export default ModalApp;