import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ videoUrl }) => (
  <div className="video section--with-padding">
    <p className="video__title section__title section__title--bolder">
      <span className="section__title--white"> ¿Cómo funciona </span>
      <span className="section__title--yellow-light">LoCambio.com?</span>
    </p>
    <div className="w-5/6 md:w-3/5">
      <iframe
        title="locambio-video"
        className="video__iframe"
        height="530"
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  </div>
);

Video.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

Video.defaultProps = {
  videoUrl: 'https://www.youtube.com/embed/8S3cEEiWB9k',
};

export default Video;
