const LOCAMBIO_PROMOS_TYPES = {
  basic: 'basic',
  medium: 'medium',
  advanced: 'advanced',
};

const LOCAMBIO_PROMOS = {
  [LOCAMBIO_PROMOS_TYPES.basic]: {
    title: 'Básico',
    shadow: 'blue'
  },
  [LOCAMBIO_PROMOS_TYPES.medium]: {
    title: 'Medio',
    shadow: 'blue'
  },
  [LOCAMBIO_PROMOS_TYPES.advanced]: {
    title: 'Avanzado',
    shadow: 'yellow'
  },
};

const LocambioPromosExport = {
  LOCAMBIO_PROMOS,
  LOCAMBIO_PROMOS_TYPES,
};

export default LocambioPromosExport;
