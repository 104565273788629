import React, { memo } from 'react';
import './ServicesLC/ServicesLC.scss';
import PropTypes from 'prop-types';
import { locambioServices } from '../../../const';
import CardCollection from '../../../components/cardCollection';

const { LOCAMBIO_SERVICES_TYPES } = locambioServices;

const locambioServicesData = {
  [LOCAMBIO_SERVICES_TYPES.locambioSeguro]: {
    ribbonText: 'Adquiérelo',
    route: '/locambio_seguro',
    image: 'https://storage.googleapis.com/locambio-admin/services_cards/service2.png',
    title: [{ value: 'CAMBIA ', types: ['bold', 'primary'] }, { value: 'SEGURO', types: ['bold'] }],
    content: (
      <section>
        <p className="services-lc__description">Con LoCambio Seguro puedes realizar tus cambios en cualquiera de nuestros puntos de cambio, en un ambiente seguro y controlado.</p>
        <p className="services-lc__question">¿Eres cauteloso?</p>
        <p>Ingresa al Chat de Soporte y realiza tus cambios con nosotros.</p>
      </section>
    ),
  },
  [LOCAMBIO_SERVICES_TYPES.locambioPremium]: {
    ribbonText: 'Adquiérelo',
    route: '/locambio_premium',
    image: 'https://storage.googleapis.com/locambio-admin/services_cards/service3.png',
    title: [
      { value: 'CAMBIA ', types: ['bold', 'primary'] },
      { value: 'ARTÍCULOS  ', types: ['bold'] },
      { value: 'DE ALTO VALOR', types: ['bold'] },
    ],
    content: (
      <section>
        <p className="services-lc__description">
          <b>Adquiere beneficios exclusivos</b> dentro y fuera de la
          plataforma; Configuraciones adicionales, Acceso a documentación y
          asesoría para cambiar artículos de alto valor.
        </p>
        <p className="services-lc__question">¿Cambiar inmuebles o Vehículos?</p>
        <p>Este servicio es ideal para ti.</p>
      </section>
    ),
  },
  [LOCAMBIO_SERVICES_TYPES.locambioAds]: {
    ribbonText: 'Adquiérelo',
    route: '/locambio_ads',
    image: 'https://storage.googleapis.com/locambio-admin/pages/ads/locambioads0.png',
    title: [
      { value: 'LOGRA ', types: ['bold'] },
      { value: 'MÁS CAMBIOS ', types: ['bold', 'primary'] },
      { value: 'LLEGANDO A MÁS PERSONAS', types: ['bold'] },
    ],
    content: (
      <section>
        <p className="services-lc__description">
          <b>Consigue más exposición</b> en los resultados de búsqueda y más visibilidad en el mural de LoCambio.com.
        </p>
        <p className="services-lc__question">¿Estás pensando en grande?</p>
        <p>Llega más fácil y rápido a los usuarios interesados en cambiar lo que tienes.</p>
      </section>
    ),
  },
  [LOCAMBIO_SERVICES_TYPES.locambioVerificationId]: {
    ribbonText: 'Gratis',
    route: '/locambio_verificacion_id',
    title: [{ value: 'CAMBIA ', types: ['bold', 'primary'] }, { value: 'CON CONFIANZA', types: ['bold'] }],
    image: 'https://storage.googleapis.com/locambio-admin/services_cards/service1.png',
    content: (
      <section>
        <p className="services-lc__description">Te presentamos la opción ideal para verificar la identidad de los usuarios que utilizan LoCambio.com.</p>
        <p className="services-lc__question">¿Te interesa?</p>
        <p>Ingresa al Chat de Soporte y verifícate en pocos pasos.</p>
      </section>
    ),
  },
};

const ServicesLC = ({ services }) => {
  const collection = services.reduce((acc, service) => {
    const serviceData = locambioServicesData[service];
    if (serviceData) {
      acc.push(serviceData);
    }
    return acc;
  }, []);

  return (
    <CardCollection
      className="services-lc"
      title={[
        { value: 'Conoce ' },
        { value: 'nuestros ', types: ['bold'] },
        { value: 'servicios', types: ['bold', 'primary'] },
      ]}
      withSwiper={false}
      collection={collection}
    />
  );
};

ServicesLC.propTypes = {
  services: PropTypes.arrayOf(PropTypes.oneOf(Object.values(LOCAMBIO_SERVICES_TYPES))),
};

export default memo(ServicesLC);
