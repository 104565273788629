import React from "react";
import { Callout, Tag, Card, Elevation } from "@blueprintjs/core";
import { connect } from "react-redux";
import axios from "axios";
import {
  needOverlay,
  productNameUrl,
  datelocambio,
} from "./../utilityFunctions";
import { config } from "./../system/Config";
import { getHeader } from "./../system/Sesion";
import HeaderProfileSkeleton from "./../components/HeaderProfileSkeleton";
import { Redirect, withRouter, Link } from "react-router-dom";
import HeaderThirdProfile from "./../components/HeaderThirdProfile";
import { Row, Col } from "react-grid-system";
import ProductSwiper from "../components/productSwiper/ProductSwiper";
import OverlayPremium from "./../components/OverlayPremium";
import Footer from "./../components/Footer";
import "./myPublications/publicationItem/PublicationItem.scss";

class MyPublicationsThird extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      initialLoading: true,
    };
  }

  componentDidMount() {
    const urlUserId = this.props.match.params.id;
    const userId = localStorage.getItem("userId");
    if (urlUserId === userId) {
      this.props.history.push("/settings?tab=profile");
      return;
    }
    this.getUser(this.props.match.params.id);
  }

  fetchGetUser = (userId) => {
    return axios.get(`${config.URL_API}/users/${userId}`, {
      headers: getHeader(),
    });
  };

  getUser = async (userId) => {
    try {
      const response = await this.fetchGetUser(userId);
      this.setUser(response.data.user);
      this.setState({ initialLoading: false });
    } catch (err) {
      console.log("error", err);
    }
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  render() {
    const { user, initialLoading } = this.state;
    const { suscription } = this.props;

    if (initialLoading) {
      return <HeaderProfileSkeleton />;
    }

    if (!initialLoading && !user.active) {
      return <Redirect to="/user/inactive/tmp" />;
    }

    const publications = [] //TODO JP

    return (
      <div className="Profile pt-16 sm:pt-16 md:pt-16 lg:pt-16 xl:pt-16">
        <HeaderThirdProfile
          switchIsOPenReport={this.switchIsOPenReport}
          user={user}
          updateInfoUser={() => this.getUser(user.id)}
        />

        <div className="container m-auto">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Callout
                className="mt-8 marginBottom-1em"
                icon="info-sign"
                intent="primary"
              >
                <h3 className="margin0">
                  Publicaciones Activas de {`${user.name} ${user.last_name}`}
                </h3>
              </Callout>
            </Col>
          </Row>
        </div>

        {publications.map((pub) => (
          <div className="container m-auto PublicationItem marginTop-1em">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Card elevation={Elevation.ONE} className="marginBottom-1em">
                  <Row>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      {needOverlay(pub.product_category, suscription) ? (
                        <OverlayPremium />
                      ) : null}
                      <ProductSwiper
                        pictures={pub.pictures}
                        needOverlay={needOverlay(
                          pub.product_category,
                          suscription
                        )}
                      />
                    </Col>
                    <Col xs={12} sm={8} md={8} lg={8}>
                      <Callout>
                        <Row>
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <p className="margin0 marginBottom-05em">
                              Publicación:
                            </p>
                            <h1 className="margin0 marginTop-05em marginBottom-05em">
                              <Link
                                to={`/${productNameUrl(pub.title || pub.name)}/${pub.id}`}
                              >
                                {pub.title || pub.name}
                              </Link>
                            </h1>
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={6}>
                            Tipo de Publicación:
                            <Tag
                              className="tag marginRight-05em marginBottom-05em ml-1 text-xs"
                              minimal={true}
                            >
                              {pub.publication_type}
                            </Tag>
                            <p className="margin0 marginBottom-05em">
                              Fecha de creación:
                            </p>
                            <h1 className="margin0 marginBottom-05em">
                              {datelocambio(pub.publish_date)}
                            </h1>
                          </Col>
                        </Row>
                        <Card
                          elevation={Elevation.ONE}
                          className={"padding-1em"}
                        >
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <p className="margin0 marginBottom-05em">
                                Interesado/a en cambiar por:
                              </p>
                              {pub.article_tags.map((tag) => (
                                <Tag
                                  key={tag.id}
                                  onRemove={true}
                                  className="tagYellow marginRight-05em marginBottom-05em"
                                >
                                  {tag.name}
                                </Tag>
                              ))}
                            </Col>
                          </Row>
                        </Card>
                      </Callout>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        ))}

        <div className="PublicationItem minCustomWrapper marginTop-1em">
          {publications.length === 0 ? (
            <div className="padding-05em">
              <Callout className="noActivityDisplay marginBottom-1em">
                <h5 className="bp3-form-helper-text">
                  {user.name} {user.last_name} no tiene publicaciones aùn
                </h5>
              </Callout>
            </div>
          ) : null}
        </div>
        <div className="minCustomWrapper">
          <Footer />
          <div className="whiteSpace"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suscription: state.auth.suscription,
  };
};

export default withRouter(connect(mapStateToProps)(MyPublicationsThird));
