import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useMediaQuery } from 'react-responsive';
import { Popover2 } from '@blueprintjs/popover2';

import UserBubble from '../../../userBubble';

import './DisplayMenu.scss';

const DisplayMenu = ({
  father,
  children,
  deployer,
  isUserBubble,
  popoverProps,
  withText,
  color,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const [displayMenu, setDisplayMenu] = useState(false);

  const showMenu = () => {
    setDisplayMenu(true);
  };

  const hideMenu = () => {
    setDisplayMenu(false);
  };

  const renderChild = (child) =>
    child instanceof Function ? child(hideMenu) : !father ? <div onClick={hideMenu} > {child} </div> : child ;

  const menuToDisplay = isMobile ? (
    <>
      {displayMenu && (
        <div onClick={hideMenu} id="close__menu" className="display-menu__close">
          <Icon
            className={`display-menu__close--${color}`}
            icon="cross"
            iconSize={22}
          />
        </div>
      )}

      {isUserBubble ? (
        <div >
          <div onClick={showMenu}>
            <UserBubble/>
          </div>
          {displayMenu && (
            <div className="display-menu">{renderChild(children)}</div>
          )}
        </div>
      ) : (
        <div>
          <div className="display-menu__section" onClick={showMenu}>
            <Icon icon={deployer} iconSize={22} />
            <p>{withText}</p>
          </div>
          {displayMenu && (
            <div className="display-menu">{renderChild(children)}</div>
          )}
        </div>
      )}
    </>
  ) : (
    <Popover2
      placement="bottom"
      content={renderChild(children)}
      portalClassName="children-without-transition"
      {...popoverProps}
    >
      {isUserBubble & !displayMenu ? (
        <UserBubble />
      ) : (
        <Icon icon={deployer} iconSize={22} />
      )}
    </Popover2>
  );

  return menuToDisplay;
};

export default DisplayMenu;
