import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '../card';
import Text from '../text';
import { config } from '../../system/Config';
import { getHeader } from '../../system/Sesion';
import { cleanClassName } from '../../utilityFunctions';
import ProgressBarWrapper from './components/progressBarWrapper';
import {
  baseCreditData,
  creditMessage,
  maxProductCredits,
  maxServiceCredits,
  progressBarValue,
  STATUSES,
} from '../../utility/credit';

import './Credit.scss';

const Credit = ({ className }) => {

const { currency } = useSelector((state) => state.geolocation)

const creditDisclaimers = {
  [STATUSES.verifiedFullCredits]: (_credit) => (
    <Text>Felicidades
      <Text color="blue" className="inline"> has reclamado todos tus créditos</Text>
    </Text>
  ),
  [STATUSES.verifiedPartialCredits]: (credit) => (
    <Text>
      Has reclamado
      <Text color="green" className="inline"> {currency}{credit}</Text>
    </Text>
  ),
  [STATUSES.verifiedWithoutCredits]: (_credit) => (
    <Text>
      <Link to="/new_publication">
        <Text color="blue" className="inline">Publica </Text>
      </Link>
      y empieza a reclamar créditos
    </Text>
  ),
  [STATUSES.noVerifiedWithCredits]: (credit) => (
    <Text>
      <Link to="/locambio_verificacion_id">
        <Text color="blue" className="inline">Verifica tu cuenta </Text>
      </Link>
      y reclama
      <Text color="green" className="inline"> {currency}{credit} </Text>
      ahora
    </Text>
  ),
  [STATUSES.noVerifiedWithoutCredits]: (_credit) => (
    <Text>
      <Link to="/locambio_verificacion_id">
        <Text color="blue" className="inline">Verifica tu cuenta </Text>
      </Link>
      y empieza a reclamar créditos
    </Text>
  ),
};

  // TODO: Add loader
  const [creditData, setCreditData] = useState(baseCreditData);
  const totalCredit = creditData.product_credits + creditData.service_credits;
  const areCreditsComplete = creditData.status === STATUSES.verifiedFullCredits;
  const shadow = areCreditsComplete ? 'none' : 'green';

  const progressBars = [
    {
      stripes: false,
      title: 'Productos',
      label: creditMessage(creditData.product_credits, maxProductCredits) + (currency ? currency : '$'),
      value: progressBarValue(creditData.product_credits, maxProductCredits),
    },
    {
      stripes: false,
      title: 'Servicios',
      label: creditMessage(creditData.service_credits, maxServiceCredits) + (currency ? currency : '$'),
      value: progressBarValue(creditData.service_credits, maxServiceCredits),
    },
  ];

  useEffect(() => {
    axios.get(`${config.URL_API}/v1/credit`, { headers: getHeader() })
      .then(({ data }) => setCreditData(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <Card className={`credit ${cleanClassName(className)}`} shadow={shadow}>
      <Text className="credit__title">
        Gana créditos y usa nuestros servicios
      </Text>
      <Link to="/locambio_creditos" className="credit__subtitle-wrapper">
        <Text className="credit__subtitle">
          Cómo funciona
        </Text>
        <Icon icon="help" className="credit__icon" />
      </Link>
      <ProgressBarWrapper
        areCreditsComplete={areCreditsComplete}
        progressBars={progressBars}
      />
      <footer className="credit__footer">
        <img
          className="credit__verification-logo"
          src="//storage.googleapis.com/locambio-admin/pages/history/check0.png"
          alt="verified checkmark"
        />
        {creditDisclaimers[creditData.status]?.(totalCredit)}
      </footer>
    </Card>
  );
};

Credit.propTypes = {
  className: PropTypes.string,
};

Credit.defaultProps = {};

export default Credit;