import React from 'react';
import Price from '../price';
import Text from '../../../text';
import Card from '../../../card';
import Button from '../../../button';
import { locambioPromos } from '../../../../const';

import './LocambioPromos.scss';

const LocambioPromo = ({ locambioPromo, onClick, active, tax, convertionRates }) => {
  const locambioPromoProps = locambioPromos.LOCAMBIO_PROMOS[locambioPromo.productable.ad_type];
  const classNames = `locambio-promo locambio-promo--${locambioPromo.productable.ad_type} ${active ? 'locambio-promo--active' : ''}`;
  const textButton = active ? 'Seleccionado' : 'Seleccionar'

  return (
    <Card shadow={active ? locambioPromoProps?.shadow : 'none'} size="small" className={classNames} onClick={() => onClick?.(locambioPromo)}>
      <Text className="locambio-promo__title" color="white">{locambioPromoProps?.title}</Text>
      <article className="locambio-promo__info">
        <Price price={locambioPromo?.price} />
        <Text className="locambio-promo__sub-title">+{locambioPromo?.productable?.views} Impresiones</Text>
      </article>
      <Button bold intent="accent" text={textButton} className="locambio-promo__button" />
    </Card>
  );
};

export default LocambioPromo;