import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Classes } from '@blueprintjs/popover2';

import './InfoContent.scss';
import infoContent from '.';

const InfoContent = ({ data, header }) => {
const handleInfo = () => {
  const closeMenu = document.getElementById("close__menu"); 
  if (closeMenu) {
    document.getElementById("close__menu").click();
  }
}

  const content = (
    <div className={header && 'info-content__header'}>
      {data.map((group) => (
        <section className="info-content__item">
          <h3
            className={
              header
                ? 'info-content__title info-content__title--header'
                : 'info-content__title'
            }
          >
            {group.title}
          </h3>
          {group.items.map((item) => (
            <Link
              key={`infoContent${item.label}`}
              to={{ pathname: item.url }}
              className={`info-content__link ${Classes.POPOVER2_DISMISS}`}
              onClick={handleInfo}
              target={item.external ? '_blank' : undefined}
            >
              {item.label}
            </Link>
          ))}
        </section>
      ))}
    </div>
  );

  return <section className="info-content">{content}</section>;
};

InfoContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default InfoContent;
