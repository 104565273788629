import React from 'react';

import './ChatInterestedPublicationsModal.scss';
import Button from '../../../../components/button/Button';
import ChatInterestedPublicationsItem from '../chatInterestedPublicationsItem';
import { sumOfPrices } from '../../../../utilityFunctions';
import UserInfo from '../../../../components/userInfo/UserInfo';
import { Divider, Text } from '@blueprintjs/core';
import { useGenericModal } from '../../../../hooks/useGenericModal/useGenericModal';

const ChatInterestedPublicationsModal = ({ publications, user, isOwner, pluralizePublicationsQuantity }) => {

  const currency = localStorage.getItem('currency');
  const { closeModal } = useGenericModal();

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <section className="chat-interested-publications-modal">
      <section className="chat-interested-publications-modal__top">
        <UserInfo user={user} />
        <Divider className="chat-interested-publications-item__card--center-divider" />
        <Text
          ellipsize
          tagName="p"
          className={"chat-interested-publications-modal__top__selected"}
        >
          <span className={`chat-interested-publications-modal__top__selected--${isOwner ? 'primary' : 'accent'}`}>
            {isOwner ? 'Ofrece' : 'Ofreces'} {publications?.length} {pluralizePublicationsQuantity(publications)}
          </span>
        </Text>
      </section>
      <section className="chat-interested-publications-modal__middle">
        <div className="chat-interested-publications-modal__middle--publication-form__container">
          {!publications ?
            <p className="chat-interested-publications-modal__middle--publication-form__container__without_publication">No tienes Publicaciones activas</p> :
            publications.map((pub) => (
              <ChatInterestedPublicationsItem
                id={pub?.id}
                key={`ModalChangeProposalItem${pub?.id}`}
                price={pub?.price}
                title={pub?.title}
                image={pub?.picture}
                linkTo={pub?.linkTo}
                status={pub?.status}
                currency={currency}
                isChat
                closeModal={closeModal}
              />
            ))
          }
        </div>
      </section>
      <section className="chat-interested-publications-modal__bottom">
        {
          publications && (
            <p className="chat-interested-publications-modal__bottom--total">
              <span className="chat-interested-publications-modal__bottom--total-bold">Total</span>
              {`${currency} ${sumOfPrices(publications)}`}
            </p>
          )
        }
      </section>
      <section className="chat-interested-publications-modal__button-wrapper">
        <Button
          className="chat-interested-publications-modal__button"
          intent="accent"
          text="Cerrar"
          onClick={handleCloseModal}
        />
      </section>
    </section>
  );
};

export default ChatInterestedPublicationsModal;