import axios from 'axios';
import { config } from '../system/Config';
import { getHeader } from '../system/Sesion';

const setConfigHeader = (config) => {
  config.headers = { ...config.headers, ...getHeader() };
  return config;
};

export const authInstanceAxios = (props) => {
  const axiosInstance = axios.create(props);
  axiosInstance.interceptors.request.use(setConfigHeader);
  return axiosInstance;
};

export const v1AuthAxios = authInstanceAxios({ baseURL: `${config.URL_API}/v1` });

export const authAxios = authInstanceAxios({ baseURL: `${config.URL_API}` });

export const v1Axios = axios.create({
  baseURL: `${config.URL_API}/v1`,
});

export const baseAxios = axios.create({
  baseURL: `${config.URL_API}`,
});
