import React from 'react';

import Button from '../../../button';

import './Actions.scss';

const Actions = ({ actions, publication, index }) => {
  const actionsElements = actions.map(({ href, onClick, ...props }, i) => {
    const parsedHref = href?.({ publication, index }) || href;
    const handleClick = () => onClick?.({ publication, index });
    return (
      <Button
        bold
        href={parsedHref}
        onClick={handleClick}
        key={`${props.text}-${i}`}
        {...props}
      />
    );
  }
  );

  return (
    <footer className="actions">
      {actionsElements}
    </footer>
  );
};

export default Actions;