import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { config } from '../../system/Config';

import './GoogleButton.scss';

const GoogleButton = ({callback}) => {
  const google = window.google
  const isMobile = useMediaQuery({ maxWidth: 425 });
  
  const responseGoogle = (response) => {
    callback(response);
  };
  
  const googleInitialize = () => google.accounts?.id.initialize({
    client_id: config.KEY_GOOGLE_LOGIN,
    
    callback: (e) => responseGoogle(e.credential)
  });
  
  let responseGogleInitialize = googleInitialize();

  return (
    <>
    <div id='signinDiv'>

    </div>
    {
      responseGogleInitialize && google.accounts?.id && google.accounts.id.renderButton(document.getElementById("signinDiv"), 
        {
          theme: 'outline',
          size: 'large',
          width: isMobile ? '275px' :'390px',
        })
    }
    </>
  );
};

export default GoogleButton;