import React from 'react';

import Accordion from '../../components/accordion';
import Footer from '../home/components/footer/Footer';
import LinkInfoPages from '../../components/linkInfoPages';
import ScrollToElement from '../../components/scrollToElement/ScrollToElement';

import './Faq.scss';
import { phoneFormatter } from '../../utilityFunctions';
import { config } from '../../system/Config';

const Faq = () => {
  const data = {
    title: 'Preguntas frecuentes',
    sections: [
      {
        title: 'Registro de Nuevos Usuarios',
        questions: [
          {
            id: 1,
            title: '¿Cómo me registro?',
            answer: (
              <ul className="faq__list">
                <li>Ingresa en Locambio.com</li>
                <li>
                  Haz clic en <b>“Registrarse”</b>.
                </li>
                <li>Llena los campos con los datos solicitados.</li>
                <li>Crea una contraseña.</li>
                <li>
                  Haz clic en <b>“Registrarse”</b> para recibir un correo de
                  confirmación.
                </li>
                <li>
                  Haz clic en el link del correo para confirmar tu cuenta.
                </li>
                <li>¡LISTO! Bienvenido a LoCambio.com.</li>
              </ul>
            ),
          },
          {
            id: 2,
            title: '¿Cómo debe ser mi contraseña?',
            answer: (
              <>
                <p className="faq__answer">
                  Por razones de seguridad te recomendamos:{' '}
                </p>
                <ul className="faq__list faq__list--sub">
                  <li>
                    Evitar usar el nombre de usuario u otro dato de acceso
                    público.
                  </li>
                  <li>Utilizar una contraseña con 6 caracteres o más.</li>
                  <li>Usar mayúsculas, números y caracteres especiales.</li>
                </ul>
              </>
            ),
          },
          {
            id: 3,
            title: '¿Se pueden registrar personas menores de edad? ',
            answer: (
              <p className="faq__answer faq__answer--alone">
                LoCambio.com ofrece la posibilidad de intercambiar bienes y/o
                servicios entre los usuarios, por razones de seguridad, los
                menores de edad no pueden registrarse, ni usar la plataforma.
              </p>
            ),
          },
          {
            id: 4,
            title: '¿Puedo registrarme si estoy fuera de Venezuela?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Por los momentos no. Sin embargo te informamos que LoCambio.com
                busca abordar varios países de Latinoamérica, comenzamos en
                Venezuela y próximamente se contemplan países como Colombia y
                Perú.
              </p>
            ),
          },
          {
            id: 5,
            title:
              '¿Debo colocar obligatoriamente mi foto para hacer el registro?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                No es obligatorio colocar la foto de perfil, pero se recomienda
                colocarla para generar más confianza entre los usuarios y
                aumentar así tus posibilidades de recibir más propuestas de
                intercambio.
              </p>
            ),
          },
          {
            id: 6,
            title: '¿Cómo puedo cambiar la foto de perfil?',
            answer: (
              <>
                <p className="faq__answer">
                  Es muy sencillo. solo debes seguir los siguientes pasos:
                </p>
                <ul className="faq__list faq__list--sub faq__list--number">
                  <li>
                    En la parte superior derecha, donde se encuentra tu{' '}
                    <b>¨Perfil¨</b>, debes hacer clic y seleccionar{' '}
                    <b>¨Perfil¨</b>.
                  </li>
                  <li>
                    Dirígete hacia la foto que tiene actualmente y presiona
                    sobre el icono de editar. (Es el que tiene forma de lápiz).
                  </li>
                  <li>
                    Selecciona la foto que deseas colocar desde archivo o
                    utiliza la cámara de tu dispositivo, ajusta la foto y
                    presiona *listo*. (Automáticamente se guarda el proceso).
                  </li>
                </ul>
              </>
            ),
          },
          {
            id: 7,
            title: '¿Puedo iniciar sesión a través de facebook o google?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Si. Estando en el formulario de{' '}
                <b>
                  <u>Iniciar sesión</u>
                </b>{' '}
                puedes hacerlo con Facebook o Google, según sea tu gusto, ya
                previamente, al registrarte haz colocado un correo afiliado a
                tus cuentas de Facebook y Google.
              </p>
            ),
          },
        ],
      },
      {
        title: 'Olvidar algún dato',
        questions: [
          {
            id: 8,
            title: '¿Cómo puedo recuperar mi contraseña?',
            answer: (
              <>
                <p className="faq__answer">
                  Si olvidaste tu contraseña la puedes recuperar fácilmente:
                </p>
                <ul className="faq__list faq__list--sub faq__list--number">
                  <li>
                    Selecciona la opción donde dice “¿Olvidaste tu clave?”
                  </li>
                  <li>Ingresa tu correo.</li>
                  <li>
                    Haz clic donde dice “Solicitar cambio de contraseña” para
                    recibir un correo con el siguiente paso.
                  </li>
                  <li>
                    Haz clic en el link recibido al correo para restablecer tu
                    contraseña.
                  </li>
                </ul>
              </>
            ),
          },
          {
            id: 9,
            title:
              'Estoy intentando registrarme y me dice que ya el correo existe. ¿Qué debo hacer?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Primero, asegúrate de haber escrito bien el correo. Si el
                problema persiste, contacta a nuestro equipo de soporte en línea
                para recibir apoyo.
              </p>
            ),
          },
        ],
      },
      {
        title: 'Acerca de locambio.com',
        questions: [
          {
            id: 10,
            title: '¿Qué es LoCambio.com?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Es una plataforma Web gratuita que ofrece a sus usuarios una
                herramienta para intercambiar o canjear sus bienes, artículos
                personales, servicios profesionales y habilidades de manera
                rápida, segura y sencilla. Además permite realizar publicaciones
                y recibir propuestas de manera ilimitada.
              </p>
            ),
          },
          {
            id: 11,
            title: '¿Cuál es el propósito de LoCambio.com?',
            answer: (
              <ul className="faq__list">
                <li>
                  Traer el trueque a la era digital, logrando que las personas
                  canjeen o cambien lo que tienen por lo que necesitan o desean,
                  sin el dinero como medio exclusivo para la negociación, aun
                  cuando puede utilizarse para complementar el trueque o cambio.
                </li>
                <li>
                  Ofrecer una plataforma global, con funcionalidades
                  especialmente diseñadas para el intercambio, pensando en las
                  necesidades de sus usuarios.
                </li>
                <li>
                  Promover el ahorro, bienestar y calidad de vida de la
                  comunidad.
                </li>
                <li>
                  Fomentar el reciclaje y educar sobre la importancia de poner
                  en circulación bienes en desuso.
                </li>
                <li>
                  Reducir el consumismo excesivo apoyando al medio ambiente.
                </li>
                <li>
                  Que las personas tengan lo que necesitan y puedan dar lo que
                  ya no usan.
                </li>
                <li>Que las cosas se reusen o reciclen.</li>
                <li>
                  Ser un mercado de personas y necesidades, no de comerciantes.
                </li>
                <li>
                  Ser la mayor red social de intercambio que abarque toda
                  Latinoamérica.
                </li>
              </ul>
            ),
          },
          {
            id: 12,
            title: '¿Locambio.com está disponible en Google Play?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                En estos momentos estamos trabajando en eso, esperamos pronto
                tener disponible nuestra Aplicación en Google Play.
              </p>
            ),
          },
          {
            id: 13,
            title: '¿Se puede usar dinero para hacer negocios en Locambio.com?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                El propósito de la plataforma es estimular intercambios, pero
                también si se desea y ambas partes lo acuerdan, se puede
                completar con dinero como parte de la propuesta de intercambio.
              </p>
            ),
          },
          {
            id: 14,
            title: '¿Puedo ganar dinero a través de  Locambio.com?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                LoCambio.com es una plataforma web que busca estimular el
                trueque o canje, sin que sea necesario el uso del dinero para
                obtener lo que se quiere o necesita. Sin embargo, usted puede
                hacer las negociaciones que le permitan satisfacer sus
                necesidades.
              </p>
            ),
          },
          {
            id: 15,
            title: '¿Para utilizar la plataforma debo registrarme?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Cualquier visitante de LoCambio.com puede usar nuestro buscador
                para conseguir bienes y servicios cerca de él. Sin embargo, para
                poder realizar propuestas de intercambio o navegar en el mural
                de actividades, debe registrarse.
              </p>
            ),
          },
          {
            id: 16,
            title: '¿Por qué medios me puedo comunicar con ustedes?',
            answer: (
              <>
                <p className="faq__answer">
                  LoCambio.com cuenta con un Chat para Soporte en línea que se
                  encuentra en la forma de un botón en la parte inferior
                  izquierda del sitio web, donde serás atendido por nuestro
                  equipo de operadores. De igual forma, puede comunicarse con
                  nosotros por otras vías como:
                </p>
                <ul className="faq__list faq__list--sub">
                  <li>Correo electrónico: Info@LoCambio.com.</li>
                  <li>WhatsApp: {phoneFormatter(config.WS_NUMBER)}.</li> 
                  <li>Telegram: (+58) 412-2022631.</li>
                  <li>
                    También puede seguirnos en nuestras redes sociales
                    @LoCambio.ve.
                  </li>
                </ul>
              </>
            ),
          },
        ],
      },
      {
        title: 'Acerca de las publicaciones',
        questions: [
          {
            id: 17,
            title: '¿Qué se puede publicar en Locambio.com?',
            answer: (
              <>
                <p className="faq__answer">
                  La plataforma te permite publicar cualquier artículo como
                  accesorios de vestir, ropa, zapatos, teléfonos, televisores,
                  muebles, neveras, lavadoras, computadoras, juguetes, libros,
                  películas, joyas, maquillaje y muchos más. Además, en
                  LoCambio.com también se pueden publicar servicios
                  profesionales, oficios y habilidades de los usuarios como
                  servicios de carpintería, jardinería, odontología, clases y
                  cursos, entre muchos otros. Las posibilidades de canjear o
                  cambiar son infinitas.
                </p>

                <p className="faq__answer faq__answer--mid">
                  Sin embargo, es importante tener en cuenta que la información
                  e imágenes del artículo que publiques, deben cumplir con los
                  Términos y Condiciones de LoCambio.com, por ejemplo:{' '}
                </p>
                <ul className="faq__list faq__list--sub">
                  <li>
                    Deben ser representativas de lo que ofreces y no pueden ser
                    engañosas en ningún sentido, ni contener información de
                    contacto o de otro sitio web.
                  </li>
                  <li>
                    No deben violar los derechos de autor o ser en cualquier
                    modo ofensivas o irrespetuosas.
                  </li>
                  <li>
                    Las imágenes tampoco pueden contener desnudos, contenido
                    erótico o inapropiado.
                  </li>
                </ul>
                <p className="faq__answer">
                  También hay ciertos artículos no permitidos para publicación
                  en LoCambio.com, tales como:
                </p>
                <ul className="faq__list faq__list--sub">
                  <li>Armas, municiones y material explosivo.</li>
                  <li>Estupefacientes y sustancias prohibidas.</li>
                  <li>Propiedad robada y hurtada.</li>
                  <li>Huesos, órganos y residuos humanos.</li>
                  <li>Bienes que atenten contra la propiedad intelectual.</li>
                  <li>Flora y fauna en peligro de extinción.</li>
                  <li>
                    Vehículos sin documentación y productos para vehículos que
                    infrinjan leyes vigentes.
                  </li>
                  <li>Base de datos.</li>
                  <li>
                    Patrimonio histórico, cultural, arqueológico y
                    paleontológico.
                  </li>
                  <li>
                    Cualquier otro artículo o servicio cuya comercialización es
                    penada por la ley vigente.
                  </li>
                </ul>
              </>
            ),
          },
          {
            id: 18,
            title: '¿Se pueden publicar cosas usadas o nuevas?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                LoCambio.com está preparada para publicar bienes usados o
                nuevos. Sin embargo, queremos recalcar la honestidad a nuestros
                usuarios sobre las condiciones de los bienes durante las
                propuestas de intercambio, para que las partes involucradas en
                el proceso de cambio queden satisfechas con el mismo.
              </p>
            ),
          },
          {
            id: 19,
            title:
              '¿Cuáles son los requisitos básicos que se necesitan para publicar y canjear?',
            answer: (
              <ul className="faq__list">
                <li>
                  Ser poseedor legítimo del bien a canjear o tener las
                  habilidades del servicio que se va a publicar.
                </li>
                <li>
                  Subir al menos una imagen JPG o PNG alusiva al artículo o
                  servicio para la publicación.
                </li>
                <li>
                  Describir detalladamente el objeto o servicio que se está
                  publicando.
                </li>
                <li>
                  Definir los bienes y/o servicios de interés que deseas recibir
                  a cambio.
                </li>
                <li>
                  Acordar con la otra parte interesada las condiciones del
                  trueque o canje, así como la ubicación para realizarlo.
                </li>
              </ul>
            ),
          },
          {
            id: 20,
            title: '¿Cómo se elimina una publicación?',
            answer: (
              <>
                <p className="faq__answer">
                  Las publicaciones no se eliminan sino que se desactivan o
                  archivan.
                </p>

                <p className="faq__answer faq__answer--mid">
                  Por favor, sigue los siguientes pasos:
                </p>
                <ul className="faq__list faq__list--sub">
                  <li>
                    En la parte superior derecha, donde se encuentra tu{' '}
                    <b>Perfil</b>, debes hacer clic en{' '}
                    <b>´Mis Publicaciones´</b>.
                  </li>
                  <li>
                    Buscar la publicación que deseas desactivar y presionar
                    *Archivar* (Automáticamente la publicación se guarda en la
                    carpeta <b>´Publicaciones Inactivas´</b>). Al hacer esto, la
                    publicación dejará de ser visible para otros usuarios.
                  </li>
                </ul>
              </>
            ),
          },
          {
            id: 21,
            title:
              '¿Se puede hacer distintas ofertas a usuarios con un mismo artículo publicado y esperar cuál de ellos acepta la propuesta primero?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Si, es una práctica conveniente a la hora de hacer negociaciones
                Oferta-Demanda y es permitido dentro de la plataforma de
                LoCambio.com. Se recomienda que al momento de realizar un
                negocio definitivo, ser honesto con los demás usuarios.
              </p>
            ),
          },
        ],
      },
      {
        title: 'Acerca del intercambio',
        questions: [
          {
            id: 22,
            title: '¿Dónde se realizan los intercambios?',
            answer: (
              <>
                <p className="faq__answer faq__answer--alone">
                  Los lugares donde se realicen los intercambios se definen en
                  la publicación con ayuda de Google Map. En LoCambio.com
                  sugerimos por medidas de seguridad pautar en zonas con cámaras
                  y/o servicio de seguridad como centros comerciales u otros
                  establecimientos.
                </p>
                <p className="faq__answer faq__answer--alone">
                  Pensando en tu seguridad, LoCambio pone a tu disposición
                  nuestro servicio <u>LoCambio Seguro</u>, que te ofrece una
                  gama de opciones que proporcionan seguridad a la hora del
                  cambio.
                </p>
              </>
            ),
          },
          {
            id: 23,
            title: '¿Es posible intercambiar animales?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Sí, LoCambio.com permite el intercambio de animales dentro de su
                plataforma. Dicho esto, invitamos a los usuarios a que aseguren
                el bienestar de los animales y velen por que se respeten sus
                derechos en todo momento.
              </p>
            ),
          },
        ],
      },
      {
        title: 'Chat de Intercambios, Buscador y Notificaciones',
        questions: [
          {
            id: 24,
            title: '¿Cómo funciona el buscador?',
            answer: (
              <>
                <p className="faq__answer faq__answer--alone">
                  Sólo debes escribir lo que quieres en la barra de búsqueda de
                  LoCambio.com, por ejemplo <i>“Ropa de bebe”</i> y el
                  explorador de LoCambio.com te mostrará las opciones más cerca
                  de tu ubicación.{' '}
                </p>
                <p className="faq__answer faq__answer--alone">
                  También contamos con un buscador bidireccional en donde puedes
                  encontrar otros usuarios que buscan lo que tu ofreces.
                </p>
              </>
            ),
          },
          {
            id: 25,
            title: '¿Qué es el Chat de Intercambio?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Es el lugar donde se generan las negociaciones dirigidas a
                concretar el cambio. Allí se pueden ampliar aún más las
                especificaciones del producto o servicio que se ofrezca para
                canje. Los mensajes del Chat de Intercambio se ven reflejados en
                la barra superior derecha, al lado de las notificaciones.
              </p>
            ),
          },
          {
            id: 26,
            title: '¿Para qué sirven las notificaciones?',
            answer: (
              <p className="faq__answer faq__answer--alone">
                Cualquier interacción relacionada con tus publicaciones genera
                un llamado a tu cuenta para que puedas revisarlas, podrás verlas
                reflejadas en la barra superior derecha, al lado del Chat de
                Intercambio. Las notificaciones son sinónimo de oportunidades de
                cambio.
              </p>
            ),
          },
        ],
      },
    ],
  };

  return (
    <>
              <ScrollToElement
          scroll={true}
          options={{ behavior: 'auto', block: 'center' }}
          highlight={false}
        >
        </ScrollToElement>
      <div className="faq">
        <div className="faq__container">
          <Accordion data={data} />
        </div>
        <div className="faq__links">
          <LinkInfoPages active={'Preguntas Frecuentes'} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
