import React from 'react';
import PropTypes from 'prop-types';
import { cleanClassName } from '../../../../utilityFunctions';
import { ProgressBar as BPProgressBar, Text } from '@blueprintjs/core';

import './ProgressBar.scss';

const ProgressBar = ({
  label,
  title,
  className,
  barClassName,
  titleClassName,
  labelClassName,
  barWrapperClassName,
  ...progressBarProps
}) => {

  return (
    <section className={`progress-bar ${cleanClassName(className)}`}>
      {title && <Text className={`progress-bar__title ${cleanClassName(titleClassName)}`}>{title}</Text>}
      <div className={`progress-bar__bar-wrapper ${cleanClassName(barWrapperClassName)}`}>
        <BPProgressBar
          className={`progress-bar__bar ${cleanClassName(barClassName)}`}
          {...progressBarProps}
        />
        {label && <Text className={`progress-bar__label ${cleanClassName(labelClassName)}`}>{label}</Text>}
      </div>
    </section>
  );
};

ProgressBar.propTypes = PropTypes.shape({
  label: PropTypes.string,
  title: PropTypes.string,
  animate: PropTypes.bool,
  stripes: PropTypes.bool,
  intent: PropTypes.string,
  values: PropTypes.number,
  className: PropTypes.string,
  barClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  barWrapperClassName: PropTypes.string,
}).isRequired;

ProgressBar.defaultProps = {
  progress: 0,
};

export default ProgressBar;