import React from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  Card,
  Elevation,
  Divider,
  AnchorButton,
  Button,
} from "@blueprintjs/core";

const HeaderProfileSkeleton = (props) => {
  return (
    <>
      <div className={"HeadarProfile"}>
        <div className={""}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className={"CoverImageProfile"}>
                  <div className="backgroundImageAvatar bp3-skeleton">
                    <figure>
                      <div className="backgroundImage">
                        <div id={`image-cover-user`}>
                          <img id={`user-cover-image`} src={null} />
                        </div>
                      </div>
                      <div className={"overlayBackgroundImageAvatar"}>
                        <p>
                          Para cambiar arrastra una imagen o pulsa para
                          selecciona una imagen
                        </p>
                      </div>
                    </figure>
                  </div>
                  {/* <div className={"gradientCoverImageProfile"}></div> */}
                </div>

                <div className={"OverlapContent"}>
                  <Row>
                    <Col xs={12} sm={12} md={3} lg={3}>
                      <Card
                        elevation={Elevation.ONE}
                        className={"AvatarProfileContainer bp3-skeleton"}
                      >
                        <div className="User-Avatar-Name">
                          <figure>
                            <div className="avatarContainer">
                              <div
                                id={`image-profile-user`}
                                className="dragDropDisclaimerContenainer"
                              >
                                <img id={`user-profile-image`} src={null} />
                              </div>
                            </div>
                          </figure>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9}>
                      <Card
                        elevation={Elevation.ONE}
                        className={"AvatarProfileInfoContainer bp3-skeleton"}
                      >
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Row>
                              <Col xs={12} sm={8} md={8} lg={8}>
                                <div className={"AvatarProfileInfo"}>
                                  <div className={"AvatarProfileName"}>
                                    <h1
                                      className={"margin0 marginBottom-05em"}
                                    ></h1>
                                    <p
                                      className={"margin0 marginBottom-05em"}
                                    ></p>
                                    <p className={"margin0"}></p>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} sm={4} md={4} lg={4}></Col>
                              <Divider
                                className={"marginTop-1em marginBottom-1em"}
                              />
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <AnchorButton
                                  className={
                                    "marginBottom-05em marginRight-05em"
                                  }
                                  minimal={true}
                                  text="Perfil"
                                />
                                {/* <Popover content={
                                    <Menu>
                                      <p className="bp3-heading bp3-text-small bp3-text-muted marginTop-05em marginBottom-05em">Mis publicaciones</p>
                                      <Menu.Item text="Publicaciones Activas" />
                                      <Menu.Item text="Publicaciones Pendientes por Cambio" />
                                      <Menu.Item text="Publicaciones inactivas" />
                                      <Menu.Item text="Camnbios Exitosos" />
                                    </Menu>
                                  } position={Position.BOTTOM}>
                                    <AnchorButton minimal={true} text="Mis Publicaciones" />
                                </Popover> */}
                                <AnchorButton
                                  className={
                                    "marginBottom-05em marginRight-05em"
                                  }
                                  minimal={true}
                                  text="Mis Publicaciones"
                                />
                                <AnchorButton
                                  className={
                                    "marginBottom-05em marginRight-05em"
                                  }
                                  minimal={true}
                                  text="Métodos de pago"
                                />
                                <AnchorButton
                                  className={
                                    "marginBottom-05em marginRight-05em"
                                  }
                                  minimal={true}
                                  text="Preferencias"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HeaderProfileSkeleton;
