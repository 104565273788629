import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './../pages/Login';
import NewPublication from './../pages/newPublication';
import Register from './../pages/register';
import DetailProduct from './../pages/DetailProduct';
import SearchList from './../pages/SearchList';
import Logout from './../pages/Logout';
import ForgotMyPassword from './../pages/ForgotMyPassword';
import RecoverPassword from './../pages/RecoverPassword';
import ConfirmateUser from './../pages/ConfirmateUser';
import LoginFlow from './../pages/LoginFlow';
import Page404 from './../pages/Page404';
import NotificationsPage from './../pages/NotificationsPage';
import AboutLoCambio from './../pages/aboutLoCambio';
import LoCambioSeguro from './../pages/LoCambioSeguro';
import LoCambioPremium from './../pages/LoCambioPremium';
import LoCambioAds from './../pages/LoCambioAds';
import LoCambioCreditos from './../pages/LoCambioCreditos';
import IdVerification from '../pages/idVerification';
import Faq from './../pages/faq';
import SecurityAdvice from './../pages/securityAdvice';
import PrivacyPolicies from './../pages/PrivacyPolicies';
import TermsAndConditions from './../pages/TermsAndConditions';
import Contacts from './../pages/contacts';
import ThirdProfile from './../pages/ThirdProfile';
import MyPublicationsThird from './../pages//MyPublicationsThird';
import PublicationCreatedSuccessfully from './../pages/publicationCreatedSuccessfully';
import SuccessfulMessage from './../pages/SuccessfulMessage';
import AdCreatedSuccessfully from './../pages/adCreatedSuccessfully';
import ChangesPage from '../pages/Changes';
import Home from '../pages/home';
import AuthRoute from './AuthRoute';
import Chat from '../pages/chat';
import SavedPublications from '../pages/savedPublications';
import Wall from '../pages/wall';
import ChangeOperation from '../pages/changeOperation';
import PublicationOverview from '../pages/public/publicationOverview/PublicationOverview';
import UserProfile from '../pages/userProfile';

class Routes extends React.Component {
  render() {
    if (!this.props.sessionVerifed) {
      return null;
    }

    const { isAuthenticated } = this.props;

    return (
      <>
        <Switch>
          <Route path="/login">
            {isAuthenticated ? <Redirect to="/wall" /> : <Login />}
          </Route>
          {isAuthenticated && (
            <Route path="/profile/:user_id" exact component={UserProfile} key="user"/>
          )}
          {isAuthenticated && (
            <Route path="/profile" exact component={UserProfile} key="owner"/>
          )}
          {isAuthenticated && (
            <Route path="/new_publication" exact component={NewPublication} key="create-publication" />
          )}
          {!this.props.isAuthenticated && (
            <Route path="/registration" exact component={Register} />
          )}
          <Route
            path="/publicacion_creada"
            component={PublicationCreatedSuccessfully}
            exact
          />
          <Route path="/successful_message/:type" component={SuccessfulMessage} exact />
          <Route
            path="/publicidad_creada"
            component={AdCreatedSuccessfully}
            exact
          />
          <Route path="/home" exact component={Home} />
          <Route
            path="/search_list/:tags/:category_id/:location/:type/:km/:latitude/:longitude/:sbp/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list/:tags/:location/:type/:km/:latitude/:longitude/:sbp/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list/[:left...:right]/:location/:type/:km/:latitude/:longitude/:sbp/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list_price/:location/:type/:km/:latitude/:longitude/:sbp/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list_interest/:tag/:location/:latitude/:longitude/:ids/:typeSearch/:search_by"
            exact
            component={SearchList}
          />
          <Route
            path="/search_list_by_category/:category/:latitude/:longitude/:query"
            exact
            component={SearchList}
          />
          <Route path="/logout" exact component={Logout} />
          <Route path="/forgot_password" exact component={ForgotMyPassword} />
          <Route path="/login_flow/:type" exact component={LoginFlow} />
          <Route
            path="/recovery_password/:token/:tlc"
            exact
            component={RecoverPassword}
          />
          {isAuthenticated && (
            <Route path="/chats/:id?" component={Chat} exact />
          )}

          {<Route path="/:name/:id" component={isAuthenticated ? DetailProduct : PublicationOverview} exact />}

          {isAuthenticated && (
            <Route
              path="/publication/:id/proposal/manager"
              component={ChangeOperation}
              exact
            />
          )}
          {isAuthenticated && (
            <Route path="/requests" component={ChangesPage} exact />
          )}

          <AuthRoute path="/wall" component={Wall} exact />
          <Route path="/confirm/user/:token" component={ConfirmateUser} exact />
          <Route path="/profile/third/:id" component={ThirdProfile} exact />
          {isAuthenticated && (
            <Route
              path="/publications/third/:id"
              component={MyPublicationsThird}
              exact
            />
          )}
          {isAuthenticated && (
            <Route
              path="/edit/publication/:id"
              component={NewPublication}
              key="edit-publication"
              exact
            />
          )}
          <Route path="/about_locambio/" component={AboutLoCambio} exact />
          <Route
            path="/locambio_verificacion_id/"
            component={IdVerification}
            exact
          />
          <Route path="/locambio_seguro/" component={LoCambioSeguro} exact />
          <Route path="/locambio_premium/" component={LoCambioPremium} exact />
          <Route path="/locambio_ads/" component={LoCambioAds} exact />
          <Route path="/locambio_creditos/" component={LoCambioCreditos} exact />
          <Route path="/faq/" component={Faq} exact />
          <Route
            path="/consejos_de_seguridad/"
            component={SecurityAdvice}
            exact
          />
          <Route
            path="/politicas-de-privacidad/"
            component={PrivacyPolicies}
            exact
          />
          <Route
            path="/terminos-y-condiciones/"
            component={TermsAndConditions}
            exact
          />
          <Route path="/contactos/" component={Contacts} exact />
          {isAuthenticated && (
            <Route path="/publications-saved/" component={SavedPublications} exact />
          )}

          {isAuthenticated && (
            <Route
              path="/notifications_page"
              component={NotificationsPage}
              exact
            />
          )}
          <Route path="/user/inactive/tmp" component={Page404} />
          <Route path="/" component={Home} />
          <Redirect to="/" />
        </Switch>
      </>
    );
  }
}

export default Routes;
