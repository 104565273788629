import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Popover2 } from '@blueprintjs/popover2';

import InfoContent from '../infoContent';
import PanelStack from "../../../panelStack"

const infoData = [
  {
    title: 'Información',
    items: [
      { label: 'Sobre LoCambio.com', url: '/about_locambio' },
      { label: 'Ganar créditos', url: '/locambio_creditos' },
      { label: 'Blog.LoCambio.com', url: 'https://blog.locambio.com/', external: true },
    ],
  },
  {
    title: 'Servicios',
    items: [
      { label: 'Verificación ID', url: '/locambio_verificacion_id' },
      { label: 'LoCambio.com Seguro', url: '/locambio_seguro' },
      { label: 'LoCambio.com Ads', url: '/locambio_ads' },
      { label: 'LoCambio.com Premium', url: '/locambio_premium' },
    ],
  },
  {
    title: 'Otros enlaces',
    items: [
      { label: 'Preguntas Frecuentes', url: '/faq' },
      { label: 'Consejos de Seguridad', url: '/consejos_de_seguridad' },
      { label: 'Políticas de Privacidad', url: '/politicas-de-privacidad' },
      { label: 'Términos y Condiciones', url: '/terminos-y-condiciones' },
      { label: 'Contáctanos', url: '/contactos' },
    ],
  },
];

const Info = (props) => {
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });
  const { latitude, longitude } = useSelector(({ geolocation }) => geolocation);
  
  const redirectToCategory = (elem) => {
    props.history.push(
      `/search_list_by_category/${elem.id}/${latitude}/${longitude}/category`
    );
  };

  const sections = [
    {
      name: 'Categorías',
      icon: 'properties',
      content: <div className='panel-stack__container'><PanelStack functionality={redirectToCategory}/></div>,
    },
    { 
      name: 'Información',
      icon: 'help',
      content: <InfoContent data={infoData} />,
    },
  ];

  const sectionElements = sections.map((section) => {
    return (
      <Popover2
        key={`popover-${section.name}`}
        placement="bottom"
        content={section.content}
        portalClassName="children-without-transition"
        {...section.props}
      >
        <section className="info__item">
          <Icon className="info__icon" icon={section.icon} iconSize="22" />
          <p className="info__label">{section.name}</p>
        </section>
      </Popover2>
    );
  });

  return (
    <section className="info">
      <section className="info__logo">
        <Link to="/home">
          <img src="https://storage.googleapis.com/locambio-admin/logos/main1.png" alt="Lo Cambio logo" className={isLargerThanMobile? "info__logo-desktop" : "info__logo-mobile" }/>
        </Link>
      </section>
      {isLargerThanMobile &&
      <section className="info__actions">{sectionElements}</section>
    }
    </section>
  );
};

export default withRouter(Info);
