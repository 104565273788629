// NotificationPageElement
import React from 'react';
import { dateShort } from '../../utilityFunctions';
import { Divider } from '@blueprintjs/core';
import AvatarUser from '../AvatarUser';
import { typeNotifications } from '../../const';
import UserName from '../userName/UserName';

class NotificationPageElement extends React.Component {

  render() {

    const {notification } = this.props;
    const typeNotification = typeNotifications[notification.type_notification_id]
    const { message, title } = typeNotification;
    const user = typeNotification.user(notification);
    const date = this.props.notification.created_at;
    const onClick = typeNotification.onClick?.(notification);

    return (
      <>
        <div className={`padding-05em ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
          <div className="headerUserCard">
            <div className="AvatarContainer">
              <AvatarUser user={user}/>
            </div>
            <div className="UserDetailsContainer marginBottom-05em">
              <div className="flex justify-between Line1">
                <UserName className ="" user={user} htmlTag='h4'></UserName>
                <div className="NotDate2">{dateShort(date)}</div>
              </div>
              <p className="bp3-form-helper-text">
                {message(notification)}
              </p>
            </div>
          </div>
        </div>
        <Divider />
      </>
    );
  }
}

export default NotificationPageElement;
