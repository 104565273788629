import React from 'react';
import './../styles/SuccessfulChangePublicationInfo.scss';
import UserInfo from '../../../../../components/userInfo/UserInfo';
import { productNameUrl } from '../../../../../utilityFunctions.js';
import { Link } from 'react-router-dom';

const SuccessfulChangePublicationInfo = (props) => {
  const { user, publication } = {
    ...props,
  };

  return (
    <>
      <main className="successful_change_publication_info">
        <UserInfo user={user} />
        <section className="publication-info">
          {publication ? (
            <>
              {publication.active ? (
                <Link
                  to={`/${productNameUrl(publication.title)}/${publication.id}`}
                  className="linked-title"
                >
                  <div className={`title title--${'standard'}`}> {/* TODO: change type_suscription name when the issue of publications premium has been done*/}
                    <p>{publication.title}</p>
                  </div>
                </Link>
              ) : (
                <div className="title title--inactive">
                  <p>{publication.title}</p>
                </div>
              )}
              <div className="img_box">
                <img
                  className="img"
                  src={publication.picture}
                  alt="publication image"
                />
              </div>
            </>
          ) : (
            <div className="img_box">
              <div className={'written-proposal'}>
                <p>Propuesta</p>
                <p>escrita</p>
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default SuccessfulChangePublicationInfo;