import React from 'react';
import { Icon } from '@blueprintjs/core';

import Item from '../item'
import textChats from '../../../../const/textChats';
import textNotifications from '../../../../const/textNotifications';

import './ItemList.scss'

const ItemList = ({elements, kind, hideMenu}) => {
  const buildElement = (element) => {
    const data = kind === 'chat' ? textChats(element) : textNotifications(element)
    return <Item data={data} key={`item-${element.id}`} hideMenu={hideMenu} />
  }

  const listProps =  {chat: {icon: 'chat', text: 'No hay mensajes'}, 
      notification: {icon: 'notifications', text: 'No hay Notificaciones'}
    }

  const nonIdealStateProps = listProps[kind]

  if (!elements.length) {
    return (<section className='item-list__empty-state' >
      <Icon className='item-list__empty-state-icon' icon={nonIdealStateProps.icon} iconSize={46} />
      <p className='item-list__empty-state-text'>{nonIdealStateProps.text}</p>
    </section>)
  }

  return (
    <section className='item-list'>
      {elements.map((current, index) => {
        return (index <= 20) && buildElement(current)
      })}
    </section>
  )
};

export default ItemList;