import React from 'react';
import { Position, Toaster } from '@blueprintjs/core';
import textNotifications from '../const/textNotifications';
import history from './history';

export const SimpleToast = Toaster.create({
  position: Position.BOTTOM_LEFT,
});

export const SimpleDangerToast = {
  show: function (options) {
    SimpleToast.show({
      ...options,
      intent: 'danger',
      icon: 'warning-sign',
    });
  },
};

export const CenteredToast = Toaster.create({
  position: Position.TOP,
});

function ToastMessage({ message, onClick, children, title, logo }) {
  return (
    <div className={`padding-05em ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      <div className="headerUserCard">
        {
          logo && <div className="AvatarContainer">
            <div className="AvatarImage">
              <img src={logo} alt="LogoAvatar" />
            </div>
          </div>
        }
        <div className="UserDetailsContainer">
          <h4>{title}</h4>
          <p className="margin0">
            {children || message}
          </p>
        </div>
      </div>
    </div>
  );
}

export const Toast = {
  show: function ({ intent = 'none', icon = 'none', ...options }) {
    CenteredToast.show({
      message: ToastMessage(options),
      intent,
      icon,
    });
  },
};

export function toastNotificationOptions(notification) {
  const handleClickItem = (url) => {
    if (!url) return;
    history.push(url);
  };
  const notificationOptions = textNotifications(notification)
  return {
    title: notificationOptions.userName,
    message: notificationOptions.title,
    logo: notificationOptions.user?.avatar,
    onClick: () => { handleClickItem(notificationOptions.url) },
  };
}

export function toastWithLink(notification) {

  const handleClickItem = (url) => {
    history.push(url);
  };
  return {
    message: notification.message,
    onClick: () => { handleClickItem(notification.url) },
  };
}
