import React, { useEffect, useState } from "react";
import { Alignment, Card, Switch } from "@blueprintjs/core";
import { Col, Row } from "react-grid-system";

import "./ProfileControlActivation.scss";
import { v1AuthAxios } from "../../utility/axios";
import { Toast } from "../../utility/toast";
import { logout } from '../../store/actions/index';
import { useDispatch } from "react-redux";
import { useGenericModal } from "../../hooks/useGenericModal/useGenericModal";

const ProfileControlActivation = ({user}) => {
  const { notifications_email, active } = { ...user };
  const [ notificationEmail, setNotificationEmail ] = useState(null);
  const [ activeAccount, setActiveAccount ] = useState(null);
  const { closeModal, buildModal } = useGenericModal();
  const dispatch = useDispatch();

  useEffect(() => {
    setNotificationEmail(notifications_email);
    setActiveAccount(active);
  }, []);

  const deactivationWarning = {
    content: (
      <div className="deactivation_warning_modal">
        <h3 className="deactivation_warning_modal__title">Advertencia</h3>
        Al desactivar su cuenta, todas sus publicaciones se inactivarán.
      </div>
    ),
    actions: [
      {
        intent: "accent",
        text: "Desactivar mi cuenta",
        onClick: () => handleDesactivateAccount(),
      },
    ],
    isOpen: true,
    canOutsideClickClose: false,
  };

  const toastData = {
    error: {
      message:'No se puede desactivar el perfil porque usted tiene transacciones activas',
      icon: 'warning-sign',
      intent: 'danger',
    },
    success: {
      message:'Se ha desactivado la cuenta'
    }
  };

  const handleEmailNotifications = async () => {
    return v1AuthAxios.patch('/user/set_email_notifications')
    .then((response) => {
      setNotificationEmail(response.data.notifications_email);
    })  
    .catch((err) => {
      console.error(err);
    })
  };

  const handleShowModal = () => {
    {buildModal(deactivationWarning)}
  };

  const handleDesactivateAccount = async () => {
    return v1AuthAxios.patch('/desactivate_account')
    .then((response) => {
      if (response.data.error) {
        return Toast.show(toastData.error);
      };
      closeModal();
      Toast.show(toastData.success)
      dispatch(logout());
    })  
    .catch((err) => {
      console.error(err);
    })
  };

  return (
    <Card className="profile-control-activation">
      <Row Card className="profile-control-activation__container">
        <Col xs={12} sm={12} md={12} lg={12}>
          <div className={"profile-control-activation__container__email"}>
            <h3>Notificaciones por correo</h3>
            <Switch
              large
              inline
              className="w-full font-bold"
              alignIndicator={Alignment.RIGHT}
              checked={notificationEmail}
              onChange={handleEmailNotifications}
              key="emails"
              label={`${
                notificationEmail ? "Desactivar" : "Activar"
              } las notificaciones por correo`}
            />
          </div>

          <div className={"profile-control-activation__container__account"}>
            <h3>Gestiòn de cuenta</h3>
            <Switch
              large
              inline
              className="w-full font-bold"
              alignIndicator={Alignment.RIGHT}
              checked={activeAccount}
              onChange={ handleShowModal }
              label={`${activeAccount ? "Desactivar" : "Activar"} mi cuenta`}
              key="account"
            ></Switch>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileControlActivation;
