import React from 'react';

import './LoCambioCreditos/LoCambioCreditos.scss';
import LinkInfoPages from '../components/linkInfoPages/';
import ServicesLC from './home/components/ServicesLC';
import Footer from './home/components/footer/Footer';
import ScrollToElement from '../components/scrollToElement/ScrollToElement';
import { locambioServices } from '../const';

const { LOCAMBIO_SERVICES_TYPES } = locambioServices;

const LoCambioCreditos = () => {
  const importance = (
    <div className="description-text">
      <p>
        En LoCambio.com estamos enfocados en fomentar el crecimiento de una
        comunidad transparente y segura para hacer negocios. Es por ello que
        deseamos brindar a nuestros usuarios verificados la oportunidad de ganar
        créditos dentro de nuestra plataforma y usarlos para disfrutar de
        nuestros servicios adicionales.
      </p>
    </div>
  );

  const firstTitle = (
    <div className="lo-cambio-creditos__main-title">
      <p className="lo-cambio-creditos__main-title--line-1 orange extra-bold">
        Gana Créditos
      </p>
      <p className="lo-cambio-creditos__main-title--line-2 extra-bold">
        por publicar
      </p>
      <p className="lo-cambio-creditos__main-title--line-3 purple extra-bold">
        productos y servicios
      </p>
    </div>
  );

  const bottomText = (
    <div className="lo-cambio-creditos__container--mid--bottom-text">
      <p>
        <p className="orange extra-bold">IMPORTANTE</p>
        <span className="purple">Los créditos estarán disponibles </span>
        <span>para su uso durante nuestra </span>
        <span className="purple">promoción de lanzamiento. </span>
        <p>
          <span className="purple extra-bold">Úsalos, </span>
          <span className="extra-bold">no dejes que se pierdan.</span>
        </p>
      </p>
    </div>
  );

  const logo = (
    <img
      className="lo-cambio-creditos__logo"
      alt="locambio creditos logo"
      src="https://storage.googleapis.com/locambio-admin/pages/premium/coins0.svg"
    />
  );

  const list = (
    <ul>
      <p>
        <span className="yellow extra-bold">Primero </span>
        <span className="extra-bold">lo Primero</span>
      </p>
      <ol>
        <li>Comienza creando una cuenta en LoCambio e inicia sesión.</li>
        <li>
          <span className="yellow">Solicita </span>nuestro servicio gratuito{' '}
          <span className="yellow extra-bold">Verificación ID </span>desde el
          chat de soporte.
        </li>
        <li>
          Una vez haya terminado el proceso correctamente tu usuario tendrá una
          insignia de "Verificado". ¡Y listo!
        </li>
        <li>
          <span className="yellow extra-bold">Empieza a recibir créditos </span>
          <span className="extra-bold">
            por publicar como usuario verificado.
          </span>
        </li>
      </ol>
      <p>
        <span className="yellow extra-bold">Cómo </span>
        <span className="extra-bold">ganar créditos</span>
      </p>
      <ol>
        <li>
          Recibe <span className="yellow">US$ 1 </span>por cada uno de tus
          primeros 10 productos publicados.
        </li>
        <li>
          A partir de 10 publicaciones,{' '}
          <span className="yellow">recibe US$ 2 por cada producto </span>que
          publiques.
        </li>
        <li>
          Recibe<span className="yellow"> US$ 10 </span> por cada servicio
          publicado.
        </li>
      </ol>
    </ul>
  );

  return (
    <>
      <ScrollToElement
        scroll={true}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      ></ScrollToElement>
      <div className="lo-cambio-creditos">
        <div className="section">
          <div className="lo-cambio-creditos__container">
            <div className="lo-cambio-creditos__container--top">
              <div className="lo-cambio-creditos__container--top--left">
                {logo}
                {firstTitle}
                {importance}
              </div>
              <div className="lo-cambio-creditos__container--top--right">
                <div className="lo-cambio-creditos__container--top--right--box">
                  {list}
                </div>
              </div>
            </div>
            {bottomText}
          </div>
          <div className="lo-cambio-creditos__slider-container">
            <ServicesLC
              services={[
                LOCAMBIO_SERVICES_TYPES.locambioVerificationId,
                LOCAMBIO_SERVICES_TYPES.locambioSeguro,
                LOCAMBIO_SERVICES_TYPES.locambioAds,
              ]}
            />
          </div>
        </div>
        <div className="lo-cambio-creditos__links">
          <LinkInfoPages active={'Ganar Créditos'} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoCambioCreditos;
