import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { config } from '../../../../system/Config';
import { getHeader } from '../../../../system/Sesion';
import { useCable } from '../../../../hooks/useCable';
import { CABLE_CHANNELS } from '../../../../utility/cableApp';
import { Toast, toastNotificationOptions } from '../../../../utility/toast';
import { orderArrayByDate } from '../../../../utilityFunctions';
import { getNotifications, updateNotifications, newNotification } from '../../../../store/actions';

import ElementsDropdown from '../elementsDropdown/ElementsDropdown';

const Notifications = () => {

  const dispatch = useDispatch();
  const notificationsData = useSelector(({ notification }) => [...notification.notifications]);

  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [lastNotificationId, setLastNotificationId] = useState(0);
  const [unreadNotifications, setUnreadNotifications] = useState('');

  const handleConnected = function () {
    setConnected(true);
  };

  const handleDisconnected = () => {
    setConnected(false);
  };

  const handleReceived = function (notification) {
    dispatch(newNotification({notification: notification}));
    dispatch(updateNotifications(notification));
    if (!notification.toast_notification) {
      Toast.show(toastNotificationOptions(notification));
      markToastAsshow(notification)
    }
  };

  const handleCatchUp = function (id) {
    this.perform('catch_up', { last_notification_id: id, user_id: localStorage.getItem('userId') });
  };

  const baseCableOptions = {
    channelOptions: { channel: CABLE_CHANNELS.notification, user_id: localStorage.userId },
    channelFunctions: {
      connected: handleConnected,
      received: handleReceived,
      catch_up: handleCatchUp,
      disconnected: handleDisconnected,
    },
  };

  const [subscription, setSubscription] = useCable(baseCableOptions);

  const markToastAsshow = (notification) => axios.put(
    `${config.URL_API}/notifications/${notification.id}`,
    { toast_notification: true },
    { headers: getHeader() },
  );

  const getLastNotificationId = (data) => {
    const notifications = orderArrayByDate(data, 'created_at');
    if (notifications[0]) {
      setLastNotificationId(notifications[0].id)
    }
  }

  const handleReconnected = useCallback(() => {
    if (!lastNotificationId || !connected) return;
    subscription.catch_up(lastNotificationId);
  }, [connected, lastNotificationId, subscription]);

  useEffect(() => {
    if (loading) dispatch(getNotifications());
  }, [dispatch, loading]);

  useEffect(() => {
    if (loading) setLoading(false);
    const unread = notificationsData.filter(notification => !notification.read).length;
    setUnreadNotifications(unread >= 10 ? '9+' : unread)
  }, [notificationsData, loading]);

  useEffect(() => {
    handleReconnected();
  }, [handleReconnected]);

  useEffect(() => {
    if (!connected && !!notificationsData[0]) return getLastNotificationId(notificationsData);
  }, [connected, notificationsData]);

  const notifications = orderArrayByDate(notificationsData, 'created_at');

  return (
    <ElementsDropdown elements={notifications} kind={'notification'} unread={unreadNotifications} />
  )
};

export default Notifications;