import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DisplayMenu from '../displayMenu';
import PanelStack from '../../../panelStack'
import InfoContent from '../infoContent/InfoContent';

import './BurgerMenu.scss';


const BurgerMenu = (props) => {
  const { latitude, longitude } = useSelector(({ geolocation }) => geolocation);

  const redirectToCategory = (elem) => {
    props.history.push(
      `/search_list_by_category/${elem.id}/${latitude}/${longitude}/category`
    );
    document.getElementById("close__menu").click();
  };

  const infoData = [
    {
      title: 'Información',
      items: [
        { label: 'Sobre LoCambio.com', url: '/about_locambio' },
        { label: 'Ganar créditos', url: '/locambio_creditos' },
        { label: 'Blog.LoCambio.com', url: 'https://blog.locambio.com/', external: true },
      ],
    },
    {
      title: 'Servicios',
      items: [
        { label: 'Verificación ID', url: '/locambio_verificacion_id' },
        { label: 'LoCambio.com Seguro', url: '/locambio_seguro' },
        { label: 'LoCambio.com Ads', url: '/locambio_ads' },
        { label: 'LoCambio.com Premium', url: '/locambio_premium' },
      ],
    },
    {
      title: 'Otros enlaces',
      items: [
        { label: 'Preguntas Frecuentes', url: '/faq' },
        { label: 'Consejos de Seguridad', url: '/consejos_de_seguridad' },
        { label: 'Políticas de Privacidad', url: '/politicas-de-privacidad' },
        { label: 'Términos y Condiciones', url: '/terminos-y-condiciones' },
        { label: 'Contáctanos', url: '/contactos' },
      ],
    },
  ];

  const burgerContent = [
    { name: 'Categorías', icon: 'properties', content: <PanelStack functionality={redirectToCategory} header/> },
    { name: 'Información', icon: 'help', content: <InfoContent accent data={infoData} header /> },
  ];

  const formatContent = (
    <div className="burger-menu">
      {burgerContent.map((section) => {
        return (
          <div className="burger-menu-section">
            <DisplayMenu deployer={section.icon} withText={section.name} color="black" header father>
              {section.content}
            </DisplayMenu>
          </div>
        );
      })}
    </div>
  );

  return <>{formatContent}</>;
};

export default withRouter(BurgerMenu);
