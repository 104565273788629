import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';

import { DRAWERS } from '../../../../utility/drawers';
import { updateDrawer } from '../../../../store/actions';

const DrawerButton = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(({ drawers }) => drawers[DRAWERS.chats]);

  const handleToggle = () => {
    dispatch(updateDrawer({ [DRAWERS.chats]: !isOpen }));
  };

  return (
    <div className="drawer-button" onClick={handleToggle}>
      <Icon className="drawer-button__icon" icon="chevron-right" />
    </div>
  );
};

export default DrawerButton;