import { config } from '../system/Config';
import { v1AuthAxios } from '../utility/axios';
import { Toast } from '../utility/toast';

export const getLocambioPromos = () => {
  return v1AuthAxios.get('/locambio_products?product=locambio_promos').then(({ data }) => data);
};

const handleError = (error, closeModal, message = "Lo sentimos, hubo un error, inténtelo mas tarde") => {
  console.error(error);
  closeModal(true);
  Toast.show(
    {
      show: true,
      message: message,
      icon: 'cross',
      intent: 'danger',
    }
  );
};

export const fetchGetProforma = async (paymentData, setLoading, closeModal, setDataSend) => {
  setLoading(true);
  let success;
  try {
    const response = await v1AuthAxios.post("/proforma", paymentData);
    success = true;
    openPaymentGateway(response.data.control);
  } catch (error) {
    handleError(error, closeModal, error.response.data.messages);
  } finally {
    setLoading(false);
    if (success) {
      setDataSend(true);
    }
  }
};

const openPaymentGateway = (affiliationCode) => {
  window.open(
    `${config.MEGASOFT_URL_BASE}${config.MEGASOFT_SECURE_VIEW_URL}${affiliationCode}`,
    '_blank'
  );

};
