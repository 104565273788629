import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImageTranslate, setSrc } from '../../store/actions/imageZoom';

import './ImageZoom.scss';
import BlurImage from '../blurImage/BlurImage';

const ImageZoom = ({ alt, src, ...props }) => {
  const dispatch = useDispatch();
  const { zoom, zoomWrapper } = useSelector(({ imageZoom }) => imageZoom);

  const handleMouseMove = (e) => {
    const { right, bottom, width, height } = e.target.getBoundingClientRect();
    const { naturalHeight, naturalWidth } = e.target;
    // Calc limits of the movement translate according with the real size of the image
    const limitX = (((naturalWidth * zoom) / 2) - (zoomWrapper.width / 2)) / zoom;
    const limitY = (((naturalHeight * zoom) / 2) - (zoomWrapper.height / 2)) / zoom;
    // Calc the movement translate in a range of: (-limitX, +limitX), (-limitY, +limitY)
    // according with the movement on the preview image
    const x = ((right - e.clientX) * limitX * 2 / width) - limitX;
    const y = ((bottom - e.clientY) * limitY * 2 / height) - limitY;
    dispatch(setImageTranslate(`${x}px, ${y}px`));
  };

  return (
    <div>
      <figure
        onMouseMove={handleMouseMove}
        onMouseOver={() => dispatch(setSrc(src))}
        onMouseLeave={() => dispatch(setSrc(''))}
      >
        <BlurImage image={src} title={alt} className={'blur-image-with-zoom'}/>
      </figure>
    </div>
  );
};

export default ImageZoom;
