import React from 'react';
import './ActivityWallCardDecorator.scss';
import { datelocambio } from '../../utilityFunctions';

const ActivityWallCardDecorator = (props) => {
  const { date, publicationType, ribbonColor, text,  titleColor} = { ...props };

  return (
    <section className="activity-successful-change-decorator">
        <div className={`ribbon ribbon__background--${ribbonColor} ribbon__color--${titleColor}`}>
        <p>{text}</p>
      </div>
      <section className="date">{datelocambio(date)} <div className="puplication_type">{publicationType}</div></section>
    </section>
  );
};

export default ActivityWallCardDecorator;