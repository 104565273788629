import React, { useEffect, useState } from "react";
import { useQueryString } from "../../hooks/useQueryString.js";
import { v1AuthAxios } from "../../utility/axios.js";
import { useParams } from "react-router-dom";

import ProfileControlActivation from "../../../src/components/profileControlActivation/ProfileControlActivation.js";
import ProfilePublications from "../../../src/components/profilePublications/ProfilePublications.js";
import ProfileUserData from "../../../src/components/profileUserData/ProfileUserData.js";
import ProfileAvatar from "../../../src/components/profileAvatar/ProfileAvatar.js";
import ProfileBanner from "../../../src/components/profileBanner/ProfileBanner.js";
import UpdateProfile from "../../components/updateProfile/UpdateProfile.js";
import Credit from "../../../src/components/credit/Credit.js";
import Campaigns from "../campaigns/Campaigns.js";

import { Spinner } from "@blueprintjs/core";
import "./UserProfile.scss";
import { Toast } from "../../utility/toast.js";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const { user } = useSelector(({ auth }) => auth);
  const currentUser = user;
  const { user_id } = useParams();
  const queryString = useQueryString();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [targetUser, setTargetUser] = useState(null);
  const [currentTab, setCurrentTab] = useState(queryString.get("tab") || "publications");
  const history = useHistory();

  useEffect(() => {
    getTargetUser().then((currentUser) => {
      setTargetUser(currentUser);
    });
  }, [user]);

    useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get("tab");
    setCurrentTab(tabParam || "publications");
  }, [location]);

  const handleSetTab = (newTab) => {
    setCurrentTab(newTab);
    queryString.set("tab", newTab);
    history.push({ search: queryString.toString() });
  };

  const canShow = (component) => {
    if (targetUser) {
      return targetUser?.isOwner ? component : null;
    }
  };

  const fetchUser = async (id) => {
    setLoading(true);
    try {
      const response = await v1AuthAxios.get(`/users/${id}`);
      setLoading(false);
      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toastError(error.response.data.error);
        history.push("/home");
      }
      setLoading(false);
    }
  };

  const toastError = async (message) => {
    const toast = {
      message: message,
      intent: 'danger',
    };
    Toast.show(toast);
  };

  const getTargetUser = async () => {
    const userId = user_id || currentUser.userId;
    const userData = await fetchUser(userId);
    const isOwner = !user_id; 
    return { isOwner, ...userData };
  };

  const userInfo = () => {
    if (targetUser) {
      return (
        <>
          <section className="user-profile__info">
            <ProfileAvatar 
              user={targetUser}
              editable={targetUser?.isOwner}
            />
            <ProfileUserData user={targetUser} handleSetTab={handleSetTab} currentTab={currentTab}/>
            {canShow(<Credit className="user-profile__credit" />)}
          </section>
          <section className="user-profile__tabs">
            {tabsComponentsMap[currentTab]}
          </section>
          <section className="user-profile__banner">
            <ProfileBanner />
          </section>
        </>
      );
    }
  };

  const tabsComponentsMap = {
    publications: <ProfilePublications currentUser={targetUser} />,
    campaigns: canShow(<Campaigns />),
    promotables: canShow(<Campaigns />),
    profile: canShow(<UpdateProfile user={targetUser} setLoading={setLoading} loading={loading}/>),
    account: canShow(<ProfileControlActivation user={targetUser} setLoading={setLoading} loading={loading} />),
  };

  const loadingOverlay = () => {
    return loading && (
      <div className="user-profile-loading__overlay">
        <Spinner className="user-profile-loading__spinner "/>
      </div>
    );
  };

  return (
    <>
      {loadingOverlay()}
      <main className="user-profile">
        {userInfo()}
      </main>
    </>

  );
};

export default UserProfile;