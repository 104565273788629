import { useEffect } from 'react';
import { checkAuthentication } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';

export const useLogout = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  useEffect(() => {
    const handleChangeStorage = () => {
      isAuthenticated && dispatch(checkAuthentication());
    };

    window.addEventListener('storage', handleChangeStorage);

    return () => {
      window.removeEventListener('storage', handleChangeStorage);
    };
  }, [isAuthenticated]);
};