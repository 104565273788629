import React, { Component } from 'react';
import './searchListResults/SearchListResults.scss';
import ActivityNewPublication from '../activitiesWall/activities/activityNewPublication/ActivityNewPublication';

class SearchListResults extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { publications, toChange } = this.props;

    return (
      <div className="SearchListResults">
        {
          publications.map((pub) => (
            <ActivityNewPublication pub={pub} />
          ))
        }
      </div>
    );

  }
}

export default SearchListResults;
