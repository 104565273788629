import React, { useRef, useState, useCallback } from 'react';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from 'react-google-maps';
import pointerMap from '../../../../Assets/System/pointerMap/pointerMap.png';

function Map(props) {
  return (
    <GoogleMap
      ref={props.refMap}
      defaultZoom={13}
      defaultCenter={props.currentPosition}
      center={props.currentPosition}
      onBoundsChanged={props.handleBoundsChanged}
      onDragEnd={props.onDragEnd}
      options={{ 
        gestureHandling: 'cooperative',
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
      }}
    >
      <Marker
        icon={{ url: pointerMap, scaledSize: { width: 44, height: 44 } }}
        name={'Current location'}
        position={props.center}
      />
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(Map));
