import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import { Callout } from "@blueprintjs/core";
import { connect } from "react-redux";

import "./userStatsInfo/UserStatsInfo.scss";


class UserStatsInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let user;
    if (this.props.user && this.props.user.active_publications ) {
      user = this.props.user;
    } else {
      user = {};
        user.activePublications = this.props.otherInfoUser.activePublications;
        user.change = this.props.otherInfoUser.change;
        user.reputation = this.props.otherInfoUser.reputation;
        user.registration_date = this.props.otherInfoUser.registration_date;
    }

    return (
      <>
        <div className={"marginTop-1em"}>
          <Row>
            <Col xs={6} sm={12} md={6} lg={6}>
              <Callout className="marginBottom-1em">
                <div className="UserChangeDetail">
                  <div className="PubCount">
                    {
                      <h2>{user.active_publications || user.activePublications}</h2>
                    }
                  </div>
                  <p className="bp3-form-helper-text margin0">Publicaciones Activas</p>
                </div>
              </Callout>
            </Col>
            <Col xs={6} sm={12} md={6} lg={6}>
              <Callout className="marginBottom-1em">
                <div className="UserChangeDetail">
                  <h2 className={"ChangeCount"}>{user.change}</h2>
                  <p className="bp3-form-helper-text margin0">Cambios Exitosos</p>
                </div>
              </Callout>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userFullName: state.auth.fullName,
    userSuscription: state.auth.suscription,
    userPublications: state.auth.publications,
    otherInfoUser: state.auth.otherInfo,
    followers: state.auth.followers,
    following: state.auth.following,
    pictureData: state.auth.imageProfile,
    pictureCover: state.auth.imageCover,
    userId: state.auth.userId,
    activePubs: state.activePublications,
  };
};

export default connect(mapStateToProps)(UserStatsInfo);
