import * as actionTypes from './actionTypes';

export const setPosition = (latitude, longitude, currency) => {
  localStorage.setItem('latitude', latitude);
  localStorage.setItem('longitude', longitude);
  return {
    type: actionTypes.SET_POSITION,
    latitude: latitude,
    longitude: longitude,
    currency: currency
  };
};

export const getPosition = () => {
  return (dispatch) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, setDefaultPosition);
    } else {
      dispatch(setPosition('10.4975585', '-66.8856196'));
    }
    function showPosition(position) {
      dispatch(
        setPosition(position.coords.latitude, position.coords.longitude)
      );
      localStorage.removeItem('clientDeniedGeolocation')
    }
    function setDefaultPosition(geolocationPositionError) {
      dispatch(setPosition('10.4975585', '-66.8856196'));
      localStorage.setItem('clientDeniedGeolocation', true)
    }
  };
};

export const checkPosition = () => {
  return (dispatch) => {
    if (localStorage.getItem('latitude') && localStorage.getItem('longitude')) {
      dispatch(
        setPosition(
          localStorage.getItem('latitude'),
          localStorage.getItem('longitude')
        )
      );
    }
  };
};
