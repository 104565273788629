import { SET_IMAGE_TRANSLATE, SET_SRC } from '../actions/actionTypes';

const initialState = {
  src: '',
  zoom: 3,
  translate: '0, 0',
  zoomWrapper: {
    width: 300,
    height: 300,
  },
};

export default function imageZoomReducer(state = initialState, action) {
  switch (action.type) {
    case SET_IMAGE_TRANSLATE: {
      return { ...state, translate: action.payload };
    }
    case SET_SRC: {
      return { ...state, src: action.payload };
    }
    default:
      return state;
  }
}
