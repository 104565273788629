import React from 'react';
import { Route } from 'react-router-dom';
import Login from './../pages/Login';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

const AuthRoute = ({isAuthenticated, component, exact, path}) =>{
  
  return(
    <Route path={path} component={isAuthenticated ? component : Login} exact={exact} />
  )

}


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default  withRouter(connect(mapStateToProps, null, null, { forwardRef: true })(AuthRoute));