import React, { useEffect, useState } from "react";
import axios from "axios";

import { Col, Row } from "react-grid-system";
import { useMediaQuery } from "react-responsive";
import {
  datelocambio,
  distanceFormat,
  checkWithCurrentUser,
  formatPrice,
  currencyFormat,
  getAllCategories,
  needOverlay,
  productNameUrl,
} from "../../utilityFunctions";
import { Button, Card, Divider, Elevation, Tag } from "@blueprintjs/core";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ShareButton from "../../components/shareButton";
import { getHeader } from "../../system/Sesion";
import { config } from "../../system/Config";
import { useModal } from "../campaigns/hooks/useModal";
import VisualizationsSummary from "../campaigns/popups/visualizationsSummary/VisualizationsSummary";
import SelectPlan from "../campaigns/popups/selectPlan/SelectPlan";
import { locambioPromos, modals as modalsConst, products } from "../../const";
import { paymentsPermissions } from "../../utility/payments";
import { useDispatch, useSelector } from "react-redux";
import { addPublicationsSaved, removePublicationSaved } from "../../store/actions";
import history from "../../utility/history";

const { PRODUCTS } = products;

export default function ProductDetailSection({
  pub,
  pubInTransaction,
  toChange,
  suscription,
  isAuthenticated,
  userId,
  wrapperImageZoom,
  imageSection,
}) {
  const { updateCampaignModal } = useModal();
  const [campaign, setCampaign] = useState();
  const [canCreateCampaign, setCanCreateCampaign] = useState();
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { notification } = useSelector(
    ({ newNotification }) => newNotification
  );
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const dispatch = useDispatch();
  const { publicationsSaved } = useSelector(({ publicationsSaved }) => publicationsSaved);

  const handleSavePublication = () => {
    if (userId && pub.id) {
      dispatch(addPublicationsSaved(userId, pub.id));
    }
  };

  const handleRemovePublication = () => {
    const publicationSaved = publicationsSaved.find((saved) => saved.publication_id === pub.id);
    dispatch(removePublicationSaved(publicationSaved.id, checkPublicationSaved()));
  };

  const checkPublicationSaved = () =>
    publicationsSaved.findIndex(
      ((publication) => publication.publication_id === pub.id)
    );

  const handleClickEdit = () => {
    history.push(`/edit/publication/${pub.id}`);
  };

  const { MODALS_TYPES } = modalsConst;

  const params = {
    picture: campaign?.publication?.picture,
    publicationTitle: campaign?.publication?.title,
    visualizations: {
      organics: campaign?.publication?.views,
      promotial: {
        count: campaign?.printer,
        total: campaign?.total_prints,
      },
      totals: campaign?.total_views,
    },
    start_date: campaign?.created_at,
  };

  const fetchCampaign = async () => {
    try {
      const response = await axios.get(
        `${config.URL_API}/v1/publications/${pub.id}?detail=campaign`,
        {
          headers: getHeader(),
        }
      );
      if (response?.data?.status === 'Activa') {
        setCampaign(response.data);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  //TODO move to const modals
  const modals = {
    [MODALS_TYPES.summary]: {
      title: "Resumen de la promoción",
      content: <VisualizationsSummary data={params} />,
      isOpen: true,
    },
    [MODALS_TYPES.selectPlan]: {
      title: "Promocionar Publicación",
      content: (
        <SelectPlan
          title={pub?.name}
          image={pub?.pictures?.[0]?.url}
          id={pub?.id}
          handleSelectPlan={fetchCampaign}
        />
      ),
      isOpen: true,
    },
  };

  const currency =
    pub.price === null
      ? "A convenir"
      : `${localStorage.getItem("currency")}${currencyFormat(pub.price)}`;

  const isOwner = checkWithCurrentUser(pub.owner.id);

  const handleCampaign = () =>
  campaign && !canCreateCampaign
    ? updateCampaignModal(modals[MODALS_TYPES.summary])
    : updateCampaignModal(modals[MODALS_TYPES.selectPlan]);

  const transactionButton = () => {
    return (
      <div className={`buttons ${isDesktop && 'buttons--desktop'}`}>
        <Button
          onClick={() => toChange(`/publication/${pub.id}/proposal/manager`)}
          className="bp3-intent-primary-btnLC BtnLCPrimary buttons__border buttons--first"
          fill={isDesktop ? false : true}
          disabled={
            !isAuthenticated || isOwner || pubInTransaction === "disabled"
          }
        >
          {pubInTransaction === "update"
            ? "Modificar Propuesta"
            : "Hacer Propuesta"}
        </Button>
        <Button
          className={`
            BtnLCPrimaryOutline buttons__border
            ${checkPublicationSaved() !== -1 ? '' : 'BtnLCPrimaryOutline--empty'}
            ${isDesktop && 'buttons--second'}
          `}
          icon="bookmark"
          onClick={
            checkPublicationSaved() !== -1
              ? handleRemovePublication
              : handleSavePublication
          }
        >
          {isDesktop && <div> {checkPublicationSaved() !== -1 ? 'Guardada' : 'Guardar'} </div>}
        </Button>
        <ShareButton />
      </div>
    );
  };

  const campaignButton = () =>
    <div className={`buttons ${isDesktop && 'buttons--desktop'} buttons__border`}>
      {campaign ? (
        <Button
          className="bp3-intent-primary-btnLC BtnLCPrimary buttons--first"
          fill={isDesktop ? false : true}
          onClick={handleCampaign}
        >
          Ver Promoción
        </Button>
      ) : (
        <Button
          className="bp3-intent-primary-btnLC BtnLCPrimary buttons__border buttons--first"
          fill={isDesktop ? false : true}
          onClick={handleCampaign}
        >
          Promocionar
        </Button>
      )}
      <Button
        className="bp3-intent-primary-btnLC BtnLCPrimary buttons__border buttons--second"
        fill={isDesktop ? false : true}
        onClick={handleClickEdit}
        icon="edit"
      >
        Editar
      </Button>
      <ShareButton />
    </div>

  const proposalButton = !needOverlay(pub.product_category, suscription) ? (
    <>{transactionButton()}</>
  ) : null;

  useEffect(() => {
    if (isOwner) fetchCampaign();
  }, []);

  useEffect(() => {
    setCanCreateCampaign(
      paymentsPermissions(PRODUCTS.locambioPromo, campaign?.proforma_status)
        .createCampaign
    );
  }, [campaign]);

  useEffect(() => {
    if (
      notification?.type_notification_id === "payment_gateway_status" ||
      (notification?.type_notification_id === "payment_with_credit" &&
        // TODO: remove the previous line when the credit notifications have been created
        isOwner)
    ) {
      fetchCampaign();
      setCanCreateCampaign(
        paymentsPermissions(PRODUCTS.locambioPromo, campaign?.proforma_status)
          .createCampaign
      );
    }
  }, [notification]);

  return (
    <>
      <div className="product-detail-container">
        <div className="product-detail-section">
          <Col xs={12} lg={6}>
            {imageSection}
          </Col>
          <Col xs={12} lg={6}>
            {wrapperImageZoom}
            <div className="InfoProductContainer">
              <Card
                elevation={Elevation.ONE}
                className="mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0"
              >
                <div className="publicationName">
                  <div className="titleWithButton">
                    <div>
                      <h1 className="mb-4 sm:mb-4 md:mb-2 lg:mb-2 xl:mb-2">
                        {pub.name}
                      </h1>
                      <Tag className="marginBottom-1em" minimal>
                        ID: <strong>{pub.id}</strong>
                      </Tag>
                    </div>
                  </div>
                  <Divider className="marginTop-05em marginBottom-1em" />
                  <Row>
                    <Col xs={6}>
                      <div className="infoDetailPublication">
                        <p className="bp3-form-helper-text mt-2 sm:mb-2">
                          Disponible para
                        </p>
                        <Tag className="tagYellow">Cambio</Tag>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="infoDetailPublication">
                        <p className="bp3-form-helper-text mt-2 mb-2">
                          {pub.publication_type === "Producto"
                            ? "Valor del artículo"
                            : "Valor del servicio"}
                        </p>
                        <Tag className="tag">
                          <strong>{currency}</strong>
                        </Tag>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="infoDetailPublication">
                        <p className="bp3-form-helper-text mt-4 mb-2">
                          Se encuentra a
                        </p>
                        <Tag className="tag">
                          {`${formatPrice(
                            distanceFormat(pub.distance).split(" km")[0]
                          ).toString()} km`}
                        </Tag>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="infoDetailPublication">
                        <p className="bp3-form-helper-text mt-4 mb-2">
                          Fecha de creación
                        </p>
                        <Tag className="tag">
                          {datelocambio(pub.publish_date)}
                        </Tag>
                      </div>
                    </Col>
                    {pub.accept_changes && (
                      <Col xs={6}>
                        <div className="infoDetailPublication">
                          <p className="bp3-form-helper-text mt-2 mb-2">
                            Esta publicación acepta propuestas de
                          </p>
                          <Tag className="tag">
                            <strong>Cualquier tipo </strong>
                          </Tag>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </Card>
            </div>
            <Card elevation={Elevation.ONE} className="marginTop-1em">
              <Row>
                <Col xs={6}>
                  <div className="infoDetailPublication">
                    <p className="bp3-form-helper-text mt-0 mb-2">Lugar</p>
                    <Tag className="tag">{pub.location}</Tag>
                  </div>
                </Col>
                {pub.publication_type === "Producto" ? (
                  <Col xs={6}>
                    <div className="infoDetailPublication">
                      <p className="bp3-form-helper-text mb-2">
                        Condición del artículo
                      </p>
                      {pub.product_status === "Nuevo" ? (
                        <Tag className="tag">Nuevo</Tag>
                      ) : (
                        <React.Fragment>
                          <Tag className="tag mr-2">Usado</Tag>
                          <Tag
                            className="tag mt-2 sm:mt-2 md:mt-0 lg:mt-0 xl:mt-0"
                            minimal
                          >
                            {pub.condition}
                          </Tag>
                        </React.Fragment>
                      )}
                    </div>
                  </Col>
                ) : null}
                <Col xs={12}>
                  <div className="infoDetailPublication">
                    <p className="bp3-form-helper-text mt-4 mb-2">Categoría</p>
                    <Breadcrumb
                      className="mb-2 mr-2"
                      data={getAllCategories(pub.product_category)}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            {pub.publications_fields.length > 0 ? (
              <Card
                elevation={Elevation.ONE}
                className="marginTop-1em marginBottom-1em"
              >
                <Row>
                  {pub.publications_fields.map((field, index) => {
                    return (
                      <Col key={index} xs={6}>
                        <p className="bp3-form-helper-text mb-2">
                          {field.field_category.label}
                        </p>
                        <h4 className="atrrCaracteristicas mb-4">
                          {() => {
                            const fieldText = field.field_category.settings
                              .options
                              ? field.field_category.settings.options.find(
                                  (option) =>
                                    option &&
                                    option.value &&
                                    option.value.toString() === field &&
                                    field.value &&
                                    field.value.toString()
                                )
                              : field.value;

                            return (fieldText && fieldText.text) || field.value;
                          }}
                        </h4>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            ) : (
              <br />
            )}
          </Col>
        </div>
        <div className="product-detail-section first-in-mobile">
          <Col xs={12} lg={6}></Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12}>
                <div className="marginBottom-1em">
                  {isOwner ? campaignButton() : proposalButton}
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </>
  );
}
