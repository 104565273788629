import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import "./ChangeProposalMap.scss";
import { config } from "../../../../system/Config";

const ChangeProposalMap = ({ latitude, longitude, google }) => {

  return (
    <section className="change-proposal-map">
      <div className="map-wrapper">
        <Map
          google={google}
          disableDefaultUI={true}
          draggable={true}
          center={{ lat: latitude, lng: longitude }}
          zoom={14}
          zoomControl={true}
        >
          <Marker position={{ lat: latitude, lng: longitude }} />
        </Map>
      </div>
    </section>
  );
};

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: null
})(ChangeProposalMap);