import React from 'react';
import PropTypes from 'prop-types';
import { Text as BPText } from '@blueprintjs/core';
import { cleanClassName } from '../../utilityFunctions';

import './Text.scss';

const Text = ({ children, className, color, ...textProps }) => {

  return (
    <BPText {...textProps} className={`text text--${color} ${cleanClassName(className)}`}>
      {children}
    </BPText>
  );
};

Text.propTypes = {
  ...BPText.propTypes,
  children: PropTypes.element || PropTypes.array,
  color: PropTypes.oneOf(['accent', 'primary', 'green', 'blue', 'none', 'white']),
};

Text.defaultProps = {
  color: 'none',
};

export default Text;