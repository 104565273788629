import { Text } from '@blueprintjs/core';
import React, { memo, useEffect, useState } from 'react';

import Map from '../map/Map';
import ModalImage from '../../../../components/ModalImage';
import ScrollToElement from '../../../../components/scrollToElement/ScrollToElement';
import Loader from '../../../../components/loader';

import { datelocambio } from '../../../../utilityFunctions';

const Message = ({ message, skeleton, isLastMessage }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [loadedMedia, setLoadedMedia] = useState(false);
  const [scroll, setScroll] = useState(false);

  const currentUserId = parseInt(localStorage.getItem('userId'));
  const className = currentUserId === message.user_from ? 'message--sent' : 'message--received';
  const skeletonClass = skeleton ? 'bp3-skeleton' : '';
  const attachment_preview = message.picture_url instanceof Blob ?
    URL.createObjectURL(message.picture_url) :
    message.picture_url;


  useEffect(() => {
    if (!isLastMessage) return;
    if (message.picture_url && !loadedMedia) return;
    setScroll(true);
  }, [isLastMessage, loadedMedia, message.picture_url]);

  const messageComponent = (
    <section className={`message children-without-transition ${className}`}>
      {
        Boolean(!message.id && message.picture_url) && (
          <div className="message__loader">
            <Loader size={20} />
          </div>
        )
      }
      {
        Boolean(message.message) &&
        <Text tagName="p" className={`message__text ${skeletonClass}`}>
          {message.message}
        </Text>
      }
      {
        message.picture_url && (
          <>
            <img
              className="message__image"
              src={attachment_preview}
              alt="message"
              onClick={() => setShowImageModal(true)}
              onLoad={() => setLoadedMedia(true)}
            />
            <ModalImage
              src={attachment_preview}
              visible={showImageModal}
              close={() => setShowImageModal(false)}
            />
          </>
        )
      }
      {
        message.latitude && message.longitude && (
          <Map
            openMapOnClick
            latitude={message.latitude}
            longitude={message.longitude}
            className={`message__map ${skeletonClass}`}
          />
        )
      }
      <Text className={`message__date ${skeletonClass}`}>
        <time>{datelocambio(message.created_at)}</time>
      </Text>
    </section>
  );

  if (!isLastMessage) {
    return messageComponent;
  }

  return (
    <ScrollToElement
      scroll={scroll}
      options={{ behavior: 'auto', block: 'center' }}
    >
      {messageComponent}
    </ScrollToElement>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.skeleton === nextProps.skeleton &&
    prevProps.isLastMessage === nextProps.isLastMessage &&
    prevProps.message.id === nextProps.message.id
  );
};

export default memo(Message, areEqual);