import React, { useEffect } from 'react';
import { Drawer as BPDrawer } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';

import { addDrawer, removeDrawer, updateDrawer } from '../../store/actions';

const Drawer = ({ drawerKey, defaultIsOpen, onClose, children, ...props }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(({ drawers }) => drawers[drawerKey]);

  useEffect(() => {
    dispatch(addDrawer({ [drawerKey]: defaultIsOpen }));

    return () => dispatch(removeDrawer(drawerKey));
  }, []);

  const handleClose = () => {
    onClose?.();
    dispatch(updateDrawer({ [drawerKey]: false }));
  };

  return (
    <BPDrawer {...props} isOpen={isOpen} onClose={handleClose}>
      {children}
    </BPDrawer>
  );
};

export default Drawer;