import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { productNameUrl } from '../../utilityFunctions';
import { removeModal } from '../../store/actions/modals';
import PageWithTitle from '../../components/pageWithTitle';
import { removePublicationSaved, updateModal } from '../../store/actions';
import './SavedPublications.scss';
import SavedPublicationsListPagination from './SavedPublicationsListPagination';
import ActivityNewPublication from '../activitiesWall/activities/activityNewPublication/ActivityNewPublication';
import Card from '../../components/card/Card';

const SavedPublications = () => {
  const publications = useSelector(({ publicationsSaved }) => publicationsSaved.publicationsSaved);
  const dispatch = useDispatch();
  const [pubId, setPubId] = useState(null);
  const [pubIndex, setPubIndex] = useState(null);
  const [publicationsPrint, setPublicationsPrint] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    totalPublications: 0,
    showMax: 10,
  })

  useEffect(() => {
    const validPubIndex = Boolean(pubIndex) || pubIndex === 0;
    const validPubId = Boolean(pubId) || pubId === 0;
    validPubIndex && validPubId && toggleModal(true);
  }, [pubIndex, pubId, publications]);

  const publicationActions = (status) => {
    const array = [
      {
        intent: 'danger',
        text: 'Eliminar',
        className: "actions__buttons",
        onClick: ({ publication, index }) => {
          switchShowRemoveConf(publication.savedPublicationId, index);
        }
      }
    ];
    if (status === 'Activo') {
      array.unshift(
        {
          intent: 'accent',
          text: 'Ver Detalles',
          href: ({ publication }) => `/${productNameUrl(publication.title)}/${publication.id}`,
        }
      )
    }
    return array;
  };

  const modalActions = [
    {
      intent: 'accent',
      text: 'Si',
      onClick: () => handleModalClick(pubId, pubIndex),
    },
    {
      intent: 'danger',
      text: 'No',
      onClick: () => toggleModal(false),
    },
  ];

  const toggleModal = (isOpen) => {
    if (isOpen) return openModal();
    closeModal();
  };

  const openModal = () => dispatch(updateModal({
    title: '¿Deseas dejar de guardar esta publicación?',
    isOpen: true,
    actions: modalActions,
  }));

  const closeModal = () => dispatch(removeModal());

  const switchShowRemoveConf = (id, index) => {
    toggleModal(true);
    setPubId(id);
    setPubIndex(index);
  };

  const handleModalClick = (id, index) => {
    dispatch(removePublicationSaved(id, index));
    switchShowRemoveConf();
    toggleModal(false);
  };

  const handlePageChange = (pageNumber) => {
    let start = ((pagination.perPage * pageNumber) - pagination.perPage);
    start = start < 0 ? 0 : start;
    const end = pagination.perPage * pageNumber
    setPublicationsPrint(publications.slice((start), (end)));
    const updatedPagination = { page: pageNumber };
    setPagination(pagination => ({
      ...pagination,
      ...updatedPagination
    }));
  };

  useEffect(() => {
    setPublicationsPrint(publications.slice(0, pagination.perPage));
    const updatedPagination = {
      totalPublications: publications.length
    }
    setPagination(pagination => ({
      ...pagination,
      ...updatedPagination
    }));
  }, [publications],
  );

  // TODO: Handle loading and empty publications array
  // TODO: Check useless renders and performance

  return (
    <PageWithTitle title="Publicaciones guardadas">
      <section className="saved-publications">
        {publications.length > 0 ? (
          publicationsPrint.map(({ publication, id }, index) => (

            <>
              <ActivityNewPublication
                actions={publicationActions(publication.publication_status)}
                pub={{ ...publication, savedPublicationId: id }}
                index={index}
                key={`${publication.title}-${index}`}
                isPublicationSaved
              />
            </>

          ))
        ) : (
          <Card className={'not-publications-saveds'}>No tiene publicaciones guardadas</Card>
        )
        }
      </section>
      {publicationsPrint.length > 0 ? (
          < SavedPublicationsListPagination
            handlePageChange={handlePageChange}
            totalPublications={pagination.totalPublications}
            activePage={pagination.page}
            perPage={pagination.perPage}
            showMax={pagination.showMax}
          />
        ) : (null)
      }
    </PageWithTitle>
  );
};

export default SavedPublications;