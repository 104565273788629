import React from 'react';
import propTypes from 'prop-types';
import { Map as GoogleMap, Marker, GoogleApiWrapper } from 'google-maps-react';
import { config } from '../../../../system/Config';

const Map = (props) => {
  const onClick = () => {
    props.onClick?.();
    if (props.openMapOnClick) {
      window.open(`https://maps.google.com?q=${props.latitude},${props.longitude}`);
    }
  };

  const position = {
    lat: props.latitude,
    lng: props.longitude,
  };

  return (
    <section className={props.className} onClick={onClick}>
      <GoogleMap
        initialCenter={position}
        zoom={16}
        google={props.google}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        panControl={false}
        rotateControl={false}
        fullscreenControl={false}
        disableDefaultUI={true}
        draggable={false}
        loaded={false}
        onReady={props.onReady}
      >
        <Marker position={position} />
      </GoogleMap>
    </section>
  )
}

Map.propTypes = {
  latitude: propTypes.oneOfType([
    propTypes.number,
    propTypes.string,
  ]).isRequired,
  longitude: propTypes.oneOfType([
    propTypes.number,
    propTypes.string,
  ]).isRequired,
  className: propTypes.string,
  openMapOnClick: propTypes.bool,
  onClick: propTypes.func,
  onReady: propTypes.func,
}

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: () => <div className="message__map" />,
})(Map);

