import React from "react";
import { Dialog, Icon } from "@blueprintjs/core";
import "./PopUp.scss";

const PopUp = ({ children, displayModal, hideModal }) => {
  return (
    displayModal && (
      <div className="pop-up">
        <div className="pop-up__container">
          <Dialog
            isOpen={displayModal}
            canOutsideClickClose
            onClose={hideModal}
          >
            <button className="pop-up__icon__container" onClick={hideModal}>
              <Icon icon="cross" iconSize={16} className="pop-up__cross" />
            </button>
            {children}
          </Dialog>
        </div>
      </div>
    )
  );
};

export default PopUp;
