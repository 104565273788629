import React from 'react';
import './Layer.scss';
import PropTypes from 'prop-types';
import { Text } from '@blueprintjs/core';
import Actions from '../actions/Actions';

const Layer = ({ title, tagName, actions, publication, index }) => {

  return (
    <main className="layer">
      <section className="layer__title">
        <Text className="layer__title" tagName={tagName}>{title}</Text>
      </section>
      {<section className="layer__actions">
        <Actions actions={actions} publication={publication} index={index} />
      </section>}
    </main>
  );
};

Layer.propTypes = {
  title: PropTypes.string,
  tagName: PropTypes.string,
};

export default Layer;