import React from 'react';
import './initialLoading/InitialLoading.scss';
import loadingLogo from './../Assets/Logo/loadingLogo.png';

const InitialLoading = props => (
  <>
    <div className={'InitialLoading'}>
      <img src={loadingLogo} />
    </div>
  </>
)

export default InitialLoading;