import React from 'react';
import { Tag, Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';

const ArticlesOfInterest = props => {
  const { articles, tagsHides, setTag } = props;
  return (
    <>
      {(articles || []).map((article) => (
        <Tag key={article.id} className={`marginRight-05em marginBottom-05 ${setTag ? 'tagViolet' : 'tagYellow'} `} ><p>{article.name}</p></Tag>
      ))}
      {tagsHides && <Tag className={`marginRight-05em marginBottom-05 ${setTag ? 'tagViolet' : 'tagYellow'} `} ><Icon icon="more" /></Tag>}
    </>
  )
}

ArticlesOfInterest.propTypes = {
  articles: PropTypes.array.isRequired
}

export default ArticlesOfInterest;
