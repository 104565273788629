import { Divider, Icon, Tag } from "@blueprintjs/core";
import React, { useCallback, useMemo, useState } from "react";

import Button from "../../../../components/button";
import Confirmation, { CONFIRMATION_TYPES } from "../confirmation/Confirmation";
import SecurityChangeModal from "../securityChangeModal/SecurityChangeModal";
import { useSelector } from "react-redux";
import history from "../../../../utility/history";
import { useGenericModal } from "../../../../hooks/useGenericModal/useGenericModal";
import BookingRequest from "../../../../components/bookingRequest/BookingRequest";
import { paymentsPermissions } from "../../../../utility/payments";
import { products } from "../../../../const";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { PRODUCTS } = products;

const BUTTONS_TYPES = {
  [CONFIRMATION_TYPES.acceptProposal]: {
    text: "Aceptar",
    className: "mr-4 ",
    intent: "success",
    icon: "tick",
  },
  [CONFIRMATION_TYPES.rejectProposal]: {
    text: "Rechazar",
    className: "mr-4 ",
    intent: "danger",
    icon: "cross",
  },
  [CONFIRMATION_TYPES.editProposal]: {
    text: "Editar",
    className: "mr-4 ",
    intent: "accent",
    icon: "edit",
  },
  [CONFIRMATION_TYPES.cancelProposal]: {
    text: "Cancelar",
    className: "mr-4 ",
    intent: "danger",
    icon: "cross",
  },
  [CONFIRMATION_TYPES.cancelChange]: {
    text: "Cancelar",
    className: "mr-4 ",
    intent: "danger",
    icon: "cross",
  },
  [CONFIRMATION_TYPES.confirmChange]: {
    text: "Calificar",
    className: "mr-4 ",
    intent: "success",
    icon: "tick",
  },
};

const PanelButtons = ({
  status,
  isOwner,
  transactionId,
  loading,
  linkToEdit,
}) => {
  const location = useLocation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState("");
  const [securityChangeOpen, setSecurityChangeOpen] = useState(false);
  const securityChange = useSelector(
    ({ securityChange }) => securityChange.securityChangeRequest
  );
  const { buildModal, closeModal } = useGenericModal();

  const bookingRequest = {
    content: <BookingRequest {...securityChange} />,
    isOpen: true,
    className: "booking-request",
  };

  const openModal = useCallback((type) => {
    setIsConfirmationOpen(true);
    setConfirmationType(type);
  }, []);

  const handleLinkTo = (link) => {
    history.push(link);
  };

  const buttonsContent = useMemo(
    () => ({
      active: isOwner
        ? [CONFIRMATION_TYPES.acceptProposal, CONFIRMATION_TYPES.rejectProposal]
        : [CONFIRMATION_TYPES.editProposal, CONFIRMATION_TYPES.cancelProposal],
      accepted: [
        CONFIRMATION_TYPES.confirmChange,
        CONFIRMATION_TYPES.cancelChange,
      ],
      confirmed_interested: isOwner ? [CONFIRMATION_TYPES.confirmChange] : [],
      confirmed_owner: isOwner ? [] : [CONFIRMATION_TYPES.confirmChange],
    }),
    [isOwner]
  );

  const canGenerateSecurityChange = useMemo(() => {
    if (!securityChange) return true;

    const permissions = paymentsPermissions(
      PRODUCTS.locambioSeguro,
      securityChange.proforma_status
    );
    return !securityChange.active && permissions.generateSecurityChange;
  }, [securityChange]);

  const handleSecurityChangeOnClick = () => {
    canGenerateSecurityChange
      ? setSecurityChangeOpen(true)
      : buildModal(bookingRequest);
  };

  const getSecurityChangeIcon = () => {
    return canGenerateSecurityChange ? "unlock" : "lock";
  };

  const inProgress = location.search === "?tab=in-progress";

  const lcsValidation = () => {

    const textToShow = {
      request: {status: false,  textToShow: "Reservar LoCambio Seguro"},
      await:   {status: true,   textToShow: "Solicitud en proceso"},
      success: {status: false,  textToShow: "Ver solicitud LCS"},
    };
  
    if (!(securityChange && (securityChange.active || securityChange.proforma_status === null))) {
      return textToShow.request;
    }
  
    if (!securityChange.active && securityChange.proforma_status === null) {
      return textToShow.await;
    }
  
    return textToShow.success;
  };

  return (
    <section className="panel-buttons">
      <SecurityChangeModal
        isOwner={isOwner}
        isOpen={securityChangeOpen}
        setIsOpen={setSecurityChangeOpen}
        transactionId={transactionId}
      />
      <Confirmation
        isOwner={isOwner}
        type={confirmationType}
        isOpen={isConfirmationOpen}
        setIsOpen={setIsConfirmationOpen}
        status={status}
        transactionId={transactionId}
      />
      {buttonsContent[status]?.length ? (
        buttonsContent[status].map((button) => (
          <Button
            bold
            key={`confirmation-${button}`}
            onClick={() =>
              button === "editProposal"
                ? handleLinkTo(linkToEdit)
                : openModal(button)
            }
            {...BUTTONS_TYPES[button]}
            className={`${
              (status === "confirmed_interested" ||
                status === "confirmed_owner") &&
              "full-width"
            }`}
          />
        ))
      ) : (
        <div className={"waiting_confirmation"}>
          <Icon
            className={"waiting_confirmation__icon"}
            icon="info-sign"
            size={22}
          />
          <div className={"waiting_confirmation__text"}>
            Esperando calificación...
          </div>
        </div>
      )}
      {inProgress
        ? status !== "confirmed_interested" &&
          status !== "confirmed_owner" && (
            <Button
              bold
              loading={loading}
              onClick={handleSecurityChangeOnClick}
              text={lcsValidation().textToShow}
              disabled={lcsValidation().status}
              icon={getSecurityChangeIcon()}
              className={`ml-4 security_change children-without-transition ${
                status !== "confirmed_interested" &&
                status !== "confirmed_owner" &&
                "full-width"
              }`}
              intent="black"
            />
          )
        : null}
    </section>
  );
};

export default PanelButtons;
