import React from 'react';
import { useHistory } from 'react-router-dom';
import { handleClosePopover } from '../../../../utilityFunctions';
import AvatarUser from '../../../AvatarUser';
import './Item.scss'

const Item = ({data, hideMenu}) => {
  const history = useHistory();
  const handleClickItem = (e, data) => {
    if (data.action) data.action()
    if (hideMenu) hideMenu()
    handleClosePopover(e);
    if (!data.url) return
    history.push(data.url);
  };
  const template =   
      <section 
        className='item' 
        onClick={(e)=>{handleClickItem(e, data)}}
      >
        <aside className='item__box-avatar' > 
          <AvatarUser user={data.user} background/>
        </aside>
        <section className='item__middle-container'>
          <div className='item__info'>
            <h4>{data.userName}</h4>
          </div>
          {data.title && <div className='item__text'> {data.title} </div>}
          <div className='item__publication'> {data.footer} </div>
        </section>
        <aside className='item__unread'> 
          <div className={!data.read ? 'item__unread-bubble' : ''}>
          </div>
        </aside>
      </section>

  return (
    <>
      {template}  
    </>
  )
};

export default Item;
