import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '../progressBar';
import { cleanClassName } from '../../../../utilityFunctions';

import './ProgressBarWrapper.scss';

const ProgressBarWrapper = ({ progressBars, areCreditsComplete }) => {
  const completeClassName = areCreditsComplete ? 'progress-bar-wrapper--complete' : '';
  const progressBarsElements = progressBars.map(({ className, ...progressBarProps }) => (
    <ProgressBar
      key={progressBarProps.title}
      className={`progress-bar-wrapper__bar ${cleanClassName(className)}`}
      barWrapperClassName="progress-bar-wrapper__bar-wrapper"
      titleClassName="progress-bar-wrapper__bar-title"
      labelClassName="progress-bar-wrapper__bar-label"
      {...progressBarProps}
    />
  ));

  return (
    <section className={`progress-bar-wrapper ${completeClassName}`}>
      {progressBarsElements}
    </section>
  );
};

ProgressBarWrapper.propTypes = {
  progressBars: PropTypes.arrayOf(ProgressBar.propTypes),

};

export default ProgressBarWrapper;