import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Position } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import UserMenu from '../userMenu';
import DisplayMenu from '../displayMenu';
import Notifications from '../notifications';
import Messages from '../messages';
import BurgerMenu from '../burgerMenu';
import { Tooltip2 } from '@blueprintjs/popover2';

const Panel = () => {
  const { isAuthenticated } = useSelector(({ auth }) => auth);
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const loggedInTags = [
    { icon: 'home', url: '/', title: 'Inicio' },
    { icon: 'page-layout', url: '/wall', title: 'Muro' },
    { section: 'notifications' }, //COMPONENTE DEL PROFE
    { section: 'chat' }, //COMPONENTE DEL PROFE
    { userBubble: true, content: <UserMenu />, title: 'Perfil' },
  ];  

  const loggedOutTags = [
    { icon: 'new-person', message: 'Registrarse', url: '/registration' },
    { icon: 'user', message: 'Iniciar sesión', url: '/login' },
  ];

  const displayPanels = isAuthenticated
    ? loggedInTags.map((tag) => {
        return tag.url ? (
          <Tooltip2 content={tag.title} position={Position.TOP}>
            <Link
              to={tag.url}
              className={
                isLargerThanMobile ? 'panel-header-navbar__icons-icon' : 'panel-header-navbar-display__mobile'
              }
              >
              <Icon icon={tag.icon} iconSize={22} />
            </Link>
          </Tooltip2>
        ) : (
          <div
            className={
              isLargerThanMobile ? 'panel-header-navbar__icons-icon' : 'panel-header-navbar-display__mobile'
            }
          >
            {tag.userBubble ? (
              <Tooltip2 content={tag.title} position={Position.TOP}>
                <DisplayMenu
                  className="panel-header-navbar__icons-icon"
                  deployer={tag.icon}
                  isUserBubble={tag.userBubble}
                  >
                  {tag.content}{' '}
                </DisplayMenu>
              </Tooltip2>
            ) : (
              tag.section === "notifications" ? <Notifications /> : <Messages />
            )}
          </div>
        );
      })

    : loggedOutTags.map((tag, index) => {
        return isLargerThanMobile ? (
          <>
            <Link to={tag.url} className="panel-header-navbar__icons-logged--Out">
              <Icon className="panel-header-navbar__icons-icon" icon={tag.icon} />
              <p>{tag.message}</p>
            </Link>
            {index === 0 && <span className="panel-header-navbar__tags-bar" />}
          </>
        ) : (
          <>
            <Link to={tag.url} className="panel-header-navbar__icons-logged--Out">
              <Icon className="panel-header-navbar__icons-icon" icon={tag.icon} />
            </Link>
            {index === 0 && <span className="panel-header-navbar__tags-bar--mobile" />}
          </>
        );
      });

  const authPanels = (
    <>
      <section
        className={isLargerThanMobile ? 'panel-header-navbar__icons' : 'panel-header-navbar__icons-mobile'}
      >
        {displayPanels}
      </section>
      <section
        className={
          isLargerThanMobile ? 'panel-header-navbar__profile' : 'panel-header-navbar__profile-mobile'
        }
      ></section>
    </>
  );

  return (
    <section className={isLargerThanMobile ? 'panel-header-navbar' : 'panel-header-navbar--mobile'}>
      {authPanels}
      <section className="panel-header-navbar__buttons">
        {isLargerThanMobile ? (
          <div className="panel-header-navbar__buttons-button">
            <Link to={isAuthenticated ? "/new_publication" : "/login"}>
              <Icon className="panel-header-navbar__buttons-icon" icon="plus" iconSize={22} />
              <p>Publicar</p>
            </Link>
          </div>
        ) : (
          <section className="panel-header-navbar__mobile">
            <DisplayMenu deployer="menu" color="black" father>
              <BurgerMenu />
            </DisplayMenu>
            <Link to="/new_publication">
              <Icon
                className="panel-header-navbar__mobile-button"
                icon="plus"
                iconSize={22}
              />
            </Link>
          </section>
        )}
      </section>
    </section>
  );
};

export default Panel;
