import React from 'react';
import { Dialog, Text } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../button';
import { updateModal } from '../../store/actions';
import { cleanClassName } from '../../utilityFunctions';
import { removeModal } from '../../store/actions/modals';

import './Modal.scss';

const Modal = () => {
  const {
    title,
    content,
    actions,
    resource,
    onClose,
    className,
    headerClassName,
    bodyClassName,
    footerClassName,
    children,
    isOpen,
    canOutsideClickClose,
  } = useSelector(({ modals }) => modals);

  const dispatch = useDispatch();
  const functionParam = resource || title;

  const closeModal = () => dispatch(removeModal());
  const openModal = () => dispatch(updateModal({ isOpen }));

  const toggleModal = (isOpen) => {
    if (isOpen) return openModal();
    closeModal();
  };

  const bodySpaceClassName = actions?.length ? 'modal__body--with-space' : '';

  const header = title && (
    <header className={`${cleanClassName(headerClassName)} modal__header`}>
      <Text
        tagName="h2"
        title={title}
      >
        {title}
      </Text>
    </header>
  );

  const body = content && (
    <section className={`${cleanClassName(bodyClassName)} ${bodySpaceClassName} modal__body`}>
      {content}
    </section>
  );

  const footer = Boolean(actions?.length) && (
    <footer className={`${footerClassName} modal__footer`}>
      {
        actions.map((action, i) => {
          return (
            <Button
              bold
              key={`${action.text}-${i}`}
              onClick={() => action.onClick?.(functionParam)}
              {...action}
            />
          );
        })
      }
    </footer>
  );

  return (
    <Dialog
      className={`${cleanClassName(className)} modal`}
      isOpen={isOpen}
      onClose={() => {
        onClose?.(functionParam);
        toggleModal(false);
      }}
      canOutsideClickClose={canOutsideClickClose}
    >
      {header}
      {body}
      {children?.(toggleModal) || children}
      {footer}
    </Dialog>
  );
};

export default Modal;