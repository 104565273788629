import React from "react";

export default ({steps})=>{
  const stepElem = steps.map((elem, index )=>{
    return <div key={`step-${index}`} className='step'>
      <p className="number">{index+1}</p>
      <p className="text">{elem.text}</p>
      <img src={elem.img} />
    </div>
  })
  return (<div>
    <h4 className="steps_title">CÓMO <span>FUNCIONA</span></h4>
    <div className="row steps_row">
      {stepElem}
    </div>
  </div>)
}