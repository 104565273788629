import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setScrollTo } from '../../store/actions';
import './ScrollToElement.scss';

const ScrollToElement = ({ children, name, scroll, options, highlight }) => {
  // TODO: Refactor this component to a hook
  const dispatch = useDispatch();
  const elementToScroll = useRef(null);
  const { scrollTo } = useSelector(({ scroll }) => scroll);
  const [showHighlight, setShowHighlight] = useState(false);

  const canScroll = scroll || name === scrollTo;
  const className = highlight && showHighlight ? 'highlight' : undefined;

  useEffect(() => {
    if (canScroll) scrollToElement();
  }, [scroll, name, scrollTo, children]);

  const scrollToElement = () => {
    if (elementToScroll?.current) {
      elementToScroll.current.scrollIntoView(options);
      setShowHighlight(true);
      if (scrollTo) {
        dispatch(setScrollTo(''));
      }
    }
  };

  return (
    <div className={className} ref={elementToScroll}>
      {children}
    </div>
  );
};

const customValidator = (props, propName, componentName, type) => {
  if (!props.name && typeof props.scroll !== 'boolean') {
    return new Error(`${componentName} should have a 'name' or a 'scroll' property`);
  }

  if (props[propName]) {
    PropTypes.checkPropTypes(
      {
        [propName]: type,
      },
      { [propName]: props[propName] },
      'prop',
      componentName,
    );

  }
};

ScrollToElement.propTypes = {
  children: PropTypes.element.isRequired,
  name: (props, propName, componentName) => customValidator(
    props,
    propName,
    componentName,
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ),
  scroll: (props, propName, componentName) => customValidator(
    props,
    propName,
    componentName,
    PropTypes.bool,
  ),
  options: PropTypes.shape({
    behavior: PropTypes.oneOf(['auto', 'smooth']),
    block: PropTypes.oneOf(['start', 'center', 'end', 'nearest']),
    inline: PropTypes.oneOf(['start', 'center', 'end', 'nearest']),
  }),
  highlight: PropTypes.bool,
};

ScrollToElement.defaultProps = {
  options: { behavior: 'smooth', block: 'center' },
};

export default ScrollToElement;
