import axios from 'axios';
import { config } from '../../system/Config';
import { Toast } from '../../utility/toast';
import * as actionTypes from './actionTypes';
import { getHeader } from '../../system/Sesion';

const lat = localStorage.getItem("latitude");
const lng = localStorage.getItem("longitude");

export const setPublicationsSaved = (publicationsSaved, single = true) => {
  const publication = Array.isArray(publicationsSaved)
    ? publicationsSaved
    : [publicationsSaved];
  return {
    type: actionTypes.SET_PUBLICATIONS_SAVED,
    data: { publication, single },
  };
};

export const removePublicationSaved = (id, index) => {
  axios.delete(`${config.URL_API}/publications_saveds/${id}`)
    .then(() => (
      Toast.show({
        intent: 'success',
        icon: 'tick',
        message: 'Has eliminado la publicación guardada',
      })
    ))
    .catch((error) => {
      Toast.show({
        intent: 'danger',
        icon: 'error',
        message: 'La publicación no ha podido eliminarse de la lista.',
      });
      console.log(error);
    });

  return {
    type: actionTypes.REMOVE_PUBLICATIONS_SAVED,
    data: index,
  };
};

export const getPublicationsSaved = (userId) => {
  return (dispatch) => {
    axios
      .get(`${config.URL_API}/publications_saveds?user_id=${userId}&latitude=${lat}&longitude=${lng}`)
      .then((response) => dispatch(setPublicationsSaved(response.data, false)));
  };
};

export const addPublicationsSaved = (userId, publicationId) => {
  const dataAPI = {
    user_id: userId,
    publication_id: publicationId,
    latitude: lat,
    longitude: lng,
  };
  return (dispatch) => {
    axios
      .post(`${config.URL_API}/publications_saveds`, dataAPI, {
        headers: getHeader(),
      })
      .then(({ data }) => {
        const publicationSaved = data;
        dispatch(setPublicationsSaved(publicationSaved));
        Toast.show({
          intent: 'success',
          icon: 'tick',
          message: `Publicación guardada correctamente`,
        });
      })
      .catch((error) => {
        Toast.show({
          intent: 'danger',
          icon: 'error',
          message: 'La publicación se no ha podido Guardar.',
        });
        console.log(error);
      });
  };
};
