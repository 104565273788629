import { Icon, IconSize } from '@blueprintjs/core';
import React from 'react';
import { Link } from 'react-router-dom';
import AvatarUser from '../AvatarUser';
import UserName from '../userName/UserName';
import './UserInfo.scss';

const UserInfo = (props) => {
  const { user } = { ...props };
  return (
    <section className="user-info">
      <div className="avatar">
        <AvatarUser user={user} />
      </div>
      <div className="user">
        <section className="name">
          <Link to={`/profile/${user.id}`}>
            <UserName user={user} htmlTag='p' className={'verified'} size={props.size}/>
          </Link>
        </section>
        <section className="ranking">
          <Icon className="IconGold" iconSize={16} icon={'star'} />
          <p className="ranking--text">{user?.reputation?.toFixed(1)}</p>
        </section>
      </div>
    </section>
  );
};

export default UserInfo;
