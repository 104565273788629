import React from 'react';
import { useSelector } from 'react-redux';

import './WrapperImageZoom.scss';

const WrapperImageZoom = () => {
  const {
    src,
    zoom,
    translate,
    zoomWrapper,
  } = useSelector(({ imageZoom }) => imageZoom);

  if (!src) return null;

  return (
    <div className="wrapperImageZoom">
      <div className="imageZoom" style={zoomWrapper}>
        <img src={src} alt="zoom" style={{ transform: `scale(${zoom}) translate(${translate})` }} />
      </div>
    </div>
  );
};

export default WrapperImageZoom;
