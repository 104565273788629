import React, { useEffect, useState } from 'react';

import ActivityNewPublication from '../../pages/activitiesWall/activities/activityNewPublication/ActivityNewPublication';
import SearchListPagination from '../../pages/searchList/SearchListPagination';
import { useQueryString } from '../../hooks/useQueryString';
import { authAxios, v1AuthAxios } from '../../utility/axios';

import './ProfilePublicationsList.scss';
import { productNameUrl } from '../../utilityFunctions';
import { Tag } from '@blueprintjs/core';
import Loader from '../loader';
import Card from '../card/Card';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { Toast } from '../../utility/toast';
import { useGenericModal } from '../../hooks/useGenericModal/useGenericModal';
import ScrollToElement from '../scrollToElement/ScrollToElement';

const ProfilePublicationsList = ({targetUser}) => {
  const perPage = 10;

  const queryString = useQueryString();
  const { user_id } = useParams();
  const urlPage = queryString.get("page")
  const urlKind = queryString.get("kind")

  const [complementUrl, setComplementUrl] = useState( user_id ? `&user_id=${user_id}` : '' );
  const [publications, setPublications] = useState([]);
  const [totalPublicationsActive, setTotalPublicationsActive] = useState(0);
  const [totalPublicationsInactive, setTotalPublicationsInactive] = useState(0);
  const [totalItems, setTotalItems] = useState({});
  const [currentPage, setCurrentPage] = useState(urlPage || 1);
  const [loading, setLoading] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [buttonKind, setButtonKind] = useState(urlKind || 'active');


  const [currentData, setCurrentData] = useState();
  
  const history = useHistory();
  const location = useRouteMatch();

  const fetchUpdatePublication = (id, status) => {
    return authAxios.post(`/publications/update_status/${id}`,
      { publication_status: status }
    );
  };

  const displayToast = (message, icon, intent) => {
    Toast.show({ show: true,
      message,
      icon,
      intent
    })};

  const { buildModal, closeModal } = useGenericModal();

  const title = (
    <div className="info-modal__title">
      <span className="info-modal__title--font"> Desactivar Publicación</span>
    </div>
  )

  const content = (
      <p className="info-modal__content">
        <span className="info-modal__content--bold">Si desactivas tu publicación</span>&nbsp;
        sera removida de las transacciones en las que se encuentre,&nbsp;
        <span className="info-modal__content--bold">esta acción no se puede deshacer.</span>
      </p>
  );

  const modalActions = (id, status) => [
    {
      intent: 'accent',
      text: 'Entendido',
      onClick: () => {
        updatePublication(id, status);
        closeModal()
      },
    },
  ];

  const warningModal = (id, status) => ({
    title: title,
    content: content,
    isOpen: true,
    actions: modalActions(id, status),
    className: 'info-modal'
  });

  const showModal = (id, status) => {
    buildModal(warningModal(id, status))
  }
  
  const updatePublication = (id, status) => {
    fetchUpdatePublication(id, status).then( () => {
      getPublications(currentPage, buttonKind)
      const message = status === 1 ? "desactivada" : "activada"
      displayToast(`La publicacion ha sido ${message} exitosamente`, 'tick', 'success');
    }).catch((err)=> displayToast('Lo sentimos, hubo un error', 'tick', 'danger'));
  };

  const updateUrl = (prevPage, newPage, kind = 'active') => {
    if (kind !== buttonKind || prevPage !== newPage) {
      const currentKind = kind ? `&kind=${kind}` : ''
      history.push({
        pathname: location.pathname,
        search: `?tab=publications&page=${newPage}${currentKind}`,
      });
    }  
  }
  
  const handlePageChange = (page) => {
    const kind = queryString.get("kind")
    const prevPage = queryString.get("page")
    getPublications(page)  
    setScroll(true);
    updateUrl(prevPage, page, kind);
  };
  
  const getPublications = (page, kind = "active") =>
    v1AuthAxios.get(`/publications?page=${page}&per_page=${perPage}&kind=${kind}${complementUrl}`)
      .then(({ data }) => {
        setCurrentData(data);
      })

  useEffect(() => {
    setLoading(true);
    getPublications(currentPage, buttonKind)
  }, []);

  useEffect(() => {
    if (currentData) {
      const { data, page, total, active, inactive } = currentData;
      setPublications(data);
      setCurrentPage(page);
      setTotalItems(total);
      setTotalPublicationsActive(active);
      setTotalPublicationsInactive(inactive);
      setLoading(false);
      setScroll(false);
    }
  }, [currentData]);

  const publicationActions = (pub) => {
    const array = [];
    if (pub.publication_status === 'Activo') {
      array.unshift(
        {
          intent: 'danger',
          text: 'Desactivar',
          className: "actions__buttons",
          onClick: () => {
            showModal(pub.id, 1)
          }
        }
      )
      array.unshift(
        {
          intent: 'accent',
          text: 'Ver Detalles',
          href: () => `/${productNameUrl(pub.title)}/${pub.id}`,
        }
      )
    } else {
      array.unshift(
        {
          intent: 'accent',
          text: 'Activar',
          className: "actions__buttons",
          onClick: () => {
            updatePublication(pub.id, 0)
          }
        }
      )
    }
    return !user_id ? array : null;
  };

  let handleClickTab = (kind) => {
    if (kind !== buttonKind) {
      setButtonKind(kind);
      getPublications(1, kind);
      updateUrl(null, 1, kind);
    }
  }

  return (
    loading ? 
    <Card className="profile-publications">
      <Loader />
    </Card> :
    <section className="profile-publications-list">
      {!user_id &&
        <ul className='list'>
        <li
          key={'button-active'}
          className={`list-button ${buttonKind === 'active' ? 'list-button--active' : ''}`}
          onClick={() => handleClickTab('active')}
        >
          <Tag minimal={true} className="tag-slate-blue marginRight-1em">
            {totalPublicationsActive}
          </Tag>
          Activas
        </li>

        <li
          key={'button-active'}
          className={`list-button ${buttonKind === 'inactive' ? 'list-button--active' : ''}`}
          onClick={() => handleClickTab('inactive')}
        >
          <Tag minimal={true} className="tag-slate-blue marginRight-1em">
            {totalPublicationsInactive}
          </Tag>
          Inactivas
        </li>
      </ul>}

      <ScrollToElement
        scroll={scroll}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      ></ScrollToElement>
      {
        publications.map((pub) => (
          <ActivityNewPublication key={`item-publication-${buttonKind}-${pub.id}`} pub={pub} actions={publicationActions(pub)} className={"item-publication"} isOwner={!user_id} />
        ))
      }

      <SearchListPagination
        handlePageChange={handlePageChange}
        totalPublications={totalItems}
        activePage={currentPage}
        perPage={perPage}
        showMax={perPage}
      />
    </section>
  );
};

export default ProfilePublicationsList;