import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import { Card, Elevation } from '@blueprintjs/core';
import axios from 'axios';
import { GoogleApiWrapper } from 'google-maps-react';
import { config } from './../../../system/Config';
import { getHeader } from './../../../system/Sesion';
import DetailProductSkeleton from './../../../components/DetailProductSkeleton';
import PublicationOverviewMainSection from './PublicationOverviewMainSection';
import history from '../../../utility/history';
import './../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './PublicationOverview.scss';

const PublicationOverview = ({ match, location }) => {

  const [pub, setPub] = useState(null);
  const [isOpenReport, setIsOpenReport] = useState(false); // esta abierto el modal para Denunciar?
  const [editorState, setEditorState] = useState('');

  useEffect(() => {
    setPub(null);
    getPub(match.params.id);
  }, [location])

  useEffect(() => {
    getPub(match.params.id);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [])

  const fetchAPIGet = (id) =>
    axios.get(
      `${config.URL_API}/publications/${id}?latitude=${localStorage.getItem('latitude',
      )}&longitude=${localStorage.getItem('longitude',
      )}&user_id=${localStorage.getItem('userId')}`,
      { headers: getHeader() },
    );

  const getPub = async (id) => {
    try {
      const response = await fetchAPIGet(id);
      if (response.data.publication.publication_status !== 'Activo') history.push('/home');
      setPub(response.data.publication);
      setEditorState(response.data.publication.editor_web);
    } catch (err) {
      console.log(err);
    }
  };

  const toChange = (link) => history.push(link);
  if (pub === null) return <DetailProductSkeleton />;

  return (
    <>
      <div className="publicationOverview sm:p-2 md:p-4 lg:p-6 xl:p-8">
        <div>

          <Row>
            <Col xl={2}></Col>
            <Col xs={12} md={12} lg={12} xl={8}>
              <PublicationOverviewMainSection
                pub={pub}
                toChange={toChange}
              />
              <Card elevation={Elevation.ONE} className="marginTop-1em">
                <h3>Descripción de la publicación</h3>
                <p className='mt-4 mb-4'>
                  {pub.editor_web ? editorState : 'El usuario no incluyó una descripción'}
                </p>
              </Card>
            </Col>
            <Col xl={2}></Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: DetailProductSkeleton,
})(withRouter(PublicationOverview));
