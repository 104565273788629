import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Dialog } from '@blueprintjs/core';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { useSelector } from 'react-redux';
import { config } from '../../../../system/Config';
import './MapAttachment.scss';

const MapAttachment = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const geolocation = useSelector(({ geolocation }) => geolocation);
  const [location, setLocation] = useState({ lat: geolocation.latitude, lng: geolocation.longitude });

  const open = () => {
    setIsOpen(true);
  };

  const handleMapClick = (options, map, { latLng }) => {
    setLocation({ lat: latLng.lat(), lng: latLng.lng() });
  };

  const handleSelect = () => {
    props.handler({ latitude: location.lat, longitude: location.lng });
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        title="Selecciona una ubicación"
        onClose={() => setIsOpen(false)}
        style={{ width: 'auto' }}
      >
        <section className="map-attachment">
          <Map
            google={props.google}
            mapTypeControl={false}
            streetViewControl={false}
            fullscreenControl={false}
            initialCenter={location}
            onClick={handleMapClick}
          >
            <Marker position={location} />
          </Map>
        </section>
        <footer className="flex justify-center p-1">
          <button
            type="submit"
            className="bp3-button bp3-intent-primary"
            onClick={handleSelect}
          >
            Seleccionar
          </button>
        </footer>
      </Dialog>
      {
        props.children?.({ open })
      }
    </>

  )
}

MapAttachment.propTypes = {
  handler: propTypes.func.isRequired,
  children: propTypes.func,
}

export default GoogleApiWrapper({
  apiKey: config.KEY_GOOGLE_MAPS,
  LoadingContainer: null,
})(MapAttachment);
