import React from "react";

import "./PriceTag.scss";

const PriceTag = ({ price, className }) => {

  const currency = localStorage.getItem("currency");

  return (
    <section className={`price-tag ${className}`}>
      <p>{`${currency} ${price}`}</p>
    </section>
  );
};

export default PriceTag;