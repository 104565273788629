import React, { Component } from 'react';
import { Button, Card, Checkbox, Divider, FormGroup, InputGroup, Icon } from '@blueprintjs/core';
import { Col, Container, Row } from 'react-grid-system';
import { getHeader } from '../system/Sesion';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from './../store/actions/index';
import { emailValidation, spaceValidation } from './../utilityFunctions';
import { config } from './../system/Config';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Footer from './../components/Footer';
import Recaptcha from 'reaptcha';
import MediaQuery from 'react-responsive';
import { Toast } from '../utility/toast';
import './login/Login.scss';
import message from './chat/components/message';
import LoginDropdown from '../components/loginDropdown';
import GoogleButton from '../components/googleButton'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: '',
      },
      viewPassword: false,
      verifyCaptcha: false,
      captchaReady: false,
      renderedCaptcha: false,
      loading: false,
    };
  }
  
  onLoad = () => this.setState({ captchaReady: true });

  /**
   * It fires when the captcha is verified.
   */
  onVerify = () => this.setState({ verifyCaptcha: true });

  /**
   * It fires when an error occurs in the captcha.
   */
  onErrorCaptcha = () => {
    Toast.show({ message: 'Por favor verifica tu conexion a internet', intent: 'warning', icon: 'warning-sign' });
  }

  componentDidUpdate() {
    /**
     * If in the reducer this property is "true" the user is redirected to the home.
     */
    if (this.props.loggued) {
      this.props.onSetLoggued(false);
      this.props.history.push(`/wall`);
    }


    if (this.props.incompleteRegister === true) {
      this.props.onLogout();
      this.props.onAuthIncomplete(
        false,
        this.props.omniauth_user,
        true
      );
      this.props.history.push(`/registration`);
    }

    /**
     * To avoid an error with the captcha library.
     */
    if (this.state.captchaReady && !this.state.renderedCaptcha) {
      this.setState({ renderedCaptcha: true }, () =>
        this.captcha.renderExplicitly(),
      );
    }
  }

  /**
   * Validations of form
   * @returns {Boolean}
   */
  validations = () => {
    let valid = true;
    if (!spaceValidation(this.state.formData.email)) {
      Toast.show({ intent: 'warning', message: 'Por favor escribe el email', icon: 'warning-sign' });
      valid = false;
    } else if (!emailValidation(this.state.formData.email)) {
      Toast.show({ intent: 'warning', message: 'Por favor escriba un email valido', icon: 'warning-sign' });
      valid = false;
    } else if (!spaceValidation(this.state.formData.password)) {
      Toast.show({ intent: 'warning', message: 'Por favor escribe la contraseña', icon: 'warning-sign' });
      valid = false;
    }
    if (!valid) this.setState({ loading: false });
    return valid;
  };

  /**
   * Handle el change of the inputs.
   */
  inputChangeHandler = (event) => {
    const { value, id } = event.target;
    const formData = { ...this.state.formData };
    formData[id] = value;
    this.setState({ formData });
  };

  /**
   * It fires when the Login button is pressed.
   */
  login = () => {
    this.setState({ loading: true })
    if (this.validations()) {
      // If the captcha has not been completed, we do NOT make the API request.
      if (!this.state.verifyCaptcha && !process.env.REACT_APP_LOCAL_ENV) {
        this.setState({ loading: false })
        Toast.show({ message: 'Debe completar el Captcha', intent: 'warning', icon: 'warning-sign' });
        return;
      }
      const error = this.props.onAuth(
        this.state.formData.email,
        this.state.formData.password,
      );
      if (error) {
        error.then((error) => {
          if (error) {
            this.setState({ loading: false })
          }
        })
      }

    }
  };


  /**
   * Facebook response
   * @param {Object} response
   */
  responseFacebook = (response) =>
    this.props.onAuthWithFacebook(response.accessToken, response.email);

  /**
   * If the pressed key is enter then login is done.
   * @param {Object} event
   * @returns {Function}
   */
  loginKey = (event) => {
    if (event.which === 13) {
      this.login();
    }
  };

  displayPassword = () => {
    this.setState({
      viewPassword: !this.state.viewPassword
    })
  }


  render() {
    const { formData } = this.state;
    const { email, password } = formData;

    function onClickHandler(response){
      console.log("Sign in with Google button clicked...", response)
    }

    const options = [
      'one', 'two', 'three'
    ];
    const defaultOption = options[0];

    return (
      <div>
        <section className="loginSection">
          <div className="gradientBackground bg-animation z-0"></div>
          <div className='z-10 relative'>
            <div> &nbsp;</div>
            <h1
              className="loginSection__title"
            >
              ¡Inicia ahora!
            </h1>
            <section className={`loginSection__slogan-bar-orange`}>
              <p >
                <span>Un negocio te espera &nbsp;</span>
              </p>
              <p> a la vuelta de la esquina!</p>
            </section>
          </div>
          <div className="loginContainer">
            <Container>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} >
                  <Card className='p-10'>
                    <div className='p-5 pt-4 sm:p-0 sm:pt-0'>
                      <FormGroup>
                        <InputGroup
                          className="mt-2"
                          placeholder="Correo Electrónico *"
                          value={email}
                          onChange={this.inputChangeHandler}
                          id="email"
                        />
                      </FormGroup>
                      <FormGroup>
                        <InputGroup
                          className="mt-3"
                          placeholder="Contraseña *"
                          onKeyPress={this.loginKey}
                          value={password}
                          onChange={this.inputChangeHandler}
                          id="password"
                          type={this.state.viewPassword ? "text" : "password"}
                        />
                        <Icon className="login__password-icon" onClick={this.displayPassword} icon={this.state.viewPassword ? "eye-open" : "eye-off"} size={22} />

                      </FormGroup>
                      <Row className="mt-6 mb-3">
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <div className="flex justify-between">
                            <Checkbox
                              className="ml-2 extra-bold"
                              checked={this.state.isEnabled}
                              label="Recordarme"
                              onChange={this.handleEnabledChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <MediaQuery query="(min-device-width: 348px)">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="w-min">
                              <Recaptcha
                                className="recaptcha mb-6"
                                ref={(e) => (this.captcha = e)}
                                sitekey={config.KEY_CAPTCHA}
                                onLoad={this.onLoad}
                                onVerify={this.onVerify}
                                onError={this.onErrorCaptcha}
                                explicit
                              />
                            </div>
                          </Col>
                        </MediaQuery>
                        <MediaQuery query="(max-device-width: 347px)">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Recaptcha
                              className="recaptcha marginBottom-1em"
                              ref={(e) => (this.captcha = e)}
                              sitekey={config.KEY_CAPTCHA}
                              onLoad={this.onLoad}
                              onVerify={this.onVerify}
                              onError={this.onErrorCaptcha}
                              explicit
                              size={'compact'}
                            />
                          </Col>
                        </MediaQuery>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Button
                            className="bp3-intent-primary-btnLC BtnLCPrimary"
                            rightIcon="log-in"
                            large="true"
                            loading={this.state.loading}
                            fill="true"
                            onClick={this.login}
                          >
                            Entrar
                          </Button>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <FacebookLogin
                            appId={config.KEY_FACEBOOK_LOGIN}
                            autoLoad={false}
                            cssClass='custom-class-facebook'
                            callback={this.responseFacebook}
                            fields="name,email,picture"
                            isProcessing={false}
                            reAuthenticate={true}
                            textButton="Continuar con Facebook"
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <GoogleButton callback={this.props.onAuthWithGoogle}></GoogleButton>
                        </Col>
                      </Row>
                      <Row><LoginDropdown /></Row>
                      <Row className="mt-6">
                        <Col className="flex justify-center" xs={12} sm={12} md={12} lg={12}>
                          ¿No tienes cuenta? &nbsp;
                          <Link className="BtnLCPrimaryLink" to="registration"> Regístrate</Link>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Footer />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    loggued: state.auth.loggued,
    incompleteRegister: state.auth.incompleteRegister,
    omniauth_user: state.auth.omniauth_user,
    omniauth: state.auth.omniauth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onSetLoggued: (value) => dispatch(actions.setLoggued(value)),
    onAuthIncomplete: (incompleteRegister, omniauth_user, omniauth) =>
      dispatch(
        actions.authIncomplete(
          incompleteRegister,
          omniauth_user,
          omniauth
        )
      ),
    onAuthWithGoogle: (tokenId) => dispatch(actions.authWithGoogle(tokenId)),
    onLogout: () => dispatch(actions.logout()),
    onAuthWithFacebook: (tokenId, email) =>
      dispatch(actions.authWithFacebook(tokenId, email)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
