import React, { useState } from 'react';
import { Row } from 'react-grid-system';
import { Card, Dialog, Elevation, Tag } from '@blueprintjs/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { isNewPublication } from '../../../utilityFunctions';
import PublicationOverviewSection from './PublicationOverviewSection';
import ProductSwiper, { swiperParams } from '../../../components/productSwiper/ProductSwiper';
import WrapperImageZoom from '../../../components/wrapperImageZoom/WrapperImageZoom';
import './PublicationOverviewMainSection.scss';

const modalStyle = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  width: '60%',
};

export default function PublicationOverviewMainSection({
  pub,
  toChange,
}) {
  const [isOpenCarouselModal, setIsOpenCarouselModal] = useState(false);

  const handleOpenCarouselModal = () => {
    setIsOpenCarouselModal(true);
  };

  const imageSection = (
    <>
      <div className="positionNewTag">
        {isNewPublication(pub.publish_date) ? (
          <Tag className="tagNew">Nueva publicación</Tag>
        ) : null}
      </div>
      <ProductSwiper
        zoom
        onClick={handleOpenCarouselModal}
        imgClass="zoom-in"
        pictures={pub.pictures}
      />
    </>
  )

  return (
    <Card elevation={Elevation.ONE}>
      <Dialog
        isOpen={isOpenCarouselModal}
        onClose={() => setIsOpenCarouselModal(false)}
        style={modalStyle}
      >
        <Swiper {...swiperParams}>
          {pub.pictures.map((image) => (
            <SwiperSlide>
              <img
                className="product-modal-img"
                alt="product"
                src={image?.url || image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Dialog>
      <Row>
        <PublicationOverviewSection
          pub={pub}
          toChange={toChange}
          wrapperImageZoom={<WrapperImageZoom />}
          imageSection={imageSection}
        />
      </Row>
    </Card>
  );
}
