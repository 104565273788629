import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { updateModal } from '../../../store/actions';
import { removeModal } from '../../../store/actions/modals';

import '../components/campaignModal/CampaignModal.scss';

export const useModal = () => {
  const dispatch = useDispatch();

  const baseCampaignModal = (content) => ({
    className: 'campaign-modal',
    headerClassName: 'campaign-modal__header',
    content: (
      <>
        <Icon className="campaign-modal__close" iconSize="25" icon="cross" onClick={removeCampaignModal} />
        {content}
      </>
    ),
  });

  const updateCampaignModal = (compactModalProps) => {
    const finalProps = {
      ...compactModalProps,
      ...baseCampaignModal(compactModalProps.content),
    };

    dispatch(updateModal(finalProps));
  };

  const removeCampaignModal = () => {
    dispatch(removeModal());
  };

  return { updateCampaignModal, removeCampaignModal };
};