import * as actionTypes from './../actions/actionTypes';
import { notificationStatus, typeRequests } from 'const';

const initialState = {
  pendingProposalChange: [],
  acceptedProposalChange: [],
  rejectedProposalChange: [],
  newChange: [],
  changeRequestFinalize: [],
  changeRejected: [],
  changeFinalized: [],
  currentTransaction: '',
};

const setRequests = (state, action) => {
  // Deep clone
  const { proposal_changes, changes } = action.data;
  const updateState = JSON.parse(JSON.stringify(state));
  const pendingProposalChange = [];
  const acceptedProposalChange = [];
  const rejectedProposalChange = [];
  const newChange = [];
  const changeRequestFinalize = [];
  const changeRejected = [];
  const changeFinalized = [];

  proposal_changes.map(proposal => {
    switch (proposal.status) {
      case notificationStatus.PENDING:
        pendingProposalChange.push(proposal);
        break;
      case notificationStatus.APPROVED:
        acceptedProposalChange.push(proposal);
        break;
      case notificationStatus.REJECTED:
        rejectedProposalChange.push(proposal);
        break;
      default:
        return null;
    }
  });

  [...changes.trans_interested, ...changes.trans_pub_owner].forEach(item => {
    item.chat = item['chat'] || {};
    if (!item.complete && !item.rejected && !item.request_finalize) {
      newChange.push(item);
    } else if (item.request_finalize && !item.rejected && !item.complete) {
      changeRequestFinalize.push(item);
    } else if (item.rejected) {
      changeRejected.push(item);
    } else if (item.complete) {
      changeFinalized.push(item);
    }
  });

  updateState.pendingProposalChange = pendingProposalChange;
  updateState.acceptedProposalChange = acceptedProposalChange;
  updateState.rejectedProposalChange = rejectedProposalChange;
  updateState.newChange = newChange;
  updateState.changeRequestFinalize = changeRequestFinalize;
  updateState.changeRejected = changeRejected;
  updateState.changeFinalized = changeFinalized;
  return updateState;
};

const updateRequests = (state, action) => {
  const { typeRequest, data } = action;
  // Deep clone
  const updateState = JSON.parse(JSON.stringify(state));

  /**
   * Update state
   * @param {String} key, key in the object local state to update
   */
  const saveData = key => {
    const index = updateState[key].findIndex(element => element.id === data.id);
    if (index > -1) {
      updateState[key][index] = data;
    } else {
      updateState[key].push(data);
    }
  };

  const deleteData = key => {
    const index = updateState[key].findIndex(element => element.id === data.id);
    updateState[key].splice(index, 1);
  };

  switch (typeRequest.id) {
    case typeRequests.PENDING_PROPOSAL_CHANGE.id:
      saveData(typeRequests.PENDING_PROPOSAL_CHANGE.collection);
      break;
    case typeRequests.ACCEPTED_PROPOSAL_CHANGE.id:
      saveData(typeRequests.ACCEPTED_PROPOSAL_CHANGE.collection);
      deleteData(typeRequests.PENDING_PROPOSAL_CHANGE.collection);
      break;
    case typeRequests.REJECTED_PROPOSAL_CHANGE.id:
      saveData(typeRequests.REJECTED_PROPOSAL_CHANGE.collection);
      deleteData(typeRequests.PENDING_PROPOSAL_CHANGE.collection);
      break;
    case typeRequests.NEW_CHANGE.id:
      saveData(typeRequests.NEW_CHANGE.collection);
      break;
    case typeRequests.CHANGE_REQUEST_FINALIZE.id:
      saveData(typeRequests.CHANGE_REQUEST_FINALIZE.collection);
      break;
    case typeRequests.CHANGE_REJECTED.id:
      saveData(typeRequests.CHANGE_REJECTED.collection);
      deleteData(typeRequests.NEW_CHANGE.collection);
      break;
    case typeRequests.CHANGE_FINALIZED.id:
      saveData(typeRequests.CHANGE_FINALIZED.collection);
      deleteData(typeRequests.CHANGE_REQUEST_FINALIZE.collection);
      break;
  }

  return updateState;
};

const setCurrentTransaction = (state, action) => ({
  ...state, currentTransaction: action.currentTransaction,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REQUESTS:
      return setRequests(state, action);
    case actionTypes.UPDATE_REQUESTS:
      return updateRequests(state, action);
    case actionTypes.SET_CURRENT_TRANSACTION:
      return setCurrentTransaction(state, action);
    default:
      return state;
  }
};

export default reducer;
