import React from 'react';
//import PropTypes from "prop-types"

export default function Card({ title, width, gradient, Children }) {
  return (
    <div style={{ width: width }} className="card">
      <div className={`${gradient} cardTitle`}>
        {title}
      </div>
      <div className="cardChild">
      {Children}
      </div>
    </div>
  );
}
