import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../utilityFunctions';

const localStorageTradeTags = localStorage.tradeTags;
const localStorageTags = localStorage.tags;

const initialState = {
  tags: JSON.parse(localStorageTags === 'undefined' || !localStorageTags ? '[]' : localStorageTags),
  tradeTags: JSON.parse(localStorageTradeTags === 'undefined' || !localStorageTradeTags ? '[]' : localStorageTradeTags),
};

const setTags = (state, action) => {
  const tagsTherePubActive = tag => {
    let valid = false;
    for (let i = 0; i < tag.publications.length; i++) {
      if (tag.publications[i].publication_status === 'Activo') {
        valid = true;
        break;
      }
    }
    return valid;
  };

  const tags = action.tags.filter(tag => tagsTherePubActive(tag));
  localStorage.setItem('tags', JSON.stringify(tags));
  return updateObject(state, {
    tags: tags,
  });
};

const setTradeTags = (state, action) => {
  localStorage.setItem('tradeTags', JSON.stringify(action.tradeTags));
  return updateObject(state, {
    tradeTags: action.tradeTags,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TAGS:
      return setTags(state, action);
    case actionTypes.SET_TRADE_TAGS:
      return setTradeTags(state, action);
    default:
      return state;
  }
};

export default reducer;
