import axios from 'axios';
import { getHeader } from './../../system/Sesion';
import { config } from './../../system/Config';
import * as actionTypes from './actionTypes';

export const setRequests = data => {
  return {
    type: actionTypes.SET_REQUESTS,
    data: data,
  };
};

export const updateRequest = (data, typeRequest) => {
  return {
    type: actionTypes.UPDATE_REQUESTS,
    data: data,
    typeRequest: typeRequest,
  };
};

export const getRequests = () => {
  return async dispatch => {
    try {
      const response = await axios.get(`${config.URL_API}/requests`, {
        headers: getHeader(),
      });
      dispatch(setRequests(response.data));
    } catch(err) {
      throw err;
    }
  };
};

export const setCurrentTransaction = (currentTransaction) => ({
  type: actionTypes.SET_CURRENT_TRANSACTION,
  currentTransaction: currentTransaction?.toString?.() || currentTransaction,
});
