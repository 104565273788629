import React from 'react';

import './ChatInterestedPublicationsItem.scss';
import Card from '../../../../components/card/Card';
import BlurImage from '../../../../components/blurImage/BlurImage';
import PriceTag from '../../../../components/priceTag/PriceTag';
import { Link } from 'react-router-dom';

const ChatInterestedPublicationsItem = ({
  id,
  price,
  title,
  image,
  linkTo,
  closeModal,
  status
}) => {

  const handleOnLinkTo = () => {
    closeModal();
  };

  return (
    <section className="chat-interested-publications-item">
      <Card className="chat-interested-publications-item__card">
        <div className="chat-interested-publications-item__card--left">
          <div className="chat-interested-publications-item__card--left-img">
            <BlurImage image={image} title={title} linkTo={linkTo} className={'small'} />
          </div>
        </div>
        <div className="chat-interested-publications-item__card--center">
          <p className="chat-interested-publications-item__card--center-title">
            <div>{title}</div>
          </p>
        </div>
        <div className="chat-interested-publications-item__card--right">
          <PriceTag price={price} className="chat-interested-publications-item__card--right__price_tag" />
        </div>

      </Card>
      {status === "waiting" || status === "finished" &&
        <div className="chat-interested-publications-item__layer"><p>Ya no está Disponible</p></div>
      }
    </section>
  );
};

export default ChatInterestedPublicationsItem;
