import React from 'react';

import './LoCambioPremium/LoCambioPremium.scss';
import LinkInfoPages from '../components/linkInfoPages/';
import { locambioServices } from '../const';
import Footer from './home/components/footer/Footer';
import ScrollToElement from '../components/scrollToElement/ScrollToElement';
import DetailBox from './LoCambioPremium/components/detailBox';
import ServicesLC from './home/components/ServicesLC';

const { LOCAMBIO_SERVICES_TYPES } = locambioServices;

const LoCambioPremium = () => {
  const importance = (
    <div className="default-text">
      <p>
        Un servicio que combina perfectamente Seguridad y Comodidad, incluye:
        asesoría legal, verificación de datos y consulta de antecedentes
        judiciales, entre otros.
      </p>
      <br />
      <b>¿Cambias inmuebles o vehículos?</b>
      <p>Este servicio es ideal para ti.</p>
    </div>
  );

  const firstTitle = (
    <h1 className="lo-cambio-premium__secure-change-titles--align-left">
      <span className="orange">CAMBIA</span> ARTÍCULOS DE ALTO VALOR
    </h1>
  );

  const secondTitle = (
    <h1 className="lo-cambio-premium__secure-change-titles">
      <span className="orange">Únete </span>
      <span className="">a la Comunidad</span> <span className="light_orange">PREMIUM</span>{' '}
      <span className="default">de LoCambio.com</span>
    </h1>
  );

  const thirdTitle = (
    <h1 className="lo-cambio-premium__secure-change-titles">
      Cómo <span className="orange">Solicitarlo</span>
    </h1>
  );

  const bottomText = () => {
    let image = (
      <img
        className="coin-img"
        alt="locambio gana creditos"
        src="https://storage.googleapis.com/locambio-admin/pages/premium/coins0.svg"
      />
    );
    return (
      <div className="lo-cambio-premium__container--mid--bottom-text">
        {image}
        <p>
          <span className="purple-bold">Gana créditos </span>
          <span className="bold">por publicar </span>Productos y Servicios en
          LoCambio.com y úsalos para probar nuestros servicios
        </p>
        {image}
      </div>
    );
  };

  const business1 = (
    <DetailBox
      title="regular"
      periodInMonths={1}
      price={5}
      securityChangeQuantity={2}
      periodBackground="plain"
    />
  );

  const business2 = (
    <DetailBox
      title="popular"
      periodInMonths={6}
      price={25}
      securityChangeQuantity={14}
      periodBackground="blue"
    />
  );

  const business3 = (
    <DetailBox
      title="recommended"
      periodInMonths={12}
      price={45}
      securityChangeQuantity={26}
      periodBackground="orange"
    />
  );

  const step1 = (
    <p className="default-text description">
      Contacta al<span className="purple-text bold"> chat de soporte </span>
      solicitando convertir tu cuenta a Premium.
    </p>
  );

  const step2 = (
    <p className="default-text description">
      Responde a las preguntas de seguridad que el operador indique.
    </p>
  );

  const step3 = (
    <p className="default-text description">
      Recibirás respuestas en un lapso máximo de 72 hrs para verificar y
      ascender tu cuenta a Premium.
    </p>
  );

  const stepOneImg = (
    <img
      alt="locambio seguro step 1"
      src="https://storage.googleapis.com/locambio-admin/pages/premium/support0.svg"
    />
  );

  const stepTwoImg = (
    <img
      alt="locambio seguro step 2"
      src="https://storage.googleapis.com/locambio-admin/pages/premium/lock0.svg"
    />
  );

  const stepThreeImg = (
    <img
      alt="locambio seguro step 3"
      src="https://storage.googleapis.com/locambio-admin/pages/premium/checkmark-yellow0.svg"
    />
  );

  const logo = (
    <img
      className="lo-cambio-premium__logo"
      alt="locambio premium logo"
      src="https://storage.googleapis.com/locambio-admin/pages/premium/locambiopremium0.svg"
    />
  );

  const list = (
    <ul>
      <h3>Documentación y Asesoría.</h3>
      <ol>
        <li>
          Proporcionamos Lista de Recaudos Jurídicos según el bien o la
          propiedad para cambiar.
        </li>
        <li>Asesoría para gestionar el traspaso de artículos de alto valor.</li>
        <li>
          LoCambio.com ofrece gestionar los trámites necesarios por un costo
          adicional.
        </li>
      </ol>
      <h3>Beneficios adicionales</h3>
      <ol>
        <li>Sube hasta 20 Imágenes e incluso videos a una publicación.</li>
        <li>Muestra tu cuenta a solo usuarios Premium.</li>
        <li>
          Filtrar tu muro y los resultados de búsqueda con publicaciones
          Premium.
        </li>
        <li>
          Distinción y privilegios de privacidad en el Mural y resultados de
          búsqueda.
        </li>
      </ol>
    </ul>
  );

  return (
    <>
      <ScrollToElement
        scroll={true}
        options={{ behavior: 'auto', block: 'center' }}
        highlight={false}
      ></ScrollToElement>
      <div className="lo-cambio-premium">
        <div className="section">
          <div className="lo-cambio-premium__container">
            <div className="lo-cambio-premium__container--top">
              <div className="lo-cambio-premium__container--top--left">
                {logo}
                {firstTitle}
                {importance}
              </div>
              <div className="lo-cambio-premium__container--top--right">
                <div className="lo-cambio-premium__container--top--right--box">
                  {list}
                </div>
              </div>
            </div>
            <div className="lo-cambio-premium__container--mid">
              {secondTitle}
              <div className="lo-cambio-premium__container--mid--business">
                <div className="lo-cambio-premium__images">{business1}</div>
                <div className="lo-cambio-premium__images">{business2}</div>
                <div className="lo-cambio-premium__images">{business3}</div>
              </div>
            </div>
            <div className="lo-cambio-premium__container--mid">
              {thirdTitle}
              <div className="lo-cambio-premium__container--mid--steps">
                <div className="lo-cambio-premium__info-box">
                  <div className="lo-cambio-premium__info-box--step-number">
                    1
                  </div>
                  {step1}
                  {stepOneImg}
                </div>
                <div className="lo-cambio-premium__info-box">
                  <div className="lo-cambio-premium__info-box--step-number">
                    2
                  </div>
                  {step2}
                  {stepTwoImg}
                </div>
                <div className="lo-cambio-premium__info-box">
                  <div className="lo-cambio-premium__info-box--step-number">
                    3
                  </div>
                  {step3}
                  {stepThreeImg}
                </div>
              </div>
              {bottomText()}
            </div>
          </div>
          <div className="lo-cambio-premium__slider-container">
            <ServicesLC
              services={[
                LOCAMBIO_SERVICES_TYPES.locambioVerificationId,
                LOCAMBIO_SERVICES_TYPES.locambioSeguro,
                LOCAMBIO_SERVICES_TYPES.locambioAds,
              ]}
            />
          </div>
        </div>
        <div className="lo-cambio-premium__links">
          <LinkInfoPages active={'LoCambio.com Premium'} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoCambioPremium;
