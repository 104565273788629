import React from "react";
import { Callout, Button } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import AvatarUser from "./../../components/AvatarUser";
import RankingUserStar from "./../../components/RankingStar";
import { Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";
import "../detailProduct/OwnerInfo.scss";
import UserName from "../../components/userName/UserName";

const OwnerInfo = ({ user }) => {
  const { isAuthenticated } = useSelector(({ auth }) => auth);
  return (
    <Callout>
      <div className="headerUserCard">
        <div className="AvatarContainer">
          <AvatarUser user={user} />
        </div>
        <div className="owner_main_info">
          <h4 >
            <UserName user={user} htmlTag='p' className={'owner_main_info__name'}/>
          </h4>
          <p className="bp3-form-helper-text margin0">{user.city}</p>
        </div>
      </div>
      {isAuthenticated && (
        <div className={"marginTop-1em marginBottom-1em"}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Link to={`/profile/${user.id}`}>
                <Button
                  icon={"person"}
                  fill={true}
                  className="bp3-intent-primary-btnLC bp3-button bp3-fill BtnLCPrimary"
                >
                  Ver Perfil
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <div
            className={
              "pt-4 pb-2 sm:pt-4 sm:pb-0 md:pt-4 md:pb-0 lg:pt-4 lg:pb-0 xl:pt-4 xl:pb-0"
            }
          >
            <RankingUserStar user={user} />
          </div>
        </Col>
      </Row>
    </Callout>
  );
}
export default OwnerInfo;
