import React, { useContext } from 'react';
import ChangeProposalItem from '../changeProposalItem/ChangeProposalItem';
import { ChangeOperationContext } from '../../providers/ChangeOperationProvider';

import './ChangeProposalPublications.scss';

const ChangeProposalPublications = ({ pluralizeSelectedTitle }) => {
  const { selectedPublications } = useContext(ChangeOperationContext);
  const currency = localStorage.getItem('currency');

  return (
    <>
      <p className="change-proposal-modal__top--counter">
        <span className="change-proposal-modal__top--counter-main-container">
          {pluralizeSelectedTitle(selectedPublications)}: {selectedPublications.length}
        </span>
      </p>
      <section className="change-proposal-publications">
        {selectedPublications.map((pub) => (
          <ChangeProposalItem
            id={pub.id}
            key={`ChangeProposalItem${pub?.id}`}
            linkTo={pub.linkTo}
            image={pub.image}
            title={pub.title}
            price={pub.price}
            currency={currency}
            status={pub.status}
          />
        ))}
      </section>
    </>
  );
};

export default ChangeProposalPublications;