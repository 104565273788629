import React from 'react';
import { Spinner } from '@blueprintjs/core';

import './Spinner.scss';

const SpinnerComp = ({ className, ...props}) => {
  const intentClass = props.intent ? `spinner--${props.intent}` : '';
  const classNames = `spinner ${className || ''} ${intentClass}`;
  return (
    <Spinner className={classNames} {...props} />
  );
};

export default SpinnerComp;
