import ActivityBanner from '../pages/activitiesWall/activities/activityBanner/ActivityBanner';
import ActivityNewPublication from '../pages/activitiesWall/activities/activityNewPublication/ActivityNewPublication';
import ActivitySuccessfulChange from '../pages/activitiesWall/activities/activitySuccessfulChange/ActivitySuccessfulChange';

const WALL_ITEMS_TYPES = {
  publication: 'publication',
  change: 'change',
  banner: 'banner',
};

const WALL_ITEMS_COMPONENTS = {
  [WALL_ITEMS_TYPES.publication]: ActivityNewPublication,
  [WALL_ITEMS_TYPES.change]: ActivitySuccessfulChange,
  [WALL_ITEMS_TYPES.banner]: ActivityBanner,
};

const wallExport = { WALL_ITEMS_TYPES, WALL_ITEMS_COMPONENTS };

export default wallExport;