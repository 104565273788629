import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Dialog, HTMLSelect, Icon } from "@blueprintjs/core";
import "./CustomCalendar.scss";
import moment from "moment";
import Button from "../button/Button";

const CustomCalendar = ({
  className,
  isOpen,
  setIsOpen,
  onClose,
  schedules,
  setSelectedDate,
  selectedDate,
  selectedSchedule,
  setSelectedSchedule,
}) => {
  //variables que deberia recibir del backend
  const disabledCustomDays = ["2023-08-08", "2023-08-09"];
  const disabledHolidays = ["2023-07-27", "2023-07-28"];
  const requestLimitonDays = 30;
  //*****************************************

  const [date, setDate] = useState(new Date());
  const [schedule, setSchedule] = useState();

  const handleSetSchedules = () => {
    const scheduleList = [
      { label: "Seleccionar turno", value: "", disabled: true, selected: true },
    ];
    schedules.map((schedule) =>
      scheduleList.push({ label: schedule, value: schedule })
    );
    return scheduleList;
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleSetDate = () => {
    setSelectedDate(date);
    setSelectedSchedule(schedule);
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen, schedule]);

  useEffect(() => {
    setDate(selectedDate ? selectedDate : new Date());
    if (selectedSchedule) setSchedule(selectedSchedule);
  }, []);

  // Bloquear despues de una cantidad limite de días
  const today = new Date();
  const maxDate = moment().add(requestLimitonDays, "days").toDate();

  // Bloquear días feriados
  const holidaysDays = disabledHolidays.map((holiday) =>
    moment(holiday).toDate()
  );

  // Bloquear días específicos
  const customDaysDays = disabledCustomDays.map((day) => moment(day).toDate());

  // Bloquear días de fin de semana
  const disabledWeekend = ({ date }) =>
    date.getDay() === 0 || date.getDay() === 6;

  // Función que verifica si una fecha está en un arreglo de fechas dadas
  const hasDate = (date, array) => {
    return array.some(
      (day) =>
        date.getFullYear() === day.getFullYear() &&
        date.getMonth() === day.getMonth() &&
        date.getDate() === day.getDate()
    );
  };

  // Función que deshabilita los días bloqueados
  const disabledDays = ({ date }) => {
    const isHoliday = hasDate(date, holidaysDays);
    const isWeekend = disabledWeekend({ date });
    const isCustomDate = hasDate(date, customDaysDays);
    return isHoliday || isWeekend || isCustomDate;
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className="custom-calendar">
      <Icon
        className="custom-calendar__cross"
        icon="cross"
        onClick={() => {
          setIsOpen(false);
        }}
      />
      <p className="custom-calendar__content__appointments_calendar">
        Escoge una fecha para el Cambio Seguro
      </p>
      <section className="custom-calendar__content">
        <Calendar
          onChange={handleDateChange}
          value={date}
          className={`${className}`}
          title={false}
          minDate={today}
          tileDisabled={disabledDays}
          maxDate={maxDate}
          prev2Label={null}
          next2Label={null}
          formatShortWeekday={(locale, value) =>
            value.toLocaleDateString(locale, { weekday: "short" }).charAt(0)
          }
        />
      </section>
      <p className="custom-calendar__content__appointments-title">
        Selecciona un turno en el que asistirás
      </p>
      <HTMLSelect
        id="schedule"
        minimal
        className={"custom-calendar__content__schedule-select"}
        options={handleSetSchedules()}
        placeholder="Selecciona una opción"
        value={schedule}
        onChange={(e) => setSchedule(e.target.value)}
      />
      <Button
        className={"custom-calendar__content__appointments-button"}
        text={"Listo"}
        intent="success"
        disabled={!schedule && date}
        onClick={() => {
          handleSetDate();
        }}
      />
    </Dialog>
  );
};

export default CustomCalendar;
