import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import Geocode from 'react-geocode';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';

import './App.scss';
import { config } from './system/Config';
import { SimpleToast } from './utility/toast';
import ChatCable from './components/ChatCable'
import InitialLoading from './components/InitialLoading';
import { setNetworkStatus } from './store/actions/network';
import { checkPosition, checkSesion, getCategories, getPosition, setPosition } from './store/actions/index';

import Modal from './components/modal';
import Header from './components/header';
import Routes from './components/Routes';
import ModalApp from './components/modalApp';

import { useLogout } from './hooks/useLogout';

import 'react-image-crop/dist/ReactCrop.css';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import { getHeader } from './system/Sesion';
import { baseAxios } from './utility/axios';

library.add(faStroopwafel);

function App(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { latitude, longitude } = useSelector((state) => state.geolocation);
  const sendinBlueSnippetBrevo = document.getElementById('brevo-conversations');

  useLogout()

  useEffect(() => {
      const path = location.pathname.split('/')[1];
      const display = path === 'chats' ? 'none' : 'block';
      if (sendinBlueSnippetBrevo) sendinBlueSnippetBrevo.style.display = display;
    }, [location.pathname, sendinBlueSnippetBrevo],
  );

  useEffect(() => {
    if (typeof latitude === 'number') {
      Geocode.setApiKey(config.KEY_GEOCODE);
      (async () => {
        const point = await Geocode.fromLatLng(latitude, longitude);
        const country = point.results.at(-1).address_components[0].short_name;
        await baseAxios.get(`${config.URL_API}/currency_by_country?country=${country}`, { headers: getHeader() })
                       .then((response) => {
                         dispatch(setPosition(latitude, longitude, response.data.currency));
                         localStorage.setItem('currency', response.data.currency);
                       })
                       .catch((err) => { console.log(err); });
      })();
    }
  }, [latitude, longitude]);
  
  useEffect(() => {
    dispatch(checkSesion());
    dispatch(checkPosition());
    dispatch(getPosition());
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    let toast;

    const handleOnline = () => {
      SimpleToast.dismiss(toast);
      dispatch(setNetworkStatus(true));
    };

    const handleOffline = () => {
      toast = SimpleToast.show({
        className: 'offline-toast',
        timeout: 0,
        intent: 'danger',
        icon: 'warning-sign',
        message: 'No tienes conexión a internet',
      });
      dispatch(setNetworkStatus(false));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const loading = !props.sessionVerifed || !props.latitude || !props.longitude;

  if (loading) return <InitialLoading />;

  const gtmParams = { id: config.GTM_ID };

  return (
    <GTMProvider state={gtmParams}>
      <ModalApp />
      <header>
        <Header />
      </header>
      <div>
        <Routes
          isAuthenticated={props.isAuthenticated}
          sessionVerifed={props.sessionVerifed}
        />

        {props.isAuthenticated && (
          <>
            <ChatCable />
          </>
        )}
      </div>
      <Modal />
    </GTMProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    sessionVerifed: state.auth.sessionVerifed,
    latitude: state.geolocation.latitude,
    longitude: state.geolocation.longitude,
  };
};

export default withRouter(
  connect(mapStateToProps, null, null, { forwardRef: true })(App),
);
