const MODALS_TYPES = {
  summary: 'summary',
  success: 'success',
  selectPlan: 'selectPlan',
}

const modals = { 
  MODALS_TYPES,
}

export default modals