import React from 'react';
import { Icon, Dialog } from '@blueprintjs/core';
import './ModalImage.scss';

class ModalImage extends React.Component {
  render() {
    const { visible, aditionalClass } = this.props;
    return (
      <Dialog isOpen={visible} className={`modal-image ${aditionalClass}`}>
        <div onClick={this.props.close} className="modal-image__close">
          <Icon
            className={`modal-image__close--cross}`}
            icon="cross"
            iconSize={22}
          />
        </div>
        <img src={this.props.src} alt="Modal image"/>
      </Dialog>
    );
  }
}

export default ModalImage;
