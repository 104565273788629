import React from 'react';
import Card from '../../components/card/Card';

import ProfilePublicationsList from '../profilePublicationsList/ProfilePublicationsList';

import './ProfilePublications.scss';

const ProfilePublications = ({targetUser}) => {
  return (
    <ProfilePublicationsList targetUser={targetUser} />
  );
};

export default ProfilePublications;