import React, { useEffect, useState } from 'react';
import { Collapse } from '@blueprintjs/core';

import Text from '../text';
import Loader from '../loader';
import LocambioPromo from './components/locambioPromo';
import { getLocambioPromos } from '../../services/locambioPromos';

import './LocambioPromos.scss';

const LocambioPromos = ({ onSelectedPlan }) => {
  const [loading, setLoading] = useState(true);
  const [locambioPromos, setLocambioPromos] = useState([]);
  const [locambioPromo, setLocambioPromo] = useState();

  useEffect(() => {
    getLocambioPromos()
      .then((data) => setLocambioPromos(data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    onSelectedPlan?.(locambioPromo);
  }, [locambioPromo]);

  return (
    <section className="locambio-promos">
      {loading ?
        (<Loader />) :
        (locambioPromos.map((locambioPromoData) => (
            <LocambioPromo
              key={locambioPromoData?.productable?.ad_type}
              locambioPromo={locambioPromoData}
              onClick={setLocambioPromo}
              active={locambioPromoData?.productable?.ad_type === locambioPromo?.productable?.ad_type}
            />
          ),
        ))
      }
      <Collapse isOpen={Boolean(locambioPromo)} className={`locambio-promos__info ${Boolean(locambioPromo) && 'locambio-promos__info--active'}`}>
        <Text className="locambio-promos__title" color="blue">Vizualizaciones</Text>
        <Text className="locambio-promos__views" color="blue">+{locambioPromo?.productable?.views}</Text>
      </Collapse>
    </section>
  );
};

export default LocambioPromos;