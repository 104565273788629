import React from 'react';

import './BookingRequest.scss';
import { dateMonthString } from '../../utilityFunctions';
import { proformas } from '../../const';

const { STATUSES } = proformas;

const STATUS_MESSAGES = {
  [STATUSES.toBeConfirmed]: 'La transacción está en verificación. ',
  null: 'La transacción está en proceso. ',
};

const BookingRequest = ({
  applicant,
  date,
  exchange_place,
  schedule,
  proforma_status,
}) => {
  const isApplicant =
    applicant.id.toString() === localStorage.getItem('userId');
  const logo = (
    <img
      className="booking-request__header__logo"
      alt="locambio seguro logo"
      src="https://storage.googleapis.com/locambio-admin/pages/seguro/seguro0.png"
    />
  );

  const title = (
    <div className="booking-request__header__product">
      <h1 className="booking-request__header__product__title">CAMBIA SEGURO</h1>
      <p className="booking-request__header__product__subtitle">
        Acércate a las sedes que ofrecemos para hacer cambios exitosos de manera
        segura.
      </p>
    </div>
  );

  const action = (
    <p className="booking-request__action">
      {!isApplicant && (
        <span className="booking-request__action__name">{applicant.name} </span>
      )}

      <span className="booking-request__action__request">
        {isApplicant ? 'Has' : 'ha'} solicitado LoCambio Seguro{' '}
      </span>
      <span>para hacer el cambio {!isApplicant && 'contigo'} el </span>
      <span className="booking-request__action__schedule">
        {dateMonthString(date)} en la {schedule}{' '}
      </span>
      <span>en la sede: </span>
    </p>
  );

  const headquarter = (
    <p className="booking-request__headquarter">{exchange_place.headquarter}</p>
  );

  const confirmText = (
    <p className="booking-request__confirmation">
      {STATUS_MESSAGES[proforma_status]}
      <span className="booking-request__confirmation__contact">
        LoCambio se pondrá en contacto
      </span>{' '}
      para confirmar el servicio.
    </p>
  );

  return (
    <section className="booking-request">
      <div className="booking-request__header">
        {logo}
        {title}
      </div>
      {action}
      {headquarter}
      {confirmText}
    </section>
  );
};

export default BookingRequest;
