import React from 'react';
import { Autoplay } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "./ProfileBanner.scss";

const ProfileBanner = ({ showText = true, marginTop = true, className }) => {
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });
  const classNames = `ProfileBanner ${marginTop ? 'marginTop-1em' : ''} ${className}`;

  const largeImages = [
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-1.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-2.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-3.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-4.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-5.jpg',
  ];

  const shortImages = [
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-m-1.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-m-2.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-m-3.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-m-4.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/profile/banner-m-5.jpg',
  ];
  
  const params = {
    modules: [Autoplay],
    centeredSlides: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    slidesPerGroup: 1,
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    rebuildOnUpdate: true,
  };
  
  const imageArray = (isLargerThanMobile ? largeImages : shortImages).map((elem, index) => {
    return (
      <SwiperSlide key={index}>
        <img src={elem} />
      </SwiperSlide>
    );
  });

  return (
    <div className={classNames}>
      <Swiper {...params}>{imageArray}</Swiper>

      {
        showText && (
          <div className="footer">
            <p className="bp3-form-helper-text">t-locambio, C.A. 2019 - RIF: J-40865264-1</p>
            <p className="bp3-form-helper-text">Todos los Derechos Reservados</p>
            <p className="bp3-form-helper-text">locambio.com</p>
          </div>
        )
      }
    </div>
  );
};

export default ProfileBanner;

