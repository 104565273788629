import React, { useState } from "react";
import verificationCheck from "../../../src/components/verifiedCheckmark/verificationCheck";
import "./UserName.scss";

const UserName = ({ user, htmlTag, className, size }) => {

  const capitalizeName = (word) => word.charAt(0).toUpperCase() + word.slice(1);
  const maxlength = 12;

  const abbreviateNames = (names = "", last_names = "") => {
    const shortName = splitAndCapitalize(names);
    const shortLastName = splitAndCapitalize(last_names);
    return `${shortName} ${shortLastName}`;
  };

  const abbreviateLongNames = (part) => {
    if (part.length <= maxlength) {
      return part;
    } else {
      return part.slice(0, maxlength - 3) + " ";
    }
  };

  const splitAndCapitalize = (text) => {
    const parts = text.split(" ");
    let result = capitalizeName(abbreviateLongNames(parts[0]));
    if (parts.length > 1) {
      result += " " + parts[1].charAt(0).toUpperCase() + " ";
    }
    return result;
  };

  const fullName = abbreviateNames(user?.name, user?.last_name);

  const tags = {
    p: <p className={`user_name__name ${className}__name`}>{fullName}</p>,
    h1: <h1 className={`user_name__name ${className}__name`}>{fullName}</h1>,
    h2: <h2 className={`user_name__name ${className}__name`}>{fullName}</h2>,
    h3: <h3 className={`user_name__name ${className}__name`}>{fullName}</h3>,
    h4: <h4 className={`user_name__name ${className}__name`}>{fullName}</h4>,
  };

  return (
    <div className={`user_name ${className}`}>
      {tags[htmlTag]}
      <div className={`user_name__name ${className}__checkmark`}>
        {user?.verified && verificationCheck(user?.type_suscription, size)}
      </div>
    </div>
  );
};

export default UserName;
