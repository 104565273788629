import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Icon, Button, Divider, FormGroup } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import BlurImage from '../../../../components/blurImage/BlurImage';

const ShowImage = ({ name, control, current, setFile, className, referenceImage, resetField, filesIndexes, watch,}) => {

  const file = watch(name);

  const onRemove = (e) => { 
    e.stopPropagation()
    const updateFiles = filesIndexes.filter((item) => (item !== current)) 
    setFile([...updateFiles])
    resetField(name)
  }
  
  return (
    <div ref={referenceImage} className={`show-image__image ${className}`}>
      <Button onClick={onRemove} className={'bp3-intent-primary-btnLC BtnLCPrimary mt-2 ml-2 absolute'} style={{zIndex: 20}}><Icon icon={'trash'} /></Button>
      <BlurImage image={file?.url} title={name}/>
    </div>
  );
}    
       
ShowImage.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  setValue: PropTypes.func,
};

export default ShowImage