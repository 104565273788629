import React from 'react';
import PropTypes from 'prop-types';

import {
  collectionPropType,
  titlePropType,
  withSwiperPropType,
} from './propTypes';
import Title from './components/title';
import Collection from './components/collection';
import { cleanClassName } from '../../utilityFunctions';

import './CardCollection.scss';

const CardCollection = ({ collection, title, withSwiper, className }) => {
  const classNames = `card-collection ${cleanClassName(className)}`

  return (
    <section className={classNames}>
      <Title title={title} />
      <Collection withSwiper={withSwiper} collection={collection} />
    </section>
  );
};

CardCollection.propTypes = {
  withSwiper: withSwiperPropType,
  title: titlePropType,
  collection: collectionPropType,
  className: PropTypes.string,
};

export default CardCollection;
