export const useParsedCategories = function getCategoriesAll(categories) {

  function filterCategories(value, index, self) {
    if (!value.includes('otros') && !value.includes('otras')) {
      return self.indexOf(value) === index;
    }
  }

  const removePunctuationMarks = (word) => {
    return word.replace(/[+*\-\/,.¿?\[\]\{\}'#$%&()"]/g, '').toLowerCase();
  };

  return categories
    .map((categorie) => {
      if (categorie.categories) {
        return [
          removePunctuationMarks(categorie.name),
          ...getCategoriesAll(categorie.categories),
        ];
      }
      return removePunctuationMarks(categorie.name);
    })
    .flat()
    .filter(filterCategories);
};
