// Este archivo es un antipatron.
// TODO: Mover cada funcion aqui a su propio archivo.
import moment from "moment";
import "moment/locale/es";
import Fuse from "fuse.js";
import { attrCategories } from "./attrCategories";
import { config } from "./system/Config";

// haciendo uso de Fuse.js, esta funcion retorna los resultados encontrados en un Array.
// recibe tres parametros: el valor a buscar, el array de objetos, y las llaves donde deberia buscar en los objetos
export const searchInArray = (value, array, keys) => {
  let options = {
    keys: keys,
    threshold: 0,
  };
  let fuse = new Fuse(array, options);
  return fuse.search(value).slice(0, 6);
};

// ordena un array por fecha
// recibe dos parametros: el array de objetos, y la llave donde esta el valor de la fecha
export const orderArrayByDate = (array, key) => {
  array.sort(function (a, b) {
    let dateA = new Date(a[key]).getTime();
    let dateB = new Date(b[key]).getTime();
    return dateA < dateB ? 1 : -1;
  });
  return array;
};

// Misma funcion Anterior
// Orden Inverso
export const orderArrayByDateReverse = (array, key) => {
  array.sort(function (a, b) {
    let dateA = new Date(a[key]).getTime();
    let dateB = new Date(b[key]).getTime();
    return dateA > dateB ? 1 : -1;
  });
  return array;
};

export const compareStrings = (a, b) => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
};

// actualiza un objeto
export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

// devuelve false si una cadena esta vacia.
export const spaceValidation = (value) => {
  if (typeof value !== "string") {
    return false;
  }
  return value.trim("");
};

// devuelve false si un email no es valido
export const emailValidation = (value) => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
};

// devuelve false si un link no es valido
export const linkValidation = (value) => {
  return /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi.test(
    value
  );
};

// impide que se escriban letras en un input
export const numbers = (e) => {
  let tecla = document.all ? e.keyCode : e.which;
  if (tecla == 13 || tecla == 8) return true;
  let patron = /[1234567890\d\.]/;
  let te = String.fromCharCode(tecla);
  if (!patron.test(te)) {
    e.preventDefault();
  }
};

export const numbersWithoutPoints = (e) => {
  let tecla = document.all ? e.keyCode : e.which;
  if (tecla == 13 || tecla == 8) return true;
  let patron = /[1234567890\d]/;
  let te = String.fromCharCode(tecla);
  if (!patron.test(te)) {
    e.preventDefault();
  }
};

export const initialValue = (value) => {
  if (!spaceValidation(value)) {
    return "";
  }
  return value.split("")[0].toUpperCase();
};

// impide que se escriban numeros en un input
export const letters = (e) => {
  let tecla = document.all ? e.keyCode : e.which;

  if (tecla == 13 || tecla == 8) return true;

  let patron = /[A-Za-zÁáÉéÍíÓóÓúÚÜüñÑ\s]/;
  let te = String.fromCharCode(tecla);
  if (!patron.test(te)) {
    e.preventDefault();
  }
};

// devuelve true si hay una letra mayuscula en una cadena
export const thereUpper = (value) => {
  const patron = new RegExp("[A-Z]");
  return patron.test(value);
};

// devuelve true si hay un numero en la cadena
export const thereNumber = (value) => {
  const patron = /\d/;
  return patron.test(value);
};

// devuelve true si hay un caracter especial
export const thereSpecialChar = (value) => {
  const patron = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  return patron.test(value);
};

// devuelve true si hay 6 o mas caracteres
export const minCharacter = (value) => {
  return value.length >= 6;
};

// devuelve true si un numero esta en el rango de 1 y 31
export const daysValidation = (value) => {
  let daysValid = [];
  for (let i = 1; i <= 31; i++) {
    daysValid.push(i.toString());
  }
  return daysValid.indexOf(value) !== -1;
};

// devuelve true si un numero esta entre 1 y 12
export const monthsValidation = (value) => {
  let monthsValid = [];
  for (let i = 1; i <= 12; i++) {
    monthsValid.push(i.toString());
  }
  return monthsValid.indexOf(value) !== -1;
};

// devuelve true si un numero esta entre 1 y 2000
export const yearsValidation = (value) => {
  let yearsValid = [];
  for (let i = 1; i <= 2000; i++) {
    yearsValid.push(i.toString());
  }
  return yearsValid.indexOf(value) !== -1;
};

// devuelve la fecha actual
export const getDateActual = () => {
  const date = new Date();
  const pad = (n) => (n < 9 ? `${0}${n}` : n);
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

// devuelve el ano actual
export const getYearActual = () => new Date().getFullYear();

// devuelve el dia actual
export const getDayActual = () => new Date().getDate();

// devuelve el mes actual
export const getMonthActual = () => new Date().getMonth() + 1;

// devuelve cuantos dias tiene un mes en un ano especifico
export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

//
export const enterAction = (event, callback) => {
  if (event.which === 13) {
    callback();
  }
};

// devuelve el indice de un elemento que se esta buscando
// OJO: el arreglo debe contener objetos y cada objeto debe tener la propiedad "id"
export const searchIndex = (array, id) => {
  let index = -1;
  array.forEach((value, i) => {
    if (value.id == id) {
      index = i;
      return;
    }
  });
  return index;
};

// devuelve true si en localStorage esta almacenada la ubicacion
export const existPosition = () => {
  let aux = true;
  if (
    localStorage.getItem("latitude") === undefined ||
    localStorage.getItem("longitude") === undefined
  ) {
    aux = false;
  }
  return aux;
};

// tipos de busqueda del searchbar
export const TYPESEARCH = {
  PRICE: "PRICE",
  TAGS: "TAGS",
  INTERST: "INTEREST",
};

// meses del ano
export const monthsOptions = [
  { label: "Mes", value: "", disabled: true, selected: true },
  { label: "Enero", value: "01" },
  { label: "Febrero", value: "02" },
  { label: "Marzo", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Mayo", value: "05" },
  { label: "Junio", value: "06" },
  { label: "Julio", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Septiembre", value: "09" },
  { label: "Octubre", value: "10" },
  { label: "Noviembre", value: "11" },
  { label: "Diciembre", value: "12" },
];

// estados o provincias de Venezuela
export const states = [
  "Amazonas",
  "Anzoátegui",
  "Apure",
  "Aragua",
  "Barinas",
  "Bolívar",
  "Carabobo",
  "Cojedes",
  "Delta Amacuro",
  "Distrito Capital",
  "Falcón",
  "Guárico",
  "Lara",
  "Mérida",
  "Miranda",
  "Monagas",
  "Nueva Esparta",
  "Portuguesa",
  "Sucre",
  "Táchira",
  "Trujillo",
  "Vargas",
  "Yaracuy",
  "Zulia",
];

// tipos de categorias
export const typeCategories = {
  SOCIAL: "SOCIAL",
  PREMIUM: "PREMIUM",
  STANDARD: "STANDARD",
};

// tipos de tiempo de uso de un producto
export const useTime = {
  YEARS: "YEARS",
  MONTHS: "MONTHS",
  DAYS: "DAYS",
};

// tipos de suscripcion
export const typeSuscription = {
  STANDARD: "Standard",
  PREMIUM: "Premium",
  PREMIUM_TRIAL: 1,
  MENSUAL: 2,
  TRIMESTRAL: 3,
  SEMESTRAL: 4,
  ANUAL: 5,
};

// anade una clase a un elemento del DOM
export const addClassElement = (id, className) => {
  if (document.getElementById(id)) {
    document.getElementById(id).classList.add(className);
  }
};

export const capitalize = ({ str, all }) =>  {
  return str.replace(all ? /(^\w{1})|(\s+\w{1})/g : /(^\w{1})/g, word => word.toUpperCase());
}

// remueve una clase a un elemento del DOM
export const removeClassElement = (id, className) => {
  if (document.getElementById(id)) {
    document.getElementById(id).classList.remove(className);
  }
};

// retorna true si es necesario mostrar el overlay
// recibe dos parametros: la categoria del producto y la suscripcion del usuario logueado
export const needOverlay = (productCategory, userSuscription) =>
  productCategory &&
  productCategory.category_premium &&
  userSuscription === typeSuscription.STANDARD;

// recibe el ID de una categoria y retorna un objeto:
// attr: valores de los atributos
// display: valores para mostrar al usuario de cierto atributo
// ej: attr: "model", display: "Modelo"
export const getAttr = (id_category) => {
  let attr = [];
  let attrDisplay = [];

  attrCategories.forEach((category) => {
    if (category.id == id_category) {
      attr = category.attr;
      attrDisplay = category.attrDisplay;
      return;
    }
  });
  return {
    attr: attr,
    display: attrDisplay,
  };
};

// recibe un array de likes y el id de una publicacion
// y retorna el id del like asociado a la publicacion
export const getIdLike = (likes, id_publication) => {
  for (let i = 0; i < likes.length; i++) {
    if (likes[i].publication_id == id_publication) {
      return likes[i].id;
    }
  }
};

/**
 * convert a file to base64.
 * @param {Object} blob
 * @returns {Promise}
 */
export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

// recibe como parametro un string y lo retorna en formato de precio
// ej: 100000 => 100.000
export const formatPrice = (x) => {
  if (x !== undefined && x !== null) {
    const prevDecimals = x
      .toString()
      .split(".")[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const nextDecimals = x.toString().split(".")[1];
    const transformedPrice = nextDecimals
      ? `${prevDecimals},${nextDecimals}`
      : `${prevDecimals}`;
    if (transformedPrice == 0) {
      return "0.00";
    }
    return transformedPrice;
  }
};

export const currencyFormat = (num) => {
  if (num !== undefined && num !== null) {
    return Number(num).toLocaleString('es-ES', {minimumFractionDigits:2, maximumFractionDigits:2})
  } else {
    return 'n/d'
  }
}

export const addZeros = (x) => {
  if (x !== undefined && x !== null) {
    const array = x.toString().split('.');
    if (array.length === 1) return '.00';
    if (array[1].length === 1) return '0';
  }
};

// recibe como parametro un string en formato de precio
// y retorna el mismo string sin los puntos
// ej: 100.000 => 100000
export const priceToString = (x) => parseInt(x.replace(/\./g, ""));

// elimina los espacios en blanco de una cadena
export const productNameUrl = (value) =>
  value?.replace(/[^A-Za-z0-9\ ]/g, "").replace(/\ /g, "-");

// Arma el mensaje si tiene un credito por la publicacion
export const textForGrantedCredit = (value) =>
  `Su publicación "${value.title}" fue aprobada` + (value.credit === 0 ? `.` : ` y le ha sumado USD$${value.credit} a sus créditos."`);

// tipos de formatos soportados para imagenes
export const acceptedFiles = "image/jpeg, image/png, image/gif, image/mba";

// clase skeleton
export const skeletonClass = "bp3-skeleton";

// recibe dos parametros, un valor a evaluar, y un string de clases CSS
// si el valor a evaluar es nulo retorna la skeletonClass sino retorna las clases
export const needSkeletonClass = (value, classes) => {
  let result = classes;
  if (!value) {
    result = skeletonClass;
  }
  return result;
};

// retorna true si un usuario es considerado como "Nuevo usuario"
// nota: un usuario con menos de un mes registrado es considerado un nuevo usuario
export const newUser = (registration_date) => {
  let userNew = false;

  let dateActual = moment(getDateActual().toString());
  let registrationDate = moment(registration_date);
  let monthAntiquity = dateActual.diff(registrationDate, "month");

  if (monthAntiquity < 1) {
    userNew = true;
  }
  return userNew;
};

// retorna true si un usuario no tiene reputacion
// nota: si la reputacion es igual a 0 es un usuario sin reputacion
export const notReputation = (reputation) => {
  return reputation === 0;
};

// devuelve true si es un usuario regular, es regular si la reputacion esta entre 1 y 33
export const regularUser = (reputation) => {
  return reputation >= 1 && reputation <= 33;
};

// devuelve true si es un usuario bueno, es bueno si la reputacion esta entre 33 y 66
export const goodUser = (reputation) => {
  return reputation > 33 && reputation <= 66;
};

// devuelve true s es un usuario excelente, es excelente si la reputacion esta entre 66 y 100
export const excelentUser = (reputation) => {
  return reputation > 66 && reputation <= 100;
};

// devuelve la anituguedad de un usuario
export const antiquityUser = (registration_date) => {
  let dateActual = moment(getDateActual().toString());
  let registrationDate = moment(registration_date);
  let monthAntiquity = dateActual.diff(registrationDate, "month");
  return monthAntiquity;
};

// dependiendo de la antiguedad y reputacion del usuario
// devuelve un texto.
export const rankingUserText = (registration_date, reputation) => {
  let resp = { text: "" };
  if (newUser(registration_date)) {
    resp.text = "0 votos";
  } else {
    let antiquity = parseInt(antiquityUser(registration_date));
    if (notReputation(reputation)) {
      resp.text = `Nadia ha evaluado a este usuario en ${
        antiquity > 1 ? "los últimos" : "el ultimo"
      } ${antiquity > 1 ? "meses" : "mes"}`;
    } else if (regularUser(reputation)) {
      resp.text = `Regular en ${antiquity > 0 ? "los últimos" : "el ultimo"} ${
        antiquity > 0 ? "meses" : "mes"
      }`;
    } else if (goodUser(reputation)) {
      resp.text = `Bueno en ${antiquity > 0 ? "los últimos" : "el ultimo"} ${
        antiquity > 0 ? "meses" : "mes"
      }`;
    } else if (excelentUser(reputation)) {
      resp.text = `Excelente en ${
        antiquity > 0 ? "los últimos" : "el ultimo"
      } ${antiquity > 0 ? "meses" : "mes"}`;
    }
  }
  return resp.text;
};

// recibe la fecha de registro del usuario y la reputacion
// devuelve la cantidad de estrellas vacias, y cantidad de estrellas llenas para el grafico del Ranking
export const rankingUserStars = (registration_date, reputation) => {
  let lengthArrayStar = 0;
  let lengthArrayStarEmpty = 0;
  let level = "";

  if (reputation <= 0) {
    level = "new";
  } else if (reputation > 0 && reputation <= 1) {
    level = "regular";
  } else if (reputation > 1 && reputation <= 2) {
    level = "good";
  } else if (reputation > 2 && reputation <= 3) {
    level = "top";
  } else if (reputation > 3 && reputation <= 4.99) {
    level = "great";
  } else if (reputation > 4.99) {
    level = "excelent";
  }
  /*
  if (newUser(registration_date)) {
    level = "new";
  } else if (notReputation(reputation)) {
    level = "not_reputation";
  } else if (regularUser(reputation)) {
    level = "regular";
  } else if (goodUser(reputation)) {
    level = "good";
  } else if (excelentUser(reputation)) {
    level = "excelent";
  } */

  switch (level) {
    case "new":
      lengthArrayStar = 0;
      lengthArrayStarEmpty = 5;
      break;
    case "not_reputation":
      lengthArrayStar = 0;
      lengthArrayStarEmpty = 5;
      break;
    case "regular":
      lengthArrayStar = 1;
      lengthArrayStarEmpty = 4;
      break;
    case "good":
      lengthArrayStar = 2;
      lengthArrayStarEmpty = 3;
    case "top":
      lengthArrayStar = 3;
      lengthArrayStarEmpty = 2;
    case "great":
      lengthArrayStar = 4;
      lengthArrayStarEmpty = 1;
    case "excelent":
      lengthArrayStar = 5;
      lengthArrayStarEmpty = 0;
      break;
    default:
      // code...
      break;
  }
  let lengthStar = {
    lengthArrayStar: new Array(lengthArrayStar),
    lengthArrayStarEmpty: new Array(lengthArrayStarEmpty),
  };
  return lengthStar;
};

// recibe la fecha de registro de un usuario y la reputacion
// retorna un objeto que contiene: los 4 estados que puede tener un usuario en el ranking
// (nuevo, regular, y excelente), cada llave del objeto sera igual al nombre de la clase
// asociado a ese estado, y si es necesario le agregara la clase "opacity"
export const rankingUserClass = (registration_date, reputation) => {
  const CLASSES = {
    NEW: "rankingGraphNormal",
    REGULAR: "rankingGraphRegular",
    GOOD: "rankingGraphGood",
    EXCELENT: "rankingGraphExcelent",
  };

  const addOpacityClass = (string) => {
    return `${string} opacity`;
  };

  let classesResult = {
    new: CLASSES.NEW,
    regular: CLASSES.REGULAR,
    good: CLASSES.GOOD,
    excelent: CLASSES.EXCELENT,
  };

  if (reputation <= 0) {
    classesResult.new = addOpacityClass(classesResult.new);
    classesResult.regular = addOpacityClass(classesResult.regular);
    classesResult.good = addOpacityClass(classesResult.good);
    classesResult.excelent = addOpacityClass(classesResult.excelent);
  } else if (reputation > 0 && reputation <= 1.25) {
    classesResult.regular = addOpacityClass(classesResult.regular);
    classesResult.good = addOpacityClass(classesResult.good);
    classesResult.excelent = addOpacityClass(classesResult.excelent);
  } else if (reputation > 1.25 && reputation <= 2.9) {
    classesResult.good = addOpacityClass(classesResult.good);
    classesResult.excelent = addOpacityClass(classesResult.excelent);
  } else if (reputation > 2.9 && reputation <= 4) {
    classesResult.excelent = addOpacityClass(classesResult.excelent);
  }
  return classesResult;
};

// recibe un fecha (con el formato que responde el API de locambio.com)
// y la retorna del tipo: 10 de Marzo de 2019
export const dateMonthString = (date) => {
  if (!date) {
    return "";
  }
  let dateFormat = moment(`${date.split(".")[0].split("T")[0]}`).format(
    "YYYY-MM-DD"
  );
  let splDate = dateFormat.split("-");

  const monthToString = (month) => {
    switch (parseInt(month)) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
    }
  };
  return `${splDate[2]} de ${monthToString(splDate[1])} de ${splDate[0]}`;
};

//formater example: "17 de Ago. 2023"
export const LCCustomDateFormater = (date) => {
  const months = [
    "Ene.",
    "Feb.",
    "Mar.",
    "Abr.",
    "May.",
    "Jun.",
    "Jul.",
    "Ago.",
    "Sep.",
    "Oct.",
    "Nov.",
    "Dic.",
  ];
  
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  return `${day} de ${month} ${year}`;
}

// recibe un fecha (con el formato que responde el API de locambio.com)
// y la retorna del tipo: 10 de Marzo de 2019
export const dateMonthStringShort = (date) => {
  if (!date) {
    return "";
  }
  let formatDate = moment(date)
    .locale("es")
    .format("DD MMM YYYY")
    .replace(" ", " de ")
    .split(" ");
  formatDate[2] =
    formatDate[2].charAt(0).toUpperCase() + formatDate[2].slice(1);
  return formatDate.join(" ");
};

// FUNCION DATE DE PRUEBA
export function getCurrentDate(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}

// DATE LOCAMBIO
// STANDARD FORMAT
moment.locale("es");
export const datelocambio = (date) => {
  return moment.parseZone(date).utc().format("D MMM YYYY");
};

export const longDate = (date) => {
  return moment.parseZone(date)
    .utc()
    .format('D [de] MMMM YYYY HH:mm a');
};

// DATE LOCAMBIO
// SHORT FORMAT
// FORMAT HOY - AYER - HACE UNA SEMANA - HACE DOS SEMANAS
moment.locale("es");
export const dateShort = (date) => {
  let date_actual = moment();
  let date_noti = moment(
    `${date.split(".")[0].split("T")[0]} ${date.split(".")[0].split("T")[1]}`
  );
  let dateFormat = moment(
    `${date.split(".")[0].split("T")[0]} ${date.split(".")[0].split("T")[1]}`
  ).format("YYYY-MM-DD hh:mm");

  let days_differnce = date_actual.diff(date_noti, "days");
  let day_comment = parseInt(dateFormat.split(" ")[0].split("-")[2]);
  let hour_comment = `${dateFormat.split(" ")[1].split(":")[0]}:${
    dateFormat.split(" ")[1].split(":")[1]
  }`;

  if (days_differnce <= 0) {
    return "Hoy";
  } else if (days_differnce === 1) {
    return "Ayer";
  } else if (days_differnce === 2) {
    return "Ante-ayer";
  } else if (days_differnce > 2 && days_differnce <= 13) {
    return "Hace una semana";
  } else if (days_differnce >= 14 && days_differnce <= 20) {
    return "Hace dos semanas";
  } else if (days_differnce >= 21 && days_differnce <= 29) {
    return "Hace tres semanas";
  } else if (days_differnce >= 30 && days_differnce <= 59) {
    return "Hace un mes";
  } else if (days_differnce >= 60 && days_differnce <= 89) {
    return "Hace dos meses";
  } else if (days_differnce > 90) {
    return "Hace meses";
  }
};

// recibe el valor de la distancia de un producto (como responde el API de locambio.com)
// y la retorna dle tipo: 10 km
export const distanceFormat = (value) => {
  if (!value) {
    return "0 km";
  }
  return (
    (parseInt(value.split(".")[0]) / 1000).toString().split(".")[0] + " km"
  );
};

// retorna true si es una nueva publicacion
// es considerada una nueva publicacion si tiene menos de 5 dias de ser creada
export const isNewPublication = (date) => {
  const date_actual = getDateActual().toString();
  const date1 = new moment(date_actual);
  const date2 = new moment(date);
  const diff = date1.diff(date2, "days");
  return diff < 5;
};

// valores para el fitro de fechas
export const DATE_FILTER_VALUES = {
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
  BEFORE_YESTERDAY: "BEFORE_YESTERDAY",
  SEVEN_DAYS: "SEVEN_DAYS",
  THIRTY_DAYS: "THIRTY_DAYS",
  THREE_MONTHS: "THREE_MONTHS",
  ORIGIN_TIMES: "",
};

// valores para el filtro por usuario (todos, solo cercanos, solo seguidores)
export const VIEW_FILTER_VALUES = {
  EVERYONE: "EVERYONE",
  ONLY_NEARBY: "ONLY_NEARBY",
  ONLY_FOLLOWERS: "ONLY_FOLLOWERS",
};

// recibe como parametro un valor de los filtros por fecha y lo retorna
// de una manera que el usuario pueda entender
export const translateValueFilterToSpanish = (value) => {
  switch (value) {
    case DATE_FILTER_VALUES.TODAY:
      return "Hoy";
    case DATE_FILTER_VALUES.YESTERDAY:
      return "Ayer";
    case DATE_FILTER_VALUES.BEFORE_YESTERDAY:
      return "Antes de Ayer";
    case DATE_FILTER_VALUES.SEVEN_DAYS:
      return "Ultimos 7 Dias";
    case DATE_FILTER_VALUES.THIRTY_DAYS:
      return "Ultimos 30 Dias";
    case DATE_FILTER_VALUES.THREE_MONTHS:
      return "Ultimos 3 Meses";
    case VIEW_FILTER_VALUES.EVERYONE:
      return "Todos";
    case VIEW_FILTER_VALUES.ONLY_NEARBY:
      return "Usuarios Cercanos";
    case VIEW_FILTER_VALUES.ONLY_FOLLOWERS:
      return "Usuarios Seguidores";
    case DATE_FILTER_VALUES.ORIGIN_TIMES:
      return "Todos";
    case "":
      return "Desde de siempre";
    default:
      return value;
  }
};

// valor del mensaje automatizado de locambio.com al crear un nuevo chat
export const AUTOMATIC_MESSAGE = "¡Nuevo chat! Contacta al usuario";

// dependiendo del usuario que este viendo el mensaje retorna el valor del mensaje automatizado
export const getTextAutomaticMessage = (userId, user_from) => {
  if (userId.toString() === user_from.toString()) {
    return "¡Nuevo chat! Contacta al usuario";
  } else {
    return "¡Nuevo chat! Contacta al usuario";
  }
};

export const handleClosePopover = (e) => {
  const { parentElement } = e.currentTarget.parentElement;
  parentElement.classList.add("bp3-popover-dismiss");
  parentElement.click();
};

export const getGCSUrl = (url) => {
  if (!url) return;
  const baseHost = "storage.googleapis";
  const newUrl = url
    .replace(`${config.GCS_BUCKET}/`, "")
    .replace(baseHost, `${config.GCS_BUCKET}.${baseHost}`);
  return `https:${newUrl}`;
};

export const lastElement = (array) => array[array.length - 1];

export const getAllCategories = (category, categories = [category]) => {
  if (category.product_category) {
    categories.push(category.product_category);
    return getAllCategories(category.product_category, categories);
  }
  return categories.reverse();
};

export const checkWithCurrentUser = (id) =>
  parseInt(localStorage.getItem("userId")) === id;

export const baseAction = (type, data) => ({
  type,
  data,
});

export const SibConversations = (d, w, c) => {
  w.SibConversationsID = "6230a5e9bc51611723584678";
  w[c] =
    w[c] ||
    function () {
      (w[c].q = w[c].q || []).push([d, w, c]);
    };
  var s = d.createElement("script");
  s.async = true;
  s.src = "https://conversations-widget.sendinblue.com/sib-conversations.js";
  if (d.head) d.head.appendChild(s);
};

  // get correct decimals
  export const RefineDecimals = (x, position = 0) => {
    var s = x.toString()
    var l = s.length
    var decimalLength = s.indexOf('.') + 1
  
    if (l - decimalLength <= position){
      return x
    }
    var isNeg  = x < 0
    var decimal =  x % 1
    var integer  = isNeg ? Math.ceil(x) : Math.floor(x)
    var decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, position)
    )
    var finalNum = integer + 
      ((decimalFormated / Math.pow(10, position))*(isNeg ? -1 : 1))
    
    return finalNum
  }


  export const phoneFormatter = (number) => {
    return `+(${number.slice(0, 2)}) ${number.slice(2, 5)}-${number.slice(5)}`
  }

  export const sumOfPrices = (obj) => {
    return obj.reduce((count, current) => count + parseFloat(current.price), 0);
  }

export const cleanClassName = (className) => className || '';