import React, { useState, useEffect } from 'react';
import { Collapse, Icon } from '@blueprintjs/core';

import './Accordion.scss';

const Accordion = ({ data, initialDisplay }) => {
  const [openPanel, setOpenPanel] = useState(1);

  useEffect(() => {
    displayValue();
  }, []
  )

  const displayValue = () => {
    if (initialDisplay) {
      setOpenPanel(1)
    } else {
      setOpenPanel(null);
    }
  }
  // qwefqwefqwefwqefijweofijweofijwoifjwoifweouoij
  const handleClick = (id) => {
    if (id === openPanel) {
      return setOpenPanel(0);
    }
    setOpenPanel(id);
  };

  const accordionData = (
    <section className="accordion">
      <h3 className="accordion__title">{data.title}</h3>
      {data.sections.map((section, index) => {
        return (
          <section key={`section-${index}`} className="accordion__section">
            <h4 className="accordion__section-title">{section.title}</h4>
            {section.questions.map((question, index) => {
              return (
                <div
                  key={`question-${index}`}
                  className="accordion__section__question"
                  onClick={(_) => {
                    handleClick(question.id);
                  }}
                >
                  <div className="accordion__section__question-container">
                    <p className="accordion__section__question-title">
                      {question.title}
                    </p>
                    <Icon
                      className="accordion__section__question-icon"
                      icon={
                        openPanel === question.id
                          ? 'chevron-up'
                          : 'chevron-down'
                      }
                      iconSize={30}
                    />
                  </div>
                  <Collapse isOpen={openPanel === question.id ? true : false}>
                    {question.answer}
                  </Collapse>
                </div>
              );
            })}
          </section>
        );
      })}
    </section>
  );

  return (
    <>
      {accordionData}
    </>
  );
};

export default Accordion;
