import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { Card, Dialog, Elevation, Tag } from '@blueprintjs/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import ProducDetailsMobileActions from './ProducDetailsMobileActions';
import { isNewPublication, needOverlay } from '../../utilityFunctions';
import OverlayPremium from '../../components/OverlayPremium';
import ProductDetailSection from './ProductDetailSection';
import ProductSwiper, { swiperParams } from '../../components/productSwiper/ProductSwiper';
import WrapperImageZoom from '../../components/wrapperImageZoom/WrapperImageZoom';
import './ProductDetailMainSection.scss';

const modalStyle = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  width: '60%',
};

export default function ProductDetailMainSection({
  pubInTransaction,
  pub,
  toChange,
  isAuthenticated,
  userId,
  handlerVisibiltyUserDetailPanel,
  suscription,
}) {
  const [isOpenCarouselModal, setIsOpenCarouselModal] = useState(false);

  const handleOpenCarouselModal = () => {
    setIsOpenCarouselModal(true);
  };

  const imageSection = (
  <>
    <div className="positionNewTag">
      {isNewPublication(pub.publish_date) ? (
        <Tag className="tagNew">Nueva publicación</Tag>
      ) : null}
    </div>
    <ProductSwiper
      zoom
      onClick={handleOpenCarouselModal}
      imgClass="zoom-in"
      pictures={pub.pictures}
      needOverlay={needOverlay(
        pub.product_category,
        suscription,
      )}
    />
    {needOverlay(pub.product_category, suscription) && (
      <OverlayPremium />
    )}
  </>
  )

  return (
    <Card elevation={Elevation.ONE}>
      <Dialog
        isOpen={isOpenCarouselModal}
        onClose={() => setIsOpenCarouselModal(false)}
        style={modalStyle}
      >
        <Swiper {...swiperParams}>
          {pub.pictures.map((image) => (
            <SwiperSlide>
              <img
                className="product-modal-img"
                alt="product"
                src={image?.url || image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Dialog>
      <Row>
        <ProductDetailSection
          pub={pub}
          pubInTransaction={pubInTransaction}
          toChange={toChange}
          suscription={suscription}
          isAuthenticated={isAuthenticated}
          userId={userId}
          wrapperImageZoom={<WrapperImageZoom />}
          imageSection={imageSection}
        />
      </Row>
    </Card>
  );
}
