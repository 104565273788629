import { useDispatch } from "react-redux";
import { setScrollTo } from "../../../../store/actions";
import React, { useState, useEffect } from "react";
import PanelStack from "../../../../components/panelStack";
import { Controller, useWatch } from "react-hook-form";
import { RadioGroup, Radio, FormGroup, Icon } from "@blueprintjs/core";

import ScrollToElement from "../../../../components/scrollToElement/ScrollToElement";

import "./Step1.scss";

export default function Step1({ control, setValue, setContinue, categories }) {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const location = window.location.pathname;
  const isUpdate = location.includes("edit");

  const handleClick = (elem) => {
    const parentsArray = [];
    let parent = "";
    let found = false;
    if (elem.category_level > 0) {
      categories.forEach((parentElem) => {
        if (found) return;
        if (parentElem.id == elem.father_id) {
          parent = parentElem.name;
          parentsArray.push(parentElem);
          found = true;
          return;
        }
        parentElem.categories.forEach((subParent) => {
          if (found) return;
          if (subParent.id == elem.father_id) {
            parent = subParent.name;
            parentsArray.push(parentElem);
            parentsArray.push(subParent);
            found = true;
            return;
          }
        });
      });
    }
    parentsArray.push(elem);
    let stringParents = "";
    parentsArray.forEach((elem, index) => {
      stringParents =
        stringParents + (index > 0 ? ` > ${elem.name}` : elem.name);
    });
    setCategory(stringParents);
    dispatch(setScrollTo(stringParents));
    setValue("category", elem.id);
    setValue("categoryWithParents", parentsArray);
    setContinue(true);
  };
  let categoryWithParentsWatch = useWatch({
    control,
    name: "categoryWithParents",
  });

  const product = useWatch({
    control,
    name: "product",
    defaultValue: "product",
  });

  useEffect(() => {
    if (!categoryWithParentsWatch?.length) {
      setContinue(false);
    } else {
      setContinue(true);
    }
    if (categoryWithParentsWatch?.length && !category) {
      let stringParents = "";
      categoryWithParentsWatch?.forEach((elem, index) => {
        stringParents =
          stringParents + (index > 0 ? ` > ${elem.name}` : elem.name);
      });
      setCategory(stringParents);
    }
  }, [categoryWithParentsWatch]);

  useEffect(() => {
    !isUpdate && removeItem()
  }, [product]);

  const removeItem = () => {
    setValue("category", "");
    setValue("categoryWithParents", undefined);
    setContinue(false);
    setCategory(undefined);
  };

  return (
    <div className="step1">
      <div className="card">
        <FormGroup label="Tipo de publicación" className="inputLabel">
          <div className="column">
            <RadioGroup large inline className="radioTags">
              <Controller
                name="product"
                control={control}
                rules={{ required: true }}
                defaultValue="product"
                render={({ field }) => (
                  <>
                    <Radio
                      {...field}
                      label="Producto"
                      value="product"
                      id="publicationType"
                      checked={field.value == "product"}
                      disabled={isUpdate}
                    />
                    <Radio
                      {...field}
                      label="Servicio"
                      value="service"
                      id="publicationType"
                      checked={field.value == "service"}
                      disabled={isUpdate}
                    />
                  </>
                )}
              />
            </RadioGroup>
          </div>
        </FormGroup>
      </div>
      <div className="card">
        <h3 className="third-Title">{product === 'product' ? '¿A qué categoria pertenece tu artículo?' : 'Busca la categoría de tu servicio'}</h3>
        <div className="row">
          <div className="categories">
            <PanelStack
              functionality={handleClick}
              categoryFilter={product == "service" ? "Servicios" : ""}
            />
          </div>
          <div className="sub-Card interest">
            <h4>Categoría seleccionada</h4>
            <div>
              {category ? (
                <ScrollToElement key={category} scroll highlight>
                  <div className="row">
                    <div className="interesItem purple-text">
                      <p>{category}</p>
                      <Icon
                        icon="cross"
                        className="purple-Text"
                        iconSize={20}
                        onClick={(e) => removeItem()}
                      />
                    </div>
                  </div>
                </ScrollToElement>
              ) : (
                <div className="no-interests">
                  <Icon icon="issue" iconSize={15} />
                  No hay categoría agregada
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
