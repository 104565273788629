import React from 'react';
import Text from '../../../text';

import './Price.scss';

const Price = ({ price, className, currencyClassName, numberClassName, decimalsClassName }) => {
  const splitPrice = price.toFixed(2).split('.');
  const classNames = `price ${className}`;
  const currencyClassNames = `price__currency ${currencyClassName}`;
  const numberClassNames = `price__number ${numberClassName}`;
  const decimalsClassNames = `price__decimals ${decimalsClassName}`;

  return (
    <section className={classNames}>
      <Text className={currencyClassNames}>$</Text>
      <Text className={numberClassNames}>{splitPrice[0]}</Text>
      <Text className={decimalsClassNames}>,{splitPrice[1]}</Text>
    </section>
  );
};

export default Price;