import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { config } from "../../system/Config";
import React, { useEffect, useState } from "react";
import { useQueryString } from "../../hooks/useQueryString";

import history from "../../utility/history";
import Text from "../../components/text/Text";
import { getHeader } from "../../system/Sesion";
import CampaignItem from "./components/campaignItem";
import SearchListPagination from "../searchList/SearchListPagination";

import "./Campaigns.scss";
import { useSelector } from "react-redux";

const Campaigns = () => {
  const { notification } = useSelector(
    ({ newNotification }) => newNotification
  );
  const location = useLocation();
  const perPage = 10;
  const [list, setList] = useState({});
  const queryString = useQueryString();
  const [campaigns, setCampaigns] = useState({});
  const [promotables, setPromotables] = useState({});
  const [listIsArray, setListIsArray] = useState(false);
  const [tab, setTab] = useState(queryString.get("tab") || "campaigns");

  const [currentData, setCurrentData] = useState();
  const [totalPages, setTotalPages] = useState({});
  const [totalItems, setTotalItems] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const fetchBase = async (urlComplement, setter, tabName) => {
    try {
      const response = await axios.get(`${config.URL_API}${urlComplement}`, {
        headers: getHeader(),
      });
      setter(response.data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const currentTabData = () => (tab === "campaigns" ? campaigns : promotables);

  const getPromotables = (page) =>
    fetchBase(
      `/v1/publication/promotables?page=${page}&per_page=${perPage}`,
      setPromotables,
      "promotables"
    );

  const getCampaigns = (page) =>
    fetchBase(
      `/v1/ads?page=${page}&per_page=${perPage}`,
      setCampaigns,
      "campaigns"
    );

  const isCampaign = tab === "campaigns";

  const handlePageChange = (number) => {
    if (isCampaign) {
      getCampaigns(number);
    } else {
      getPromotables(number);
    }
  };

  const isCurrentTab = (value) => tab === value;

  const classActiveTab = (value) =>
    isCurrentTab(value) ? "campaigns__tab-option--active" : "";

  const emptyStateElements = {
    campaigns: {
      title: "No tienes promociones activas",
      text1: "Ve a la pestaña",
      anchor: "Promocionables",
      text2: "y escoge un paquete de visualizaciones",
      color: "blue",
      url: {
        route: "/profile",
        search: "?tab=promotables",
      },
    },
    promotables: {
      title: "No tienes publicaciones disponibles para promocionar",
      text1:
        "Todas las publicaciones activas pueden promocionarse, anímate y empieza a ",
      anchor: "Publicar",
      text2: "tus productos y servicios",
      color: "accent",
      url: { route: "/new_publication" },
    },
  };

  const emptyStateComponent = () => {
    const {
      title,
      text1,
      text2,
      color,
      anchor,
      url: { route, search },
    } = emptyStateElements[tab];
    return (
      <section className="campaigns__empty-state">
        <h1 className="campaigns__empty-state-title">{title}</h1>
        <p className="campaigns__empty-state-text">
          {text1}
          <Link to={{ pathname: route, search: search }}>
            <Text color={color} className={"campaigns__anchor--in-line"}>
              &nbsp;{anchor}&nbsp;
            </Text>
          </Link>
          {text2}
        </p>
      </section>
    );
  };

  const getBaseData = () => {
    getCampaigns(currentPage);
    getPromotables(currentPage);
  };

  useEffect(() => {
    setCurrentData(currentTabData);
  }, [promotables, campaigns]);

  useEffect(() => {
    if (
      notification?.type_notification_id === "payment_gateway_status" ||
      notification?.type_notification_id === "payment_with_credit"
      // TODO: remove the previous line when the credit notifications have been created
    ) {
      getBaseData();
    }
  }, [notification]);

  useEffect(() => {
    getBaseData();
  }, []);

  useEffect(() => {
    history.push(`/profile?tab=${tab}`);
    setCurrentData(currentTabData);
  }, [tab]);

  useEffect(() => {
    if (currentData) {
      const { data, page, pages, total } = currentData;
      setList(data);
      setCurrentPage(page);
      setTotalPages(pages);
      setTotalItems(total);
    }
  }, [currentData]);

  useEffect(() => {
    setTab(queryString.get("tab") || "campaigns");
  }, [location.search]);

  useEffect(() => {
    if (Array.isArray(list)) {
      setListIsArray(true);
    }
  }, [list]);

  return (
    <section className="campaigns">
      <nav className="campaigns__panel">
        <div
          className={`campaigns__tab-option ${classActiveTab("campaigns")}`}
          onClick={() => {
            setTab("campaigns");
          }}
        >
          <div className="campaigns__rounded-square">
            <div>{campaigns?.total || 0}</div>
          </div>
          Promociones Activas
        </div>
        <div
          className={`campaigns__tab-option ${classActiveTab("promotables")}`}
          onClick={() => {
            setTab("promotables");
          }}
        >
          <div className="campaigns__rounded-square">
            <div>{promotables?.total || 0}</div>
          </div>
          Promocionables
        </div>
      </nav>

      <section className="campaigns__list">
        {listIsArray && list?.length > 0 ? (
          <>
            {list.map((current) => (
              <CampaignItem
                key={current.id}
                dataElement={current}
                isCampaign={isCampaign}
                handleSelectPlan={getBaseData}
              />
            ))}
            <div className="campaigns__list-pagination">
              <SearchListPagination
                handlePageChange={handlePageChange}
                totalPublications={totalItems}
                activePage={currentPage}
                perPage={perPage}
                showMax={perPage}
              />
            </div>
          </>
        ) : (
          emptyStateComponent()
        )}
      </section>
    </section>
  );
};

export default Campaigns;
