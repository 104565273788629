import React from 'react';
import PropTypes from 'prop-types';
import { Navigation } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';


const settings = {
  modules: [Navigation],
  navigation: true,
  initialSlide: 0,
  slidesPerView: 1.5,
  spaceBetween: 20,
  centeredSlides: true,
};

const Categories = ({ categories, title, type }) => {
  const {latitude, longitude} = useSelector((state) => state.geolocation)
  let history = useHistory();
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const { normal, highlight } = title;
  const toSearch = (category) => {
    history.push(
      `/search_list_by_category/${category}/${latitude}/${longitude}/category`
    );
  };

  const allCategories = categories.map((category) => {
    const content = (
      <div
        onClick={() => toSearch(category.id)}
        className="categories__category cursor-pointer"
        key={`category-${category.name}`}
      >
        <img
          className="categories__image"
          src={category.image}
          alt={category.name}
        />
        <p className="categories__name">{category.name}</p>
      </div>
    );

    if (isNotMobile) return content;
    return (
      <SwiperSlide key={`category-slide-${category.name}`}>
        {content}
      </SwiperSlide>
    );
  });

  return (
    <div className="categories section section--with-max-width">
      <p className="section__title">
        <span className="section__title--bolder">{normal || title} </span>
        <span className={`section__title--${type} section__title--gilroy`}>{highlight} </span>
      </p>
      {isNotMobile ? (
        <div className="categories__container">{allCategories}</div>
      ) : (
        <Swiper {...settings}>{allCategories}</Swiper>
      )}
    </div>
  );
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      normal: PropTypes.string.isRequired,
      highlight: PropTypes.string,
    }),
  ]).isRequired,
  type: PropTypes.oneOf(['primary', 'accent']),
};

Categories.defaultProps = {
  categories: [
    {
      name: 'Carros y camionetas',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/vehicles.svg',
      id: 529,
    },
    {
      name: 'Inmuebles',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/real_estate.svg',
      id: 2123,
    },
    {
      name: 'Hogar y Muebles',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/furniture.svg',
      id: 1792,
    },
    {
      name: 'Ropa, Zapatos y Accesorios',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/clothing.svg',
      id: 2629,
    },
    {
      name: 'Accesorios para vehículos',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/automotive.svg',
      id: 46,
    },
    {
      name: 'Juegos y Juguetes',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/toys.svg',
      id: 2286,
    },
    {
      name: 'Estética y Belleza',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/beauty.svg',
      id: 1477,
    },
    {
      name: 'Bebés',
      image: 'https://storage.googleapis.com/locambio-admin/pages/home/categories/products/kids.svg',
      id: 370,
    },
  ],
  title: {
    normal: 'Explora artículos',
    highlight: 'nuevos o usados'
  },
  type: 'primary',
};

export default Categories;
