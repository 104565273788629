import React, { useState, useEffect } from 'react';
import SelectWithInput from '../../../../components/selectWithInput';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FormGroup,
  InputGroup,
  HTMLSelect,
  RadioGroup,
  Radio,
  Position,
  Popover,
  Checkbox,
  Dialog,
  Icon,
} from '@blueprintjs/core';
import { monthsOptions } from '../../../../utilityFunctions';
import moment from 'moment';
import PasswordPopover from '../passwordPopover';
import { Col, Row } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import PopUp from '../../../../components/popUp';
import DropZone from '../../../../components/customDropZone';
import './IndividualForm.scss';

export default function IndividualForm({
  handleFormChange,
  countries,
  states,
  errors,
  control,
  regularExpressions,
  name,
  lastName,
  email,
  hasPassword,
  setValue,
  resetField,
}) {
  const phoneOptions = [
    {
      label: 'Cod.',
      value: '',
      selected: true,
      disabled: true,
    },
    { label: '412', value: '412' },
    { label: '416', value: '416' },
    { label: '426', value: '426' },
    { label: '414', value: '414' },
    { label: '424', value: '424' },
  ];
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState(monthsOptions);
  const [statesEmpty, setStatesEmpty] = useState([
    { label: 'Selecciona', value: '', disabled: true, selected: true },
  ]);
  const [year, setYear] = useState(control?._fields?.year?._f.value);
  const [month, setMonth] = useState(control?._fields?.month?._f.value);
  const [country, setCountry] = useState(control?._fields?.country?._f.value);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [password, setPassword] = useState('');
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const isLeap = (year) => {
    return parseInt(year) % 4 === 0;
  };

  useEffect(() => {
    setDatesForm();
  }, []);

  const getDays = (month, year) => {
    let totalDays = 31;
    const daysArr = [
      { label: 'Día', value: '', disabled: true, selected: true },
    ];
    if (!month || !year) return daysArr;
    switch (month) {
      case '02':
        if (isLeap(year)) {
          totalDays = 29;
          break;
        }
        totalDays = 28;
        break;
      case '04':
      case '06':
      case '09':
      case '11':
        totalDays = 30;
        break;
    }
    for (let i = 1; i <= totalDays; i++) {
      daysArr.push({ label: i, value: i.toString() });
    }
    return daysArr;
  };
  const [days, setDays] = useState(
    control?._fields?.month?._f.value && control?._fields?.year?._f.value
      ? getDays(
          control?._fields?.month?._f.value,
          control?._fields?.year?._f.value
        )
      : getDays(0, 0)
  );

  const setDatesForm = () => {
    const date_actual = moment(new Date()).subtract(18, 'year').format('YYYY');
    const years = [];
    years.push({ label: 'Año', value: '', disabled: true, selected: true });
    for (let i = 0; i < 75; i++) {
      let year = moment(date_actual).subtract(i, 'year').format('YYYY');
      years.push({ label: year, value: year.toString() });
    }
    setYears(years);
  };

  const handleFocusPassword = (e) => {
    setIsPasswordFocused(true);
  };
  const handleBlurPassword = (e) => {
    setIsPasswordFocused(false);
  };

  function handleShowModal(handleShowModal) {
    setShowSecurityModal(!showSecurityModal);
  }

  return (
    <>
      <PopUp displayModal={showSecurityModal} hideModal={handleShowModal}>
        <img
          src={
            isLargerThanMobile
              ? 'https://storage.googleapis.com/locambio-admin/popups/locambioseguro/popup0-600x400.jpg'
              : 'https://storage.googleapis.com/locambio-admin/popups/locambioseguro/popup0-250x300.jpg'
          }
        />
      </PopUp>
      <p onClick={handleShowModal} className="link_to_modal">
        ¿Sabe por qué le solicitamos esta información? Es por su seguridad, por
        favor haga clic en este vínculo para leer sobre nuestros consejos de
        seguridad
      </p>
      <div>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <FormGroup
              label="Nombre"
              labelFor="firstName"
              labelInfo="*"
              className="inputLabel"
            >
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                defaultValue={name ? name : null}
                render={({ field }) => (
                  <div className="fullWidth">
                    <InputGroup
                      {...field}
                      className={`inputField ${
                        errors.firstName && '.bp4-intent-danger'
                      }`}
                      id="firstName"
                      intent={errors.firstName && 'danger'}
                      type="text"
                      placeholder="Escríbelo tal como está en tu cédula"
                    />
                    {errors.firstName && (
                      <p className="errorMessage">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <FormGroup
              label="Apellido"
              labelFor="lastName"
              labelInfo="*"
              className="inputLabel"
            >
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                defaultValue={lastName ? lastName : null}
                render={({ field }) => (
                  <div className="fullWidth">
                    <InputGroup
                      {...field}
                      className={`inputField ${
                        errors.lastName && '.bp4-intent-danger'
                      }`}
                      id="lastName"
                      intent={errors.lastName && 'danger'}
                      type="text"
                      placeholder="Escríbelo tal como está en tu cédula"
                    />{' '}
                    {errors.lastName && (
                      <p className="errorMessage">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <FormGroup
              label="Correo electrónico"
              labelFor="email"
              labelInfo="*"
              className="inputLabel"
            >
              <Controller
                name="email"
                control={control}
                defaultValue={email ? email : null}
                rules={{
                  pattern: regularExpressions && regularExpressions.email,
                  required: true,
                }}
                render={({ field }) => (
                  <div className="fullWidth">
                    <InputGroup
                      {...field}
                      className={`inputField ${
                        errors.email && '.bp4-intent-danger'
                      }`}
                      id="email"
                      type="email"
                      disabled={email ? true : false}
                      intent={errors.email && 'danger'}
                      placeholder="Escribe aquí"
                    />
                    {errors.email && (
                      <p className="errorMessage">Este campo es obligatorio</p>
                    )}
                  </div>
                )}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <SelectWithInput
              handleFormChange={handleFormChange}
              label="Teléfono"
              placeholder="Escribe aqui"
              required
              inputName="phoneString"
              selectName="phoneType"
              options={phoneOptions}
              control={control}
              rule={regularExpressions}
              setValue={setValue}
              errors={errors}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="location">
              <div>
                <FormGroup
                  label="País"
                  labelFor="country"
                  labelInfo="*"
                  className="inputLabel countrySelect"
                >
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <HTMLSelect
                          {...field}
                          id="country"
                          minimal
                          className={`selectDates ${
                            errors.country ? 'bp3-intent-danger' : null
                          }`}
                          options={countries}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            field.onChange(e);
                          }}
                        />
                        {errors.country && (
                          <p className="errorMessage">
                            Este campo es obligatorio
                          </p>
                        )}
                      </>
                    )}
                  />
                </FormGroup>
              </div>
              <div>
                <FormGroup
                  label="Estado"
                  labelFor="state"
                  labelInfo="*"
                  className="inputLabel countrySelect"
                >
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <HTMLSelect
                          {...field}
                          id="state"
                          minimal
                          disabled={!country}
                          className={`selectDate ${
                            !country ? 'disabled' : ''
                          } ${errors.state ? 'bp3-intent-danger' : null}`}
                          options={country ? states[country] : statesEmpty}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                        {errors.state && (
                          <p className="errorMessage">
                            Este campo es obligatorio
                          </p>
                        )}
                      </>
                    )}
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <FormGroup
              label="Ciudad"
              labelFor="city"
              labelInfo="*"
              className="inputLabel"
            >
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <InputGroup
                    {...field}
                    className="inputField"
                    id="city"
                    type="text"
                    placeholder="Escribe tu ciudad aquí"
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <FormGroup
              label="Fecha de nacimiento (+18 años)"
              labelFor="year"
              labelInfo="*"
              className="inputLabel"
            >
              <Row className="date">
                <Col xs={4} sm={4} md={4} lg={4}>
                  <Controller
                    name="year"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <HTMLSelect
                        {...field}
                        id="year"
                        minimal
                        className={`selectDates ${
                          errors.year || errors.month || errors.day
                            ? 'bp3-intent-danger'
                            : null
                        }`}
                        options={years}
                        onChange={(e) => {
                          setYear(e.target.value);
                          field.onChange(e);
                          setDays(getDays(month, e.target.value));
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <Controller
                    name="month"
                    control={control}
                    render={({ field }) => (
                      <HTMLSelect
                        {...field}
                        id="month"
                        minimal
                        className={`selectDates ${
                          errors.year || errors.month || errors.day
                            ? 'bp3-intent-danger'
                            : null
                        }`}
                        options={months}
                        onChange={(e) => {
                          setMonth(e.target.value);
                          field.onChange(e);
                          setDays(getDays(e.target.value, year));
                        }}
                      />
                    )}
                  />
                </Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                  <Controller
                    name="day"
                    control={control}
                    render={({ field }) => (
                      <HTMLSelect
                        {...field}
                        id="day"
                        minimal
                        disabled={!month || !year}
                        className={`selectDates ${
                          !month || !year ? 'disabled' : ''
                        } ${
                          errors.year || errors.month || errors.day
                            ? 'bp3-intent-danger'
                            : null
                        }`}
                        options={days}
                      />
                    )}
                  />
                </Col>
              </Row>
            </FormGroup>
            {errors.year || errors.month || errors.day ? (
              <p className="errorMessage">Este campo es obligatorio</p>
            ) : null}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <FormGroup label="Género" labelInfo="*" className="inputLabel">
              <RadioGroup selectedValue="M" className="radioTags" inline>
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Radio {...field} label="Masculino" value="1" selected />
                  )}
                />
                <Controller
                  name="gender"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Radio {...field} label="Femenino" value="0" />
                  )}
                />
              </RadioGroup>
              {errors.gender && (
                <p className="errorMessage">Este campo es obligatorio</p>
              )}
            </FormGroup>
          </Col>
        </Row>
        {hasPassword && (
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Popover
                isOpen={isPasswordFocused}
                position={Position.TOP_LEFT}
                content={
                  <PasswordPopover
                    regularExpressions={regularExpressions}
                    password={password}
                  />
                }
              >
                <FormGroup
                  label="Contraseña"
                  labelFor="password"
                  labelInfo="*"
                  className="inputLabel"
                >
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: true,
                      pattern:
                        regularExpressions &&
                        regularExpressions.upperCase &&
                        regularExpressions.special &&
                        regularExpressions.number,
                    }}
                    render={({ field }) => (
                      <div className="fullWidth">
                        <InputGroup
                          {...field}
                          className={`inputField ${
                            errors.password && '.bp4-intent-danger'
                          }`}
                          intent={errors.password && 'danger'}
                          id="password"
                          placeholder="Escribe aquí"
                          type="password"
                          onFocus={handleFocusPassword}
                          onBlur={handleBlurPassword}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            field.onChange(e);
                          }}
                        />
                        {errors.password && (
                          <p className="errorMessage">
                            Este campo es obligatorio
                          </p>
                        )}
                      </div>
                    )}
                  />
                </FormGroup>
              </Popover>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <FormGroup
                label="Confirmar contraseña"
                labelFor="confirmPassword"
                labelInfo="*"
                className="inputLabel"
              >
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputGroup
                      {...field}
                      className={`inputField ${
                        errors.confirmPassword && '.bp4-intent-danger'
                      }`}
                      intent={errors.confirmPassword && 'danger'}
                      id="confirmPassword"
                      type="password"
                      placeholder="Escribe aquí"
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </div>
      <div className="row">
        <div className="img_box">
          <DropZone
            control={control}
            name="profilePicture"
            label="Imagen de perfil"
            setValue={setValue}
            resetField={resetField}
            withThumb
          />
        </div>
      </div>
    </>
  );
}

IndividualForm.propTypes = {
  handleFormChange: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.object),
  states: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
  regularExpressions: PropTypes.object,
  setValue: PropTypes.func,
};
