const STATUSES = {
  approved: 'approved',
  pending: 'pending',
  toBeConfirmed: 'to_be_confirmed',
  rejected: 'rejected',
};

const proformasExport = {
  STATUSES,
};

export default proformasExport;