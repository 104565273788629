import React from "react";
import { Row, Col } from "react-grid-system";
import { Callout } from "@blueprintjs/core";
import "./ProfileStatsInfo.scss";

const ProfileStatsInfo = (props) => {
  const { user } = { ...props };
  const publications = user.publication_actives || 0;
  const changes = user.successful_changes || 0;
  return (
    <div className={"profile-stats-info"}>
      <div>
        <h2>{publications}</h2>
        <p>Publicaciones Activas</p>
      </div>
      <div>
        <h2>{changes}</h2>
        <p>Cambios Exitosos</p>
      </div>
    </div>
  );
};

export default ProfileStatsInfo;
