import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Icon, MenuDivider } from '@blueprintjs/core';
import { handleClosePopover } from '../../../../utilityFunctions';
import UserName from '../../../userName';
import './UserMenu.scss';
import axios from 'axios';
import { getHeader } from '../../../../system/Sesion';
import { config } from '../../../../system/Config';
import BlurImage from '../../../blurImage/BlurImage';

const UserMenu = () => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { fullName, avatar, user } = useSelector(({ auth }) => auth);
  const publicationsSaved = useSelector(({ publicationsSaved }) => publicationsSaved.publicationsSaved);
  const chat = useSelector(({ chat }) => chat);
  const publicationsActives = user.activePublications;
  const [campaignsTotal, setCampaignsTotal] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${config.URL_API}/v1/ads?page=1&per_page=10`, { headers: getHeader() });
        setCampaignsTotal(response.data.total);
      }
      catch (err) { console.error(err)}
    })();
  }, []);

  const userInitials = (
    <p className="user-menu__avatar-initial">
      {user.name[0].toUpperCase()}{user.last_name[0].toUpperCase()}
    </p>
  );

  const userInfo = (
    <section className="user-menu__info bg-animation--blue">
      {avatar ? (
        <BlurImage className="user-menu__avatar" image={avatar} title="user photo"/>
      ) : (
        userInitials
      )}
      <UserName fullName={fullName} user={user} htmlTag='p' className={'user-menu__name'}></UserName>
    </section>
  );

  const userMenuSections = [
    {
      subSection: [{ url: '/profile?tab=profile', icon: 'id-number', content: 'Perfil' }],
    },
    {
      subSection: [
        {
          url: '/chats/?tab=proposal',
          icon: 'swap-horizontal',
          content: 'Propuestas de Cambio',
          amount: chat.proposal.length
        },
        {
          url: '/chats/?tab=in-progress',
          icon: 'arrows-horizontal',
          content: 'Cambios en proceso',
          amount: chat.in_progress.length
        },
        {
          url: '/requests?tab=3',
          icon: 'history',
          content: 'Historial de cambios'
        },
      ],
    },
    {
      subSection: [
        {
          url: '/profile?tab=campaigns',
          icon: 'star-empty',
          content: 'Promocionadas',
          amount: campaignsTotal
        },
      ]
    },
    {
      subSection: [
        {
          url: '/publications-saved',
          icon: 'bookmark',
          content: 'Publicaciones guardadas',
          amount: publicationsSaved.length
        },
        {
          url: '/profile?tab=publications',
          icon: 'applications',
          content: 'Mis publicaciones',
          amount: publicationsActives
        },
        {
          url: '/new_publication',
          icon: 'plus',
          content: 'Publicar'
        },
      ],
    },
    {
      subSection: [
        {
          url: '/profile?tab=account',
          icon: 'cog',
          content: 'Configuración y Privacidad',
        },
      ],
    },
    {
      subSection: [
        {
          url: '/logout',
          icon: 'log-out',
          content: 'Cerrar Sesión'
        },
      ],
    },
  ];

  const userMenu = (
    <section className={isMobile ? "user-menu--mobile" : "user-menu"}>
      {userInfo}
      <section onClick={handleClosePopover}>
        {userMenuSections.map((section, index) => {
          return (
            <>
              {section.subSection.map((subSection) => {
                return (
                  <Link to={subSection.url} className="user-menu__section">
                    <Icon icon={subSection.icon} iconSize={22} />
                    <p>{subSection.content}</p>
                    <p>{subSection.amount > 0 ? subSection.amount : ''}</p>
                  </Link>
                );
              })}
              {index !== userMenuSections.length - 1 && <MenuDivider />}
            </>
          );
        })}
      </section>
    </section>
  );

  return userMenu;
};

export default UserMenu;
