import React from 'react';
import { useEffect, useState } from 'react';
import './styles/ActivitySuccessfulChange.scss';
import { config } from '../../../../system/Config';
import axios from 'axios';
import SuccessfulChangePublicationInfo from './components/SuccessfulChangePublicationInfo';
import SuccessfulChangeStatus from './components/SuccessfulChangeStatus';
import Card from '../../../../components/card/Card';
import ActivityWallCardDecorator from '../../../../components/activityWallCardDecorator/ActivityWallCardDecorator';

const ActivitySuccessfulChange = ({ change }) => {
  function activity() {
    return (
      <section className="activity-successful-change">
        <SuccessfulChangePublicationInfo
          user={change.publication.owner}
          ranking={change.publication.owner?.reputation}
          verified={change.publication.owner?.verified}
          publication={change.publication}
          // TODO: submit this property when the premium post story is complete
          // publicationType={change.publication.premium.publication_type}
          // ******************************************************************
        />
        <SuccessfulChangeStatus />
        <SuccessfulChangePublicationInfo
          user={change.interested_user}
          ranking={change.interested_user?.reputation}
          verified={change.interested_user?.verified}
          publication={change?.publication_interested}
          // TODO: submit this property when the premium post story is complete
          // publicationType={change.publication.publication_type}
          // ******************************************************************
        />
      </section>
    );
  }

  return (
    <>
      {change && (
        <Card>
          <ActivityWallCardDecorator date={change.updated_at}/>
          {activity()}
        </Card>
      )}
    </>
  );
};

export default ActivitySuccessfulChange;
