import React from 'react';
import Faq from './components/Faq';
import Video from './components/Video';
import Footer from './components/footer/Footer';
import Slider from './components/Slider';
import Categories from './components/Categories';

import './home.scss';

const servicesProps = {
  type: 'accent',
  title: {
    normal: 'Descubre servicios',
    highlight: 'profesionales o habilidades',
  },
  categories: [
    {
      name: 'Servicios Médicos',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/medical.svg',
      id: '2854',
    },
    {
      name: 'Plomería',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/plumbing.svg',
      id: '969',
    },
    {
      name: 'Electricidad',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/electrician.svg',
      id: '2838',
    },
    {
      name: 'Servicio Técnico',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/specialists.svg',
      id: '2863',
    },
    {
      name: 'Servicios para mascotas',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/pets.svg',
      id: '2885',
    },
    {
      name: 'Servicios Profesionales',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/professional.svg',
      id: '2844',
    },
    {
      name: 'Cursos y Clases',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/learning.svg',
      id: '2760',
    },
    {
      name: 'Jardinería',
      image:
        'https://storage.googleapis.com/locambio-admin/pages/home/categories/services/gardening.svg',
      id: '2803',
    },
  ],
};

const Home = () => {
  return (
    <div className="home">
      <Slider />
      <Categories />
      <Categories {...servicesProps} />
      <div className="section--video-faqs">
        <Video />
        <Faq />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
