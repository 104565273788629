import React from 'react';
import PropTypes from 'prop-types';

import './PageWithTitle.scss';
import { Icon } from '@blueprintjs/core';
import history from '../../utility/history';

const PageWithTitle = ({ title, className, children, historyBack }) => {

  const handleClick = () => {history.goBack()}
  
  return (
    <section className={`page-with-title ${className || ''}`}>
      <header className={`page-with-title__header ${className || ''}__header`}>
        {historyBack && <Icon icon="arrow-left" className="page-with-title__header__icon" iconSize={20} onClick={handleClick}/>}
        <h1 className={`page-with-title__header__title ${className || ''}__title`}>{title}</h1>
      </header>
      {children}
    </section>
  );
};

PageWithTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PageWithTitle;