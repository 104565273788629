import React from 'react';
import { useModal } from '../../hooks/useModal';
import SelectPlan from '../../popups/selectPlan';
import StatisticalItems from '../statisticalItems';
import Card from '../../../../components/card/Card';
import { datelocambio } from '../../../../utilityFunctions';
import VisualizationsSummary from '../../popups/visualizationsSummary';
import { modals as modalsConst} from '../../../../const';

import './CampaignItem.scss';
import BlurImage from '../../../../components/blurImage/BlurImage';

const CampaignItem = ({ dataElement, isCampaign, handleSelectPlan }) => {
  const { updateCampaignModal } = useModal();

  const { MODALS_TYPES } = modalsConst

  const params = {
    picture: dataElement?.publication?.picture,
    publicationTitle: dataElement?.publication?.title,
    visualizations: {
      organics: dataElement?.publication?.views,
      promotial: {
        count: dataElement?.printer,
        total: dataElement?.total_prints,
      },
      totals: dataElement?.total_views,
    },
    start_date: dataElement?.created_at,
  };

  //TODO move to const modals
  const modals = {
    [MODALS_TYPES.summary]: {
      title: 'Resumen de la promoción',
      content: <VisualizationsSummary data={params} />,
      isOpen: true,
    },
    [MODALS_TYPES.selectPlan]: {
      title: 'Promocionar Publicación',
      content: <SelectPlan
        title={dataElement?.title}
        image={dataElement?.picture}
        id={dataElement?.id}
        handleSelectPlan={handleSelectPlan}
      />,
      isOpen: true,
    },
  };

  const text = {
    campaign: {
      styleFont: 'blue',
      publication: dataElement?.publication,
      shadow: 'blue',
      handleClick: () => updateCampaignModal(modals[MODALS_TYPES.summary]),
    },
    publication: {
      styleFont: 'primary',
      publication: dataElement,
      shadow: 'none',
      handleClick: () => updateCampaignModal(modals[MODALS_TYPES.selectPlan]),
    },
  };

  const { publication, styleFont, shadow, handleClick } = text[isCampaign ? 'campaign' : 'publication'];

  return (
    <Card
      className="campaign-item"
      onClick={handleClick}
      shadow={shadow}
    >
      {
        publication && <>
          <div className="a">
            <BlurImage image={publication.picture} title="publication"/>
          </div>
          <div className="b">
            <div className={`campaign-item__title campaign-item__title--${styleFont}`}>{publication.title}</div>
            <div className="campaign-item__date-container">
              <div className="campaign-item__date">{datelocambio(publication.publish_date)}</div>
              <div className="campaign-item__kind">{publication.publication_type}</div>
            </div>
          </div>
          <StatisticalItems publication={publication} />
        </>
      }
    </Card>
  );
};

export default CampaignItem;