import React from 'react';
import PropTypes from 'prop-types';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useMediaQuery } from 'react-responsive';

const settings = {
  modules: [Pagination, Navigation, Autoplay],
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  pagination: { type: 'bullets', clickable: true },
  navigation: true,
  loop: true,
  initialSlide: 0,
};

const Slider = ({ desktopImages, mobileImages }) => {
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });
  let images = (isLargerThanMobile ? desktopImages : mobileImages)
  return (
    <div className="slider section">
      <Swiper {...settings}>
        {images.map((image) => (
          <SwiperSlide key={`slider-${image}`}>
            <img alt="main-slider" src={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Slider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired),
};

Slider.defaultProps = {
  desktopImages: [
    'https://storage.googleapis.com/locambio-admin/banner/banner-1.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-2.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-3.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-4.jpg',
  ],
  mobileImages: [
    'https://storage.googleapis.com/locambio-admin/banner/banner-m-1.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-m-2.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-m-3.jpg',
    'https://storage.googleapis.com/locambio-admin/banner/banner-m-4.jpg',
  ],
};
export default Slider;
