import React from 'react';
import { Callout, Card, Elevation, Icon } from '@blueprintjs/core';

const SuccessfulMessage = (props) => {

  const { match } = props;
  const type = match.params.type;

  return (

    <>
      <div className="card-container">
        <div className="p-12">
          <Card elevation={Elevation.ONE} className="marginBottom-1em">
            <Callout>
              <div className="p-10">
                <p className="text-center text-green mb-8">
                  <Icon iconSize="48" icon="tick-circle" />
                </p>
                <h3 className="text-center text-green-darker mb-0">
                  {type === 'recovery' && ('Correo de recuperación enviado')}
                  {type === 'resend' && ('Correo de confirmación enviado')}
                </h3>
              </div>
            </Callout>
            <div className="pb-6">
              <p className="text-center mb-0">
                Revisa tu bandeja de entrada y sigue
              </p>
              <p className="text-center mb-0">
                las instrucciones para {type === 'recovery' ? 'recuperar' : 'confirmar'}
              </p>
              <p className="text-center mb-0">
                tu {type === 'recovery' ? 'contraseña' : 'cuenta'}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );

};

export default SuccessfulMessage;
