import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Icon, Position, Text } from '@blueprintjs/core';

import Item from '../item';
import ListButton from '../listButton';
import Drawer from '../../../../components/drawer';

import { DRAWERS } from '../../../../utility/drawers';
import { useQueryString } from '../../../../hooks/useQueryString';
import { CHAT_KINDS, CHAT_KINDS_BUTTONS, CHAT_KINDS_URL, CHAT_KINDS_URL_MAP } from '../../../../utility/chat';

const List = () => {
  const { id } = useParams();
  const queryString = useQueryString();
  const kind = CHAT_KINDS_URL_MAP[queryString.get('tab') || CHAT_KINDS_URL[CHAT_KINDS.proposal]];
  const chats = useSelector(({ chat }) => chat[kind]);

  const isDesktop = useMediaQuery({ minWidth: 1025 });
  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const itemsList = useMemo(() => {
    if (!chats.length) {
      return (
        <section className="chat__empty-state">
          <Icon icon="swap-horizontal" iconSize={30} className="mb-4" />
          <Text tagName="h2">
            {`No hay ${kind === CHAT_KINDS.proposal ? 'propuestas' : 'cambios'} actualmente`}
          </Text>
        </section>
      );
    }
    return (
      chats.map((chat, i) => (
        <li key={`chat-item-${i}${chat.id}`}>
          <Item chat={chat} />
        </li>
      ))
    );
  }, [chats, kind]);

  const list = useMemo(() => {
    return (
      <aside className="list">
        <nav>
          <ul className="list__options">
            {
              CHAT_KINDS_BUTTONS.map((button) => (
                <ListButton
                  key={`list-button-${button.key}`}
                  label={button.label}
                  buttonKind={button.key}
                />
              ))
            }
          </ul>
        </nav>
        <ul className="list__items hide-scroll">
          {itemsList}
        </ul>
      </aside>
    );
  }, [itemsList]);

  return (
    isDesktop ?
      list :
      <Drawer
        defaultIsOpen={!id}
        canOutsideClickClose={!!id && isLargerThanMobile}
        drawerKey={DRAWERS.chats}
        usePortal={false}
        hasBackdrop={isLargerThanMobile}
        position={Position.LEFT}
        size={isLargerThanMobile ? '27rem' : '100%'}
        enforceFocus={false}
      >
        {list}
      </Drawer>
  );
};

export default List;