import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tag } from '@blueprintjs/core';

const Breadcrumb = ({ data, emptyMessage, ...props }) => {
  if (!data.length) {
    if (emptyMessage) {
      return (
        <div className="calloutNoActivity">
          <p><Icon className="mr-2" icon="issue" />{emptyMessage}</p>
        </div>
      );
    }
    return null;
  }

  return (
    <Tag
      className="my-1 mr-2"
      minimal
      {...props}
    >
      {
        data.map?.((element, index) => {
          if (!element) return null;
          const arrow = index !== 0 && <Icon icon="chevron-right" />;
          const name = element?.name || element;
          return (
            <div className="inline" key={`breadcrumb-item-${name}-${index}`}>
              {arrow}
              {name}
            </div>
          );
        })
      }
    </Tag>
  );
};

Breadcrumb.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
  ).isRequired,
  emptyMessage: PropTypes.string,
};

export default Breadcrumb;
