import { Icon } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryString } from '../../../../hooks/useQueryString';
import { CHAT_KINDS, CHAT_KINDS_URL, CHAT_KINDS_URL_MAP } from '../../../../utility/chat';

const ListButton = ({ label, buttonKind }) => {
  const history = useHistory();
  const { id } = useParams();
  const queryString = useQueryString();
  const kind = CHAT_KINDS_URL_MAP[queryString.get('tab') || CHAT_KINDS_URL[CHAT_KINDS.proposal]];

  const handleClickChat = useCallback((chatKind) => {
    if (chatKind !== kind) history.push(`/chats/?tab=${chatKind}`);
  }, [history, id, kind]);

  return (
    <li
      key={`button-${buttonKind}`}
      className={`list-button ${buttonKind === kind ? 'list-button--active' : ''}`}
      onClick={() => handleClickChat(CHAT_KINDS_URL[buttonKind])}
    >
      {label}
      <Icon className="children-without-transition" icon="chevron-up" />
    </li>
  );
};

export default ListButton;