import React from 'react';
import './../styles/SuccessfulChangeStatus.scss';

const activityTitle = (
  <div className="title">
    <p className={'change'}>
      Cambio <span className={'successful'}>exitoso</span>
    </p>
  </div>
);

const activityLogo = (
  <div className={'activity-img'}>
    <img
      alt="successful change"
      src="https://storage.googleapis.com/locambio-admin/pages/seguro/step3.png"
    />
  </div>
);

const SuccessfulChangeStatus = () => {
  return (
    <section className={'successful-change-status'}>
      {activityTitle}
      {activityLogo}
    </section>
  );
};

export default SuccessfulChangeStatus;
