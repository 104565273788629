import axios from 'axios';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import { Button, Callout, Dialog, Tab, Tabs } from '@blueprintjs/core';
import { withRouter } from 'react-router-dom';
import './changes/Changes.scss';
import withCable from '../hocs/withCable';
import History from '../components/history';
import { config } from '../system/Config';
import Footer from './../pages/home/components/footer';
import { getHeader } from '../system/Sesion';
import { getQuerystring } from '../utility/url';
import * as actions from './../store/actions/index';
import { Toast } from '../utility/toast';

class ChangesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: '3',
      loadingAcceptChange: [],
      loadingRejectChange: [],
      dialogCancelTransaction: false,
      deletingTransaction: false,
      changeTemp: null,
      isOpen: false,
      action: [],
      isOpenModalRank: false,
      rankingChange: null,
      transaction: null,
      typeRank: null, // AQUI EMPIEZAN LOS CAMBIOS V2
      profile: null,
      proposals: [],
      changesTrans: [],
      history: [],
    };
    this.userId = parseInt(localStorage.getItem('userId'));
    this.cableConnection = null;
  }

  tabChange = () => (this.props.history.push(`/requests?tab=3`));

  switchDialogCancelTransaction = () => {
    this.setState({
      dialogCancelTransaction: !this.state.dialogCancelTransaction,
    });
  };

  fetchUpdateTransaction = (id, data) => {
    return axios.put(`${config.URL_API}/v1/transactions/${id}`, data, {
      headers: getHeader(),
    });
  };

  showRejectToast = (transaction) => {
    const isOwner = transaction.interested_id !== parseInt(localStorage.userId);

    const { action } = this.state;
    const { interested_user, publication_interested, publication, tag_change, publication: { owner } } = transaction;
    const getInterestedInfo = () => publication_interested ? publication_interested : tag_change;
    const toastInfo = isOwner ?
      { user: interested_user, title: getInterestedInfo() } :
      { user: owner, title: publication };

    const message = <span>
      {action === 'cancel' ? 'Cancelaste' : 'Rechazaste'} el cambio con <b>
      {toastInfo.user.name} {toastInfo.user.last_name}</b> en <b>
      {toastInfo.title.tag ? toastInfo.title.tag : toastInfo.title.name}</b>
    </span>;

    Toast.show({ message });
  };

  cancelTransaction = async (transaction) => {
    this.setState({
      deletingTransaction: true,
      dialogCancelTransaction: false,
    }, async () => {
      try {
        let data = {
          status: 'rejected',
        };
        await this.fetchUpdateTransaction(transaction.id, data);
        this.getTransactionsByTab();

        this.showRejectToast(transaction);
      } catch (err) {
        this.setState({ deletingTransaction: false });
      }
    });
  };

  openModal = (id, transaction = false, action = false) => {
    this.setState({
      dialogCancelTransaction: true,
      changeTemp: id,
      transaction: transaction,
      action: action,
    });
  };

  closeModal() {
    this.setState({ dialogCancelTransaction: false });
  }

  componentDidMount() {
    const tabSelected = getQuerystring(this.props.location.search, 'tab') || '3';
    this.setState({ tabSelected }, () => {
      this.cableConnection = this.props.cableApp.subscriptions.create(
        { channel: 'RequestChannel', user_id: this.userId },
        {
          received: (response) => {
            this.getTransactionsByTab();
          },
        },
      );
      this.getTransactionsByTab();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const tabSelected = '3';
    if (tabSelected !== prevState.tabSelected) {
      this.setState({ tabSelected }, () => this.getTransactionsByTab());
    }
  }

  async getTransactionsByTab() {
    let { tabSelected } = this.state;
    switch (tabSelected) {
      case '3':
        this.fetchRequests('history')
          .then(
            (data) => {
              let { transactions } = data.data;
              if (!transactions) {
                transactions = [];
              }
              this.setState({
                history: [...transactions],
              });
            },
          );
        break;
    }
  }

  componentWillUnmount() {
    this.cableConnection.unsubscribe();
  }


  fetchRequests = (kind) => {
    return axios.get(
      `${config.URL_API}/v1/requests?kind=${kind}`,
      { headers: getHeader() },
    );
  };

  render() {
    const {
      tabSelected,
      dialogCancelTransaction,
      transaction,
      proposals,
      changesTrans,
      history,
    } = this.state;

    const search = this.props.location.search;
    const transactionId = new URLSearchParams(search).get('transactionId');

    const changes = proposals.concat(changesTrans, history);

    return (
      <>
        <div>
          <div className="customWrapper">
            <div className="">
              <History
                suscription={this.props.suscription}
                userId={this.userId}
                openModal={this.openModal}
                history={history}
              />
            </div>
            {changes.length === 0 ? (
              <Callout className="noActivityDisplay marginBottom-1em">
                <h5 className="bp3-form-helper-text">
                  Sin Transacciones
                </h5>
              </Callout>
            ) : null}
            <Footer/>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.request,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetRequests: () => dispatch(actions.getRequests()),
    onUpdateRequests: (data, typeRequest) =>
      dispatch(actions.updateRequest(data, typeRequest)),
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCable(ChangesPage)));