import * as actionTypes from '../actions/actionTypes';
import { updateObject } from './../../utilityFunctions';

const initialState = {
  publicationsSaved: [],
  publicationsSavedLoaded: false,
};

const setPublicationsSaved = (state, action) => {
  if (action.data.single === true) {
    return updateObject(state, {
      publicationsSaved: [
        ...action.data.publication,
        ...state.publicationsSaved,
      ],
      publicationsSavedLoaded: true,
    });
  } else {
    return updateObject(state, {
      publicationsSaved: action.data.publication,
      publicationsSavedLoaded: true,
    });
  }
};

const removePublicationSaved = (state, action) => {
  const publicationsSaved = [...state.publicationsSaved];
  publicationsSaved.splice(action.data, 1);
  return updateObject(state, { publicationsSaved });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PUBLICATIONS_SAVED:
      return setPublicationsSaved(state, action);
    case actionTypes.REMOVE_PUBLICATIONS_SAVED:
      return removePublicationSaved(state, action);
    default:
      return state;
  }
};

export default reducer;
