import React from 'react';
import { TextArea } from '@blueprintjs/core';

import './WrittenChangeProposal.scss';

const WrittenChangeProposal = ({ hasPublications, setTag, tag }) => {

  return (
    <section className="written-change-proposal">
      <p className={'proposal-container__add_publication--title'}>
        {
          hasPublications ?
            <div className="simple_title">
              Agrega un mensaje
              <span className="simple_title--optional"> (Opcional)</span>
            </div> :
            'Propuesta Escrita'
        }
      </p>

      <TextArea
        className={'proposal-container__write-proposal'}
        placeholder="Escribe tu propuesta"
        onChange={(e) => {setTag(e.target.value)}}
        value={tag}
      />
    </section>
  );
};

export default WrittenChangeProposal;