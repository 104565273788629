import React from 'react';
import { AnchorButton, Divider } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import './LinkInfoPages.scss';

export default ({ active }) => {
  return (
    <div className="LinkInfoPages">
      <h4>Información</h4>
      <p
        className={`link_page${
          active == 'Sobre LoCambio.com' ? ' disabled' : ''
        }`}
      >
        <Link to="/about_locambio">Sobre LoCambio.com</Link>
      </p>

      <p
        className={`link_page${
          active == 'Ganar Créditos' ? ' disabled' : ''
        }`}
      >
        <Link to="/locambio_creditos">Ganar Créditos</Link>
      </p>

      <p
        className={`link_page${
          active == 'Blog.LoCambio.com' ? ' disabled' : ''
        }`}
      >
        <Link to={{pathname: 'https://blog.locambio.com/'}} target='_blank' >Blog.LoCambio.com</Link>
      </p>

      <Divider className='marginTop-05em marginBottom-05em' />

      <h4>Servicios</h4>
      <p
        className={`link_page${
          active == 'Verificación ID' ? ' disabled' : ''
        }`}
      >
        <Link to="/locambio_verificacion_id">Verificación ID</Link>
      </p>
      <p
        className={`link_page${
          active == 'LoCambio.com Seguro' ? ' disabled' : ''
        }`}
      >
        <Link to="/locambio_seguro">LoCambio.com Seguro</Link>
      </p>
      <p
        className={`link_page${
          active == 'LoCambio.com Ads' ? ' disabled' : ''
        }`}
      >
        <Link to="/locambio_ads">LoCambio.com Ads</Link>
      </p>
      <p
        className={`link_page${
          active == 'LoCambio.com Premium' ? ' disabled' : ''
        }`}
      >
        <Link to="/locambio_premium">LoCambio.com Premium</Link>
      </p>
      <Divider className="marginTop-05em marginBottom-05em" />
      <h4>Otros enlaces</h4>
      <p
        className={`link_page${
          active == 'Preguntas Frecuentes' ? ' disabled' : ''
        }`}
      >
        <Link to="/faq">Preguntas Frecuentes</Link>
      </p>
      <p
        className={`link_page${
          active == 'Consejos de Seguridad' ? ' disabled' : ''
        }`}
      >
        <Link to="/consejos_de_seguridad">Consejos de Seguridad</Link>
      </p>
      <p
        className={`link_page${
          active == 'Políticas de Privacidad' ? ' disabled' : ''
        }`}
      >
        <Link to="/politicas-de-privacidad">Políticas de Privacidad</Link>
      </p>
      <p
        className={`link_page${
          active == 'Términos y Condiciones' ? ' disabled' : ''
        }`}
      >
        <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>
      </p>
      <p className={`link_page${active == 'Contáctanos' ? ' disabled' : ''}`}>
        <Link to="/contactos">Contáctanos</Link>
      </p>
    </div>
  );
};
