import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../../../Assets/Logo/logoB&W.png';
import { config } from '../../../../system/Config';
import './footer.scss'

const Footer = () => {
  const { isAuthenticated } = useSelector(({ auth }) => auth);

  const directory = [
    {
      title: 'Información',
      urls: [
        { route: '/about_locambio', page: 'Sobre Locambio.com'},
        { route: '/locambio_creditos', page: 'Ganar créditos' },
        { route: 'https://blog.locambio.com/', page: 'Blog.LoCambio.com', externalUrl: true },
      ],
    },
    {
      title: 'Servicios',
      urls: [
        { route: '/locambio_verificacion_id', page: 'Verificación ID' },
        { route: '/locambio_seguro', page: 'LoCambio Seguro' },
        { route: '/locambio_ads', page: 'LoCambio Ads', },
        { route: '/locambio_premium', page: 'LoCambio Premium' }
      ],
    },
    {
      title: 'Ayuda',
      urls: [
        { route: '/faq', page: 'Preguntas Frecuentes' },
        { route: '/consejos_de_seguridad', page: 'Consejos de Seguridad' },
        { route: '/politicas-de-privacidad', page: 'Políticas de Privacidad', },
        { route: '/terminos-y-condiciones', page: 'Términos y Condiciones' },
        { route: '/contactos', page: 'Contáctanos' },
      ],
    },
    {
      title: 'Cuenta',
      urls: [
        { route: '/registration', page: 'Registrarse', toInvited: true },
        { route: '/login', page: 'Iniciar Sesión', toInvited: true },
        { route: '/profile', search: '?tab=profile', page: 'Perfil', onlyToUser: true },
        { route: '/wall', page: 'Mural', onlyToUser: true },
        { route: '/chats/', search: '?tab=in-progress', page: 'Cambios', onlyToUser: true },
        {
          route: '/publications-saved',
          page: 'Publicaciones Guardadas',
          onlyToUser: true,
        },
        {
          route: '/new_publication',
          page: 'Crear Publicación',
          onlyToUser: true,
        },
      ],
    },
    {
      title: 'Redes Sociales',
      urls: [
        {
          route: 'https://www.instagram.com/locambiove/?hl=es',
          page: 'Instagram',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/ig.svg',
        },
        {
          route: 'https://www.facebook.com/locambiove/',
          page: 'Facebook',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/fb.svg',
        },
        {
          route: 'https://twitter.com/locambiove?t=Ne34mVDBPWUn5vR6DQvyTA&s=09',
          page: 'Twitter',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/tw.svg',
        },
        {
          route: 'https://www.youtube.com/channel/UCtw_sZ7rp6bISUG6AJixiiA',
          page: 'Youtube',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/yt.svg',
        },
        {
          route: 'https://www.tiktok.com/@locambiove',
          page: 'Tiktok',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/tt.svg',
        },
        {
          route: `https://wa.me/${config.WS_NUMBER}`,
          page: 'Whatsapp',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/ws.svg',
        },
        {
          route: 'http://t.me/LoCambioVe',
          page: 'Telegram',
          img: 'https://storage.googleapis.com/locambio-admin/pages/contact/icons/rrss/tg.svg',
        },
      ],
    },
  ];

  const item = (url, index) => {
    const target = url.externalUrl ? '_blank' : '_self'
    return (
      <Link
        key={`url${index}`}
        to={url.disabled ? '' : { pathname: url.route, search: url.search}}
        className={url.disabled ? 'footer__section-url-disabled' : ''}
        target={target}
      >
        <p className={`footer__directory__section__url${url.disabled ? '__label-disabled' : ''}`}>{url.page}</p>
      </Link>
    )
  }

  const socialIcons = (
    <p className="default-text">
      Conscientes de la importancia que tiene la seguridad a la hora de
      concretar un cambio, hemos concebido LoCambio Seguro, poniendo a
      tu disposición nuestras Sedes de Cambio para ofrecerte una mayor seguridad.
    </p>
  );

  const displayDirectory = directory.map(
    (section, index) => (
      section.urls[index].img === undefined ? (
        <div key={`section${index}`} className="footer__directory__section">
          <h2 className="footer__directory__section__title">{section.title}</h2>
          {section.urls.map((url, index) =>
            url.onlyToUser
              ? isAuthenticated && item(url, index)
              : url.toInvited
                ? !isAuthenticated && item(url, index)
                : !url.onlyToUser &&
                !url.toInvited &&
                item(url, index)
          )}
        </div>
      ) : (
        <div className="footer__directory__section__social-container">
          <h2 className="footer__directory__section__title">{section.title}</h2>
          <div key={`section${index}`} className="footer__directory__section__social-icons">
            <div className="footer__directory__section__social-icons--row">
              <a href={directory[4].urls[0].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[0].page}
                  src={directory[4].urls[0].img}
                />
              </a>
              <a href={directory[4].urls[1].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[1].page}
                  src={directory[4].urls[1].img}
                />
              </a>
              <a href={directory[4].urls[2].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[2].page}
                  src={directory[4].urls[2].img}
                />
              </a>
            </div>
            <div className="footer__directory__section__social-icons--row">
              <a href={directory[4].urls[3].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[3].page}
                  src={directory[4].urls[3].img}
                />
              </a>
              <a href={directory[4].urls[4].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[4].page}
                  src={directory[4].urls[4].img}
                />
              </a>
            </div>
            <div className="footer__directory__section__social-icons--row">
              <a href={directory[4].urls[5].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[5].page}
                  src={directory[4].urls[5].img}
                />
              </a>
              <a href={directory[4].urls[6].route} target="_blank">
                <img
                  className="footer__social-icon"
                  alt={directory[4].urls[6].page}
                  src={directory[4].urls[6].img}
                />
              </a>
            </div>
          </div>
        </div>
      )
    )
  );

  const greyBar = (
    <div className="footer__grey-bar">
      <p className="footer__disclaimer">
        t-locambio, C.A. 2019 -RIF: J-40865264-1
      </p>
      <img className="footer__logo" src={Logo} />
      <p className="footer__disclaimer">
        Todos los Derechos Reservados Ⓒ locambio.com
      </p>
    </div>
  );

  return (
    <footer className="footer">
      <div className="footer__directory">{displayDirectory}</div>
      {greyBar}
    </footer>
  );
};

export default Footer;
