import React from 'react';

import LinkInfoPages from '../components/linkInfoPages/';
import "./privacyPolicies/PrivacyPolicies.scss";
import Footer from "./home/components/footer/Footer";
import ScrollToElement from '../components/scrollToElement/ScrollToElement';

const PrivacyPolicies = () => {
  const sections = [
    {
      title: 'Registro de Usuarios',
      text: [
	      'Para poder hacer uso de los servicios de WWW.LOCAMBIO.COM es necesario que el “Usuario” cree una cuenta en el portal web, para lo cual deberá suministrar toda la información que se le  solicite como obligatoria de forma precisa y veráz. Asimismo, el “Usuario” será responsable de la información otorgada, garantizando su veracidad, reservándonos el derecho de suspender de forma temporal o definitivamente, en caso de que resultaren falsos o fraudulentos en cualquier forma. Si posterior al registro del “Usuario” surge alguna modificación de la información suministrada al momento de su suscripción en el sistema, el “Usuario” se compromete a actualizar la información lo antes posible.',
        'En caso de inhabilitación, se suspenderán todas las operaciones pendientes, sin que ello genere algún derecho a resarcimiento por parte de WWW.LOCAMBIO.COM',
        'Solo esta permitida la creación de una cuenta por persona, ya sea natural o jurídica. Si se comprueba duplicidad en la información en más de una cuenta, WWW.LOCAMBIO.COM se reserva el derecho de suspenderlas o inhabilitarlas de manera temporal o indefinida.',
        'El “Usuario” accederá a su sesión personal e intransferible, mediante su correo electrónico y clave de seguridad personal elegida ("Contraseña"). En ese sentido, el uso de la cuenta está restringido al uso de la “Contraseña”, que es de conocimiento único y exclusivo del “Usuario”, en consecuencia, se obliga a tomar todas las previsiones como buen pater familia y mantener la confidencialidad de su “Contraseña”. Será de exclusiva responsabilidad del “Usuario” si un tercero lograre acceder a su cuenta, con o sin su consentimiento, y perfeccione o ejecute en su nombre acciones dentro del sitio o viole la confidencialidad de los datos del “Usuario”.',
        'El “Usuario” se compromete a notificar de forma inmediata, fehaciente y escrita, a través de nuestros canales de soporte de WWW.LOCAMBIO.COM, de cualquier uso no autorizado de su cuenta, así como el ingreso por terceros no autorizados a la misma.',
        'WWW.LOCAMBIO.COM se reserva el derecho a negar el acceso al servicio, a inhabilitar o a eliminar cualquier cuenta cuando se incumpliera la legislación aplicable, los “Términos y Condiciones” o las Políticas de privacidad, sin que ello genere algún derecho a indemnización o resarcimiento por parte de la plataforma.',
      ],
    },
    {
      title: 'Privacidad de la Información y Confidencialidad',
      text: [
	      'Como fue expresado anteriormente, para que un “Usuario” goce de los servicios de WWW.LOCAMBIO.COM debe crear una cuenta personal o jurídica, ingresando datos de información personal que allí se requieren como obligatorios para verificar la autenticidad y veracidad de los usuarios y poder otorgar mayores garantías en la prestación del servicio. La información proporcionada por el “Usuario” al momento de crear su cuenta, será almacenada en nuestros servidores que mantienen altos niveles de seguridad.',
        'No será responsabilidad de WWW.LOCAMBIO.COM las consecuencias que puedan derivarse del uso indebido y no autorizado de un “Usuario” o un tercero, de la información suministrada en la página, ni de la información que el “Usuario” divulgue a terceros por redes sociales, o por cualquier otro medio.',
        'WWW.LOCAMBIO.COM se compromete a proteger la privacidad de la información; no obstante, puede suceder que, a causa de órdenes judiciales o de regulaciones legales, WWW.LOCAMBIO.COM ,se vea obligado a revelar información a las autoridades o terceras partes dependiendo de las circunstancias, y cuando discrecionalmente se considere necesario.',
        'El “Usuario” puede cancelar, suspender, eliminar o actualizar su cuenta e información de la base de datos de WWW.LOCAMBIO.COM cuando así lo desee; sin embargo, en casos determinados, la información personal del “Usuario” no será eliminada de manera inmediata de los archivos por motivos técnicos o legales.',
        'Si la plataforma WWW.LOCAMBIO.COM modificara los términos en que la información personal es administrada, notificará vía correo electrónico para verificar la conformidad de sus usuarios. Si el “Usuario” no está de acuerdo con las referidas modificaciones, deberá abstenerse de utilizar el sitio y/o los servicios.',
        'Al suscribirse, el “Usuario” de la plataforma WWW.LOCAMBIO.COM reconoce y acepta que WWW.LOCAMBIO.COM podrá utilizar un sistema de seguimiento mediante la utilización de cookies (en lo adelante "Cookies"). Esto es con el fin de otorgar un mejor servicio o proveer información que considere de interés.',
        'WWW.LOCAMBIO.COM podrá acceder al sistema de mensajería instantánea de los usuarios dentro de la plataforma o aplicación móvil, ello con el fin de controlar la calidad del servicio, y prevenir violaciones a los “Términos y Condiciones”.',
      ],
    },
  ];

  const allSections = sections.map((section, index) => {
    const content = (
      <div className="privacy-policies__container">
        <div className="privacy-policies__container--box">
          <h2 className="title">{section.title}</h2>
          {section.text.map((block, index) => {
            const paragraph = <p>{block}</p>;
            return paragraph;
          })}
        </div>
      </div>
    );
    return content;
  });

  return (
    <>
              <ScrollToElement
          scroll={true}
          options={{ behavior: 'auto', block: 'center' }}
          highlight={false}
        >
        </ScrollToElement>
      <div className="privacy-policies">
        <div className="section">{allSections}</div>
        <div className="privacy-policies__links">
          <LinkInfoPages active={'Políticas de Privacidad'} />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default PrivacyPolicies;
