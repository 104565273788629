import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import check_icon from "../../../../Assets/Logo/check_icon.svg";

import "./CreditPaymentSuccessModal.scss";
import { useDispatch } from "react-redux";
import { checkSesion } from "../../../../store/actions";

const CreditPaymentSuccessModal = ({
  title,
  subTitle,
  linkTo,
  boldText,
  descriptionText,
  complementText,
  className = "credit-payment-success-modal",
}) => {
  const { removeCampaignModal } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkSesion());
  }, [dispatch]);

  const link = (
    <Link to={linkTo}>
      <span className={`${className}__link`} onClick={removeCampaignModal}>
        {title}{" "}
      </span>
    </Link>
  );

  return (
    <section className={`${className}`}>
      <img className={`${className}__icon`} src={check_icon} alt="success" />
      <p className={`${className}__title`}>{subTitle}</p>
      <p>
        {descriptionText}
        <b>{boldText}</b>
        {linkTo && link}
        {complementText}
      </p>
    </section>
  );
};

export default CreditPaymentSuccessModal;
