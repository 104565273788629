import React, { useState, useEffect, useRef } from 'react';
import './Step3.scss';
import {
  Button,
  Icon,
  RadioGroup,
  Radio,
  Callout,
  MenuItem,
} from '@blueprintjs/core';
import { Controller, useWatch } from 'react-hook-form';
import { Suggest } from '@blueprintjs/select';
import { useSelector } from 'react-redux';
import { config } from '../../../../system/Config';
import axios from 'axios';
import { getHeader } from '../../../../system/Sesion';
import InfoModal from './infoModal'
import { useParsedCategories } from '../../../../hooks/useParsedCategories';

export default function Step3({ control, errors, setValue, setContinue }) {
  const { all } = useSelector(({ categories }) => categories);
  const [interest, setInterest] = useState([]);
  const [excludedWords, setExcludedWords] = useState([]);
  const [inputFieldValue, setInputFieldValue] = useState('');
  const [infoTextReaded, setInfoTextReaded] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const getCategoriesAll = useParsedCategories(all);
  const inputRef = useRef();
  const interestLength = { min: 2, max: 24 }
  const inputProps = {
    inputRef: inputRef,
    className: 'suggest-field',
    placeholder: 'Ej. celulares, clases de inglés, etc.',
    id: 'interest',
    onKeyPress: (e) => e.key === 'Enter' && e.preventDefault(),
  };

  useEffect(() => {
    setSuggestions(getCategoriesAll);
  }, [all]);

  useEffect(() => {
    axios
      .get(`${config.URL_API}/excluded_words`, {
        headers: getHeader(),
      })
      .then((response) => setExcludedWords(response.data));
  }, []);

  const handleAdd = (value = '') => {
    if (value === '') return;
    const currentInputRef = inputRef.current;
    const temp = [...interest];
    value.split(' ').map((word) => {
      if (
        !temp.includes(word) &&
        !excludedWords.includes(word) &&
        word.length >= interestLength.min &&
        word.length <= interestLength.max &&
        word !== '') {
        temp.push(word);
      }
    });
    setInterest(temp);
    setValue('interest', temp);
    setInfoTextReaded(true);
    currentInputRef?.focus();
  };

  const handleAddSuggestionByClick = () => {
    handleAdd(inputFieldValue);
    setInputFieldValue('');
  };

  const handleMenuRenderer = (item, { id, handleClick }) => (
    <MenuItem text={item} value={item} key={id} onClick={handleClick} />
  );

  const handleCheckSize = (query) => {
    const newQuery = []
    if (query.charAt(0) !== '') {
      query.split(' ').map((word) => {
        if (word !== '') {
          if (word.length > interestLength.max || word.length < interestLength.min) {
            setShowError(true);
            newQuery.push(<div className='alert suggestion-item '>- {word}</div>);
          } else {
            newQuery.push(<div className='purple-text suggestion-item '>- {word}</div>);
          }
        }
      });
    }
    return (
      <div>
        <p>
          <span className='blue-text extra-bold-text suggestion-title'>
            Agregar inter{newQuery.length === 1 ? 'és:' : 'eses:'}
          </span>
          {newQuery}
        </p>
      </div>
    )
  };

  const createNewItemRenderer = (query, active, handleClick) => {
    setShowError(false);
    return (
      <div className='suggestion-pop' onClick={handleClick}>
        <div>{handleCheckSize(query, handleClick)}</div>
        {showError ?
          <p className='alert extra-bold-text error'>
            Solo se admitirán palabras entre 2 y 24 Caracteres
          </p> : null
        }
      </div>
    )
  };

  function handleShowModal() {
    setShowPremiumModal(!showPremiumModal);
  }

  const initialContent = (
    <div>
      <Callout className={'info-text'}>
        <h3 className="extra-bold-text blue-text">Tip</h3>

        <p className={'padding40px'}>
          <span className="blue-text">
            Los términos similares, así como la marca/modelo que deseas, mejoran
            tu visibilidad.
          </span>
        </p>
        <p className={'padding40px'}>
          Ej. <span> teléfonos, celulares, android, samsung</span>
        </p>
      </Callout>
    </div>
  );

  const handleListFilterItems = (query, items) => {
    const queryLowercase = query.toLowerCase();
    return items.filter(item => item.toLowerCase().includes(queryLowercase)).slice(0,10) 
  };

  const getInputValue = (query) => {
    setInputFieldValue(query);
  };

  const interestWatch = useWatch({
    control,
    name: 'interest',
  });
  const openToProposal = useWatch({
    control,
    name: 'openToProposal',
  });

  useEffect(() => {
    if (!interest?.length && !interestWatch?.length && openToProposal !== '1') {
      setContinue(false);
      setValue('openToProposal', '1');
    } else {
      setContinue(true);
    }
    if (interestWatch && interestWatch.length != interest.length) {
      setInterest([...interestWatch] || []);
    }
  }, [interestWatch, openToProposal]);

  const removeItem = (index) => {
    const temp = [...interest];
    temp.length === 1 && setInfoTextReaded(false);
    temp.splice(index, 1);
    setInterest(temp);
    setValue('interest', temp);
  };

  const interestItems = interest.map((elem, index) => {
    return (
      <div className="interestItem" key={index}>
        {elem}
        <Icon
          icon="cross"
          className="purple-Text interest_icon"
          iconSize={20}
          onClick={() => removeItem(index)}
        />
      </div>
    );
  });

  return (
    <div>
      <InfoModal displayModal={showPremiumModal} />
      <div className="step3">
        <div className="card">
          <h3 className="third-Title info-box-title ">Intereses a cambio</h3>
          <p className="subtitle">
            Indica qué quieres a cambio{' '}
            <span
              onClick={handleShowModal}
              className="purple-text modal-interaction"
            >
              Recomendación
            </span>
          </p>
          <div className="suggestions-bar">
            <div className="suggestions-input">
              <Suggest
                closeOnSelect
                resetOnSelect
                items={suggestions}
                query={inputFieldValue}
                onQueryChange={getInputValue}
                itemRenderer={handleMenuRenderer}
                itemListPredicate={handleListFilterItems}
                inputValueRenderer={() => ''}
                inputProps={inputProps}
                onItemSelect={handleAdd}
                createNewItemFromQuery={(query) => query}
                createNewItemRenderer={createNewItemRenderer}
                initialContent={!infoTextReaded && initialContent}
              />
            </div>
            <div className="suggestions-button">
              <Button
                onClick={handleAddSuggestionByClick}
                className="button"
                icon={<Icon icon="plus" iconSize={22} className="white-text" />}
              />
            </div>
          </div>
          <div className="">
            <div className="sub-Card interest">
              <h4>Intereses agregados</h4>
              <div>
                {' '}
                {interest.length > 0 ? (
                  <div className="row">{interestItems}</div>
                ) : (
                  <div className="row no-interests bold-text">
                    <Icon icon="issue" iconSize={15} />
                    No has agregado ningún interés
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`card `}>
          <h3 className="third-Title">
            ¿Deseas recibir cualquier tipo de propuestas?
          </h3>
          <RadioGroup large inline className="radioTags">
            <Controller
              name="openToProposal"
              control={control}
              rules={{ required: true }}
              defaultValue="1"
              render={({ field }) => (
                <>
                  <Radio
                    {...field}
                    disabled={interest.length == 0}
                    label="Si"
                    value="1"
                    id="openToProposal"
                    checked={field.value == '1'}
                  />
                  <Radio
                    {...field}
                    label="No"
                    disabled={interest.length == 0}
                    value="0"
                    checked={field.value == 0}
                    id="openToProposal"
                  />
                </>
              )}
            />
          </RadioGroup>
          {errors.condition && (
            <p className="errorMessage">Este campo es obligatorio</p>
          )}
        </div>
      </div>
    </div>
  );
};
