import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Card, Elevation } from '@blueprintjs/core';
import UserStatsInfo from './UserStatsInfo';
import RankingGraph from './../components/RankingGraph';
import RankingStar from './../components/RankingStar';
import { dateMonthString, initialValue } from './../utilityFunctions';
import './headerThirdProfile/HeaderThirdProfile.scss';
import UserName from './userName/UserName';
import ProfileAvatar from './profileAvatar/ProfileAvatar';

const HeaderThirdProfile = (props) => {
  const { user } = props;


  return (
    <div className={'HeaderThirdProfile'}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className={'OverlapContent'}>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3}>
                  <ProfileAvatar 
                    user={user}
                    editable={false}
                  />
                </Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                  <Card
                    elevation={Elevation.ONE}
                    className={'mt-3 AvatarProfileInfoContainer'}
                  >
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Row>
                          <Col xs={12} sm={12} md={6} lg={8}>
                            <div className={'AvatarProfileInfo'}>
                              <div className={'AvatarProfileName'}>
                                <UserName className ="margin0 marginBottom-05em" user={user} htmlTag='h1'></UserName>
                                <p className="margin0 marginBottom-05em capitalize Gilroy600">
                                  {user.city}
                                </p>
                                <p className="margin0 Gilroy600">
                                  Se unió el{' '}
                                  {dateMonthString(user.registration_date)}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={4}>
                            <div className="mt-4 md:mt-8">
                              <UserStatsInfo user={user} />
                              <div className="mt-0 md:mt-8">
                                <RankingStar user={user} />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderThirdProfile;
