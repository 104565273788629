import * as actionTypes from './actionTypes';
import axios from 'axios';
import { config } from '../../system/Config';

export const setCategories = (categories) => ({
  type: actionTypes.SET_CATEGORIES,
  categories: categories,
});

export const getCategories = () => {
  return dispatch => {
    axios.get(`${config.URL_API}/product_categories`)
      .then(({ data }) => {
        dispatch(setCategories(data));
      })
      .catch((e) => console.log(e));
  };
};
