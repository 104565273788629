import React from 'react';
import { useHistory } from 'react-router-dom';

import Title from '../title';
import Ribbon from '../ribbon';
import { collectionItemPropType } from '../../propTypes';

const CollectionItem = ({ title, greyFilter, image, route, content, ribbonText }) => {
  const history = useHistory();
  const classNames = `collection-item ${greyFilter ? 'collection-item--grey-filter' : ''}`;

  const handleClick = (route) => {
    history.push(route);
  };

  return (
    <section className={classNames} onClick={() => handleClick(route)}>
      {ribbonText && <Ribbon text={ribbonText} />}
      {image && <img className="collection-item__image" src={image} alt={title} />}
      <section className="collection-item__content-wrapper">
        <Title title={title} className="collection-item__title" />
        {
          content && (
            <section className="collection-item__content">
              {content}
            </section>
          )
        }
      </section>
    </section>
  );
};

CollectionItem.propTypes = collectionItemPropType;

export default CollectionItem;