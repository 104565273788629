const LOCAMBIO_SERVICES_TYPES = {
  locambioSeguro: 'locambioSeguro',
  locambioPremium: 'locambioPremium',
  locambioAds: 'locambioAds',
  locambioVerificationId: 'locambioVerificationId',
};

const locambioServicesExport = { LOCAMBIO_SERVICES_TYPES };

export default locambioServicesExport;
