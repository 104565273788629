import React, { useState } from "react";
import { Button, Card, Collapse, Icon } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";


const faq = [
  {
    question: "¿Cómo encontrar publicaciones en LoCambio.com?",
    answer: (
      <p>
        Puedes apoyarte de nuestro buscador bidireccional; usa el modo “Lo Quiero” para encontrar aquello que te interesa conseguir a cambio; usa el modo “Lo Tengo” para encontrar publicaciones de usuarios que estén interesados en lo que tu ofreces.<br/><br/>
        Puedes hacer una exploración más clasificada de publicaciones con el botón “Categorías”.<br/><br/>
        Además, como usuario registrado, podrás navegar en el Mural para encontrar publicaciones nuevas, que te Interesan, cercanas a ti y de usuarios interesados en tus publicaciones.
      </p>
    ),
  },
  {
    question: "¿Cuánto cobra de comisión LoCambio.com ?",
    answer: (
      <p>
        Nuestra plataforma es GRATUITA y no contempla cobro ni comisión por publicar artículos o servicios, así como tampoco por los cambios o trueques realizados.<br/><br/>
        Por otro lado, ofrecemos servicios adicionales (LoCambio Seguro, locambio ads y LoCambio Premium), que permiten mejorar la experiencia dentro y fuera de la plataforma.
      </p>
    ),
  },
  {
    question: "¿Es seguro Cambiar en LoCambio.com?",
    answer: (
      <p>
        Nuestra plataforma facilita encontrar oportunidades para negociar el trueque o cambio entre usuarios. La condición de los bienes, la calidad de los servicios, así como el lugar para concretar el negocio, dependen de los mismos usuarios.<br/><br/>
        Solicita nuestro servicio LoCambio Seguro, para mayor seguridad al momento de realizar el canje o intercambio.
      </p>
    ),
  },
  {
    question: "¿Cómo puedo intercambiar productos o servicios en LoCambio.com?",
    answer: (
      <p>
        Lo primero es iniciar tu sesión en LoCambio.com, encontrar una publicación de tu interés, ya sea utilizando nuestro buscador bidireccional, explorando las Categorías o desde el Mural. Ahora, presiona el botón enviar propuesta, selecciona 1 o varias de tus publicaciones junto a un mensaje adicional si lo deseas, o también puedes hacer una propuesta escrita y finalmente presionar el botón enviar propuesta. Inmediatamente se abrirá el Chat de Intercambio; donde podrás negociar y dar más detalles de tu propuesta al otro usuario hasta llegar a un acuerdo y lograr un Cambio Exitoso.
      </p>
    ),
  },
];

const Faq = () => {
  const [isOpen, setIsOpen] = useState(null);
  const history = useHistory();

  const handleOpen = (i) => {
    if (i === isOpen) return setIsOpen(null);
    setIsOpen(i);
  };

  const faqElements = faq.map((element, i) => (
    <div className="mt-2" key={element.question}>
      <Button
        text={element.question}
        className="faqs__button"
        fill
        onClick={() => handleOpen(i)}
      >
        <Icon
          className={`faqs__icon ${i === isOpen ? "faqs__icon--active" : ""}`}
          icon="chevron-down"
        />
      </Button>
      <Collapse isOpen={i === isOpen}>
        <Card className="faqs__content">{element.answer}</Card>
      </Collapse>
    </div>
  ));

  const moreQuestions =
    <div className="mt-2 faqs__moreQuestions">
      <Button
        text="Ver más preguntas"
        className="faqs__moreQuestions--button"
        onClick={() => history.push('/faq')}
      >
      </Button>
    </div>

  return (
    <div className="faqs section--with-padding">
      <div className="faqs__cover" />
      <div className="faqs__container">
        <p className="faqs__title">
          <span className="section__title--white"> ¿Tienes </span>
          <span className="section__title--yellow-light">dudas?</span>
        </p>
        {faqElements}
        {moreQuestions}
      </div>
    </div>
  );
};

export default Faq;
