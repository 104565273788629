import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import { Button, Dialog, Spinner } from "@blueprintjs/core";
import { Col, Container, Row } from "react-grid-system";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "./ModalCrop/ModalCrop.scss";

const ModalCrop = (props) => {
  // Set custom variables
  const defaultMainImgWidth = 750; // result of the cropping to the main image size in px
  const defaultThumbImgWidth = 250; // result of the cropping to the thumb image size in px
  const defaultCropSize = 0.9; // max value 1 for the 100% to the image
  const minSizeRequired = 350; // min image size in px for be accepted
  // *********************************************************************

  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [minWidthCroppingArea, setMinWidthCroppingArea] = useState(null);
  const [minHeightCroppingArea, setMinHeightCroppingArea] = useState(null);
  const [isValidImg, setIsValidImg] = useState(true);
  const [loading, setLoading] = useState(true);
  const buttonSize = isValidImg ? 6 : 12;

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoading(false);
      if ( img.naturalWidth < minSizeRequired || img.naturalHeight < minSizeRequired ) {
        setIsValidImg(false);
      }
    };
    img.src = props.img;
  }, [props.img]);

  useEffect(() => {
    setIsValidImg(true);
  }, [props.isOpen]);

  const resizeImage = async (image, newCrop, width) => {
    const selectedImage = await createImage(image.src);
    const newCanvas = document.createElement("canvas");
    const scaleX = selectedImage.width / image.width;
    const scaleY = selectedImage.height / image.height;

    newCanvas.width = width;

    // Calculate the height proportionally based on the aspect ratio of the original image
    newCanvas.height = (newCrop.height / newCrop.width) * newCanvas.width;
    // ***********************************************************************************

    const ctx = newCanvas.getContext("2d");
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      newCrop.x * scaleX,
      newCrop.y * scaleY,
      newCrop.width * scaleX,
      newCrop.height * scaleY,
      0,
      0,
      newCanvas.width,
      newCanvas.height
    );

    return new Promise((resolve) => {
      newCanvas.toBlob(
        (blob) => {
          blob.name = "fileName";
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const createImage = (imgUrl) => {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.onload = function () {
        resolve(this);
      };
      img.onerror = function () {
        reject("Invalid base64 data");
      };
      img.src = imgUrl;
    });
  };

  const getCroppedImg = async (image, newCrop) => {
    if (!newCrop.width || !newCrop.height) return;
    const newCanvas = await resizeImage(image, newCrop, defaultMainImgWidth);
    if (props.withThumb) {
      let imageThumb = await resizeImage(image, newCrop, defaultThumbImgWidth);
      return [newCanvas, imageThumb];
    }
    return newCanvas;
  };

  const onImageLoaded = (img) => {
    imgRef.current = img;
    setMinWidthCroppingArea(img.naturalWidth  >= defaultMainImgWidth ? img.width * 0.5 : img.width * 0.8);
    setMinHeightCroppingArea(img.naturalHeight >= defaultMainImgWidth ? img.height * 0.5 : img.height * 0.8);
    setDynamicCrop();
    props.onImageLoaded?.(img);
    return false;
  };

  const setDynamicCrop = () => {
    const width = imgRef.current.width * defaultCropSize;
    const height = imgRef.current.height * defaultCropSize;
    const x = (imgRef.current.width - width) / 2;
    const y = (imgRef.current.height - height) / 2;
    setCrop({ unit: "px", x, y, width, height });
  };

  const onChange = (newCrop) => {
    setCrop(newCrop);
    props.onChange?.(newCrop);
  };

  const handleComplete = () => {
    const image = imgRef.current;
    getCroppedImg(image, crop).then((newCanvas) => {
      props.handleComplete?.(newCanvas);
    });
  };

  const showCroppingArea = () => {
    if (isValidImg) {
      return (
        <ReactCrop
          className="self-center children-without-transition"
          crop={crop}
          src={props.img}
          onImageLoaded={onImageLoaded}
          onChange={onChange}
          keepSelection
          minWidth={minWidthCroppingArea}
          minHeight={minHeightCroppingArea}

        />
      );
    } else {
      return (
        <div className={`min_size_error ${props.className}`}>
          <p className="min_size_error-title"> La imagen es muy pequeña </p>
          <p>{`Debe tener más de ${minSizeRequired} pixeles por lado.`}</p>
          <p>Seleccione otra imagen</p>
        </div>
      );
    }
  };

  const showSpiner = (
    <Spinner className="marginTop-1em marginBottom-1em" size="32" />
  );

  return (
    <Dialog
      canOutsideClickClose={false}
      isOpen={props.isOpen}
      icon={props.icon}
      onClose={props.handleCancel}
      title={props.title}
      className={props.className}
    >
      <div className="flex flex-col">
        {loading ? showSpiner : showCroppingArea()}
        <div className="mb-4">
          <Container>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                {isValidImg && (
                  <Button
                    fill
                    icon="undo"
                    className="bp3-fill BtnLCCancel marginTop-1em"
                    onClick={props.handleCancel}
                  >
                    Cancelar
                  </Button>
                )}
              </Col>
              <Col xs={12} sm={buttonSize} md={buttonSize} lg={buttonSize}>
                <Button
                  fill
                  icon="tick-circle"
                  className="bp3-fill bp3-intent-primary-btnLC BtnLCPrimary marginTop-1em marginRight-1em"
                  onClick={isValidImg ? handleComplete : props.handleCancel}
                >
                  {" "}
                  {isValidImg ? "Listo" : "Entendido"}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Dialog>
  );
};

ModalCrop.propTypes = {
  img: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  handleCancel: PropTypes.func,
  onImageLoaded: PropTypes.func,
  handleComplete: PropTypes.func,
};

ModalCrop.defaultProps = {
  title: "Recorte de imagen",
  icon: "widget",
};

export default ModalCrop;
