import React, { useState, useEffect } from "react";
import { config } from "./../../../../system/Config";
import { checkWithCurrentUser } from "../../../../utilityFunctions";
import ProductSwiper from "../../../productSwiper/ProductSwiper";
import { datelocambio } from "../../../../utilityFunctions";
import UserInfo from "../../../userInfo/UserInfo";
import { v1AuthAxios } from "../../../../utility/axios";

const Transaction = ({ id }) => {
  const initialState = {
    id: NaN,
    user: {},
    kindProposal: "Placeholder",
    publication: {},
    tag_change: {},
    publication_interested: {},
    rejected: true,
    textStatus: { normal: "Place", highlight: "holder" },
  };

  const [transactionData, setTransactionData] = useState(null);
  const [transactionElements, setTransactionElements] = useState(initialState);
  const [loading, setLoading] = useState(true);

  const getTransactionElements = () => {
    const isCurrent = checkWithCurrentUser(transactionData.owner_id);

    setTransactionElements({
      id: transactionData.id,
      user: getUser(transactionData, isCurrent),
      kindProposal: isCurrent ? "Propuesta Recibida" : "Propuesta Enviada",
      publicationPictures: transactionData.publication.pictures,
      publicationInterestedPictures: transactionData.tag_change
        ? [
            "//storage.googleapis.com/locambio-admin/empty_states/writen_proposal0.jpg",
          ]
        : [transactionData.publication_interested?.picture], // TODO update to show only one picture
      rejected: transactionData.status === "rejected",
      textStatus: {
        normal: "Cambio",
        highlight:
          transactionData.status === "rejected" ? "cancelado" : "exitoso",
      },
      transactionDate: datelocambio(transactionData.created_at),
    });
  };

  useEffect(() => {
    fetchTransaccion();
  }, []);

  const fetchTransaccion = async () => {
    setLoading(true);
    await v1AuthAxios
      .get(`${config.URL_API}/transactions/${id}`)
      .then((response) => {
        setTransactionData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (transactionData) getTransactionElements();
  }, [transactionData]);

  const getUser = (transactionData, isCurrent) => {
    let user;
    if (isCurrent) {
      user = (transactionData?.tag_change?.owner || transactionData?.publication_interested?.owner)
    } else {
      user = (transactionData.publication.owner)
    }
    return user;
  };

  const {
    user,
    rejected,
    textStatus,
    publicationPictures,
    publicationInterestedPictures,
    transactionDate,
  } = transactionElements;
  const arrowSource = rejected
    ? "//storage.googleapis.com/locambio-admin/pages/history/exchance-g0.png"
    : "//storage.googleapis.com/locambio-admin/pages/history/exchange-c0.png";

  let skeletonClass = loading ? "bp3-skeleton" : "";

  return (
    <div className="transaction__wrapper">
      <section className="transaction__date">{transactionDate}</section>
      <section >
        {user && (
          <UserInfo user={user} size={{ width: "14", height: "14" }}></UserInfo>
        )}
      </section>
      <section className="transaction__publications">
        <div
          className={`transaction__publications-image-wrapper ${skeletonClass}`}
        >
          <ProductSwiper pictures={publicationPictures} />
        </div>
        <div
          className={`transaction__publications-image-wrapper ${skeletonClass}`}
        >
          <img
            src={arrowSource}
            className="transaction__publications-image-wrapper--separator"
            alt="Place holder"
          />
        </div>
        <div
          className={`transaction__publications-image-wrapper ${skeletonClass}`}
        >
          <ProductSwiper pictures={publicationInterestedPictures} />
        </div>
      </section>
      <section className={`transaction__status ${skeletonClass}`}>
        <p>
          {textStatus.normal}{" "}
          <span className={rejected ? "rejected" : "succesful"}>
            {textStatus.highlight}
          </span>
        </p>
      </section>
    </div>
  );
};

export default Transaction;
