import React from 'react';
import './styles/ActivityBanner.scss';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { config } from '../../../../system/Config';
import { getHeader } from '../../../../system/Sesion';

const ActivityBanner = ({ id, url_banner, url_image_big, url_image_small, title }) => {

  const isLargerThanMobile = useMediaQuery({ minWidth: 576 });

  const handleClick = async () => {
    try {
      await axios.get(`${config.URL_API}/increase_click_banner/${id}`, {
        headers: getHeader(),
      });
    } catch(err) {
      console.log('error', err);
    }
  };

  return (
    <section className="activity_banner">
      <a
        href={url_banner}
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt={title}
          src={isLargerThanMobile ? url_image_big : url_image_small}
        />
      </a>
    </section>
  );
};

export default ActivityBanner;
