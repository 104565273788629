const objectToFormData = (object, formDataReference = null) => {
  const formData = formDataReference || new FormData();

  // TODO: Improve conditions
  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((arrayValue) => formData.append(`${key}[]`, arrayValue));
    } else if (value && value.constructor === Object) {
      objectToFormData(value, formData);
    } else {
      formData.append(key, value);
    }
  });
  return formData;
};

export {
  objectToFormData,
};