const STATUSES = {
  verifiedFullCredits: 'verified_full_credits',
  verifiedPartialCredits: 'verified_partial_credits',
  verifiedWithoutCredits: 'verified_without_credits',
  noVerifiedWithCredits: 'no_verified_with_credits',
  noVerifiedWithoutCredits: 'no_verified_without_credits',
};

const baseCreditData = {
  status: STATUSES.noVerifiedWithoutCredits,
  product_credits: 0,
  service_credits: 0,
};

const maxProductCredits = 40;
const maxServiceCredits = 20;

const creditMessage = (value, maxValue) => `${value}/${maxValue} `;
const progressBarValue = (value, maxValue) => value / maxValue;

export {
  STATUSES,
  creditMessage,
  baseCreditData,
  progressBarValue,
  maxServiceCredits,
  maxProductCredits,
};