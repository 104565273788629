import React from "react";
import Card from "../../components/card/Card";
import "./ProfileUserData.scss";
import UserName from "../userName/UserName";
import RankingUserStar from "../RankingStar";
import { Divider } from "@blueprintjs/core";
import ProfileStatsInfo from "../profileStatsInfo/ProfileStatsInfo";
import { currencyFormat, dateMonthString } from "../../utilityFunctions";

const ProfileUserData = (props) => {
  const { user, handleSetTab, currentTab } = { ...props };
  const owner = user?.isOwner;
  const all = !!user;

  const canShow = {
    userName: all,
    ranking: all,
    state: all,
    memberSince: all,
    balance: owner,
    publications: all,
    divider: owner,
    menu: owner,
  };

  const selected = (tab) => {
    if (currentTab === tab) {
      return 'selected'
    }
  };

  const menuElements = {
    profile: {
      props: {
        className: `profile-user-data__menu__tab ${selected('profile')}`,
        children: 'Datos Personales',
        onClick: () => {handleSetTab('profile')}
      }
    },
    publications: {
      props: {
        className: `profile-user-data__menu__tab ${selected('publications')}`,
        children: 'Mis Publicaciones',
        onClick: () => {handleSetTab('publications')}
      }
    },
    promotables: {
      props: {
        className: `profile-user-data__menu__tab ${selected('campaigns')} ${selected('promotables')}`,
        children: 'Promocionadas',
        onClick: () => {handleSetTab('promotables')}
      }
    },
    account: {
      props: {
        className: `profile-user-data__menu__tab ${selected('account')}`,
        children: 'Ajustes',
        onClick: () => {handleSetTab('account')}
      }
    },
  };

  function createMenuElement(json) {
    const { type, props } = json;
    const { children, onClick, ...restProps } = props;
  
    const processedChildren = Array.isArray(children)
      ? children.map(createMenuElement)
      : children;
  
    const elementProps = {
      ...restProps,
      onClick: onClick || undefined
    };
  
    return React.createElement('div', elementProps, processedChildren);
  }

  const balance = () => (
    <div className="profile-user-data__user-info__left__balance">
      <span className="selected">Saldo: </span>
      <span className="balanceMoney">
        {localStorage.getItem("currency")}
        {user?.balance
          ? currencyFormat(user?.balance)
          : "0,00"}
      </span>
    </div>
  );

  const publications = () => (
    <div
      className={`profile-user-data__user-info__right ${
        !owner && "profile-user-data__user-info__right--upper"
      } `}
    >
      <div
        className={`
          profile-user-data__user-info__right__publications 
          ${!owner && "profile-user-data__user-info__right--upper"}
        `}
      >
        <ProfileStatsInfo user={user} />
      </div>
    </div>
  );

  const menu = () => (
    <section className="profile-user-data__menu">
      {Object.values(menuElements).map((element, index) => (
        <React.Fragment key={index}>
          {createMenuElement(element)}
        </React.Fragment>
      ))}
    </section>
  );

  const state = () => (
    <div className="profile-user-data__user-info__left__state">{user?.state}</div>
  );

  const memberSince = () => (
    <div className="profile-user-data__user-info__left__member-since light">
      Miembro desde {dateMonthString(user?.created_at)}
    </div>
  );

  const ranking = () => <RankingUserStar user={user} />;

  const userName = () => <UserName user={user} htmlTag="h3" />;

  const divider = () => <Divider />;

  const userData = () => (
    <section className="profile-user-data__user-info">
      <div className="profile-user-data__user-info__left">
        {components["userName"]}
        {components["ranking"]}
        {components["state"]}
        {components["memberSince"]}
        {components["balance"]}
      </div>
      {components["publications"]}
    </section>
  );

  const components = {
    userName: canShow["userName"] && userName(),
    ranking: canShow["ranking"] && ranking(),
    state: canShow["state"] && state(),
    memberSince: canShow["memberSince"] && memberSince(),
    balance: canShow["balance"] && balance(),
    publications: canShow["publications"] && publications(),
    divider: canShow["divider"] && divider(),
    menu: canShow["menu"] && menu(),
  };

  return (
    <Card
      className={`profile-user-data ${
        !user?.isOwner && "profile-user-data--large"
      }`}
    >
      {userData()}
      {components["divider"]}
      {components["menu"]}
    </Card>
  );
};

export default ProfileUserData;
