import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PanelStack2, Icon } from '@blueprintjs/core';

import SearchCategory from './components/searchCategory';
import Spinner from '../spinner';

import './PanelStack.scss';

const Panel = ({
  data,
  setPanelTitle,
  currentPanelTitle,
  functionality,
  ...props
}) => {
  const openNewPanel = (data, father = '') => {
    props.openPanel({
      props: {
        data: data,
        setPanelTitle: setPanelTitle,
        currentPanelTitle: currentPanelTitle,
        functionality: functionality,
      },
      renderPanel: Panel,
      title: father ? <p>{father}</p> : <p>currentPanelTitle</p>,
    });
  };

  const displayNewPanel = (elem, from) => {
    setPanelTitle(elem.name);
    openNewPanel(elem.categories, from);
  };

  return data.map((elem) => {
    return (
      <div className="panel-stack__item">
        <div onClick={() => functionality(elem)}>
          {elem?.from && (
            <div className="panel-stack__father">
              <p className="panel-stack__father-item">{elem.from}</p>
            </div>
          )}
          {elem.name}{' '}
        </div>
        {elem?.categories?.length > 0 && (
          <Icon
            className={
              elem.from ? 'panel-stack__icon has__father' : 'panel-stack__icon'
            }
            icon="chevron-right"
            iconSize={18}
            onClick={() => displayNewPanel(elem, elem.name)}
          />
        )}
      </div>
    );
  });
};

export default ({ functionality, categoryFilter = '' }) => {
  const { all, loading } = useSelector(({ categories }) => categories);
  const [dataArray, setDataArray] = useState(all);
  const [panelTitle, setPanelTitle] = useState('Categorías');
  const [currentPanelStack, setCurrentPanelStack] = useState([
    {
      props: {
        panelNumber: 1,
        data: dataArray,
        setPanelTitle: setPanelTitle,
        functionality: functionality,
        clicked: false,
      },
      renderPanel: Panel,
      title: '',
    },
  ]);
  useEffect(() => {
    setCurrentPanelStack([
      {
        props: {
          panelNumber: 1,
          data: dataArray,
          setPanelTitle: setPanelTitle,
          functionality: functionality,
          clicked: false,
        },
        renderPanel: Panel,
        title: '',
      },
    ]);
  }, [all, dataArray]);
  
  useEffect(() => {
    let toUse = [...all];
    if (categoryFilter != '') {
      toUse = all.filter((elem) => {
        return elem.name === categoryFilter;
      })[0]?.categories;
    }else {
      toUse = all.filter((elem) => {
        return elem.name !== 'Servicios';
      });
    }
    if (toUse === undefined) return
    if (dataArray.length != toUse.length) setDataArray(toUse);
  }, [categoryFilter, all]);

  const addToPanelStack = useCallback(
    (newPanel) => setCurrentPanelStack((stack) => [...stack, newPanel]),
    []
  );

  const removeFromPanelStack = useCallback(() => {
    setPanelTitle('Categorías');
    setCurrentPanelStack((stack) => stack.slice(0, -1));
  }, []);

  const handleSearch = (search) => {
    if (!search?.trim()) return setDataArray(dataArray);
    const searchedData = [];
    searchData({ search, searchedData });
    if (searchedData.flat().length !== 0) setDataArray(searchedData.flat());
  };

  const searchData = ({
    search,
    searchedData,
    array = dataArray,
    deep = 0,
    from = '',
  }) => {
    const regExp = new RegExp(search, 'i');
    searchedData[deep] = searchedData[deep] || [];
    array.forEach((element) => {
      if (regExp.test(element['name']))
        searchedData[deep].push({ ...element, from: element?.from || from });
      if (!element['categories'] || !element['categories'].length) return;
      searchData({
        search,
        searchedData,
        array: element['categories'],
        deep: deep + 1,
        from: element['name'],
      });
    });
  };

  return (
    <>
      <div className="panel-stack">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <SearchCategory handleSearch={handleSearch} />
            <PanelStack2
              onOpen={addToPanelStack}
              onClose={removeFromPanelStack}
              renderActivePanelOnly
              showPanelHeader
              stack={currentPanelStack}
              title={''}
              className="data-list"
            />
          </>
        )}
      </div>
    </>
  );
};
