import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from './../store/actions/index';
import { withRouter } from 'react-router-dom';
import { Toast } from '../utility/toast';

class Logout extends Component {

  componentDidMount() {
    this.props.onLogout()
    this.props.history.push(`/login`)
    Toast.show({ message: 'Vuelve pronto! Te esperamos.' });
  }

  render() {

    return(
      <div></div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout()),
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Logout));