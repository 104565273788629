import { useDispatch } from 'react-redux';
import React, { useCallback, useState, useEffect, } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useWatch} from 'react-hook-form';
import { Icon, Button, Divider, FormGroup } from '@blueprintjs/core';
import ModalCrop from '../ModalCrop';
import PropTypes from 'prop-types';
import './CustomDropZone.scss';
import BlurImage from '../blurImage/BlurImage';
export default function CustomDropZone({ name, control, label, setValue, next, setNext, setFile, base, resetField, filesIndexes=[], scale, withThumb }) {
  const [cropping, setCropping] = useState(false);
  const [imgCropping, setImgCropping] = useState(null);
  const [addNewFile, setAddNewFile] = useState(true);
  const [cropCurrent, setCropCurrent] = useState(null);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);

  const cancelCrop = () => {    
    setCropping(false);
    setImgCropping(null);
  };

  useEffect(() => {
    if (images.length > 0) {
      setCropping(true);
    }
  }, [images]);

  useEffect(() => {
    if (images.length > 0) {
      setImgCropping(URL.createObjectURL(images[0]));
      if (!cropping) { let updateImages = [...images]
      updateImages.shift();
      setImages(updateImages)
    }
    }
  }, [cropping]);


  const onRemove = (e) => { //todo
    e.stopPropagation()
    setImage(null);
    const updateFiles = [...filesIndexes]
    updateFiles.pop()
    if (setFile) {setFile([...updateFiles])}
    resetField(name)
  }

  const onDrop = (files) => {
    let error 
    const acceptedFiles =
      'image/jpeg, image/png, image/gif, image/mba, image/jpg';
    if (acceptedFiles.includes(files[0].type)) {
      if (files.length === 1) {
        setCropping(true);
        setImgCropping(URL.createObjectURL(files[0]));
      }
    } else {error = 'File not accepted'}
    if (files.length > 1 && Array.isArray(files)) {
      let updateImages = [...images]
      files.map((current, index) => {
        if (acceptedFiles.includes(current.type)) {
          updateImages.push(current)
        } else {error = 'File not accepted'}
        setImages(updateImages)
      })
    }
    if (error) console.error(error) 
  };

  const onCropComplete = async (canvas) => {
    const image = await canvas;    
    const updateFiles =   Array.isArray(filesIndexes) ? [...filesIndexes, next] : next
    if (setFile) { setFile(updateFiles) }

    let url;
    if (Array.isArray(canvas)) {
      const avatar = await canvas[0];
      const thumb = await canvas[1];
      url = URL.createObjectURL(avatar);
      setValue(name, [{url: url, image: avatar }, {image: thumb}]);
    } else {
      url = URL.createObjectURL(image);
      setValue(name, {url: url, image: image});
    }

    setCropping(false);
    setImgCropping(null);
    if (!setFile) setImage(url);
  };

  const modalCrop = <ModalCrop
        isOpen={cropping}
        img={imgCropping}
        handleComplete={onCropComplete}
        handleCancel={cancelCrop}
        scale={scale}
        withThumb={withThumb}
      />

  

  return (
    <>
      {modalCrop}
      <FormGroup
        label={label}
        labelFor={name}
        className="inputLabel innerMargin"
      >
        <Controller
          render={({ field: { onChange } }) => (
            <Dropzone
              onChange={async (e) => {
                const image = await onDrop(e.target.files);
                onChange(image);
              }}
              toCrop={onDrop}
              onRemove={onRemove}
              setAddNewFile={setAddNewFile}
              image={image}
            />
          )}
          name={name}
          control={control}
        />
      </FormGroup>
    </>
  );
}

const Dropzone = ({ onChange, toCrop, image, onRemove, setAddNewFile, ...rest }) => {
  if (image) setAddNewFile(false)
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {toCrop(acceptedFiles)}, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()}>
      <input {...getInputProps({ onChange })} />
      <div className="dropzone relative">
        {image ? (
          <div>
            <Button onClick={onRemove} className={'bp3-intent-primary-btnLC BtnLCPrimary mt-2 ml-2 absolute'}><Icon icon={'trash'} /></Button>
            <BlurImage className="blur-image" image={image} title="user photo"/>
          </div>
        ) : (
          <>
            <Icon icon="plus" className="purple mr-0" iconSize={25} />
            <p className="purple biggerText bolder">Subir Imagen</p>
            <Divider />
            <p>
              Arrastra un archivo o{' '}
              <span className="purple bolder"> presiona aquí</span> para cargar
              una imagen
            </p>
          </>
        )}
      </div>
    </div>
  );
};

CustomDropZone.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  setValue: PropTypes.func,
};
