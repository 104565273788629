import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import ModalCrop from '../../../../components/ModalCrop';

const Attachment = ({ accept, handler, children }) => {
  const [attachment, setAttachment] = useState(null);

  const { getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: accept,
    multiple: false,
    onDropAccepted: (acceptedFiles) => {
      const objectURL = URL.createObjectURL(acceptedFiles[0])
      setAttachment(objectURL);
    },
  });

  return (
    <>
      <input {...getInputProps()} />
      {children?.({ open, attachment })}
      {
        attachment && (
          <ModalCrop
            isOpen={Boolean(attachment)}
            img={attachment}
            handleComplete={(blobPromise) => {
              blobPromise.then((blob) => {
                setAttachment(null);
                handler(blob);
              })
            }}
            handleCancel={() => {
              setAttachment(null);
            }}
          />
        )
      }
    </>
  )
}

Attachment.propTypes = {
  accept: PropTypes.string,
  handler: PropTypes.func.isRequired,
}

Attachment.defaultProps = {
  accept: '*/*',
}

export default Attachment;
