import React, { useState, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import Reaptcha from "reaptcha";
import { config } from "../../system/Config";
import { Toast } from "../../utility/toast";
import PropTypes from "prop-types";
import axios from "axios";
import { getHeader } from "../../system/Sesion";
import history from "../../utility/history";
import { authAxios } from "../../utility/axios";

const Form = ({
  children,
  url,
  verifiData,
  hasCaptcha,
  successMessage,
  successUtilityFunction,
  header = false,
  redirect = "",
  setLoadingButton,
  isUpdate,
  resetFormOnSuccess
}) => {
  const regularExpressions = {
    email: new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    dni: new RegExp(/^[0-9]{6,8}$/),
    phone: new RegExp(/^[0-9]{7}$/),
    upperCase: new RegExp("[A-Z]"),
    special: new RegExp(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/),
    number: new RegExp(/\d/),
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    resetField,
    reset,
  } = useForm({ mode: "onChange" });
  const [stateUrl, setStateUrl] = useState(url);
  const [verifyCaptcha, setVerifyCaptcha] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const onVerify = useCallback(() => setVerifyCaptcha(true), []);

  const onErrorCaptcha = () => {
    Toast.show({
      message: "Por favor verifica tu conexion a Internet",
      intent: "warning",
      icon: "error",
    });
  };

  const onExpireCaptcha = () => {
    setVerifyCaptcha(false);
  };

  const memoCaptcha = useMemo(() => {
    return (
      <Reaptcha
        className={`${errorCaptcha && "captchaError"}`}
        sitekey={config.KEY_CAPTCHA}
        onVerify={onVerify}
        onExpire={onExpireCaptcha}
        onError={onErrorCaptcha}
      />
    );
  }, [onVerify, errorCaptcha]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (hasCaptcha && !verifyCaptcha) {
      Toast.show({
        message: "Debe completar el Captcha",
        intent: "warning",
        icon: "error",
      });
      return;
    }
    if (setLoadingButton !== undefined) {
      setLoadingButton(true);
    }
    const formatedData = await verifiData(data);
    if (formatedData.error)
      return Toast.show({
        message: formatedData.error,
        intent: "warning",
        icon: "error",
      });
    try {
      let request;
      if (!header) {
        request = await axios.post(stateUrl, formatedData);
      } else {
        request = await (isUpdate ?
          authAxios.put(stateUrl, formatedData) :
          authAxios.post(stateUrl, formatedData)
        )
      }
      if (request.status < 300) {
        if (successMessage) {
          Toast.show({
            message: successMessage,
            intent: "success",
            icon: "saved",
          });
        }
        if (successUtilityFunction) successUtilityFunction(request)
        if (redirect) history.push(redirect);
        if (resetFormOnSuccess) reset();
      } else {
        Toast.show({
          message: "Lo sentimos, hubo un error",
          intent: "warning",
          icon: "error",
        });
      }
    } catch (err) {
      if (err?.response?.status === 500 && err?.response?.data) {
        if (Array.isArray(err.response.data.errors)) {
          err.response.data.errors.map((error) => {
            Toast.show({
              message: error,
              intent: "warning",
              icon: "error",
            });
          });
        } else {
          Toast.show({
            message: "Lo sentimos, hubo un error",
            intent: "warning",
            icon: "error",
          });
        }
      } else {
        Toast.show({
          message: "Lo sentimos, hubo un error",
          intent: "warning",
          icon: "error",
        });
      }
    }
    if (setLoadingButton !== undefined) {
      setLoadingButton(false);
    }
  };

  const params = {
    register,
    watch,
    errors,
    control,
    memoCaptcha,
    setValue,
    resetField,
    regularExpressions,
  };

  return (
    <form className="fullWidth" onSubmit={handleSubmit(onSubmit)}>
      {children(params)}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.element,
  url: PropTypes.string,
  verifiData: PropTypes.func,
  hasCaptcha: PropTypes.bool,
  successMessage: PropTypes.string,
  header: PropTypes.bool,
  redirect: PropTypes.string,
  resetFormOnSuccess: PropTypes.bool
};

export default Form;
