import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { config } from './../system/Config';
import axios from 'axios';
import { Spinner } from "@blueprintjs/core";
import { Toast } from '../utility/toast';
import message from './chat/components/message';

class ConfirmateUser extends Component {

  componentDidMount() {
    this.validateUser();
  }

  showToast(message, intent, icon) {
    Toast.show({
      message: message,
      intent: intent,
      icon: icon,
    })
  }

  async validateUser() {
    const token = this.props.match.params.token;
    const api_path = `${config.URL_API}/active_user/${token}`
    await axios.get(api_path)
      .then((data) => {
        if (data.data.response) {
          // this.showToast("Usuario verificado correctamente. Puede iniciar sesión.", "success", "saved");
          this.showToast("Correo confirmado. Ya puedes ingresar a su cuenta", "success", "saved");
          this.props.history.push(`/login`)
        } else {
          this.showToast("No se encontró el usuario", "warning", "error");
          this.props.history.push(`/`)
        }
      })
      .catch((error) => {
        this.showToast("Lo sentimos, hubo un error. Comuníquese con nosotros.", "danger", "warning-sing");
        this.props.history.push(`/`)
      })
  }

  render() {
    return (
      <Spinner className={'marginTop-1em marginBottom-1em'} size={'32'} />
    )
  }
}

export default withRouter(ConfirmateUser);