import React, { useEffect, useState } from "react";

import "./PaymentResumeModal.scss";
import { Divider, Tab, Tabs } from "@blueprintjs/core";
import { useGenericModal } from "../../hooks/useGenericModal/useGenericModal";
import PaymentSuccessModal from "../paymentSuccessModal/PaymentSuccessModal";
import { fetchGetProforma } from "../../services/locambioPromos";
import Loader from "../loader/Loader";
import { v1AuthAxios } from "../../utility/axios";
import CreditPaymentSuccessModal from "../../pages/campaigns/popups/creditPaymentSuccessModal/CreditPaymentSuccessModal";
import { products } from "../../const";
import { Toast } from "../../utility/toast";
import { useDispatch } from "react-redux";
import { newNotification } from '../../store/actions';

const { PRODUCTS } = products;

const PaymentResumeModal = (props) => {
  const { buildModal, closeModal } = useGenericModal();
  const currency = localStorage.getItem("currency"); //TODO Change currency by local currency (Bs)
  const [activeTabId, setActiveTabId] = useState("paymentGateway");
  const [loading, setLoading] = useState();
  const { locambio_product_attributes, currentPlan } = props;
  const {
    id,
    name,
    tax_units,
    payment_review,
    applicable_taxes_keys,
    available_balance,
    price,
    productable_type,
  } = props.currentPlan;
  const [totalTaxes, setTotalTaxes] = useState();
  const [dataSend, setDataSend] = useState(false);
  const dispatch = useDispatch();
  
  const paymentData = {
    locambio_product_attributes: locambio_product_attributes,
    locambio_product_id: id,
    payment_review,
    applicable_taxes_keys: applicable_taxes_keys,
  };
  const creditData = {
    locambio_product_attributes: locambio_product_attributes,
    locambio_product_id: id,
  };
  const hasCredit = (available_balance >= price);

  const creditPaymentSuccessInfo = {
    [PRODUCTS.locambioSeguro]: {
      title: "Locambio Seguro",
      subTitle: "Solicitud enviada exitosamente",
      descriptionText: 'En el botón ',
      boldText: '"Ver Solicitud LCS" ',
      complementText: "podrás ver los detalles de la reservación.",
    },
    [PRODUCTS.locambioPromo]: {
      title: "Promociones Activas",
      subTitle: "Promoción activada exitosamente",
      linkTo: "/profile?tab=campaigns",
      descriptionText: "Ve a la pestaña ",
      complementText: "para hacerle seguimiento",
    },
  };

  const successCreditsModals = {
    content: (
      <CreditPaymentSuccessModal {...creditPaymentSuccessInfo[productable_type]} />
    ),
    actions: [
      {
        intent: "accent",
        text: "Entendido",
        onClick: () => closeModal(),
      },
    ],
    isOpen: true,
  };

  const securityChangeSuccessMessage = () => {
    Toast.show({
      message: "Se ha generado una Solicitud de LoCambioSeguro",
      intent: "success",
      icon: "saved",
    });
  };

  const securityChangeErrorMessage = () => {
    Toast.show({
      message: "Lo sentimos hubo un error",
      intent: "warning",
      icon: "error",
    });
  };

  const fetchCreateProduct = async () => {
    setLoading(true);
    try {
      await v1AuthAxios.post("/locambio_products", creditData);
      dispatch(newNotification({notification: {type_notification_id: "payment_with_credit"}}));
      // TODO: remove the previous line when the credit notifications have been created 
      securityChangeSuccessMessage();
      buildModal(successCreditsModals);
    } catch (error) {
      securityChangeErrorMessage();
    } finally {
      setLoading(false);
    }
  };

  const creditsResume = {
    content: (
      <PaymentResumeModal
        currentPlan={currentPlan}
        locambio_product_attributes={locambio_product_attributes}
        available_balance={available_balance}
      />
    ),
    actions: [
      {
        intent: "accent",
        text: "Entendido",
        onClick: () => fetchCreateProduct(),
      },
    ],
    isOpen: true,
    canOutsideClickClose: false
  };

  const paymentResume = {
    content: (
      <PaymentResumeModal
        currentPlan={currentPlan}
        locambio_product_attributes={locambio_product_attributes}
      />
    ),
    actions: [
      {
        intent: "accent",
        text: "Ir a pagar",
        onClick: () =>
          fetchGetProforma(paymentData, setLoading, closeModal, setDataSend),
      },
    ],
    isOpen: true,
    canOutsideClickClose: false
  };

  const paymentInProcess = {
    content: <PaymentSuccessModal />,
    actions: [
      {
        intent: "accent",
        text: "Cerrar",
        onClick: () => closeModal(),
      },
    ],
    isOpen: true,
    className: "payment-success-modal",
    canOutsideClickClose: false
  };

  const paymentCredits = (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h4 className="payment-resume-modal__title">
            Resumen de la transacción
          </h4>
          <div className="payment-resume-modal__details">
            <div className="div1">Item:</div>
            <div className="div2">{name}</div>
            <div className="div3">Crédito a debitar</div>
            <div className="div4">{price}</div>
            <div className="div7">Crédito disponible:</div>
            <div className="div8">{available_balance}</div>
          </div>
          <Divider />
          <div className="payment-resume-modal__total">
            <span className="payment-resume-modal__total--remaining">
              (Crédito restante){"   "}
            </span>
            <span className="payment-resume-modal__total--amount">
              {available_balance - price}
            </span>
          </div>
        </div>
      )}
    </>
  );

  const paymentGateway = (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h4 className="payment-resume-modal__title">
            Resumen de la transacción
          </h4>
          <div className="payment-resume-modal__details">
            <div className="div1">Item:</div>
            <div className="div2">{name}</div>
            <div className="div3">Precio Base:</div>
            <div className="div4">{payment_review?.sub_total} Bs.</div>
            <div className="div5">
              {totalTaxes?.map((tax) => {
                const [key, value] = Object.entries(tax)[0];
                return (
                  <div key={key}>
                    <span className="div5__tax">
                      ({`${key.toUpperCase()}`})
                    </span>{" "}
                    {`16${tax_units && tax_units[key]}`}{" "}
                    {/* //TODO: get iva value from backend*/}
                  </div>
                );
              })}
            </div>
          </div>
          <Divider />
          <div className="payment-resume-modal__total">
            {`${payment_review?.amount} Bs.`}{" "}
            {/* //TODO Change currency by local currency (Bs) */}
          </div>
        </div>
      )}
    </>
  );

  useEffect(() => {
    if (hasCredit) {
      setActiveTabId("paymentCredits");
    }
  }, [hasCredit]);

  useEffect(() => {
    buildModal(
      activeTabId === "paymentCredits" ? creditsResume : paymentResume
    );
  }, [activeTabId]);

  useEffect(() => {
    const results = Object.keys(tax_units)
      .filter((key) => key in payment_review)
      .map((key) => ({ [key]: payment_review[key] }));
    setTotalTaxes(results);
  }, []);

  useEffect(() => {
    if (dataSend) {
      buildModal(paymentInProcess);
    }
  }, [dataSend]);

  return (
    <section className="payment-resume-modal">
      <h4 className="payment-resume-modal__title">
        Seleccione el Método de pago
      </h4>
      <Tabs
        id="TabsExample"
        onChange={setActiveTabId}
        selectedTabId={activeTabId}
      >
        <Tab
          id="paymentCredits"
          className="tab"
          title="Pagar con Créditos"
          panel={paymentCredits}
          style={{ width: "50%" }}
          disabled={!hasCredit}
        />
        <Tab
          id="paymentGateway"
          className="tab"
          title="Pasarela de pago"
          panel={paymentGateway}
          style={{ width: "50%" }}
        />
      </Tabs>
    </section>
  );
};

export default PaymentResumeModal;
