import React, { useEffect } from "react";

import "./PaymentSuccessModal.scss";
import { Callout, Icon } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { useGenericModal } from "../../hooks/useGenericModal/useGenericModal";

const PaymentSuccessModal = () => {
  const { closeModal } = useGenericModal();
  const { notification } = useSelector(({ newNotification }) => newNotification);

  useEffect(() => {
    if (notification?.type_notification_id === "payment_gateway_status") {
      closeModal();
    }
  }, [notification]);

  return (
    <div className="payment-success-modal">
      <Callout>
        <p className="text-center text-green mb-8">
          <Icon iconSize="48" icon="tick-circle" />
        </p>
        <h2 className="text-center text-green-darker mb-0">Ventana de Pago</h2>
        <h3 className="text-center font-thin mt-12">
          Formalice su pago mediante la pasarela segura en la nueva ventana que
          se abrirá.
        </h3>
        <p className="payment-success-modal__note">
          <span className="payment-success-modal__note__bold">Nota: </span>
          Recibirá una notificación una vez se confirme su pago.
        </p>
      </Callout>
    </div>
  );
};

export default PaymentSuccessModal;
