import { useCallback, useEffect, useState } from 'react';
import cableApp from '../utility/cableApp';

export const useCable = function ({ channelOptions, channelFunctions }) {
  const [subscription, setSub] = useState(() => cableApp.subscriptions.create(
    channelOptions,
    channelFunctions,
  ));

  useEffect(() => () => subscription.unsubscribe?.(), []);

  const setSubscription = useCallback((sub) => {
    subscription.unsubscribe?.();
    setSub(sub);
  }, []);

  return [subscription, setSubscription];
};