import React from 'react';
import { Icon } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { updateModal } from '../../store/actions';
import { removeModal } from '../../store/actions/modals';

import './useGenericModal.scss';

export const useGenericModal = () => {
  const dispatch = useDispatch();

  // This function adds the header with the close button to all modals
  const modalConstructor = (content, className) => ({
    className: `generic-modal ${className}`,
    headerClassName: 'generic-modal__header',
    content: (
      <>
        <Icon className="generic-modal__close" iconSize="20" icon="cross" onClick={closeModal} />
        {content}
      </>
    ),
  });
  
  // send a dispatch from your component, can receive these props
  // title, content, actions, resource, onClose, className, headerClassName, 
  // bodyClassName, footerClassName, children, isOpen
  const buildModal = (props) => {
    const fullProps = {
      ...props,
      ...modalConstructor(props.content, props.className),
    };
    dispatch(updateModal(fullProps));
  };

  const closeModal = () => {
    dispatch(removeModal());
  };

  return { buildModal, closeModal };
};